import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useParams,NavLink ,useHistory} from 'react-router-dom';
import { MenuListWrapper} from '../Style/style';
import { Row, Col ,Button,message,notification,Popover,Popconfirm} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faList, faRefresh, faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';

import {getModelDetails,createModel,deleteModel,updateModel} from '../../redux/modelDetails/modelDetailActions';
import appActions from '../../redux/app/actions';
import {DetailMenu} from "../commonComponent/detailMenu";
import {ModelGenerals} from "./models/modelGenerals";
import {ModelSpecifications} from "./models/modelSpecifications";
import {ModelFiles} from "./models/modelFiles";
import {SpecModal} from "./models/specModal";
import {ProModal} from "./models/productionModal";
import {UploadModal} from "./models/uploadModal";
import {ModelProduction} from "./models/modelProduction";
import blank from '../../static/img/resyok.jpeg';
//const CoverSection = lazy(() => import('../overview/CoverSection'));

const ModelDetails = ( ) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { modelID } = useParams();
  const { messages } = useIntl();
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { modelDetail, modelDetailLoading,modelDetailStatus } = useSelector(state => state.modelDetails);
  const [dataSource, setDataSource] = React.useState(modelDetail);
  const {copyValues,copyForm} = useSelector(state => state.App);
  const { certificates } = useSelector(state => state.myCompany.myCompany);



  const { setCopyValues,setCopyForm } = appActions;
  const [state, setState] = React.useState({
    specModalIndex:null,
    proModalIndex:null,
    downloadModaIndex:null,
    specModalActive:null,
    proModalActive:null,
    downloadModalActive:null,
    uploadModalIndex:null,
    uploadModalActive:null,
    activePanel:1,
    modalactivepanel:1,
    collapsed:false,
    tabID:"general"
  });
  const MenuList =[
    {tabID:"general",MenuName:messages["model.info"]},
    {tabID:"specifications",MenuName:messages["model.specifications"]},
    {tabID:"operations",MenuName:messages["model.OperationComponent"]},
    {tabID:"files",MenuName:messages["model.downloads"]}
  ];
  const buttonList = [
    <div key={0}>
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>
      {dataSource && dataSource._id && 
      <Popconfirm
        title={messages["alert.deletequest"]}
        okText={messages["genel.yes"]}
        cancelText={messages["genel.no"]}
        onConfirm={()=>onDeleteModel(dataSource._id)}
      >
        <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faTrash} color="#ce1818"/>}  style={{marginRight:'10px'}}/>
      </Popconfirm>}
      <Popover content= {messages["button.save"]} placement="bottom">
        <Button shape="circle"  onClick={()=>onSaveModel()} size='large' icon={<FontAwesomeIcon icon={faSave} />} style={{marginRight:'10px'}}/>
      </Popover>           
      <Popover content= {messages["sidebar.models"]} placement="bottom">
        <NavLink key={4} to="/admin/models" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faList}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  const {collapsed,tabID} = state;
  const lang = locale === 'tr' ? 0 :1
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (modelID !=="new") {
      dispatch((getModelDetails({id:modelID})));
    }else{
      if(modelID ==="new"){
        setDataSource({
          model_sira:1,
          model_adi:["","-"],
          model_kodu:"",
          model_aciklama:["","-"],
          model_birimi:"Adet",
          model_kdv:"20",
          model_kategori:"",
          model_durumu:true,
          productiondetail:[],
          ModelFeatures:[],
          certificates:[],
          ModelFeatures:[],
          modelDownloads:[], 
          model_etiket:[],
        })
      }
    }
  }, []);

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    modelID !=="new" && setDataSource(modelDetail)
  }, [modelDetail]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(modelDetailStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [modelDetailStatus]);
  //******************************************************************************************************************************************************************/
  function gotoPage  (page)  {
    setState({
      ...state,
      tabID:page
    })
  }
  //******************************************************************************************************************************************************************/
  function convertBase64  (file)  {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  //******************************************************************************************************************************************************************/
  const removeSpecRow = (index) => {
    let temp = dataSource.ModelFeatures;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        ModelFeatures:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val) =>{
    if(name==="specModalIndex"){
      setState({
        ...state,
        [name]: val,
        specModalActive:true
      });
    }else if (name==="proModalIndex"){
      setState({
        ...state,
        [name]: val,
        proModalActive:true
      });
   
    }else if (name==="uploadModalIndex"){
      setState({
        ...state,
        [name]: val,
        uploadModalActive:true
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const closeModal = (name,val) => {
    if(name==="specModalIndex"){
      setState({
        ...state,
        [name]: val,
        specModalActive:false
      });
    }else if (name==="proModalIndex"){
      setState({
        ...state,
        [name]: val,
        proModalActive:false
      });
    }else if (name==="uploadModalIndex"){
      setState({
        ...state,
        [name]: val,
        uploadModalActive:false
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateSpecRow = (Field,eData,i) => {
    let tempdata = dataSource.ModelFeatures
    if(Field==="no"){
      tempdata[state.specModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.specModalIndex][Field][i] = eData;
    }
      
    setDataSource({
      ...dataSource,
      ModelFeatures:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  const addNewSpecRow = () => {
    let temp = dataSource.ModelFeatures;
    temp.push({
      no:temp.length ? temp.length +1 :1,
      label:["",""],
      feature: ["",""]
    })
    setDataSource({
      ...dataSource,
      ModelFeatures:temp
    });
    setState({
      ...state,
      specModalIndex: temp.length-1,
      specModalActive:true
    });
  }
  //******************************************************************************************************************************************************************/
  const modelSpecCopy=()=>{
    dispatch(setCopyValues(dataSource.ModelFeatures))
    dispatch(setCopyForm("modelSpec"))

  }
  //******************************************************************************************************************************************************************/
  const modelSpecPaste=()=>{
    if(copyValues &&  copyForm === "modelSpec"){
      setDataSource({
        ...dataSource,
        ModelFeatures : copyValues
      });
    }else{
        if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
    }
  }
 //******************************************************************************************************************************************************************/
  const onDeleteModel = (id) => {
    if(id){
      dispatch(deleteModel(id))
      .then(res=>{
        message.success(messages["alert.deletesuccess"],2);
        history.push('/admin/models')
      })
    }
  }
  //******************************************************************************************************************************************************************/
  const removeProRow = (index) => {
    let temp = dataSource.productiondetail;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        productiondetail:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    if(Field==="model_adi" || Field==="model_aciklama"){
      let temp = dataSource[Field];
      temp[lang]=eData;
      setDataSource({
        ...dataSource,
        [Field]:temp
      });
    }else{
      setDataSource({
        ...dataSource,
        [Field]:eData
      });
    }

  }
  //******************************************************************************************************************************************************************/
  const updateProRow = (Field,eData) => {
    let tempdata = dataSource.productiondetail
    tempdata[state.proModalIndex][Field] = eData;
    setDataSource({
      ...dataSource,
      productiondetail:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  const addNewProRow = () => {
    let temp = dataSource.productiondetail;
    temp.push({
      no:temp.length ? temp.length +1 :1,
      Operation:"",
      OperationDescription:"",
      OperationComponent:[]
    })
    setDataSource({
      ...dataSource,
      productiondetail:temp
    });
    setState({
      ...state,
      proModalIndex: temp.length-1,
      proModalActive:true
    });
  };
  //******************************************************************************************************************************************************************/
  const onSaveModel = () => {
    message.loading(messages["alert.updated"]);
    if(dataSource._id){
      dispatch(updateModel({...dataSource}))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      })
    }else{
      dispatch(createModel({...dataSource}, history))
      .then(res=>{
        message.success(messages["alert.createsuccess"], 2);
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const modelProductionCopy=()=>{
    dispatch(setCopyValues(dataSource.productiondetail))
    dispatch(setCopyForm("modelProduction"))

  }
  //******************************************************************************************************************************************************************/
  const modelProductionPaste=()=>{
    if(copyValues &&  copyForm === "modelProduction"){
      setDataSource({
        ...dataSource,
        productiondetail : copyValues
      });
    }else{
        if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
    }
  }
  //******************************************************************************************************************************************************************/
  const removeDownloadRow = (index) => {
    let temp = dataSource.modelDownloads;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        modelDownloads:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const addNewDownloadRow = () => {
    let temp = dataSource.modelDownloads;
    temp.push({
      file_descirpiption: ["",""],
      name:"",
      type:"",
      size:"",
      base64:""
    })
    setDataSource({
      ...dataSource,
      modelDownloads:temp
    });
    setState({
      ...state,
      uploadModalIndex: temp.length-1,
      uploadModalActive:true
    });

  };
  //******************************************************************************************************************************************************************/
  const updateImageRow= async (field,file)=>{
    let tempdata = dataSource;
    if(field==='file'){
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        message.error('Image must smaller than 4Mb!');
        return false;
      }
      const base64 = await convertBase64(file)
      let tempdata = dataSource
      tempdata.modelDownloads[state.uploadModalIndex].base64 = base64;
      tempdata.modelDownloads[state.uploadModalIndex].name=file.name;
      tempdata.modelDownloads[state.uploadModalIndex].size=file.size;
      tempdata.modelDownloads[state.uploadModalIndex].type=file.type;
    }else{
      tempdata.modelDownloads[state.uploadModalIndex].file_descirpiption[lang]=file;
    }
    setDataSource({
      ...dataSource,
      tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
 //console.log(state)
  return (
    
    <>
      <PageHeader
        ghost
        title={dataSource.model_adi ? dataSource.model_adi[lang]: "Yükleniyor"} 
        buttons={buttonList}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center"  gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
                <DetailMenu 
                  MenuList={MenuList} 
                  data0={null} 
                  data1={messages["model.kategori"+ dataSource.model_kategori]}
                  messages={messages} 
                  image={dataSource.imageData} 
                  imageField={null}
                  imageWidth={135}
                  tabID={tabID}
                  beforeUpload={null}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  gotoPage={gotoPage}
                  updateData={updateData}
                />   
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              {tabID === "general" ? 
                  <ModelGenerals
                    messages={messages}
                    lang={lang}
                    modelDetailLoading={modelDetailLoading}
                    model={dataSource}
                    certificates={certificates}
                    updateData={updateData}
                  />
                :tabID === "specifications" ? 
                  <ModelSpecifications
                    messages={messages}
                    modelDetailLoading={modelDetailLoading}
                    specifications={dataSource.ModelFeatures}
                    removeSpecRow={removeSpecRow}
                    toggleModal={toggleModal}
                    addNewSpecRow={addNewSpecRow}
                    modelSpecCopy={modelSpecCopy}
                    modelSpecPaste={modelSpecPaste}
                  />
                :tabID === "operations" ?
                  <ModelProduction
                    messages={messages}
                    modelDetailLoading={modelDetailLoading}
                    production={dataSource.productiondetail}
                    removeProRow={removeProRow}
                    toggleModal={toggleModal}
                    addNewProRow={addNewProRow}
                    modelProductionCopy={modelProductionCopy}
                    modelProductionPaste={modelProductionPaste}
                    onSaveModel={onSaveModel}
                  />
                  :tabID === "files" ?
                    <ModelFiles
                      messages={messages}
                      lang={lang}
                      loading={modelDetailLoading}
                      files={dataSource.modelDownloads}
                      onSaveModel={onSaveModel}
                      removeDownloadRow={removeDownloadRow}
                      addNewDownloadRow={addNewDownloadRow}
                      toggleModal={toggleModal}
                      
                    />
                  :null
                }
            </Col>
          </Row>
        </MenuListWrapper>
      </Main>
      {state.specModalActive && 
        <SpecModal 
          messages={messages}
          lang={lang}
          specification={state.specModalIndex !== null ? dataSource.ModelFeatures[state.specModalIndex]:null}
          specModalActive={state.specModalActive}
          updateSpecRow={updateSpecRow}
          closeModal={closeModal}
        />}
        {state.proModalActive && 
        <ProModal 
          messages={messages}
          lang={lang}
          production={state.proModalIndex !== null ? dataSource.productiondetail[state.proModalIndex]:null}
          proModalActive={state.proModalActive}
          updateProRow={updateProRow}
          closeModal={closeModal}
        />}
        {state.uploadModalActive && 
        <UploadModal 
          messages={messages}
          lang={lang}
          file={state.uploadModalIndex !== null ? dataSource.modelDownloads[state.uploadModalIndex]:null}
          uploadModalActive={state.uploadModalActive}
          closeModal={closeModal}
          updateImageRow={updateImageRow}
       
        />}
    </>
  );
}



export  {ModelDetails};
