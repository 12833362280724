import {
	PRODUCT_DETAILS_ACTION,
	PRODUCT_DETAILS_SUCCESS,
	PRODUCT_DETAILS_ERROR,
	CREATE_PRODUCT_ACTION,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_ERROR,
	UPDATE_PRODUCT_ACTION,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_FAILURE,
	DELETE_PRODUCT_ACTION,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_FAILURE
} from '../actions';

const INIT_STATE = {
	productDetail: {
		model_category:"",
		urun_sira:0,
		urun_adi:["",""], 
		urun_model:"",
		urun_kodu:"",
		urun_optik:"",
		urun_cct:"",
		urun_aku:"",
		urun_panel:"",
		urun_birimi:"",
		urun_kdv:0,
		applicationArea:"",
		description:"",
		product_active:1,
		stockSum:0,
	},
	productDetailLoading:false,
	productDetailStatus:null
};

const productDetails  = (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCT_DETAILS_ACTION: //GET OFFER DETAILS
			return { 
				...state, 
				productDetailLoading: true,
				productDetailStatus:null,
				productDetail:INIT_STATE.productDetail
			};
		case PRODUCT_DETAILS_SUCCESS:
            return { 
				...state,
				productDetailLoading: false,  
				productDetail: action.response,
				productDetailStatus:200
				};
		case PRODUCT_DETAILS_ERROR:
            return { 
				...state, 
				productDetailLoading: false ,
				productDetailStatus:action.response
			};	
		case CREATE_PRODUCT_ACTION: //CREATE OFFER
			return { 
				...state, 
				productDetailLoading: true,
				productDetailStatus:null
			};
		case CREATE_PRODUCT_SUCCESS:
			return { 
				...state, 
				productDetailLoading: false, 
				productDetail: action.response,
				productDetailStatus:200
			};
		case CREATE_PRODUCT_ERROR:
			return { 
				...state, 
				productDetailLoading: false,
				productDetail:null,
				productDetailStatus:action.response 
			};	
		case UPDATE_PRODUCT_ACTION: //UPDATE OFFER
			return { 
				...state, 
				productDetailLoading: true,
				productDetailStatus:null
			};
		case UPDATE_PRODUCT_SUCCESS:
			return { 
				...state, 
				productDetailLoading: false, 
				productDetail: action.response,
				productDetailStatus:200
			};
		case UPDATE_PRODUCT_FAILURE:
			return { 
				...state, 
				productDetailLoading: false,
				productDetailStatus:action.response 
			}; 
		case DELETE_PRODUCT_ACTION: //UPDATE OFFER
			return { 
				...state, 
				productDetailLoading: true, 
				productDetailStatus:null
			};
		case DELETE_PRODUCT_SUCCESS:
			return { 
				...state, 
				productDetailLoading: false,
				productDetailStatus:200
			};
		case DELETE_PRODUCT_FAILURE:
			return { 
				...state, 
				productDetailLoading: false,
				productDetailStatus:action.response 
			};
		default: return state;
	}
}
export default productDetails
