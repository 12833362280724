import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Dashboard from './dashboard';


import withAdminLayout from '../../layout/withAdminLayout';
import {CompanyProfile} from '../../pages/myCompany/CompanyDetail';
import {Users} from '../../pages/users/Users';
import {UserProfile} from '../../pages/users/UserDetail';
import {Projects} from '../../pages/projects/Projects';
import {LastCheckList} from '../../pages/lastCheck/lastCheckList';
import {LastCheckDetails} from '../../pages/lastCheck/lastCheckDetails';

import {ProjectDetails} from '../../pages/projects/ProjectDetails';
import {ProjectMaterialList} from '../../pages/projects/projectMaterialList';
import {ProjectMaterialListSum} from '../../pages/projects/projectMaterialListSummary';
import {Models} from '../../pages/models/models';
import {ModelDetails} from '../../pages/models/modelDetail';
import {Products} from '../../pages/products/products';

import {ProductDetails} from '../../pages/products/productDetail';
import {Materials} from '../../pages/materials/materials';
import {MaterialDetails} from '../../pages/materials/materialDetail';
import {Offers} from '../../pages/offers/offers';
import {OfferDetails} from '../../pages/offers/offerDetails';
import {Companies} from '../../pages/companies/companies';
import {CompanyDetails} from '../../pages/companies/companyDetails';
import {Buyings} from '../../pages/buyings/buyings';
import {BuyingDetails} from '../../pages/buyings/buyingDetails';

import {Employee} from '../../pages/employee/Employee';
import {EmployeeProfile} from '../../pages/employee/EmployeeDetail';

import {Unauthorized} from '../../pages/utiles/401';
import {NotFound} from '../../pages/utiles/404';
function Admin() {
  
const { path } = useRouteMatch();



  return (
    
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
       
        <Route path={`${path}/company`} component={CompanyProfile} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/userdetails/:userID`} component={UserProfile} />
        <Route path={`${path}/project`} component={Projects} />
        <Route path={`${path}/lastchecklist`} component={LastCheckList} />
        <Route path={`${path}/lastcheckdetail/:projectID`} component={LastCheckDetails} />

        <Route path={`${path}/projectdetails/:projectID`} component={ProjectDetails} />
        <Route path={`${path}/projectmateriallist`} component={ProjectMaterialList} />
        <Route path={`${path}/projectmateriallistsummary`} component={ProjectMaterialListSum} />

        <Route path={`${path}/models`} component={Models} />
        <Route path={`${path}/modeldetails/:modelID`} component={ModelDetails} />
        <Route path={`${path}/products`} component={Products} />
        <Route path={`${path}/productdetails/:productID`} component={ProductDetails} />
 
        <Route path={`${path}/materials`} component={Materials} />
        <Route path={`${path}/materialdetails/:materialID`} component={MaterialDetails} />

        <Route path={`${path}/offers`} component={Offers} />
        <Route path={`${path}/offerdetails/:offerID`} component={OfferDetails} />

        <Route path={`${path}/companies`} component={Companies} />
        <Route path={`${path}/companydetails/:companyID`} component={CompanyDetails} />
        <Route path={`${path}/buyings`} component={Buyings} />
        <Route path={`${path}/buyingdetails/:buyingID`} component={BuyingDetails} />
        <Route path={`${path}/employee`} component={Employee} />
        <Route path={`${path}/employeedetails/:employeeID`} component={EmployeeProfile} />

        <Route path={`${path}/401`} component={Unauthorized} />
        <Route path={`${path}/404`} component={NotFound} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Admin);
