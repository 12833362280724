import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

import { Cards } from '../../../../components/cards/frame/cards-frame';
import Heading from '../../../../components/heading/heading';

import { CheckboxGroup } from '../../../../components/checkbox/checkbox';
import { Sidebar, SidebarSingle } from '../../../Style/style';
const optionsBrand = [
  {
    label: (
      <>
        Güç <span className="brand-count">150 W</span>
      </>
    ),
    value: 'cup',
  },
  {
    label: (
      <>
        Güç <span className="brand-count">100 W</span>
      </>
    ),
    value: 'plate',
  },
  {
    label: (
      <>
        Güç <span className="brand-count">60 W</span>
      </>
    ),
    value: 'chair',
  },
  {
    label: (
      <>
        Güç <span className="brand-count">40 W</span>
      </>
    ),
    value: 'juice',
  },
];


const Filters = ({messages,loading,lang,categoryList,onChangeFilter,productsFilter}) => {

  return (
    <Sidebar>
      <Cards
        title={
          <span>
            <FeatherIcon icon="sliders" size={14} />
            {messages["product.pricelistcategory"]}
          </span>
        }
      >
{/*         <SidebarSingle style={{ marginBottom: 32 }}>
          <Heading as="h5">Price Range</Heading>
          <Slider max={1500} onChange={onChange} range defaultValues={[min, max]} />
          <p className="price-range-text">
            ${min} - ${max}
          </p>
        </SidebarSingle> */}
        <SidebarSingle style={{ marginBottom: 32 }}>
          <Heading as="h5">{messages["product.category"]}</Heading>

          <nav>
            <ul className="atbd-category-list">
   
              <li  className='active' >
                <NavLink onClick={() => onChangeFilter('Tümü')} to="#" activeClassName={'Tümü'===productsFilter ? 'active' : ''}>
                  <span className="category-count">{messages['genel.all']}</span>
                </NavLink>
              </li>
              {categoryList.map((categori,index)=>{
                return (
                  <li key={index}>
                    <NavLink onClick={() => onChangeFilter(categori.value)} to="#" activeClassName={categori.value===productsFilter ? 'active' : ''} >
                      <span className="category-count">{messages[categori.label]}</span>
                    </NavLink>
                  </li>
                )})}

            </ul>
          </nav>
        </SidebarSingle>

        <SidebarSingle style={{ marginBottom: 32 }}>
          <Heading as="h5">{messages["product.power"]}</Heading>
          <CheckboxGroup options={optionsBrand}  />

          <div className="sidebar-single__action">
            <NavLink className="btn-seeMore" to="#">
              See more
            </NavLink>
          </div>
        </SidebarSingle>

        {/* <SidebarSingle>
          <Heading as="h5">Ratings</Heading>
          <CheckboxGroup options={options}  />
        </SidebarSingle> */}
      </Cards>
    </Sidebar>
  );
}

export {Filters};
