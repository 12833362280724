
import React from 'react';
import { useSelector } from 'react-redux';
import {Row, Col, Modal,Button ,Form,Input,Radio,DatePicker} from 'antd';
import { FormWrapper } from '../../Style/style';

const ActivitesModal = ({messages,task,toggleModal,taskModalActive,onSaveNewTaskRow,updateNewTaskData}) => {
  const { taskDetailLoading } = useSelector((state) => state.taskDetails);
  const [form10] = Form.useForm();
  //const _task = form3.getFieldValue("task");
   const values = Form.useWatch([], form10);
   const [submittable, setSubmittable] = React.useState(false);
   React.useEffect(() => {
    form10.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);
  return (

      taskModalActive &&
      <Modal
        forceRender
        type='primary'
        title={messages["task.edit"]}
        open={taskModalActive}
        footer={
          <div key={0}>
            <Button size="large" type="white" onClick={() => toggleModal("taskModalActive",null)}  >
              {messages["button.cancel"]}
            </Button>
            <Button  size="large" type="primary" loading={taskDetailLoading} onClick={()=>onSaveNewTaskRow()} disabled={!submittable} >
              {messages["button.save"]}
            </Button>
          </div>
        }
        closable={false}
        width={610}
      >
        <FormWrapper  style={{height:'300px'}}>
          <Form form={form10} initialValues={task}>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.date"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="date" rules={[{ required:true}]} >
                  <DatePicker format="DD/MM/YYYY" onChange={(eData)=>updateNewTaskData("date",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["task.title"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="title" rules={[{ required:true}]} >
                  <Input onChange={(eData)=>updateNewTaskData("title",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["task.description"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="description"  >
                  <Input onChange={(eData)=>updateNewTaskData("description",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.qty"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="task_qty" rules={[{ required: true, message: 'Please input Product Qty!' }]} >
                  <Input type='number' decimalseparator=',' onChange={(eData)=>updateNewTaskData("task_qty",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["task.type"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="tasktype" rules={[{ required: true, message: 'Please input Product Qty!' }]} >
                    <Radio.Group value={task.tasktype} onChange={(eData)=>updateNewTaskData("tasktype",eData.target.value)}>
                      <Radio value={0}>{messages["task.taskType0"]}</Radio>
                      <Radio value={1}>{messages["task.taskType1"]}</Radio>
                      <Radio value={2}>{messages["task.taskType2"]}</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["task.importance"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="importance" rules={[{ required: true, message: 'Please input Product Qty!' }]} >
                    <Radio.Group value={task.importance} onChange={(eData)=>updateNewTaskData("importance",eData.target.value)}>
                      <Radio value={0}>{messages["task.importance0"]}</Radio>
                      <Radio value={1}>{messages["task.importance1"]}</Radio>
                      <Radio value={2}>{messages["task.importance2"]}</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Modal>

  );

}



export  {ActivitesModal};
