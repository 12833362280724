const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: ""
  }
  
  export  const currencyFormatter = (value, options) => {
    if (typeof value !== 'number') value = 0
    options = { ...defaultOptions, ...options }
    value = options.significantDigits ?   value.toFixed(options.significantDigits ):value
  
    if(options.significantDigits){
      const [currency, decimal] = value.split('.');
      return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
      )}${options.decimalSeparator}${decimal}`
    }else{
      const currency = value.toFixed();
      return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
      )}`
    }
 

  }