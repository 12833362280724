import React from 'react';
import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';

//************************************************ */
import Header from './Header'
import TasksRows from './OrderTasks'
import ComponentRows from './OrderComponents'
import OfferInfo from './OrderInfo';
import OrderControl from './OrderControl';
import ProductInfo from './ProductInfo'
import OrderNotes from './OrderNotes'
import Foother from './Foother'
import styled from '@react-pdf/styled-components';
//import blank  from '@iso/assets/images/resyok.jpeg';
import OpenSansFont from "../font/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../font/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFontBold',
        paddingTop: 20,
        paddingLeft:20,
        paddingRight:20,
        flexDirection: 'column'
    }, 
    ViewContainer: {
        marginTop: 3,
        flexDirection: "row",
        width:550,
    },     
    ColumnContainer: {
        marginTop: 2,
        flexDirection: "column",
        border: 1,
        width:550
    },
    rowContainer: {
        flexDirection: 'row',
        height: '14px',
        marginTop:3,
        width:549,
        borderBottom:1
    },

    Foother: {
        position: 'absolute',
        flexDirection: "row",
        border:0.3,
        width:550,
        alignItems:'center',
        bottom: 30,
        right:30,
        height:60
    }
  });
  const Label = styled.Text`
  padding:3px;
  font-size:7px;
  text-align:left;
  height:18px;
  `;
  const Material = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  width:100px;
  `;
  const Field60px = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  width:60px;
  `;
  const Field120px = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  width:120px;
  `;
  const Field370px = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  width:370px;
  `;
  const MaterialQty = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:center;
  width:30px;
  `;
  const Task = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  width:60px;
  `;
  const Status = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:center;
  border-left:0.3;
  width:20px;
  `;
  const Status2 = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:center;
  border-left:0.3;
  width:60px;
  `;
  const Description = styled.Text`
  paddingLeft:2px;
  font-size: 6px;
  text-align: left;
  border-left: 0.3;
  width:100px;
  `;
const WorkOrder = ({data,company}) =>{
    const {orderNotes,componentList,taskList} = data;
    return (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header company={company} Dil={"tr"}/>
            <View style={styles.ViewContainer}>
                <OfferInfo data={data} Dil={"tr"}/>
            </View>
            <View style={styles.ViewContainer}>
                <ProductInfo data={data} Dil={"tr"}/>
            </View>
            <Label>Üretim Notları : </Label>
            <View style={styles.ColumnContainer}>
                {data && orderNotes && orderNotes.length>0 &&  orderNotes.map((s,index)=>{
                    return (
                        <OrderNotes key={index} note={s}/>
                    )
                })}
            </View>
            <Label>Malzeme Listesi : </Label>
            <View style={styles.ColumnContainer}>
                <View style={styles.rowContainer}>
                    <Field60px>Kategori</Field60px>
                    <Field60px>Grup</Field60px>
                    <Material>Malzeme</Material>
                    <MaterialQty>Miktar</MaterialQty>
                    <MaterialQty>Toplam</MaterialQty>
                    <MaterialQty>Birim</MaterialQty>
                    <Task>Görev</Task>
                    <Status>1</Status>
                    <Status>2</Status>
                    <Status>3</Status>
                    <Status>4</Status>
                    <Description>Açıklama</Description>
                </View>
                {componentList && componentList.map((row,index)=>{
                    return (
                        <ComponentRows key={index} row={row}/>
                    )
                })}
            </View>
            <Label>İşlemler : </Label>
            <View style={styles.ColumnContainer}>
                <View style={styles.rowContainer}>
                    <Field60px>İşlem </Field60px>
                    <Field120px>Başlama/Bitiş Tarihleri</Field120px>
                    <Field120px>İşlem Açıklaması</Field120px>
                    <Field60px>Miktar</Field60px>
                    <Status>1</Status>
                    <Status>2</Status>
                    <Status>3</Status>
                    <Description>Açıklama/Personel</Description>
                </View>
                {taskList && taskList.length>0 && taskList.sort((a, b) => (a.importance > b.importance) ? -1 : 1).map((row,index)=>{
                    return (
                        <TasksRows key={index} row={row}/>
                    )
                })}
            </View>

            <Label>Son Kontrol : </Label>
            <View style={styles.ColumnContainer}>
                <View style={styles.rowContainer}>
                    <Field370px>Kontrol </Field370px>
                    <Status2>RED</Status2>
                    <Status2>KABUL EDİLEBİLİR</Status2>
                    <Status2>UYGUN</Status2>
                </View>
                <OrderControl/>
            </View>
       
            <View style={styles.Foother} fixed>
               <Foother id={data._id}/>
            </View>
          
           
        </Page>
    </Document>
)};
  
export default WorkOrder