import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';
import { Table ,Spin,Tag,Popover} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import moment from 'moment';
import {currencyFormatter} from '../../../helpers/currencyFormat';
import Heading from '../../../components/heading/heading';

const CompanyOffers = ({messages}) => {
  const {companyOfferList,offerListLoading} = useSelector(state => state.offerList);
  let offers = companyOfferList && companyOfferList.length && companyOfferList.map((el, idx) => ({key: idx, ...el}))
  const defaultOptions = {
    significantDigits: 1,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: ""
  }
  //******************************************************************************************************************************************************************/

  const offersColumn = [
    {
      title: [messages["genel.date"]],
      dataIndex: 'teklif_tarihi',
      key: 'teklif_tarihi',
      width: '15%',
      render: (text) => moment(text).format("DD.MM.YYYY")
    },
    {
      title: [messages["offer.subject"]]+" / "+[messages["offer.offerno"]],
      dataIndex: 'teklif_konu',
      key: 'teklif_konu',
      align: 'left',
      render:(text,record) =>  
      <Popover
        title={messages["sidebar.products"]}
        content={
          <div>
            {record.offeritem && record.offeritem.length && record.offeritem.map((item,i)=>{
              return(
                <h5 key={i}> {item.urun_adi+" / "+item.urun_miktari+ " "+ item.urun_birimi+" / "+item.urun_fiyati+ " "+ item.urun_dovizi+"  => "+item.toplamDoviz+" "+ item.urun_dovizi }</h5>
              )
            })}
          </div>
        }
      >
          <NavLink key={4} to={"/admin/offerdetails/"+record._id} className="project-edit">{text +" / "+record.teklif_no}</NavLink>
      </Popover>
      ,
      width: '50%',
    },
    {
      title: messages["offer.status"],
      dataIndex: 'teklif_durumu',
      key: 'teklif_durumu',
      render: (text,record) => <Tag className="red" >{messages["offer.OfferStatus"+text]}</Tag>,
      width: '15%',
      align:'right'
    },
    {
      title: messages["genel.gtotal"],
      dataIndex: 'teklif_gtoplamdoviz',
      key: 'teklif_gtoplamdoviz',
      render: (text,record) => <span>{currencyFormatter(text,defaultOptions)} {record.teklif_dovizi}</span>,
      width: '20%',
      align:'right'
    }
  ];

  return (
    <div className="full-width-table">
      <Cards
        title={ <Heading as="h5">{messages["sidebar.offers"]}</Heading>}
        size="large"
        bodypadding="0px"
        loading={offerListLoading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <div className="table-bordered top-seller-table table-responsive">
          <Table 
            columns={offersColumn} 
            dataSource={offers} 
            pagination={false}     
            scroll={{ y: 635 }}
          />
        </div>
      </Cards>
    </div>
  );
}

export {CompanyOffers};
