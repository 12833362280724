
import React from 'react';
import { Tag, Tooltip, Popconfirm,Spin,Button} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashCan, faEdit ,faListCheck, faPrint} from '@fortawesome/free-solid-svg-icons';
import { Link} from 'react-router-dom';
import userBlank from '../../../static/img/blank.png';
import styled from 'styled-components';
import moment from 'moment';

const CardWrapper = styled.figure`
    margin-bottom: 0;
    .banner-card{
      padding: 20px 25px 25px 25px;
      border-radius: 10px;
      &.banner-card-primary{
          background-color: ${({ theme }) => theme['primary-color']};
      }
      &.banner-card-dark{
          background-color: ${({ theme }) => theme['dark-color']};
      }
      &.banner-card-border{
          border: 1px solid ${({ theme }) => theme['border-color-light']};
          .banner-card__title{
              color: ${({ theme }) => theme['dark-color']} !important;
          }
          .banner-card__body{
            margin-bottom: 10px;
          }
          .banner-card__bottom{
            .author-name{
                color: ${({ theme }) => theme['gray-color']};
            }
            .card-meta{
                li{
                    span{
                        color: ${({ theme }) => theme['extra-light-color']};
                    }
                }
            }
          }
      }
      .banner-card__top {
          .banner-card__title{
              font-size: 16px;
              font-weight: 500;
              display: flex;
              align-items: center;
              color: #fff;
              margin-bottom: 14px;
              img,
              svg,
              i{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
              }
            }
            .banner-card__action{
              align-items: center;
              margin-bottom: 8px;
            }
            .activity-icon{
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              margin: ${({ theme }) => (theme.rtl ? '4px 0 0 0' : '4px 0 0 0')};
              &.bg-danger{
                  background: ${({ theme }) => theme['danger-color']}15;
                  color: ${({ theme }) => theme['danger-color']};
              }
              &.bg-info{
                background: ${({ theme }) => theme['info-color']}15;
                color: ${({ theme }) => theme['info-color']};
              }
              &.bg-success{
                background: ${({ theme }) => theme['success-color']}15;
                color: ${({ theme }) => theme['success-color']};
              }
            }
        }
      }
      .banner-card__body{
          p{
              margin-bottom: 20px;
              line-height: 1.786;
              color: #ffffff90;
          }
      }
      .banner-card__bottom {
        .card-author{
            img{
                max-width: 30px;
                border-radius: 50%;
            }
            .author-name{
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
                font-weight: 500;
                color: #ffffff90;
            }
        }
        .card-meta{
            ul{
                display: flex;
                align-items: center;
                li{
                    display: flex;
                    align-items: center;
                    &:not(:last-child){
                        margin-right: 10px;
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                    }
                    svg,
                    img{
                        color: ${({ theme }) => theme['extra-light-color']};
                        margin-right: 6px;
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
                        width: 16px;
                    }
                    span{
                        font-size: 13px;
                        color: #fff;
                    }
                }
            }
        }
      }
  }
`;

const ImageUrl = styled.div`
  ${({ bgUrl }) => bgUrl && `background-image: url(${require(`../../../static/img/sampleCards/${bgUrl}`)})`};
  background-size: cover;
  background-reapet: no-reapet;
  background-position: center center;
`;

const ReceivedList = ({loading,messages,received,toggleModal,deleteReceivedRow,index}) => {
  return (
    <>
      {loading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
        <div style={{margin:'10px'}}>
        <CardWrapper>
          <ImageUrl className={`banner-card banner-card-${'border'}`} bgUrl={""}>
            <div className="banner-card__top align-center-v justify-content-between">
              <h4 className="banner-card__title">
                <span> {moment(received.date).format("DD.MM.YYYY")} / {received.qty}  {received.unit}</span>
              </h4>
              <div className="banner-card__action">
                <div className="more">
                    <Link  to="#" onClick={()=>toggleModal("receivedModalActive",true,index)} style={{marginRight:'10px'}}>
                      <Tooltip title="Düzenle" placement="bottom" >
                        <span className="activity-icon bg-info">
                          <FontAwesomeIcon icon={faEdit} size="sm"/>
                        </span>
                      </Tooltip>
                    </Link>
                    {received && received.waybill_no && 
                    <Link  to="#" onClick={()=>toggleModal("qcPrintModal",true,index)} style={{marginRight:'10px'}}>
                      <Tooltip title="Kalite Kontrol Formu" placement="bottom" >
                        <span className="activity-icon bg-success">
                          <FontAwesomeIcon icon={faPrint} size="sm"/>
                        </span>
                      </Tooltip>
                    </Link>}
                    <Link  to="#" onClick={()=>toggleModal("qcModalActive",true,index)} style={{marginRight:'10px'}}>
                      <Tooltip title="Kalite Kontrol" placement="bottom" >
                        <span className="activity-icon bg-success">
                          <FontAwesomeIcon icon={faListCheck} size="sm"/>
                        </span>
                      </Tooltip>
                    </Link>
                    <Link to="#">
                      <span className="activity-icon bg-danger">
                        <Tooltip title="İşlem Satırını Siler" placement="bottom" >
                          <Popconfirm
                            title={messages["alert.deletequest"]}
                            okText={messages["genel.yes"]}
                            cancelText={messages["genel.no"]}
                            onConfirm={()=>deleteReceivedRow(index)}
                          >
                            <FontAwesomeIcon icon={faTrashCan} size="sm"/>
                          </Popconfirm>
                        </Tooltip>
                      </span>
                    </Link>
                </div>
              </div>
            </div>
            <div className="banner-card__body">              
                <Tag color="blue">{messages["genel.price"]} : {received.price}  {received.doviz}</Tag>
                <Tag color="blue">{messages["genel.totalprice"]} : {received.toplamDoviz}  {received.doviz}/ {received.toplamTL} ₺</Tag>
                <Tag color={received.stockSaved ?"green":"red"}> {received.stockSaved ? "Stok Girişi Yapıldı":"Stok Girişi Yapılmadı"}</Tag>
                <Tag color={received.closed===1 ? "green" : "red"}>{messages["delivery."+(received.closed===1?2:0)]}</Tag>
            </div>
            <div className="banner-card__bottom  align-center-v justify-content-between ">
              <div className="card-author">
                <img src={userBlank} alt="" />
                <span className="author-name">{received.receiver}</span>
              </div>
            </div>
          </ImageUrl>
        </CardWrapper>
      </div>)}

    </>

  );

}



export  {ReceivedList};
