import React from 'react';
import { Button, Modal,Input, Select ,Form,Row, Col,Upload} from 'antd';
import { FormWrapper } from '../../Style/style';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  useSelector } from 'react-redux';

const ImageModal = ({ productImage,productImageModalActive,lang,messages,toggleModal,beforeUpload,updateProductImageRow}) => {
  const [form23] = Form.useForm();

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{messages["button.upload"]}</div>
    </div>
  );
  const imageTypeList= [
    {value:"Ana Görsel",label:"Ana Görsel"},
    {value:"Liste Görseli",label:"Liste Görseli"},
    {value:"Teknik Resim",label:"Teknik Resim"},
    {value:"Görsel",label:"Görsel"}
  ]


  return (
  <>
    <Modal
      title={messages["genel.edit"]}
      open={productImageModalActive}
      footer={
        <Button  size="large" type="primary" onClick={() => toggleModal("productImageModalActive",null)}>
          {messages["button.save"]}
        </Button>
      }
      onCancel={() => toggleModal("productImageModalActive",null)}
      forceRender
      closable={false}
      width={600}
    >
      <FormWrapper style={{height:'auto'}}>
          <Form form={form23} initialValues={productImage}>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={(file)=>beforeUpload(file,"productImages","image")}
                >
                  {productImage.image ? <img src={productImage.image} alt="moduled" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </Col>

              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["genel.sira"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name='no'>
                      <Input type='number' onChange={(eData)=>updateProductImageRow("no",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">Açıklama</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['description', `${0}`]}>
                      <Input onChange={(eData)=>updateProductImageRow("description",eData.target.value,0)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">Description</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['description', `${1}`]}>
                      <Input onChange={(eData)=>updateProductImageRow("description",eData.target.value,1)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="imageType" rules={[{ required:true}]} >
                  <Select  options={imageTypeList} onSelect={(eData)=>updateProductImageRow("imageType",eData)}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
      </FormWrapper>
    </Modal>
  
  </>
  );
}

export  {ImageModal};
