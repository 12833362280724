import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from "moment";
const styles = StyleSheet.create({
    OfferInfoContainer: {
        flexDirection: "row",
        width:550,
        border :1
    },
    ImageContainer:{
        flexDirection: "column",
        width:60,
        borderRight :1
    },
    BuyerInfo:{
        flexDirection: "column",
        borderRight :1,
        width:360,
    },
    OrderInfo: {
        width: 190,
    },  
    ViewRowContainer:{
        flexDirection: "row",
        textAlign:"right"
    }
  });
  const BuyerCompanyName = styled.Text`
  padding:2px
  font-size:7px;
  text-align:left;
  height:16px;
  `;
  const BuyerAdres1 = styled.Text`
  padding:2px
  font-size:7px;
  text-align:left;
  height:32px;
  `;
  const BuyerLabel = styled.Text`
  padding:2px
  font-size:7px;
  text-align:left;
  height:16px;
  `;
  const Field = styled.Text`
  padding:2px
  font-size:7px;
  text-align:left;
  height:16px;
  width:120px;
  `;
  const Label = styled.Text`
  padding:2px
  font-size:7px;
  text-align:right;
  height:16px;
  width:70px;
  `;
 
const OfferInfo = ({data,Dil}) => {
    const {offer} = data;
    return(
        <View style={styles.OfferInfoContainer}>
            <View style={styles.BuyerInfo}>
                <BuyerLabel>{Dil==="tr" ? " MÜŞTERİ :" : "BUYER : "}</BuyerLabel>
                <BuyerCompanyName> {offer&&offer.firma_adi &&offer.firma_adi}</BuyerCompanyName>
                <BuyerAdres1> {offer &&offer.teklif_sevkadresi &&offer.teklif_sevkadresi }</BuyerAdres1>
            </View>    
            <View style={styles.OrderInfo}>
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "Teklif Tarihi : ":"Date : "}</Label>
                    <Field>{ offer.teklif_tarihi && moment(offer.teklif_tarihi).format('DD.MM.YYYY')}</Field>
                </View>
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "Sipariş Tarihi : ":"Invoice No : "}</Label>
                    <Field>{ offer.siparis_tarihi && moment(offer.siparis_tarihi).format('DD.MM.YYYY')}</Field>
                </View>    
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "Teklif No : ":"Order No : "}</Label>
                    <Field>{offer.teklif_no && offer.teklif_no}</Field>
                </View>     
            </View> 
        </View>
)};
  
export default OfferInfo