import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    HeaderContainer:{
        marginTop: 1,
        flexDirection: "row",
        border:1,
        width:550,
    },
    FormContainer:{
        flexDirection: "column",
        width:190,
    },
    TitleContainer:{
        flexDirection: "column",
        borderRight :1,
        width:200,
        justifyContent:"center"
    },
    logo: {
        width: 160,
        height: 40,
        marginLeft: '0',
        borderRight :1
    },  

});
const Title = styled.Text`
font-size:10px;
text-align:center;
`;
  const InvoiceTitle = ({company,Dil}) => {
    return(
    <View style={styles.HeaderContainer}>
        <Image style={styles.logo} src={company.sirket_rapor_logo}/>
        <View style={styles.TitleContainer}>
            <Title>İŞ EMRİ</Title>
        </View>
        <View style={styles.FormContainer}>

        </View>

       
    </View>
  )};
  
  export default InvoiceTitle