import React, { useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card,Spin,message,Collapse } from 'antd';
import { useParams,NavLink } from 'react-router-dom';
import {useIntl} from "react-intl";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../Style/styled';
import { ProductDetailsWrapper,ProjectTabContent } from '../../Style/style';
import blank from '../../../static/img/resyok.jpeg';
import {getProductDetails} from '../../../redux/productDetails/productDetailActions';




import {ElectricalInfo} from "./products/electricalInfo";
import {OpticalInfo} from "./products/opticalInfo";
import {MechanicalInfo} from "./products/mechanicalInfo";
import {OtherInfo} from "./products/otherInfo";
import {TechnicalDrawingsList } from './products/technicalDrawingsList';
import {ProductPhotometryList } from './products/productPhotometryList';
import {OtherPhotoList } from './products/orherPhotosList';


const DetailsRight = lazy(() => import('./products/DetailsRight'));

const ProductDetails = ({  }) => {
  const { Panel } = Collapse;

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {categoryList} = useSelector(state => state.App);
  const { productDetail, productDetailLoading,status} = useSelector(state => state.productDetail);

  const [dataSource, setDataSource] = React.useState(productDetail);
  const {electricalInfo,opticalInfo,mechanicalInfo,otherInfo,technicalDrawings,productPhotometry,otherPhotos} = dataSource;

  const company = useSelector(state => state.myCompany.myCompany);
  const {tcmb} = useSelector(state => state.tcmb);
  // const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  // const EURSatis = tcmb && tcmb.Data[1].ForexSelling;

  const dispatch = useDispatch();
  const { productID } = useParams();
  const { messages } = useIntl();
  const lang = locale === 'tr' ? 0 :1;

  //******************************************************************************************************************************************************************/
  let SpecData = [];

  electricalInfo && electricalInfo.length && electricalInfo.forEach(row => {
    SpecData.push(row);
  })
  opticalInfo && opticalInfo.length && opticalInfo.forEach(row => {
    SpecData.push(row);
  })
  mechanicalInfo && mechanicalInfo.length && mechanicalInfo.forEach(row => {
    SpecData.push(row);
  })
  otherInfo && otherInfo.length && otherInfo.forEach(row => {
    SpecData.push(row);
  })
  let filter = null;
  filter = SpecData.filter(x=>x.visibility===1)
  const ProductPower = filter && filter.length ? SpecData.filter(x=>x.visibility===1)[0].value[0] : "???"
  filter = SpecData.filter(x=>x.visibility===2)
  const ProductLumen = filter && filter.length ? SpecData.filter(x=>x.visibility===2)[0].value[0] : "???"

  filter = SpecData.filter(x=>x.visibility===3)
  const ProductDimensions = filter && filter.length ? SpecData.filter(x=>x.visibility===3)[0].value[0] : "???"
  filter = SpecData.filter(x=>x.visibility===4)
  const ProductWeight = filter && filter.length ? SpecData.filter(x=>x.visibility===4)[0].value[0] : "???"
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    setDataSource(productDetail)
},[productDetail]);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    if (productID !=="new" && dataSource._id !== productID) {
      dispatch((getProductDetails({id:productID})))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      });
    }
  },[]);
  //******************************************************************************************************************************************************************/


  const  mainImage = dataSource && dataSource.mainImage && dataSource.mainImage.length ? dataSource.mainImage[0].image : blank;
  return (
    <>
      <PageHeader
        ghost
        title={messages["product.details"]}
      />
      <Main>
        <Card>
          <ProductDetailsWrapper>
            <div className="product-details-box">
              <Row gutter={25}>
                <Col xs={24} lg={10}>
                  {productDetailLoading ? 
                    <div className="spin">
                      <Spin/>
                    </div>  
                  :
                    <div className="product-details-box__left pdbl">
                    <figure>
                      <img style={{ width: '100%' }} src={mainImage} alt="" />
                    </figure>
                    <div className="pdbl__slider pdbs">
                      <Row gutter={5}>
                        {dataSource && dataSource.productImages &&  dataSource.productImages.length
                          ? dataSource.productImages
                              .map((image, index) => {
                                return (
                                  index <= 3 && (
                                    <Col md={4} key={index}>
                                      <div className="pdbl__image">
                                        <figure>
                                          <NavLink to='#'>
                                            <img
                                              style={{ width: '100%' }}
                                              src={image.image.image}
                                              alt=""
                                            />
                                          </NavLink>
                                        </figure>
                                      </div>
                                    </Col>
                                  )
                                );
                              })
                          : null}
                      </Row>
                    </div>
                  </div>}
                </Col>
                <Col xs={24} lg={14}>
                  <DetailsRight 
                    product={dataSource} 
                    messages={messages}
                    loading={productDetailLoading}
                    ProductPower={ProductPower}
                    ProductLumen={ProductLumen}
                    ProductDimensions={ProductDimensions}
                    ProductWeight={ProductWeight}
                    lang={lang}                    
                  />
                </Col>
              </Row>
            </div>
          </ProductDetailsWrapper>
        </Card>

        <Collapse defaultActiveKey={['1']} style={{marginTop:20}}>
          <Panel header={messages["product.electricalinfo"]} key="1">
            <ElectricalInfo
              messages={messages}
              lang={lang}
              electricalInfo={electricalInfo}
              productDetailLoading={productDetailLoading}
            />
          </Panel>
          <Panel header={messages["product.opticalinfo"]} key="2">
            <OpticalInfo
              messages={messages}
              lang={lang}
              opticalInfo={opticalInfo}
              productDetailLoading={productDetailLoading}
            />
          </Panel>
          <Panel header={messages["product.mechanicalinfo"]} key="3">
            <MechanicalInfo
              messages={messages}
              lang={lang}
              mechanicalInfo={mechanicalInfo}
              productDetailLoading={productDetailLoading}
            />
          </Panel>
          <Panel header={messages["product.otherinfo"]} key="4">
            <OtherInfo
              messages={messages}
              lang={lang}
              otherInfo={otherInfo}
              productDetailLoading={productDetailLoading}
            />
          </Panel>
          <Panel header={messages["product.drawings"]} key="5">
            <TechnicalDrawingsList
              messages={messages}
              lang={lang}
              technicalDrawings={technicalDrawings}
              productDetailLoading={productDetailLoading}
            />
          </Panel>
          <Panel header={messages["product.photometry"]} key="6">
            <ProductPhotometryList
              messages={messages}
              lang={lang}
              productPhotometry={productPhotometry}
              productDetailLoading={productDetailLoading}
            />
          </Panel>
          <Panel header={messages["product.otherphotos"]} key="7">
            <OtherPhotoList
              messages={messages}
              lang={lang}
              otherPhotos={otherPhotos}
              productDetailLoading={productDetailLoading}
            />
          </Panel>
        </Collapse>
      </Main>
    </>
  );
}


export {ProductDetails};
