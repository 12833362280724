import React from 'react';
import { Input, Form, Modal,Button, Select } from 'antd';
import { FormWrapper } from '../../Style/style';
const AuthModal = ({ authRow,authModalActive,updateAuthRow,formList,messages,toggleModal,userAuthList}) => {
  const [form2] = Form.useForm();
  React.useEffect(() => {
    form2.setFieldsValue(authRow)
   }, [authRow])
  return (
  <>
  {authModalActive && authRow &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={authModalActive}
      footer={         
         <div className="sDash-modal-actions">
          <Button  size="large" type="primary" onClick={() => toggleModal("authModalActive",null,null)}>
            {messages["button.close"]}
          </Button>
        </div>}
      onCancel={() => toggleModal("authModalActive",null,null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'250px'}}>
        <Form form={form2} initialValues={authRow}>
          <Form.Item name="sira" label={messages["genel.sira"]} >
            <Input type='number' onChange={(eData)=>updateAuthRow("sira",eData.target.value)}/>
          </Form.Item>
          <Form.Item name="form" label={messages["user.form"]} >
            <Select options={formList}  onSelect={(eData)=>updateAuthRow("form",eData)}/>
          </Form.Item>
          <Form.Item name="auth" label={messages["user.form"]} >  
            <Select options={userAuthList.map(x=>{return({label:messages[x.label],value:x.value})})} onSelect={(eData)=>updateAuthRow("auth",eData)}/>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {AuthModal};
