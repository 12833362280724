import React from 'react';
import { Image} from 'antd';

const ProductPhotometryList = ({productPhotometry,productDetailLoading}) => {
  return (

        <div className="table-bordered top-seller-table table-responsive" >
          <Image.PreviewGroup>
            {productPhotometry && productPhotometry.length ? productPhotometry.map((image,index)=>{
              return(
                <Image width={200} src={image.image} key={index} />
              )
            }):null}
          </Image.PreviewGroup>
        </div>


  );
}
 


export  {ProductPhotometryList};
