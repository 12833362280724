import React from 'react';
import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import Header from '../common/reportHeaders/Header1';
import Rows from './Rows';
//import blank  from '@iso/assets/images/resyok.jpeg';
import OpenSansFont from "../font/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../font/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFontBold',
        paddingTop: 20,
        paddingBottom:60,
        paddingLeft:20,
        paddingRight:20,
        flexDirection: 'column'
    }, 
    Title: {
        marginBottom: 2,
        flexDirection: "row",
        borderBottomWidth:1,
        width:550,

    },
    Header: {
        flexDirection: "row",
    },     
    RowContainer: {
        width:550,
        bottom:5,
        top:5
    }, 
    Row: {
        width:550,
        height:64,
    },
    Foother: {
        position: 'absolute',
        border:0.3,
        width:550,
        marginLeft:20,
        alignItems:'center',
        bottom: 17,
        height:40
    },
    TitleContainer:{
        marginTop: 2,
        flexDirection: "row",
        borderBottomWidth: 0.2,
        width:550,
        fontFamily: 'OpenSansFontBold',
    },
    TextContainer:{
        marginTop: 2,
        flexDirection: "column",
        borderBottomWidth: 0.2,
        width:550,
        fontFamily: 'OpenSansFontBold',
    },
  });
const Titlestyle = styled.Text`
    margin: 0px;
    font-size: 12px;
    text-align: center;
    width:550px;
    text-align: center;
`;
const PriceListText = styled.Text`
    marginTop: 10px;
    marginBottom: 10px;
    text-indent:30px;
    font-size: 10px;
    text-align: justify;
`;

const priceList = ({data,company,oran,kategori,pricelisttext,priceListTextOpen,messages,lang,selectedCategories}) =>{
    const isDataSelected = (product, selectedItems, itemKey) => {
        return !selectedItems.length || selectedItems.includes(product[itemKey]);
    };
    const  datas =data.filter((product) => {
        return (
          isDataSelected(product, selectedCategories,"model_category")
        );
      }).sort((a, b) => (a.model_sira > b.model_sira) ? 1 : (a.model_sira === b.model_sira) ? ((a.urun_sira > b.urun_sira) ? 1 : -1) : -1 );
    return(
    <Document >
        <Page size="A4" style={styles.page}>
            <View style={styles.Header} fixed >
                <Header company={company} messages={messages}/>
            </View> 
            <View style={styles.Title} fixed >
                <Titlestyle>{(messages["product.pricelist"]).toUpperCase()}</Titlestyle>
            </View>            
            {pricelisttext  && priceListTextOpen && oran > 0   && lang=== 0 && 
            <View style={styles.TitleContainer} >
                <PriceListText>{pricelisttext}</PriceListText>
            </View>}  
            {oran ===0 && lang=== 0 && 
            <View style={styles.TextContainer} >
                <PriceListText>{"Dikkat !! "}</PriceListText>
                <PriceListText>{"Listede Yer alan fiyatlar iskonto UYGULANMIŞ fiyatlardır !!"}</PriceListText>
            </View>}  
            <View style={styles.RowContainer}>
                {datas.map((a) => {
                    return( <Rows style={styles.Row} data={a} oran={oran} messages={messages} key={a._id} lang={lang}/>);}
                )} 
            </View>

        </Page>
    </Document>
)};
  
export default priceList