import React from 'react';
import { Row, Col, Form, Input,Radio,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
const { TextArea } = Input;

const CompanyNotes =({data,loading,messages,updateData})=> {
  const [form12] = Form.useForm();
  React.useEffect(() => {
    form12.setFieldsValue(data)
   }, [data]);

  return (
      <Cards
        title={<Heading as="h5">{messages["company.notes"]}</Heading>}
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <FormWrapper>
          <Form  form={form12} initialValues={data} >
              <Form.Item name="company_notes"  label={messages["company.notes"]} >
                <TextArea onChange={(eData)=>updateData("company_notes",eData.target.value)} rows={10} />
              </Form.Item>
              <Row align="middle">

                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["company.status"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item  >
                    <Radio.Group value={data.company_status} onChange={(eData)=>updateData("company_status",eData.target.value)}>
                      <Radio value={true}>{messages["company.active"]}</Radio>
                      <Radio value={false}>{messages["company.deactive"]}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
          </Form>
        </FormWrapper>
      </Cards>

  );
}

export  {CompanyNotes};
