import {
    USERS_GET_LIST,
    USERS_GET_LIST_SUCCESS,
	USERS_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	data: {},
	loading:false,
	status:null
};

const UserList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case USERS_GET_LIST: // GEL LIST 
			return { ...state, loading: true,status:null };
		case USERS_GET_LIST_SUCCESS:
            return { 
				...state, 
                loading: false,
				data:action.response,
				status:200
            };
		case USERS_GET_LIST_ERROR:
			return {
				...state, 
				loading: false,
				status:action.response};
		default: return  state;
	}
}
export default UserList