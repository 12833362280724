export function getView(width) {
  let newView = 'MobileView';
  if (width > 1280) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLEAR_MENU: 'CLEAR_MENU',
  SET_OFFER_REPORT_TYPE:'SET_OFFER_REPORT_TYPE',
  SET_PRICE_LIST_PERCENT:'SET_PRICE_LIST_PERCENT',
  SET_PRICE_LIST_TEXTOPEN:'SET_PRICE_LIST_TEXTOPEN',
  SET_PRICE_LIST_TEXT:'SET_PRICE_LIST_TEXT',
  SET_PRICE_LIST_TYPE:"SET_PRICE_LIST_TYPE",
  SET_PRICE_LIST_FILTER:"SET_PRICE_LIST_FILTER",
  SET_COPY_FORM:"SET_COPY_FORM",
  SET_COPY_VALUES:"SET_COPY_VALUES",
  SET_OFFER_FILTERS:"SET_OFFER_FILTERS",
  SET_ORDER_FILTERS:"SET_ORDER_FILTERS",
  SET_MATERIAL_FILTERS:"SET_MATERIAL_FILTERS",
  SET_MODEL_FILTERS:"SET_MODEL_FILTERS",
  SET_BW_LOGO_OPEN:"SET_BW_LOGO_OPEN",
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  setLogoOpen: (val) => ({
    type: actions.SET_BW_LOGO_OPEN,
    val
  }),
  setOfferReportType: (val) => ({
    type: actions.SET_OFFER_REPORT_TYPE,
    val
  }),
  setPriceListPercent: (val) => ({
    type: actions.SET_PRICE_LIST_PERCENT,
    val
  }),
  setPriceListTextOpen: (val) => ({
    type: actions.SET_PRICE_LIST_TEXTOPEN,
    val
  }),
  setPriceListText: (val) => ({
    type: actions.SET_PRICE_LIST_TEXT,
    val
  }),
  setPriceListType: (val) => ({
    type: actions.SET_PRICE_LIST_TYPE,
    val
  }),
  setPriceListFilter: (val) => ({
    type: actions.SET_PRICE_LIST_FILTER,
    val
  }),
  setCopyValues: (val) => ({
    type: actions.SET_COPY_VALUES,
    val
  }),
  setCopyForm: (val) => ({
    type: actions.SET_COPY_FORM,
    val
  }),
  setOfferFilter: (val) => ({
    type: actions.SET_OFFER_FILTERS,
    val
  }),
  setOrderFilter: (val) => ({
    type: actions.SET_ORDER_FILTERS,
    val
  }),
  setMaterialFilter: (val) => ({
    type: actions.SET_MATERIAL_FILTERS,
    val
  }),
  setModelFilter: (val) => ({
    type: actions.SET_MODEL_FILTERS,
    val
  }),
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),
};
export default actions;
