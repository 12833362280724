import axios from 'axios';
import {
    USER_LOGIN_ACTION,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_REAUTH_ACTION,
    USER_REAUTH_FAILURE,
    USER_REAUTH_SUCCESS,
    USER_LOGOUT_ACTION,  
    CLEAR_ERRORS,
} from '../actions';
/* import {getCompany,} from '../actions' */

export const clearErrors = () => {
    return {
    type: CLEAR_ERRORS
    };
};

export const user_login_set_pending = (email) => ({
    type: USER_LOGIN_ACTION,
    email: email
})

export const user_reauth_set_pending = (token) => ({
    type: USER_REAUTH_ACTION,
    token: token
})

export const user_login_set_successful = (email, res) => ({  
    type: USER_LOGIN_SUCCESS,
    email: email,
    response: res
})

export const user_login_set_failed = (res) => ({
    type: USER_LOGIN_FAILURE,
    response: res
})

export const user_logout_request = () => ({
    type: USER_LOGOUT_ACTION
})

export const user_reauth_set_successful = (token, res) => ({
    type: USER_REAUTH_SUCCESS,
    token: token,
    response: res
})

export const user_reauth_set_failure = (res) => ({
    type: USER_REAUTH_FAILURE,
    response:res
})

// ******************************************************* LOGIN USER *******************************************************
export const loginUser = (data) => dispatch => {

    const { email, password } = data;
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    
    const body = JSON.stringify({ email, password });
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + '/auth/login', body, config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status && res.data.status === "successful") {
                        //dispatch(getCompany({id:1})) 
                        dispatch(user_login_set_successful(email, res));
 
                        resolve(true);
                    } else if (res.data.status && res.data.status === "failed") {
                        dispatch(user_login_set_failed(res.data));
                        resolve(false);
                    }
                }
            })
            .catch(err => {
                dispatch({
                    type: USER_LOGIN_FAILURE
                });
                reject(err);
                //dispatch(returnErrors("uyari.hata",err.response.msg,"error", err.response.status));
                //returnErrors(err.response.msg, err.response.status, 'LOGIN_USER_ERROR')
            });
    });
};

// ******************************************************* RE AUTH *******************************************************
export const user_reauth_request = (token) => dispatch => {
dispatch(user_reauth_set_pending(token));
axios.get(
    process.env.REACT_APP_SERVER_URL + '/auth/verifytoken', 
    {
    headers: {Authorization: 'Bearer ' + token
    }
})
    .then(res => {
    if (res.status === 200) {
        dispatch(user_reauth_set_successful(token, res));
    }
    else if (res.status === 401) {
        dispatch(user_reauth_set_failure(res));
    }
    })
    .catch((err) => {
    dispatch(user_reauth_set_failure());
    //dispatch(error({title: "Re-Auth Failed", message: "The authorization token your browser has is not valid anymore. This problem usually happens when user logs in from another device. Just try to log-in again.", autoDismiss: 10}));
    });
}

// Register User




// ******************************************************* logout *******************************************************
export const logoutUser = () => {
   // window.location.reload();
    return {
    type: USER_LOGOUT_ACTION,
    
    };
};
   // Logout User
   
