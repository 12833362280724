import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus,faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,Popconfirm, Popover} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import moment from 'moment';


const StockOperations = ({stockList,loading,messages,toggleModal,removeStockRow,addNewStockRow}) => {
  const stockLists = stockList && stockList.operations.length && stockList.operations.map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
      <NavLink to="#" onClick={()=>addNewStockRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
  );

  const stockListColumn = [
    {
      title: messages["genel.date"],
      dataIndex: 'operation_date',
      key: 'operation_date', 
      align: 'left',
      width: '18%',
      render:(text) => moment(text).format("DD.MM.YYYY")
    },
    {
      title: [messages["genel.qty"]],
      dataIndex: 'operation_type',
      key: 'operation_type',
      align: 'center',
      render: (text,record) => <Popover content={record.description}>{(text.toUpperCase())}</Popover> ,
      width: '14%',
    },
    {
      title: [messages["genel.qty"]],
      dataIndex: 'qty',
      key: 'qty',
      render: (text,record) => (text.toFixed(2)+" "+record.unit) ,
      width: '18%',
    },
    {
      title: [messages["genel.price"]],
      dataIndex: 'price',
      key: 'price',
      render: (text,record) => (text.toFixed(2)+" "+record.doviz) ,
      width: '18%',
    },
    {
      title: [messages["genel.totalprice"]],
      dataIndex: 'sumPrice',
      key: 'sumPrice',
      render: (text,record) => ((text && text.toFixed(2))+" "+record.doviz) ,
      width: '20%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("deliveryModalActive",true,record)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Popconfirm
          title={messages["alert.deletequest"]}
          okText={messages["genel.yes"]}
          cancelText={messages["genel.no"]}
          onConfirm={()=>removeStockRow(record)}
        >
          <Button
            shape="circle"
            size="small"
          >
            <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
          </Button>  
        </Popconfirm>
      </>
    }
  ];

  return (
    <div className="full-width-table">
      <Cards
        title={messages["product.production"]}
        size="large"
        bodypadding="0px"
        more={moreContent}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'730px'}}>
          <Table 
            columns={stockListColumn} 
            dataSource={stockLists} 
            pagination={false} 
            loading={loading}
            scroll={{y:650}}
          />
        </div>
      </Cards>
    </div>
  );
}

export {StockOperations};
