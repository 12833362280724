import {
    STOCK_GET_LIST,
    STOCK_GET_LIST_SUCCESS,
	STOCK_GET_LIST_ERROR,
	STOCK_OUTGET_LIST,
	STOCK_GET_OUTLIST_SUCCESS,
	STOCK_GET_OUTLIST_ERROR
} from '../actions';
const INIT_STATE = {
	stockList: [],
	stockOutList: [],
	stockListLoading:false,
	StockListStatus:null
};

const stockList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case STOCK_GET_LIST: // GEL LIST 
			return { 
				...state,
				stockList:[], 
				stockListLoading: true,
				StockListStatus:null
			};
		case STOCK_GET_LIST_SUCCESS:
            return { 
				...state, 
                stockListLoading: false, 
                stockList: action.response.stock, 
				StockListStatus:200 
            };
		case STOCK_GET_LIST_ERROR:
			return { 
				...state,
				stockList:[], 
				loading: false,
				StockListStatus:action.response
			};
		case STOCK_OUTGET_LIST: // GEL LIST 
			return { 
				...state,
				stockOutList:[], 
				stockListLoading: true, 
				StockListStatus:null
			};
		case STOCK_GET_OUTLIST_SUCCESS:
            return { 
				...state, 
                stockListLoading: false, 
				StockListStatus:null,
                stockOutList: action.response.stock,  
            };
		case STOCK_GET_OUTLIST_ERROR:
			return { 
				...state,
				stockOutList:[], 
				stockListLoading: false,
				StockListStatus:action.response
			};
		default: return  {...state};
	}
}
export default stockList
