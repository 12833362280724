import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCirclePlus,faAlignLeft,faAlignRight,faX,faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';
import { Row, Col, Input,message,Spin,Button,Tag,Tooltip,Badge,notification} from 'antd';
import { Main } from '../Style/styled';
import moment from 'moment';
import { MenuListWrapper,NoteNav ,Bullet ,ListWrapper,SearchListSidebar,ListContent} from '../Style/style';
import { PageHeader } from '../../components/page-headers/page-headers';
import {getBuyingsList} from '../../redux/buyingList/buyingListActions';

const  Buyings =()=> {
  const { buyingList, buyingListLoading,buyingListStatus } = useSelector((state) => state.buyingList);
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const [dataSource, setDataSource] = React.useState(data);
  const {modelFilter} = useSelector(state => state.App);
  const [state, setState] = React.useState({
    visible: false,
    collapsed: false,
    buyingFilter:"Tümü"
  });
  const { collapsed } = state;
  const { messages } = useIntl();
  const dispatch = useDispatch();
  let history = useHistory();
  const lang = locale === 'tr' ? 0 :1;
  const left = !rtl ? 'left' : 'right';
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(buyingListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [buyingListStatus]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(modelFilter==="Tümü"){
       dispatch(getBuyingsList("list","date",0,4))
       .then(res=>{
         message.loading(messages["alert.updated"]);
       });
    }else{
      dispatch(getBuyingsList("list","date",0,0))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      });
    }
  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(buyingList)
    // eslint-disable-next-line
  }, [buyingList]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = search => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.model_adi[lang].toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setDataSource(buyingList)
    }else{
      setDataSource(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      dispatch(getBuyingsList("list","date",0,4))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      });
    }else{
      dispatch(getBuyingsList("list","date",filterVal,filterVal))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      });
    }
    setState({
      ...state,
      buyingFilter:filterVal
    })
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const collapseSidebar = () => {
    setState({
      ...state,
      collapsed: false,
    });
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        title={messages["sidebar.buyings"]}
        subTitle={<>{dataSource && dataSource.length ? dataSource.length +" "+messages["genel.Adet"]+" "+messages["sidebar.buyings"]:" "}</>}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
              {window.innerWidth <= 991 && (
                <Button type="link" className="mail-sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                  <FontAwesomeIcon icon={collapsed ? faAlignLeft : faAlignRight} />
                </Button>
              )}
              {window.innerWidth > 991 ? (
                <div className="sidebar-card">
                  <Cards headless>
                    <div className="note-sidebar-top">
                      <Button shape="round" type="primary" size="default" block>
                        <FontAwesomeIcon icon={faCirclePlus} size="1x" style={{ marginRight: 10 }}/> {messages["button.add"]}
                      </Button>
                    </div>

                    <div className="note-sidebar-bottom">
                      <NoteNav>
                        <div className="nav-labels">   
                          <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'red'}  status="processing" text={messages["material.category"]}/></p>                      
                          <ul>
                            <ListContent style={{height:'580px'}}>
                              <Scrollbars
                                className="custom-scrollbar"
                                autoHide
                                autoHideTimeout={500}
                                autoHideDuration={200}
                                renderThumbHorizontal={renderThumbHorizontal}
                                renderThumbVertical={renderThumbVertical}
                                renderView={renderView}
                                renderTrackVertical={renderTrackVertical}
                              >
                                <li>
                                  <NavLink to='#' activeClassName={state.buyingFilter === "Tümü" ? "active":""} onClick={()=>onChangeFilter("Tümü")}>
                                    <Bullet className="personal" /> {messages["genel.all"]}
                                  </NavLink>
                                </li>
                                <li >
                                  <NavLink to='#' activeClassName={state.buyingFilter === 0 ? "active":""} onClick={()=>onChangeFilter(0)}>
                                    <Bullet className="personal" /> {messages["buying.buyingStatus0"]}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to='#' activeClassName={state.buyingFilter === 1 ? "active":""} onClick={()=>onChangeFilter(1)}>
                                    <Bullet className="personal" /> {messages["buying.buyingStatus1"]}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to='#' activeClassName={state.buyingFilter === 2 ? "active":""} onClick={()=>onChangeFilter(2)}>
                                    <Bullet className="personal" /> {messages["buying.buyingStatus2"]}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to='#' activeClassName={state.buyingFilter === 3 ? "active":""} onClick={()=>onChangeFilter(3)}>
                                    <Bullet className="personal" /> {messages["buying.buyingStatus3"]}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to='#' activeClassName={state.buyingFilter === 4 ? "active":""} onClick={()=>onChangeFilter(4)}>
                                    <Bullet className="personal" /> {messages["buying.buyingStatus4"]}
                                  </NavLink>
                                </li>
                                
                        
                              </Scrollbars>
                            </ListContent>
                          </ul>
                        </div>
                      </NoteNav>
                    </div>
                  </Cards>
                </div>
              ) : (
                <div className={collapsed ? 'sidebar-card note-sideabr show' : 'sidebar-card note-sideabr hide'}>
                  <Cards headless>
                    <Button
                      type="link"
                      className="mail-sidebar-trigger trigger-close"
                      style={{ marginTop: 0 }}
                      onClick={toggleCollapsed}
                    >
                      <FontAwesomeIcon icon={faX} />
                    </Button>
                    <div className="note-sidebar-top">
                      <Button  shape="round" type="primary" size="default" block>
                        <FontAwesomeIcon icon={faPlus} size='1x' /> {messages["button.add"]}
                      </Button>
                    </div>
                    <div className="note-sidebar-bottom">
                      <NoteNav>
                        <div className="nav-labels">
                          <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'red'}  status="processing" text={messages["material.category"]}/></p>
                          <ul>
                            <li>
                              <NavLink to='#' activeClassName={state.buyingFilter === "Tümü" ? "active":""} onClick={()=>onChangeFilter("Tümü")}>
                                <Bullet className="personal" /> {messages["genel.all"]}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to='#' activeClassName={state.buyingFilter === 0 ? "active":""} onClick={()=>onChangeFilter(0)}>
                                <Bullet className="personal" /> {messages["buying.buyingStatus0"]}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to='#' activeClassName={state.buyingFilter === 1 ? "active":""} onClick={()=>onChangeFilter(1)}>
                                <Bullet className="personal" /> {messages["buying.buyingStatus1"]}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to='#' activeClassName={state.buyingFilter === 2 ? "active":""} onClick={()=>onChangeFilter(2)}>
                                <Bullet className="personal" /> {messages["buying.buyingStatus2"]}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to='#' activeClassName={state.buyingFilter === 3 ? "active":""} onClick={()=>onChangeFilter(3)}>
                                <Bullet className="personal" /> {messages["buying.buyingStatus3"]}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to='#' activeClassName={state.buyingFilter === 4 ? "active":""} onClick={()=>onChangeFilter(4)}>
                                <Bullet className="personal" /> {messages["buying.buyingStatus4"]}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </NoteNav>
                    </div>
                  </Cards>
                </div>
              )}
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
                <SearchListSidebar>
                  <Cards headless>
                    <div className="chatbox-search">
                      <Input  style={{width:'100%'}} onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                    </div>
                    <ListContent style={{height:'675px'}}>
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={renderThumbHorizontal}
                        renderThumbVertical={renderThumbVertical}
                        renderView={renderView}
                        renderTrackVertical={renderTrackVertical}
                      >
                        <ListWrapper>
                          {loading ? 
                          <div className="spin"><Spin/></div>:
                          <ul>
                            {dataSource &&
                              dataSource
                                .sort((a, b) => {
                                  return  a.talep_tarihi - b.talep_tarihi;
                                })
                                .map((buying, key) => {
                                  const {_id, talep_tarihi,siparis_tarihi,teslim_tarihi,siparis_notu,related_person,related_person_email,order_id,tedarikci_id,tedarikci_adi,tedarikci_eposta,material_id,material_qty,material_price,USD_Kur,EUR_Kur,Doviz_TotalPrice,satinalma_durumu,TL_TotalPrice,material_doviz } = buying;
                                  let color;
                                  if (satinalma_durumu === 0 ){color = 'red'} 
                                  else if (satinalma_durumu === 1){color = 'blue'} 
                                  else if (satinalma_durumu === 2){color = 'green'} 
                                  else if (satinalma_durumu === 3){color = 'yellow'} 
                                  else if (satinalma_durumu === 4){color = "#24bc42"} 
                                  return (
                                    <li key={key} className="chat-link-signle" >
                                      <NavLink to={`/admin/buyingdetails/${_id}`}>
                                        <div className="author-figure">
                                          <span>{moment(talep_tarihi).format("DD.MM.YYYY")}</span>
                                          
                                        </div>
                                        <div className="author-info">
                                          <span className="author-name" style={{display: 'block'}}>{material_id && material_id.material_category &&  (material_id.material_category+" / "+material_id.material_group+" / "+material_id.material_name)}</span>
                                          <Tooltip title = {order_id &&  order_id.teklifno && order_id.teklifno.firma_adi} ><span>{order_id && order_id.urun_adi && order_id.urun_adi}</span></Tooltip>
                                          <span className="author-chatText" >{tedarikci_adi}</span>
                                        </div>

                                        <div className="author-chatMeta">
                                          <Tag color={color} >{messages["buying.buyingStatus"+satinalma_durumu]}</Tag>
                                        </div>
                                        <div className="author-action">
                                          <Button
                                            shape="circle"
                                            size="small"
                                            style={{marginLeft:"25px"}}
                                           
                                          >
                                            <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
                                          </Button > 
                                        </div>
                                      </NavLink>
                                    </li>
                                  );
                                })}
                          </ul>}
                        </ListWrapper>
                      </Scrollbars>
                    </ListContent>
                  </Cards>
                </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>

      </Main> 

    </>
  );
}



export  {Buyings};
