import {
	EMPLOYEE_DETAILS_ACTION,
	EMPLOYEE_DETAILS_SUCCESS,
    EMPLOYEE_DETAILS_ERROR,
	CREATE_EMPLOYEE_ACTION,
	CREATE_EMPLOYEE_SUCCESS,
	CREATE_EMPLOYEE_ERROR,
	UPDATE_EMPLOYEE_ACTION,
	UPDATE_EMPLOYEE_SUCCESS,
	UPDATE_EMPLOYEE_FAILURE,
	DELETE_EMPLOYEE_ACTION,
	DELETE_EMPLOYEE_SUCCESS,
	DELETE_EMPLOYEE_FAILURE
} from '../actions';

const INIT_STATE = {
	employeeDetail: {},
	employeeDetailStatus:null,
	employeeDetailLoading:false
};

const  employeDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case EMPLOYEE_DETAILS_ACTION: //GET OFFER DETAILS
			return { 
				...state, 
				employeeDetailLoading: true,
				employeeDetailStatus:null 
			};
		case EMPLOYEE_DETAILS_SUCCESS:
            return { 
				...state,
				employeeDetailLoading: false,  
                employeeDetail: action.response,
				employeeDetailStatus:200
			};
		case EMPLOYEE_DETAILS_ERROR:
            return { 
				...state, 
				employeeDetailLoading: false,
				employeeDetailStatus:action.response 
			};	
            	
		case CREATE_EMPLOYEE_ACTION: //CREATE MODEL
			return { 
				...state, 
				employeeDetailLoading: true ,
				employeeDetailStatus:null
			};
		case CREATE_EMPLOYEE_SUCCESS:
			return { 
				...state, 
				employeeDetailLoading: false, 
				employeeDetail: action.response,
				employeeDetailStatus:200 
			};
		case CREATE_EMPLOYEE_ERROR:
			return { 
				...state, 
				employeeDetailLoading: false,
				employeeDetail:{},
				employeeDetailStatus:action.response 
			};	
		case UPDATE_EMPLOYEE_ACTION: //UPDATE MODEL
			return { 
				...state, 
				employeeDetailLoading: true,
				employeeDetailStatus:null 
			};
		case UPDATE_EMPLOYEE_SUCCESS: 
			return { 
				...state, 
				employeeDetailLoading: false, 
				employeeDetail: action.response,
				employeeDetailStatus:200 
			};
		case UPDATE_EMPLOYEE_FAILURE:
			return { 
				...state, 
				employeeDetailLoading: false,
				employeeDetailStatus:action.response 
			}; 
		case DELETE_EMPLOYEE_ACTION: //DELETE MODEL
			return { 
				...state, 
				employeeDetailLoading: true,
				employeeDetailStatus:null 
			};
		case DELETE_EMPLOYEE_SUCCESS:
			return { 
				...state, 
				employeeDetailLoading: false, 
				employeeDetailStatus:200 
			};
		case DELETE_EMPLOYEE_FAILURE:
			return { 
				...state, 
				employeeDetailLoading: false ,
				employeeDetailStatus:action.response
			};
		default: return state;
	}
}
export default employeDetails;