import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
      LabelContainer: {
        flexDirection: 'row',
        marginTop:20,
        alignItems: 'center',
        borderBottomWidth:0.5,
        height: 30,
        width:550,
        fontFamily: 'OpenSansFontBold',
    }

});
const ProductName = styled.Text`
padding-left:3px;
font-size: 8px;
text-align: left;
width: 265px;
text-transform: uppercase;
`;
const ProductQty = styled.Text`
font-size: 8px;
text-align: center;
width: 50px;
text-transform: uppercase;
`;
const Tax = styled.Text`
font-size: 8px;
text-align: center;
width: 25px;
text-transform: uppercase;
`;
const ProductPrice = styled.Text`
font-size: 8px;
text-align: right;
width: 60px;
text-transform: uppercase;
`;
const TotalPrice = styled.Text`
font-size: 8px;
text-align: right;
width: 75px;
text-transform: uppercase;
`;
const GTotalPrice = styled.Text`
font-size: 8px;
text-align: right;
width: 75px;
text-transform: uppercase;
`;
  const InvoiceItemsTable = ({messages}) => (
    
    <View style={styles.LabelContainer}>
        <ProductName>{messages["offer.product"]}</ProductName>
        <ProductQty>{messages["offer.qty"]}</ProductQty>
        <Tax>{messages["offer.tax"]}</Tax>
        <ProductPrice>{messages["offer.price"]}</ProductPrice>
        <TotalPrice>{messages["offer.rowtotal"]}</TotalPrice>
        <GTotalPrice>{messages["offer.rowgrandtotal"]}</GTotalPrice>
    </View>
  );
  
  export default InvoiceItemsTable