import React from 'react';
import {Modal} from 'antd';
import {OfferReport}  from '../../../reports/offerReport';


const PrintModal = ({messages,printModalActive,toggleModal}) => {
  
  return (
    <>

      {printModalActive && 
      <Modal
        forceRender
        type='primary'
        className="sDash_addTask-modal"
        open={printModalActive}
        footer={null}
        closable={false}
        width={1000}
        onCancel={() => toggleModal("printModalActive",null)}
      >
        <OfferReport/>
      </Modal>}
    </>

  );

}



export  {PrintModal};