import React from 'react';
import {Modal} from 'antd';
import {MaterialLabel}  from '../../../reports/MaterialLabel';


const ReportModal = ({reportModalActive,toggleModal,printTypeCode}) => {
  
  return (
    <>

      {reportModalActive && 
      <Modal
        forceRender
        type='primary'
        open={reportModalActive}
        footer={null}
        closable={false}
        width={800}
        onCancel={() => toggleModal("reportModalActive",null,null)}
      >
        {printTypeCode===0 ? 
            <MaterialLabel/>
          :null
        }
      </Modal>}
    </>

  );

}



export  {ReportModal};