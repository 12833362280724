import React from 'react';
import { Row, Col, Form, Input, Button,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Password = ({loading,messages,onChangePassword}) => {
  const [form2] = Form.useForm();
  const values = Form.useWatch([], form2);
  const [submittable, setSubmittable] = React.useState(false);
  React.useEffect(() => {
    form2.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);

  return (
      <Cards
        title={<Heading as="h5">{messages["user.changepassword"]}</Heading>}
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <FormWrapper>
          <Form form={form2} name="changePassword" onFinish={(eData)=>onChangePassword(eData)}>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["user.password"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="password" rules={[{required: true, message: 'Please input your password!'}]}>
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["user.password"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="password1" rules={[{required: true, message: 'Please input repeat your password!'}]} extra="Minimum 5 characters">
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Button htmlType="submit" type="primary" loading={loading} disabled={!submittable}>
                {messages["user.setPassword"]}
              </Button>
          </Form>
        </FormWrapper>
      </Cards>

  );
}

export {Password};
