import {
   EMPLOYEE_FILE_GET_LIST,
   EMPLOYEE_FILE_GET_LIST_SUCCESS,
	EMPLOYEE_FILE_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	employeeFileList: [],
	employeeFileListLoading:false,
	employeeFileListStatus:null
};

const EmployeeList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case EMPLOYEE_FILE_GET_LIST: // GEL LIST 
			return { 
				...state, 
				employeeFileListLoading: true,
				employeeFileListStatus:null
			};
		case EMPLOYEE_FILE_GET_LIST_SUCCESS:
            return { 
				...state, 
                employeeFileListLoading: false, 
                employeeFileList: action.response.employeeFiles, 
				employeeFileListStatus:200 
            };
		case EMPLOYEE_FILE_GET_LIST_ERROR:
			return { 
				...state, 
				employeeFileListLoading: false,
				employeeFileListStatus:action.response
			};
		default: return  state;
	}
}
export default EmployeeList