import React from 'react';
import { Input, Form, Modal,Button,InputNumber, Select } from 'antd';
import { BasicFormWrapper } from '../../Style/style';
import { useSelector } from 'react-redux';


const DepartmentModal = ({ department,departmenRowModalActive,updateDepartmentRow,messages,toggleModal}) => {
  const [form2] = Form.useForm();
  const {ColorList} = useSelector(state => state.App);
  React.useEffect(() => {
    department && form2.setFieldsValue(department)
   }, [department]);
  return (
  <>
  {departmenRowModalActive && department &&
    <Modal
      title={messages["genel.edit"]}
      className="sDash_addTask-modal"
      type='primary'
      open={departmenRowModalActive}
      footer={null}
      onCancel={() => toggleModal("departmenRowModalActive",null,null)}
      forceRender
      closable={false}
    >
      <BasicFormWrapper>
        <Form form={form2} name="add-task" onFinish={(eData) => updateDepartmentRow(eData)} initialValues={department}>
          <Form.Item name="no" label={messages["genel.sira"]} >
            <InputNumber/>
          </Form.Item>
          <Form.Item name="department" label={messages["company.department"]} >
            <Input />
          </Form.Item>
          <Form.Item name="color" label={messages["genel.color"]} >
            <Select  options={ColorList.map(x=>{return({label:messages[x.label],value:x.value})})}/>
          </Form.Item>


          <div className="sDash-modal-actions">
            <Button  size="small" type="primary" htmlType="submit" >
              {messages["button.save"]}
            </Button>
          </div>
        </Form>
      </BasicFormWrapper>
    </Modal>
  }
  </>
  );
}

export  {DepartmentModal};
