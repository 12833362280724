import React from 'react';
import { View, StyleSheet,Image ,Text} from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from "moment";
import QRCode from 'qrcode';
const styles = StyleSheet.create({
    HeaderContainer:{
        fontFamily:'OpenSansFontBold',
        flexDirection: "row",
        borderBottomWidth: 2,
        width:400,
        height:30,
        borderColor:"grey",
        border:1
    },
    LeftContainer:{
        flexDirection: "column",
        width:90,
        borderRight:1,
        borderColor:"grey"
    },
    MiddleContainer:{
        flexDirection: "column",
        width:190,
        borderRight:1,
        borderColor:"grey"
    },
    RightContainer: {
        width: 90,
        height: 30,
    },  
    RightContainerRow:{
        flexDirection: "row",
        width:90,
    },
    RightContainerCol:{
        flexDirection: "column",
        width:90,
    },
    RightContainer2: {
        width: 30,
        height: 30,
    },  
});
const CompanyName = styled(Text)`
margin-bottom: 3px;
font-size: 9px;
text-align: center;
`;
const FormTitle = styled(Text)`
font-size: 9px;
text-align: center;
`;


const Email = styled(Text)`
margin: 0px;
font-size: 8px;
text-align: center;
color: #004678;
`;
const Web = styled(Text)`
margin: 0px;
font-size: 8px;
text-align: center;
color: #004678;
`;
const Date = styled(Text)`
margin-left: 2px;
font-size: 8px;
text-align: left;
hegiht:28px;

`;
const Adres2 = styled(Text)`
margin: 1px;
font-size: 8px;
text-align: right;
color: #004678;
`;

  const InvoiceTitle = ({company,messages,data,index}) => {
    let QRimage=""
    let value = window.location.origin+"/admin/buyingdetails/"+data._id;
    QRCode.toDataURL(value, function(err, url) {
      QRimage=  url;
    });

    return(
    <View style={styles.HeaderContainer}>

        <Image style={styles.LeftContainer} src={company.sirket_rapor_logo}/>

        <View style={styles.MiddleContainer}>
            <CompanyName>{company.sirket_adi.toUpperCase()}</CompanyName>
            <FormTitle>MALZEME GİRİŞ KONTROL FORMU</FormTitle>
        </View>

        <View style={styles.RightContainer}>
            <View style={styles.RightContainerRow}>
                <Date>{messages["genel.date"].toUpperCase()} : </Date>
                <Date>{data.received[index].waybill_date ? moment(data.received[index].waybill_date).format('DD.MM.YYYY'):null}</Date>
            </View>
            <Date>{messages["buying.reportno"].toUpperCase()} : </Date>
        </View>
        <View style={styles.RightContainer1}>
            <Image src={QRimage}/>
        </View>

       
    </View>
  )};
  
  export default InvoiceTitle