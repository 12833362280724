import React from 'react';
import { View, StyleSheet,Image,Link } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import blank from '../../../static/img/resyok.jpeg';
import {getProductCode} from '../../../helpers/getProductCode';


const BoderColor = '#E7E7E7'

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        borderColor:BoderColor,
        borderBottomWidth:0.5,
        marginBottom:2
    },
    ProductImages: {
        width: 30,
        height: 30,
        padding:3,
        borderColor:BoderColor,
        border:0.5,
        borderRadius:17.5,
     
    },
    ProductNameContainer: {
        flexDirection: 'column',
        width: 235,
        height: 50,
        justifyContent:'center'
    },
    QTYContainer: {
        flexDirection: 'column',
        width: 50,
        height: 50,
        justifyContent:'center'
    },
    ProductPriceContainer: {
        flexDirection: 'column',
        width: 60,
        height: 50,
        fontFamily:'ClearSans'
    },
    totalContainer: {
        flexDirection: 'column',
        width: 75,
        height: 50,
        fontFamily:'ClearSans'
    },
    gtotalContainer: {
        flexDirection: 'column',
        width: 75,
        height: 50,
        fontFamily:'ClearSans'
    }
  });
  const ProductName = styled.Text`
  font-family:'OpenSansFontBold';
  paddingLeft:5px;
  margin-top: 1px;
  font-size: 8px;
  text-align: left;
  `;
  const ProductCode = styled.Text`
  font-family:'OpenSansFontBold';
  paddingLeft:5px;
  margin-top: 1px;
  font-size: 7px;
  text-align: left;
  color: #3b0217;
  `;
  const Spec = styled.Text`
    font-family:'OpenSansFontBold';
    paddingLeft:5px;
    margin-top: 2px;
    margin-buttom: 2px;
    font-size: 7px;
    text-align: left;
    color: #042e6e;
    `;


  const Qty = styled.Text`
  font-family:'OpenSansFontBold';
  marginTop:3px;
  font-size: 10px;
  text-align: center;
  width:50px;
  `;
  const Unit = styled.Text`
  font-family:'OpenSansFontBold';
  margin-top: 2px;
  font-size: 7px;
  text-align: center;
  width:50px;
  `;


  const Tax = styled.Text`
  font-size: 8px;
  text-align: center;
  width:25px;
  `;
  const DovizPrice = styled.Text`
  font-size: 8px;
  text-align:right;
  width:60px;
  font-family:'ClearSans';
  `;
  const TLPrice = styled.Text`
  font-size:8px;
  text-align: right;
  width:60px;
  color:red;
  `;

  const TLTotal = styled.Text`
  font-size: 8px;
  text-align: right;
  width:75px;
  color:red;
  `;
  const DovizTotal = styled.Text`
  font-size: 8px;
  text-align: right;
  width:75px;
  font-family:'ClearSans';
  `;

const OfferTableRow = ({data,doviz,messages}) => {
    console.log(data)
    const OfferrCurrency = doviz === "$" ?  'USD' :  doviz === "€" ? 'EUR' : "TRY"
    const ProductCurrency = data.urun_dovizi === "$" ?  'USD' :  data.urun_dovizi === "€" ? 'EUR' : "TRY"
    const tlopen = false;
    const urun_fiyati = (Number(data.urun_fiyati).toLocaleString('tr-TR', { style: 'currency', currency: ProductCurrency }))
    const toplamDoviz = (Number(data.toplamDoviz).toLocaleString('tr-TR',{ style: 'currency', currency: OfferrCurrency }))
    const GtoplamDoviz = (Number(data.toplamDoviz+data.toplamKdvDoviz).toLocaleString('tr-TR',{ style: 'currency', currency: OfferrCurrency }))
    const urun_miktari = Number(data.urun_miktari).toLocaleString('tr');
    const urun_fiyatiTL = (Number(data.toplamTL/data.urun_miktari).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }));
    const totalTL = (Number(data.toplamTL).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }));
    const gtotalTL = (Number(data.toplamTL+data.toplamKdvTL).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }));
    let img = blank;
    let category = null
    if(data.urun_resim ){
        img = data.urun_resim;
    }else{
        img = blank ;
    }
    if(data.urun_id &&  data.urun_id.model_category){
        category = data.urun_id.model_category;
    }else{
        category = 9 ;
    }

    let code = {};
    code = getProductCode("",data.urun_kodu[0],data.urun_kodu[1],data.urun_kodu[2],data.urun_kodu[3],data.urun_kodu[4],data.urun_kodu[5])
    return(

        <View style={styles.rowContainer}>
            <Link src={"https://moduled.akaysis.com/web/productdetails/"+(data  && data.urun_id  ? data.urun_id : "404")}>
                <Image style={styles.ProductImages} src={img}/>
            </Link>
            <View style={styles.ProductNameContainer}>
                <ProductName style={styles.ProductName}>{data.urun_adi}</ProductName>
                <ProductCode style={styles.ProductName}>{code}</ProductCode>
                {data.order_type === 'product' && (category < 7)?
                <>
                    {category !== 6 ?<Spec>{ messages["product.bodycolor"]+" :"+(data.urun_kodu[6]!==null ?   data.urun_kodu[6]:"Lütfen Yazılı Belirtiniz")}</Spec>:null}
                    <Spec>{ messages["product.cct"]+" :"+(data.urun_kodu[3]!==null ?   data.urun_kodu[3]+" Kelvin":"Lütfen Işık Rengini Belirtiniz")}</Spec>
                </>:null}

  
            </View > 
            <View style={styles.QTYContainer}>
                <Qty>{urun_miktari} </Qty>
                <Unit>{messages["genel."+data.urun_birimi]}</Unit>
            </View >
            <Tax>{"%"+data.urun_kdv}</Tax>
            {tlopen ?
            <View style={styles.ProductPriceContainer}>
                <DovizPrice>{urun_fiyati}</DovizPrice>
                <TLPrice>{urun_fiyatiTL}</TLPrice>
            </View>
            :

                  <DovizPrice>{doviz === '₺' ? urun_fiyatiTL : urun_fiyati}</DovizPrice>

            }
            {tlopen?
            <View style={styles.totalContainer}>
                <DovizTotal>{toplamDoviz}</DovizTotal>
                <TLTotal>{totalTL}</TLTotal>
            </View>:
                <DovizTotal>{toplamDoviz}</DovizTotal>
            }           
             {tlopen?
            <View style={styles.totalContainer}>
                <DovizTotal>{GtoplamDoviz}</DovizTotal>
                <TLTotal>{gtotalTL}</TLTotal>
            </View>:
                <DovizTotal>{GtoplamDoviz}</DovizTotal>
            }            


        </View>

  
)};
  
export default OfferTableRow