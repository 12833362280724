import {
	TASK_DETAILS_ACTION,
	TASK_DETAILS_SUCCESS,
    TASK_DETAILS_ERROR,
	CREATE_TASK_ACTION,
	CREATE_TASK_SUCCESS,
	CREATE_TASK_ERROR,
	UPDATE_TASK_ACTION,
	UPDATE_TASK_SUCCESS,
	UPDATE_TASK_FAILURE,
	DELETE_TASK_ACTION,
	DELETE_TASK_SUCCESS,
	DELETE_TASK_FAILURE,
} from '../actions';

const INIT_STATE = {
	taskDetail: {},
	taskDetailStatus:null,
	taskDetailLoading:false
};

const  taskDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case TASK_DETAILS_ACTION: //GET TASK DETAILS
			return { 
				...state, 
				taskDetailLoading: true,
				taskDetailStatus:null
			};
		case TASK_DETAILS_SUCCESS:
            return { ...state,
				taskDetailLoading: false,  
                 taskDetail: action.response,
				 taskDetailStatus:200
				};
		case TASK_DETAILS_ERROR:
            return { 
				...state, 
				taskDetailLoading: false,
				taskDetailStatus:action.response 
			};	
            	
		case CREATE_TASK_ACTION: //CREATE TASK
			return { 
				...state, 
				taskListLoading: true,
				taskDetailStatus:null
			};
		case CREATE_TASK_SUCCESS:
			return { 
				...state, 
				taskDetailLoading: false, 
				taskDetail: action.response,
				taskDetailStatus:200
			};
		case CREATE_TASK_ERROR:
			return { 
				...state, 
				taskDetailLoading: false,
				taskDetail:{},
				taskDetailStatus:action.response 
			};	
		case UPDATE_TASK_ACTION: //UPDATE TASK
			return { 
				...state, 
				taskDetailLoading: true,
				taskDetailStatus:null
			};
		case UPDATE_TASK_SUCCESS: 
			return { 
				...state, 
				taskDetailLoading: false, 
				taskDetail: action.response,
				taskDetailStatus:200
			};
		case UPDATE_TASK_FAILURE:
			return { 
				...state, 
				taskDetailLoading: false,
				taskDetailStatus:action.response 
			}; 
		case DELETE_TASK_ACTION: //DELETE TASK
			return { 
				...state, 
				taskDetailLoading: true,
				taskDetailStatus:null
			};
		case DELETE_TASK_SUCCESS:
			return { 
				...state, 
				taskDetailLoading: false,
				taskDetailStatus:200  
			};
		case DELETE_TASK_FAILURE:
			return { 
				...state, 
				taskDetailLoading: false ,
				taskDetailStatus:action.response
			};
		default: return state;
	}
}
export default taskDetails