import React from 'react';
import { Input, Form, Modal,Button, Select } from 'antd';

const OptionModal = ({ messages,optionModalActive,toggleModal,onChangeOptionData,option,CCTList,optikList,productCodeIndex}) => {

  return (
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={optionModalActive}
      footer={          
        <div>
          <Button  size="large" type="primary" onClick={() => toggleModal("optionModalActive",null)}>
            {messages["button.close"]}
          </Button>
        </div>}
      onCancel={() => toggleModal("optionModalActive",null)}
      forceRender
      width={300}
      closable={false}
    >
      <Form.Item rules={[{ required: true, message: 'Please add a Title' }]} >
        {productCodeIndex ===3 ?
        <Select  options={CCTList} defaultValue={option} onSelect={(eData) => onChangeOptionData(eData)}/>
        :productCodeIndex ===2 ?
        <Select  options={optikList} defaultValue={option} onSelect={(eData) => onChangeOptionData(eData)}/>
        :
        <Input value={option} onChange={(eData) => onChangeOptionData(eData.target.value)}/>
        }
      </Form.Item>
    </Modal>
  );
}

export  {OptionModal};
