import React from 'react';
import { useSelector, useDispatch,useState } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCirclePlus,faAlignLeft,faAlignRight, faX ,faPlus} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
//import CreateProject from './overview/CreateProject';
import { MenuListWrapper,ListWrapper,SearchListSidebar,ListContent} from '../Style/style';
import {Row,Col,Spin,Input,Tag,notification} from 'antd';
import { Main } from '../Style/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Scrollbars } from 'react-custom-scrollbars';
import {ListMenu} from "../commonComponent/listMenu"
import {getUserList} from '../../redux/users/userListActions';
import blank from '../../static/img/resyok.jpeg';



const  Users =()=> {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = React.useState();
  const { data, loading,status } = useSelector((state) => state.userList);
  const {userFilter,ColorList} = useSelector(state => state.App);
  const { messages } = useIntl();
  let history = useHistory();
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const left = !rtl ? 'left' : 'right';
  const [state, setState] = React.useState({
    visible: false,
    collapsed: false,
    userFilter:"Tümü"
  });
  const {collapsed} =state;
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    dispatch(getUserList('list',null));
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
      setDataSource(data)
  }, [data]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(status && status.status===401){
      notification.warning({
        message: status.statusText,
        description: status.data,
        duration:1
      });
      history.push({pathname:"/admin/401"})
    }else if (status===200){
      notification.info({
        message: 'Bilgi !',
        description: (data && data.length && data.length) + ' Adet Kullanıcı Listelendi..',
        duration:1
      });   
    }
  }, [status]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = (searchText) => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.firstname.toLowerCase().includes(searchText.toLowerCase()) })
    if (!!searchText){
      setDataSource(filteredList);
    }else{
      setDataSource(data);
    }
  };
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      dispatch(getUserList('list',null))
    }else{
      dispatch(getUserList("category",filterVal))
    }
    setState({
      ...state,
      userFilter:filterVal,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const NewUsers = () => {
    history.push("/admin/userdetails/new")
  }
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader title={messages["sidebar.users"]}/>
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
                <ListMenu 
                  MenuList={userFilter} 
                  messages={messages} 
                  activeFilter={state.userFilter} 
                  newData={NewUsers}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  onChangeFilter={onChangeFilter}
                />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              <SearchListSidebar>
                <Cards headless>
                  <div className="chatbox-search">
                    <Input  style={{width:'100%'}} onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                  </div>
                  <Spin spinning={loading} delay={100}>
                    <ListContent style={{height:'710px'}}>
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={renderThumbHorizontal}
                        renderThumbVertical={renderThumbVertical}
                        renderView={renderView}
                        renderTrackVertical={renderTrackVertical}
                      >
                        <ListWrapper>
                          <ul>
                            {dataSource &&
                              dataSource.length ? 
                              dataSource
                                .sort((a, b) => {
                                  return a.firstname.localeCompare - (b.firstname);
                                })
                                .map((user, key) => {
                                  const { firstname, lastname, usercompany_id, role,status,_id ,user_img} = user;
                                  const color = ColorList && ColorList[role] && ColorList[role].value ? ColorList[role].value : "gold";
                                  return (
                                    <li key={key} className="chat-link-signle" >
                                      <NavLink to={`/admin/userdetails/${_id}`}>
                                        <div className="author-figure">
                                          <img src={user_img ? user_img : blank} alt="Moduled" />
                                          <span className={true ? 'active' : 'inactive'} />
                                        </div>
                                        <div className="author-info">
                                          <span className="author-name" style={{display: 'block'}}>{firstname+" "+lastname}</span>
                                          <span className="author-chatText" >
                                            {usercompany_id && usercompany_id.length && usercompany_id[0] ? usercompany_id[0].company_name : "Firma Seçilmemiş"}
                                          </span>
                                        </div>
                                        <div className="author-chatMeta">
                                          <span>{status}</span>
                                          <Tag color={color} >{messages["user."+role]}</Tag>
                                        </div>
                                      </NavLink>
                                    </li>
                                  );
                                }):null}
                          </ul>
                        </ListWrapper>
                      </Scrollbars>
                    </ListContent>
                  </Spin>
                </Cards>
              </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 
    </>
  );
}



export  {Users};
