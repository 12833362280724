import React from 'react';
import { Table } from 'antd';

const ElectricalInfo = ({electricalInfo,messages,productDetailLoading,lang}) => {
  let info = electricalInfo && electricalInfo.length && electricalInfo.map((el, idx) => ({key: idx, ...el}))

  const electricalInfoColumn = [
    {
      title: [messages["genel.sira"]],
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => b.no - a.no,
      sortOrder:'descent',
      align: 'left',
      render: (text) => text+1,
      width: '10%',
    },
    {
      title: [messages["model.label"]],
      dataIndex: 'label',
      key: 'label',
      align: 'left',
      render: (text) => 
      <div style={{display:'flex',flexDirection:'column'}}>
        {text[lang]}
      </div>,
      width: '35%',
    },
  
    {
      title: [messages["product.specifications"]],
      dataIndex: 'value',
      key: 'value',
      align: 'left',
      render: (text) =>    
      <div style={{display:'flex',flexDirection:'column'}}>
        {text[lang]}
      </div>,
      width: '55%',
    }
  ];

  return (
      <div className="table-bordered top-seller-table table-responsive">
        <Table 
          columns={electricalInfoColumn} 
          dataSource={info} 
          pagination={false} 
          loading={productDetailLoading}
        />
      </div>
  );
}

export {ElectricalInfo};
