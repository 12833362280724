import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint, faSpinner,faTrash} from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,message,Spin,Popconfirm,Popover} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {PopupChoser} from '../../../components/PopupChoser';
import Heading from '../../../components/heading/heading';
import {updateDelivery} from '../../../redux/deliveryDetails/deliveryDetailActions';
import {getDeliveryList} from '../../../redux/deliveryList/deliveryListActions';
import { deleteDelivery } from '../../../redux/deliveryDetails/deliveryDetailActions';

const CompanyDelivery = ({messages,deliveryStatus,companyID,toggleModal,deliveryListLoading,company_doviz,openPrintModal}) => {
  const {deliveryDetailLoading,deliveryList} = useSelector(state => state.deliveryList);
  const [dataSource, setDataSource] = React.useState();
  let deliverylist = [];
  React.useEffect(() => {
    let totalTL = 0 ; 
    let totalUSD = 0 ;
    for (let i of deliveryList) { 
      if (company_doviz ==="₺"){
        if(i.sumPriceTl && i.status<2){
          totalTL = totalTL + i.sumPriceTl
        }
      }else{
        if(i.sumPriceTl && i.status<2){
          totalUSD = totalUSD + i.sumPriceUsd
        }
      }
      let tempData={
        _id:i._id,
        date : i.date,
        productname : i.productname,
        qty : i.qty,
        unit : i.unit,
        price : i.price,
        doviz : i.doviz,
        usd : i.usd,
        eur : i.eur,
        sumPrice : i.sumPrice,
        sumPriceTl : i.sumPriceTl,
        sumPriceUsd : i.sumPriceUsd,
        status : i.status,
        receiver : i.receiver,
        deliverer : i.deliverer,
        runTotal: company_doviz==="₺" ? totalTL : totalUSD
      }
      deliverylist.push(tempData);
      
    };
    setDataSource(deliverylist)
   },[deliveryList]);

   let delivery = dataSource && dataSource.length && dataSource.map((el, idx) => ({key: idx, ...el}));

  const dispatch = useDispatch();
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>toggleModal("deliveryListModalActive",true)}>
        <span><FontAwesomeIcon icon={faPrint} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.print"]}</span>
      </NavLink>
  
    </>
  );
  //******************************************************************************************************************************************************************/
  const onChangeDeliveryRowStatus =(data,field,value)=>{
    let temp = data;
    temp[field]=value
    console.log(temp)
    dispatch(updateDelivery(temp))
    .then(res => {
        message.success(messages["alert.savesuccess"],2);
        dispatch(getDeliveryList("",companyID))
    })
  }
  //******************************************************************************************************************************************************************/
  const deleteDeliveryRow = (record) => {
    if(record._id){
      dispatch(deleteDelivery(record))
      .then(x=>{
          dispatch(getDeliveryList("",companyID))
          message.success(messages["alert.deletesuccess"],2);
      })   
    }
  }
  //******************************************************************************************************************************************************************/
  const deliveryColumn = [
    {
      title: [messages["genel.date"]],
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (text) => moment(text).format("DD.MM.YYYY")
    },
    {
      title: [messages["genel.qty"]],
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',
      render: (text,record) => text +" "+record.unit,
      width: '12%',
    },


    {
      title: messages["product.price"],
      dataIndex: 'price',
      key: 'price',
      width: '12%',
      render: (text,record) => text +" "+record.doviz,
      align:'right'
    },
    {
      title: messages["product.totalprice"],
      dataIndex: 'runTotal',
      key: 'runTotal',
      render: (text,record) => text.toFixed(2) +" "+company_doviz,
      width: '18%',
      align:'right'
    },
    {
      title: messages["product.price"],
      dataIndex: 'sumPrice',
      key: 'sumPrice',
      render: (text,record) => text +" "+record.doviz,
      width: '15%',
      align:'right'
    },
    {
      title: [messages["delivery.status"]],
      dataIndex: 'status',
      key: 'status',
      align:'center',
      render: (text,record) =>                
      <PopupChoser
        option={deliveryStatus}
        choseData={(e)=>onChangeDeliveryRowStatus(record,'status',e)}
        selectedData={text}
      /> ,
      width: '15%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '13%',
      render: (text,record) => 
        <>
          <Popover placement="right" title={<h5>{messages["delivery.details"]}</h5>} 
            content={
              <div>
                <h5>{messages["product.name"] + " " + record.productname }</h5>
                <h5>{messages["delivery.receiver"] + " "+ record.receiver }</h5>
                <h5>{messages["delivery.deliverer"] + " " + record.deliverer }</h5>
              </div>}
            >
              <Button
                shape="circle"
                size="small"
                style={{marginLeft:"5px"}}
              >
                <FontAwesomeIcon icon={faEdit} style={{color:' #2855eb'}} size='sm' onClick={() => toggleModal("deliveryModalIndex",record.key)}/>
              </Button>  
          </Popover>
          <Popconfirm
            title={messages["alert.deletequest"]}
            okText={messages["genel.yes"]}
            cancelText={messages["genel.no"]}
            onConfirm={()=>deleteDeliveryRow(record)}
          >
            <Button
              shape="circle"
              size="small"
            >
              <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
            </Button>  
          </Popconfirm>


      </>
      
    }
  ];

  return (

      <Cards
        title={ <Heading as="h5">{messages["company.deliveryList"]}</Heading>}
        size="large"
        bodypadding="0px"
        more={moreContent}
        loading={deliveryDetailLoading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <div className="table-bordered top-seller-table table-responsive">
          <Table 
            columns={deliveryColumn} 
            loading={deliveryListLoading}
            dataSource={delivery} 
            pagination={false}     
            scroll={{ y: 635 }}
          />
        </div>
      </Cards>
  
  );
}

export {CompanyDelivery};
