import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus,faCopy,faPaste} from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,Popconfirm, Popover,Tag} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';



const MechanicalInfo = ({mechanicalInfo,messages,productDetailLoading,toggleModal,addNewMechanicalInfoRow,removeMechanicalInfoRow,dataCopy,dataPaste}) => {
  let info = mechanicalInfo && mechanicalInfo.length && mechanicalInfo.map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>addNewMechanicalInfoRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>dataCopy("mechanicalInfo")}>
        <span><FontAwesomeIcon icon={faCopy} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.copy"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>dataPaste("mechanicalInfo")}>
        <span><FontAwesomeIcon icon={faPaste} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.paste"]}</span>
      </NavLink>
  
    </>
  );
  
  const mechanicalInfoColumn = [
    {
      title: [messages["genel.sira"]],
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => b.no - a.no,
      sortOrder:'descent',
      align: 'left',
      render: (text) => text,
      width: '15%',
    },
    {
      title: [messages["product.label"]],
      dataIndex: 'label',
      key: 'label',
      align: 'left',
      render: (text) => 
      <div style={{display:'flex',flexDirection:'column'}}>
        <Tag className='blue' style={{marginBottom:'5px'}}>{text[0]}</Tag>
        <Tag className='yellow'>{text[1]}</Tag>
      </div>,
      width: '35%',
    },
  
    {
      title: [messages["product.spec"]],
      dataIndex: 'value',
      key: 'value',
      render: (text) =>    
      <div style={{display:'flex',flexDirection:'column'}}>
        <Tag className='blue' style={{marginBottom:'5px'}}>{text[0]}</Tag>
        <Tag className='yellow'>{text[1]}</Tag>
      </div>,
      width: '35%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("mechanicalInfoModalIndex",record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeMechanicalInfoRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];

  return (
    <div className="full-width-table" >
      <Cards
        title={messages["product.mechanicalinfo"]}
        size="large"
        bodypadding="0px"
        more={moreContent}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'730px'}}>
          <Table 
            columns={mechanicalInfoColumn} 
            dataSource={info} 
            pagination={false} 
            loading={productDetailLoading}
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  );
}

export {MechanicalInfo};
