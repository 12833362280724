import {
	TASK_DETAILS_ACTION,
	TASK_DETAILS_SUCCESS,
    TASK_DETAILS_ERROR,
	CREATE_TASK_ACTION,
	CREATE_TASK_SUCCESS,
	CREATE_TASK_ERROR,
	UPDATE_TASK_ACTION,
	UPDATE_TASK_SUCCESS,
	UPDATE_TASK_FAILURE,
	DELETE_TASK_ACTION,
	DELETE_TASK_SUCCESS,
	DELETE_TASK_FAILURE,
} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';

// POST - TASK Create ****************************************************************************************************************************************************
export const create_task_pending = () => ({
	type: CREATE_TASK_ACTION
});

export const create_task_success = (res) => ({
	type: CREATE_TASK_SUCCESS,
	response: res
});

export const create_task_failure = (res) => ({
	type: CREATE_TASK_ERROR,
	response:res
});

export const createTask = (data) => dispatch => {
    const url = `/tasks/create/`;
	dispatch(create_task_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_task_success(res.data));
				resolve(true);
			} else {
				dispatch(create_task_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_task_failure(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){
			}else{
			}
			reject(err);
		});
	});
}
// update TASK ****************************************************************************************************************************************************
export const update_task_pending = (taskID) => ({
	type: UPDATE_TASK_ACTION,
	taskID: taskID
});

export const update_task_successful = (res) => ({
	type: UPDATE_TASK_SUCCESS,
	response: res
});

export const update_task_failed = (res) => ({
	type: UPDATE_TASK_FAILURE,
	response:res
});

export const updateTask = (data) => dispatch => {
	
	const url = `/tasks/update/${data._id}`
	dispatch(update_task_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_task_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_task_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_task_failed(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}

// delete TASK ****************************************************************************************************************************************************
export const delete_task_pending = () => ({
	type: DELETE_TASK_ACTION,
});

export const delete_task_successful = (res) => ({
	type: DELETE_TASK_SUCCESS,
	response:res
	
});

export const delete_task_failed = (res) => ({
	type: DELETE_TASK_FAILURE,
	response:res
});

export const deleteTask = (id) => dispatch => {
	const url = `/tasks/delete/${id}` 
	dispatch(delete_task_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				//console.log(res)
				dispatch(delete_task_successful(res.data));        
				resolve(true);
			} else {
				dispatch(delete_task_failed());

				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_task_failed(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
