import React from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import {useIntl} from "react-intl";
import {  useSelector } from 'react-redux';
import QControl from "./QControl/Type0/QControl";
import { Spin} from 'antd';

const QualityControl = ({index})=> {

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);

  const { data, loading } = useSelector(state => state.buyingDetail);

  const { messages } = useIntl();
  const Lang = locale === 'tr' ? 0 :1

//console.log(index)
    return !loading &&  !myCompanyLoading ?  (
        <div>
          <PDFViewer  width="100%" height={600}>
            <QControl data={data} company={myCompany} Lang={Lang} messages={messages} index={index}/>
          </PDFViewer>      
        </div>
    ) : (
      <div className="spin">
        <Spin />
      </div>
    );
    
}
export {QualityControl}
