import {
	DELIVERY_DETAILS_ACTION,
	DELIVERY_DETAILS_SUCCESS,
    DELIVERY_DETAILS_ERROR,
	CREATE_DELIVERY_ACTION,
	CREATE_DELIVERY_SUCCESS, 
	CREATE_DELIVERY_ERROR,
	UPDATE_DELIVERY_ACTION,
	UPDATE_DELIVERY_SUCCESS, 
	UPDATE_DELIVERY_ERROR,
	DELETE_DELIVERY_ACTION,
	DELETE_DELIVERY_SUCCESS,
	DELETE_DELIVERY_FAILURE
} from '../actions';

const INIT_STATE = {
	deliveryDetail: {},
	deliveryDetailLoading:false,
	deliveryDetailStatus:null
};

const deliveryDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case DELIVERY_DETAILS_ACTION: //GET DELIVERY DETAILS
			return { 
				...state, 
				deliveryDetailLoading: true,
				deliveryDetailStatus:null 
			};
		case DELIVERY_DETAILS_SUCCESS:
            return { 
				...state,
				deliveryDetailLoading: false,  
                deliveryDetail: action.response,
				deliveryDetailStatus:200
			};
		case DELIVERY_DETAILS_ERROR:
            return { 
				...state, 
				deliveryDetailLoading: false,
				deliveryDetailStatus:action.response
			};	
		case UPDATE_DELIVERY_ACTION: //UPDATE
			return { 
				...state,
				deliveryDetailLoading: true,
				deliveryDetailStatus:action.response
			};
		case UPDATE_DELIVERY_SUCCESS:
			return { 
				...state, 
				deliveryDetailLoading: false, 
				deliveryDetail: action.response,
				deliveryDetailStatus:200
			};
		case UPDATE_DELIVERY_ERROR:
			return { 
				...state, 
				deliveryDetailLoading: false, 
				deliveryDetailStatus:action.response
			};
		case CREATE_DELIVERY_ACTION: //CREATE DELIVERY
			return { 
				...state, 
				deliveryDetailLoading: true 
			};
		case CREATE_DELIVERY_SUCCESS:
			return { 
				...state, 
				deliveryDetailLoading: false, 
				deliveryDetail: action.response,
				deliveryDetailStatus:200
			};
		case CREATE_DELIVERY_ERROR:
			return { 
				...state, 
				deliveryDetailLoading: false, 
				deliveryDetailStatus:action.response 

			};	
		case DELETE_DELIVERY_ACTION: //DELETE DELIVERY
			return { 
				...state, 
				deliveryDetailLoading: true 
			};
		case DELETE_DELIVERY_SUCCESS:
			return {
				...state, 
				deliveryDetailLoading: false,
			};
		case DELETE_DELIVERY_FAILURE:
			return { 
				...state, 
				deliveryDetailLoading: false ,
				deliveryDetailStatus:action.response
			};
		default: return state;
	}
}
export default deliveryDetails;
