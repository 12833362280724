import React from 'react';
import { Tag, Row, Col,Progress,Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare,faPause,faPlayCircle,faListOl,faCheckDouble,faCheckCircle} from '@fortawesome/free-solid-svg-icons';

import { NavLink } from 'react-router-dom';
import Heading from '../../../components/heading/heading';
import { ProductCard ,ProjectCard} from '../../Style/style';
import {getProductCode} from '../../../helpers/getProductCode';
import moment from 'moment';
const LastCheckListCard = ({messages,blank,product})=> {

  const productImage = product.urun_id && product.urun_id.productImages && product.urun_id.productImages.length ? product.urun_id.productImages[0].image.smallimage : blank
  let color;let statusicon=faCheckSquare;
  if (product.siparis_durumu === 0 ){color = 'magenta'} 
  else if (product.siparis_durumu === 1){color = 'red',statusicon=faPause} 
  else if (product.siparis_durumu === 2){color = 'blue',statusicon=faPlayCircle} 
  else if (product.siparis_durumu === 3){color = 'yellow',statusicon=faListOl} 
  else if (product.siparis_durumu === 4){color = "#24bc42",statusicon=faCheckCircle} 
  else if (product.siparis_durumu === 5){color = '#24bc42',statusicon=faCheckDouble} 
  else {color = 'purple'};
  let orderType = "";
  let orderColor = "";
  if (product.order_type==="material"){
    orderType=messages["material.material"];
    orderColor="blue"
  }else{
    orderType=messages["product.name"];
    orderColor="green"
  }
  return (
    <ProductCard className="list-view" style={{ marginBottom: 20 }}>
      <div className="product-list">
        <Row gutter={10}>
          <Col md={4} xs={24}>
              <NavLink to={`/admin/lastcheckdetail/`+product._id}>
                <figure>
                  <img style={{ width: '100%' }} src={productImage} alt="" />
                </figure>
                  <Tooltip placement="right" title={messages["order.orderStatus"+product.siparis_durumu]}>
                    <FontAwesomeIcon
                      icon={statusicon}
                      size='lg'
                      style={{ textShadow: '0 1px 0 ',color:color }}
                    />
                  </Tooltip>
              </NavLink>
          </Col>
          <Col md={11} xs={24}>
            <div className="product-single-description">
              <Heading className="product-single-title" as="h6">
                <NavLink to={`/admin/projectdetails/`+product._id} >{product.urun_adi}</NavLink>
              </Heading>
              <p>{product.teklifno.firma_adi.slice(0,50)}</p>
              <p>{product.urun_kodu && getProductCode("",product.urun_kodu[0],product.urun_kodu[1],product.urun_kodu[2],product.urun_kodu[3],product.urun_kodu[4],product.urun_kodu[5])}</p>
            </div>

            <ProjectCard>
              <div className="project-timing">
                <div>
                  <span>{messages["order.date"]}</span>
                  <strong>{moment(product.siparis_tarihi).format("DD.MM.YYYY")}</strong>
                </div>
                <div>
                  <span>{messages["order.startdate"]}</span>
                  <strong>{product.baslama_tarihi ? moment(product.baslama_tarihi).format("DD.MM.YYYY"): "--.---.----"}</strong>
                </div>
                <div>
                  <span>{messages["order.enddate"]}</span>
                  <strong>{product.bitis_tarihi ?  moment(product.bitis_tarihi).format("DD.MM.YYYY") : "--.--.----"}</strong>
                </div>
              </div>
              <Tag color={product.ready ?"green":"red"}>{product.ready ?"Üretim için Hazır":"Malzeme Listesi Tamamlanmamış"}</Tag>
            </ProjectCard>
          </Col>
          <Col md={9} xs={24}>
            <div className="product-single-info">
              <NavLink  className="btn-heart" to="#">
                <Tag color={orderColor}>{orderType}</Tag>
              </NavLink>
              <p className="product-single-price">
                <span className="product-single-price__new"></span>
              </p>
              <ProjectCard>
                <div className="project-timing">
                  <div>
                    <span>{messages["order.componentstatus2"]}</span>
                    <strong>{product.urun_miktari} {messages["genel.Adet"]}</strong>
                  </div>
                  <div>
                    <span>{messages["order.orderStatus5"]}</span>
                    <strong>{product.teslim_edilmis} {messages["genel.Adet"]}</strong>
                  </div>
                </div>
  
                <div className="project-progress">
                  <Progress
                    percent={product.tamamlanmaOrani.toFixed(1)}
                    strokeWidth={5}
                    status="primary"
                    className="progress-primary"
                  />
                  {product.componentList && product.componentList.length && product.componentList.map((component,index)=>{
                    return(
                        <p key={index}>{component.material_category +" : "+ (component.material && component.material.material_name ? component.material.material_name  :"Malzeme Seçiniz")+" => "+(component.material && component.material.stockSum ? component.material.stockSum +" "+component.material.material_unit+" Stok":"")}</p>
                    )})
                }  
                </div>
              </ProjectCard>
            </div>
          </Col>
        </Row>
      </div>
    </ProductCard>
  );
}


export {LastCheckListCard};
