import React from 'react';
import {Modal} from 'antd';
import {DeliveryReport}  from '../../../reports/DeliveryReport';


const DeliveryPrintModal = ({deliveryPrintModalActive,toggleModal}) => {
  
  return (
    <>

      {deliveryPrintModalActive && 
      <Modal
        forceRender
        type='primary'
        open={deliveryPrintModalActive}
        closable={false}
        footer={null}
        width={1000}
        onCancel={() => toggleModal("deliveryPrintModalActive",null)}
      >
        <DeliveryReport/>
      </Modal>}
    </>

  );

}



export  {DeliveryPrintModal};