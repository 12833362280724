import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import blank from '../../static/img/resyok.jpeg';
import {getProductCode} from '../../helpers/getProductCode';
const styles = StyleSheet.create({
    ProductInfoContainer: {
        marginTop: 2,
        flexDirection: "row",
        width:550,
        border :1
    },
    ImageContainer:{
        flexDirection: "column",
        width:60,
        borderRight :1
    },
    ProductInfo:{
        flexDirection: "column",
        borderRight :1,
        width:300
    },
    QtyContainer: {
        width: 190
    },  
    ViewRowContainer:{
        flexDirection: "row",
        alignItems:"center"
    }
  });
  const Label = styled.Text`
  padding-left:2px
  justify-content: center;
  font-size: 7px;
  text-align: left;
  height :12px;
  width: 60px;
  `;
  const Field = styled.Text`
  padding-left:2px
  font-size: 7px;
  text-align: left;
  height :12px;
  color : grey
  `;
  const FieldQty = styled.Text`
  padding:1px
  font-size: 24px;
  text-align: center;
  height :36px;
  `;
  const FieldQtyCenter = styled.Text`
  font-size: 8px;
  text-align: center;
  height :16px;
  color : grey
  `;

const ProductInfo = ({data,Dil}) => {
    const {product,urun_adi,urun_kodu,urun_miktari,urun_birimi} = data;
    return(
        <View style={styles.ProductInfoContainer}>
            <Image style={styles.ImageContainer} src={product && product.imageData ? product.imageData: blank}/>
            <View style={styles.ProductInfo}>
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "ÜRÜN :" : "PRODUCT : "}</Label>
                    <Field>{urun_adi && urun_adi}</Field>
                </View> 
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "ÜRÜN KODU :" : "PRODUCT CODE : "}</Label>
                    <Field>{urun_kodu && getProductCode("",urun_kodu[0],urun_kodu[1],urun_kodu[2],urun_kodu[3],urun_kodu[4],urun_kodu[5])}</Field>
                </View>
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "Gövde RENGİ :" : "Body Color : "}</Label>
                    <Field>{urun_kodu && urun_kodu[6]}</Field>
                </View>
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "LED :" : "LED Mark : "}</Label>
                    <Field>{urun_kodu && urun_kodu[7]}</Field>
                </View>
                <View style={styles.ViewRowContainer}>
                    <Label>{Dil==="tr" ? "LED Sürücü :" : "LED Drivers : "}</Label>
                    <Field>{urun_kodu && urun_kodu[8]}</Field>
                </View>
            </View>    
            <View style={styles.QtyContainer}>
                <FieldQty>{urun_miktari && urun_miktari}</FieldQty>
                <FieldQtyCenter>{urun_birimi && urun_birimi}</FieldQtyCenter>
            </View> 
        </View>
)};
  
export default ProductInfo