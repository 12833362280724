import {

	TCMB_ACTION,
	TCMB_SUCCESS,
	TCMB_ERROR
} from '../actions';

const INIT_STATE = {
	tcmb:null,
	loading:null
};
const tcmb = (state = INIT_STATE, action) => {
	switch (action.type) {
		
		case TCMB_ACTION: //GET OFFER DETAILS
			return { ...state ,loading: true};
		case TCMB_SUCCESS:
			return { ...state, loading: false, tcmb: action.response };
		case TCMB_ERROR:
			return { ...state, loading: false, status:action.response};	
		default: return state ;
	}
}
export default tcmb