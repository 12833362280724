import React from 'react';
import { Button ,Popover} from 'antd';
import PopupChooserDropdown from './PopupChoser.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useIntl} from "react-intl";
import {MenuOutlined } from '@ant-design/icons';
import { faList, faPrint } from '@fortawesome/free-solid-svg-icons';

export default function ({ option, selectedData, choseData ,disabled}) {
  const [visible, setVisibility] = React.useState(false);
  const { messages } = useIntl();
  function hide() {
    setVisibility(false);
  }
  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }
  const content = () => (
    <PopupChooserDropdown disabled={disabled}>
      {option.length >0 && !disabled ? 
      option.map((option, index) => {
        const onClick = () => {
          hide();
          choseData(option.value)
        };
        return <Popover content= {option.popovercontent} placement="bottom" key={index}>
                <Button shape="circle"  onClick={onClick} size='large' icon={option.icon} style={{marginRight:'5px'}}/>
              </Popover>  
        ;
      })
      :
      null }
    </PopupChooserDropdown>
  );
  return (
    <Popover
      content={content()}
      trigger="click"
      open={visible}
      placement='bottom'
      onOpenChange={handleVisibleChange}
    >
      <Popover content= {messages["button.list"]} placement="bottom">
        <Button shape="circle"  size='large' icon={<FontAwesomeIcon icon={faPrint} />} style={{marginRight:'10px'}}/>
      </Popover>
    </Popover>
  );
}
