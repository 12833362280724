import React, {Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import {  useSelector } from 'react-redux';
import {DeliveryReportPage} from "./DeliveryReport/DeliveryReport";
import {useIntl} from "react-intl";
import { Spin } from 'antd';


const DeliveryReport = () => {
  const {deliveryList,deliveryListLoading} = useSelector(state => state.deliveryList);
  const {orderItemDetail} = useSelector(state => state.orderItemDetail);
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
 

  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);
  const { messages } = useIntl();
  const Lang = locale === 'tr' ? 0 :1

    return  (
        <Fragment>
          { !deliveryListLoading && !myCompanyLoading  ?                
          <PDFViewer  width="100%" height={600}>
              <DeliveryReportPage orderItem={orderItemDetail}company={myCompany} Lang={Lang} messages={messages}/>
          </PDFViewer>
          :
          <div  className="spin">
            <Spin />
          </div>}
        </Fragment>
    )

}

export {DeliveryReport}
