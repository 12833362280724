import React from 'react';
import { Row, Col, Form, Input,Spin} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const CompanyTaxInfo =({data,loading,messages,updateData,setSubmittable})=> {
  const [form2] = Form.useForm();
  React.useEffect(() => {
    form2.setFieldsValue(data)
   }, [data]);
   const values = Form.useWatch([], form2);

   React.useEffect(() => {
    form2.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]); 

  return (
      <Cards
        title={<Heading as="h5">{messages["company.taxinfo"]}</Heading>}
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <FormWrapper>
          <Form  form={form2} initialValues={data} name="editAccount">
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.taxoffice"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_vergidairesi" rules={[{ message:  'Please input your Tax Office!'}]} >
                  <Input onChange={(eData)=>updateData("sirket_vergidairesi",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.taxno"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_verginno" rules={[{ message:  'Please input your Tax No!'}]} >
                  <Input onChange={(eData)=>updateData("sirket_verginno",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>               
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.mersis"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_mersis"  >
                  <Input onChange={(eData)=>updateData("sirket_mersis",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>     
          </Form>
        </FormWrapper>
      </Cards>
  );
}

export  {CompanyTaxInfo};
