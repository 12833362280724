import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
   
    TitleContainer:{
        flexDirection: "row",
        backgroundColor:"#666666",
        width:400,
        height:14,
        fontFamily: 'OpenSansFontBold'
    },
});

const Titlestyle = styled.Text`
    font-size: 9px;
    color:#ffffff;
    text-align: center;
    width: 400px;
`;
  const Title = ({title}) => {
    return (
    <View style={styles.TitleContainer}>
       <Titlestyle>{title}</Titlestyle>
    </View>
  )};
  
  export default Title