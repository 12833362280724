import React, { Fragment } from 'react';
import {  useSelector } from 'react-redux';
import { Spin} from 'antd';
import {PDFViewer} from '@react-pdf/renderer'
import OfferWorkOrders from "./OrderWorkOrders/WorkOrder" ;

const WorkOrderReport = () => {

  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);
  const { orderItemDetail, orderItemDetailLoading } = useSelector(state => state.orderItemDetail);
  const {componentList,componentListLoading} = useSelector(state => state.componentList);
  const {taskList} = useSelector(state => state.taskList);
     
    return !orderItemDetailLoading &&  !myCompanyLoading && !componentListLoading ?  
    (
      <Fragment>
        { orderItemDetail && myCompany && componentList && taskList?                
        <PDFViewer  width="100%" height={600}>
            <OfferWorkOrders data={orderItemDetail} company={myCompany} components={componentList} tasks={taskList} />
        </PDFViewer>:
        <Spin />
        }       
      </Fragment>
    ) : (
      <div className="spin">
        <Spin />
      </div>
    );
    
}

export  {WorkOrderReport};



