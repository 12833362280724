import React from 'react';
import { Input, Form, Modal,Button,InputNumber } from 'antd';
import { BasicFormWrapper } from '../../Style/style';

const { TextArea } = Input;

const EntryControlModal = ({ question,entryControlRowModalActive,updateEntryControlRow,messages,toggleModal}) => {
  const [form2] = Form.useForm();
  React.useEffect(() => {
    question && form2.setFieldsValue(question)
   }, [question]);
  return (
  <>
  {entryControlRowModalActive && question &&
    <Modal
      title={messages["genel.edit"]}
      className="sDash_addTask-modal"
      type='primary'
      open={entryControlRowModalActive}
      footer={null}
      onCancel={() => toggleModal("entryControlRowModalActive",null)}
      forceRender
      closable={false}
    >
      <BasicFormWrapper>
        <Form form={form2} name="add-task" onFinish={(eData) => updateEntryControlRow(eData)} initialValues={question}>
          <Form.Item name="no" label={messages["genel.sira"]} >
            <Input type='number' max={8}/>
          </Form.Item>
          <Form.Item name="Question" label={messages["buying.qcQuestion"]} >
            <TextArea  rows={3}/>
          </Form.Item>



          <div className="sDash-modal-actions">
            <Button  size="small" type="primary" htmlType="submit" >
              {messages["button.save"]}
            </Button>
          </div>
        </Form>
      </BasicFormWrapper>
    </Modal>
  }
  </>
  );
}

export  {EntryControlModal};
