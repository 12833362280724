/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faCircleCheck, faEllipsisV, faStar, faTrash} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Card } from '../../Style/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Dropdown } from '../../../components/dropdown/dropdown';
import { Bullet } from '../../Style/style';
import { Popconfirm } from 'antd';


const NoteCard = ( {data, Dragger,onUpdateTodo ,User,onDeleteTodo,messages}) => {

  const { task, read, importance, person, status,date,form,material_id,_id } = data;
   // console.log(data)
  let link= "/admin"
  const content = (
    <>
      <div className="nav-labels">
        <ul>
        {read.map((x,key)=>{return (
          <li key={key}>
            <Link  to="#">
              <Bullet className="personal" /> {x}
            </Link>
          </li>
        )})}
        </ul>
      </div>
    </>
  );
  if(form==='material'){
    link="/admin/materialdetails/"+material_id
  }


  return (
      <Card className={form} >
        <Cards headless>
          <Link to={link} >
            <h4>
              <span >
                {person}
                {read.filter(x=>x===User).length===0 && <span className={`status-bullet ${form}`} />}
              </span>
            
              <Dragger />
            </h4>
          </Link>
          <p onClick={() => onUpdateTodo(data,"read")} >{task +" "+moment(date).format("DD.MM.YYYY")}</p>
          <div className="actions">
            <span>
              <Link
                className={status ? 'star active' : 'star'}
                onClick={() => onUpdateTodo(data,"status")}
                to="#"
              >
                <FontAwesomeIcon icon={faCircleCheck} size='1x' />
              </Link>

              <Popconfirm
                  title={messages["alert.deletequest"]}
                  okText={messages["genel.yes"]}
                  cancelText={messages["genel.no"]}
                  onConfirm={()=>onDeleteTodo(_id)}
                >
              <Link  to="#">
                <FontAwesomeIcon icon={faTrash} size='1x' />
              </Link>
              </Popconfirm>
            </span>
            <Dropdown content={content}>
              <Link to="#">
                <FontAwesomeIcon icon={faEllipsisV} size='1x' />
              </Link>
            </Dropdown>
          </div>
        </Cards>
      </Card>
  );
};


export {NoteCard};
