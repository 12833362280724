import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCirclePlus,faAlignLeft,faAlignRight,faX, faDollarSign, faTurkishLira,faPlus} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import moment from 'moment';
import { MenuListWrapper,NoteNav ,Bullet ,ListWrapper,SearchListSidebar,ListContent} from '../Style/style';
import {Input,Spin,Tag,Col,Row,Button,Badge,notification,message} from 'antd';
import { Main } from '../Style/styled';
import {ListMenu} from "../commonComponent/listMenu";
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';
import { PageHeader } from '../../components/page-headers/page-headers';
import {getOfferList} from '../../redux/offerList/offerListActions';

import {currencyFormatter} from '../../helpers/currencyFormat';

import appActions from '../../redux/app/actions';

const defaultOptions = {
  significantDigits: 1,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ""
}
const tlOptions = {
  significantDigits: 1,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: "₺"
}

const  Offers =()=> {
  const { offerList, offerListLoading ,offerListStatus} = useSelector((state) => state.offerList);
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const [dataSource, setDataSource] = React.useState(offerList);
  const [totalUSD, setTotalUSD] = React.useState(0);
  const [totalTL, setTotalTL] = React.useState(0);

  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const lang = locale === 'tr' ? 0 :1;
  const left = !rtl ? 'left' : 'right';
  const { messages } = useIntl();
  const {offersFilter,OfferStatus} = useSelector(state => state.App);
  const { setOfferFilter } = appActions;
  let history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    visible: false,
    collapsed: false
  });
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(offerListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [offerListStatus]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(offersFilter!=="Tümü"){
      dispatch(getOfferList(1000,"date",offersFilter,offersFilter))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      })
    }else{
      dispatch(getOfferList(1000,"date",0,4))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      })
    }
    // eslint-disable-next-line
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    let totalGUSD = 0;
    let tottalGTL = 0;
    offerList && offerList.length && offerList.forEach(({ teklif_dovizi,teklif_gtoplamdoviz, teklif_gtoplamtl }) => {
      if (teklif_dovizi==="₺"){
        tottalGTL += teklif_gtoplamtl;
      }else if (teklif_dovizi==="$"){
        totalGUSD += teklif_gtoplamdoviz;
      }
    });
    setTotalTL(tottalGTL)
    setTotalUSD(totalGUSD)
    setDataSource(offerList)
    // eslint-disable-next-line
  }, [offerList]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = search => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.firma_adi.toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setDataSource(data)
    }else{
      setDataSource(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      dispatch(getOfferList(1000,"date",0,4));
      dispatch(setOfferFilter("Tümü"))
    }else{
      dispatch(getOfferList(1000,"date",filterVal,filterVal));
      dispatch(setOfferFilter(filterVal))
    }
    setTotalUSD(0);
    setState({
      ...state,
      collapsed:false,
    });
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const NewOffer = () => {
    history.push("/admin/offerdetails/new")
  }
  //******************************************************************************************************************************************************************/
  const more = 
   [ <div key={9999}>
      <li>
        <NavLink to="#" activeClassName="">
          <FontAwesomeIcon icon={faDollarSign} size='1x' />
          <span className="nav-text">
            <span>{(currencyFormatter(totalUSD,defaultOptions))}</span>
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="#" activeClassName="">
          <FontAwesomeIcon icon={faTurkishLira} size='1x' />
          <span className="nav-text">
            <span>{(currencyFormatter(totalTL,defaultOptions))}</span>
          </span>
        </NavLink>
      </li>
    </div>];

  return (
    <>
      <PageHeader title={messages["sidebar.offers"]}/>
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
              <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
                <ListMenu 
                  MenuList={OfferStatus} 
                  more={more}
                  MenuList2={null} 
                  messages={messages} 
                  activeFilter={offersFilter} 
                  activeFilter2={null} 
                  newData={NewOffer}       
                  collapsed={state.collapsed}
                  toggleCollapsed={toggleCollapsed}
                  onChangeFilter={onChangeFilter}
                  onChangeFilter2={null}
                />
              </Col>
              <Col xxl={18} xl={17} lg={16} xs={24}>
                <SearchListSidebar>
                  <Cards headless>
                    <div className="chatbox-search">
                      <Input  style={{width:'100%'}} onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                    </div>
                    <Spin spinning={offerListLoading} delay={100}>
                      <ListContent style={{height:'710px'}}>
                        <Scrollbars
                          className="custom-scrollbar"
                          autoHide
                          autoHideTimeout={500}
                          autoHideDuration={200}
                          renderThumbHorizontal={renderThumbHorizontal}
                          renderThumbVertical={renderThumbVertical}
                          renderView={renderView}
                          renderTrackVertical={renderTrackVertical}
                        >
                          <ListWrapper>
                            <ul>
                              {dataSource &&
                                dataSource
                                  .sort((a, b) => {
                                    return  new Date(b.teklif_tarihi)-new Date(a.teklif_tarihi)
                                  })
                                  .map((offer, key) => {
                                    const { firma_adi,teklif_konu,teklif_tarihi, teklif_gtoplamtl,teklif_gtoplamdoviz,_id,teklif_dovizi,teklif_durumu,offer_creator_user,offer_modified_user} = offer;
                                    let color;
                                    if (teklif_durumu === 0 ){color = 'red'} 
                                    else if (teklif_durumu === 1){color = 'yellow'} 
                                    else if (teklif_durumu === 2){color = 'blue'} 
                                    else if (teklif_durumu === 3){color = 'green'} 
                                    else if (teklif_durumu === 4){color = "dark"} 
                            
                                    return (
                                      <li key={key} className="chat-link-signle" >
                                        <NavLink to={`/admin/offerdetails/${_id}`}>
                                          <div className="author-figure">
                                            {teklif_dovizi==='₺' ?
                                            <FontAwesomeIcon icon={faTurkishLira} size='xl' />
                                            :
                                            <FontAwesomeIcon icon={faDollarSign} size='xl' />
                                            }
                                          </div>
                                          <div className="author-figure">
                                            <span>{moment(teklif_tarihi).format("DD.MM.YYYY")}</span>
                                            <Tag className={color} >{messages["offer.OfferStatus"+teklif_durumu]}</Tag>
                                          </div>
                                          <div className="author-info">
                                            <span className="author-name" style={{display: 'block'}}>{firma_adi.substring(0,50)}</span>
                                            <span className="author-chatText" >{offer_creator_user +" / "+offer_modified_user }</span>
                                          </div>
                                          <div className="author-chatMeta">
                                            <span>{currencyFormatter(teklif_gtoplamdoviz,defaultOptions)} {teklif_dovizi}</span>
                                            <span>{(currencyFormatter(teklif_gtoplamtl,tlOptions))}</span>
                                          </div>
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                            </ul>

                          </ListWrapper>
                        </Scrollbars>
                      </ListContent>
                    </Spin>
                  </Cards>
                </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 

    </>
  );
}



export  {Offers};
