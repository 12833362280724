import {
    BUYINGS_GET_LIST,
    BUYINGS_GET_LIST_SUCCESS,
	BUYINGS_GET_LIST_ERROR
} from '../actions';
const INIT_STATE = {
	buyingList: [],
	buyingListLoading:false,
	buyingListStatus:null
};

const buyings = (state = INIT_STATE, action) => {
	switch (action.type) {
		case BUYINGS_GET_LIST: // GEL LIST 
			return { 
				...state, 
				buyingListLoading: true 
			};
		case BUYINGS_GET_LIST_SUCCESS:
            return { 
				...state, 
                buyingListLoading: false, 
                buyingList: action.response.buyings,  
            };
		case BUYINGS_GET_LIST_ERROR:
			return { 
				...state, 
				buyingListLoading: false,
				buyingListStatus: action.response};
		default: return  state;
	}
}
export default buyings
