import {
	STOCK_DETAILS_ACTION,
	STOCK_DETAILS_SUCCESS,
    STOCK_DETAILS_ERROR,
	CREATE_STOCK_ACTION,
	CREATE_STOCK_SUCCESS, 
	CREATE_STOCK_ERROR,
	UPDATE_STOCK_ACTION,
	UPDATE_STOCK_SUCCESS,
	UPDATE_STOCK_FAILURE,
	DELETE_STOCK_ACTION,
	DELETE_STOCK_SUCCESS,
	DELETE_STOCK_FAILURE

} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';
// Get - stock Details ****************************************************************************************************************************************************
export const stock_details_pending = () => ({
	type: STOCK_DETAILS_ACTION
});

export const stock_details_success = (res) => ({
	type: STOCK_DETAILS_SUCCESS,
	response: res
});

export const stock_details_failure = () => ({
	type: STOCK_DETAILS_ERROR
});

export const getstockDetails = (id) => dispatch =>{
	const url = `/stock/${id.id}`
	dispatch(stock_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(stock_details_success(res.data))
                resolve(true);
            } else {
				dispatch(stock_details_failure());
                reject();
            }
        })
        .catch((err) => {
		
            dispatch(stock_details_failure());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
            reject(err);
            
        });
    });
};

// POST - Stock Create ****************************************************************************************************************************************************
export const create_stock_pending = () => ({
	type: CREATE_STOCK_ACTION
});

export const create_stock_success = (res) => ({
	type: CREATE_STOCK_SUCCESS,
	response: res
});

export const create_stock_failure = (response) => ({
	type: CREATE_STOCK_ERROR,
	response: response
});

export const createStock = (data) => dispatch => {
    const url = `/stock/create/`
	dispatch(create_stock_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_stock_success(res.data));

				resolve(true);
			} else {
				dispatch(create_stock_failure());

				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_stock_failure(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}
// update STOCK ****************************************************************************************************************************************************
export const update_stock_pending = (material_ID) => ({
	type: UPDATE_STOCK_ACTION,
	materialID: material_ID
});

export const update_stock_successful = (res) => ({
	type: UPDATE_STOCK_SUCCESS,
	response: res
});

export const update_stock_failed = (response) => ({
	type: UPDATE_STOCK_FAILURE,
	response: response
});

export const updateStock = (data) => dispatch => {
	const url = `/stock/update/${data._id}`
	dispatch(update_stock_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_stock_successful(res.data));

				resolve(true);
			} else {
				dispatch(update_stock_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_stock_failed(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}

// delete STOCK ****************************************************************************************************************************************************
export const delete_stock_pending = () => ({
	type: DELETE_STOCK_ACTION,
});

export const delete_stock_successful = (res) => ({
	type: DELETE_STOCK_SUCCESS,
	response:res
	
});

export const delete_stock_failed = (res) => ({
	type: DELETE_STOCK_FAILURE,
	response:res
});

export const deleteStock = (stock) => dispatch => {
	const url = `/stock/delete/${stock._id}/${stock.stock_id}`
	dispatch(delete_stock_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_stock_successful(res.data));           
				resolve(true);
			} else {
				dispatch(delete_stock_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_stock_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
