import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
    ScoreContainer:{
        marginTop: 2,
        flexDirection: "column",
        width:400,
        height:42,
        border:1,
        borderRadius:2,
        alignItems:'center'
    },
    ResultRow:{
        marginTop: 1,
        flexDirection: "row",
        justifyContent:'center',
        alignItems:'center'
    },
    ResultRowBox:{
        marginRight:15,
        marginLeft:15,
        flexDirection: "row",
        width:100,
        height:22,
        border:1,
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
    ResultRowBoxCol:{
        flexDirection: "column",
        width:60,
        height:22,
        borderRight:1,
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
});

const Title = styled.Text`
    margin-left: 5px;
    font-size: 8px;
    text-align: left;
`;
const Box = styled.Text`
    font-size: 8px;
    text-align: center;
    width:60px;
`;
const ScoreBox = styled.Text`
    font-size: 10px;
    text-align: center;
    width:40px;
`;
const BoxInfo = styled.Text`
    font-size: 6px;
    text-align: center;
    width:60px;
`;
  const Result = ({data}) => {
    return(
    <View style={styles.ScoreContainer}>
        <Title>SONUÇ</Title>
        <View style={styles.ResultRow}>
            <View style={styles.ResultRowBox}>
                <View style={styles.ResultRowBoxCol}>
                    <Box>KABUL</Box>
                    <BoxInfo>5 Puan</BoxInfo>
                </View>
                <ScoreBox>{data.qc_result===0?"X":" "}</ScoreBox>
            </View>
            <View style={styles.ResultRowBox}>
                <View style={styles.ResultRowBoxCol}>
                    <Box>ŞARTLI KABUL</Box>
                    <BoxInfo>3 Puan</BoxInfo>
                </View>
                <ScoreBox>{data.qc_result===1?"X":" "}</ScoreBox>
            </View>
            <View style={styles.ResultRowBox}>
                <View style={styles.ResultRowBoxCol}>
                    <Box>RED</Box>
                    <BoxInfo>0 Puan</BoxInfo>
                </View>
                <ScoreBox>{data.qc_result===2?"X":" "}</ScoreBox>
            </View>
        </View>

    </View>
    )
    };
  
  export default Result;