import React, { useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col,Skeleton,Input,message} from 'antd';
import {useIntl} from "react-intl";
import {  NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../Style/styled';
import { TopToolBox } from '../../Style/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import appActions from '../../../redux/app/actions';
import {getProductList} from '../../../redux/productList/productListActions';
import {ProductGrid} from './products/Grid';
import {ProductList} from './products/List';
import {Filters} from './products/Filters';

const  Products = () => {

  const dispatch = useDispatch();
  const searchData = useSelector(state => state.headerSearchData);

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { productList, productListLoading } = useSelector((state) => state.productList);
  const categoryList = useSelector(state => state.App.categoryList);
  const [dataSource, setDataSource] = React.useState(productList);
  const {tcmb} = useSelector(state => state.tcmb);
  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const {productsFilter} = useSelector(state => state.App);
  const [tabID,setTabID] = React.useState("list")
  const { messages } = useIntl();

  const lang = locale === 'tr' ? 0 :1;

  const [reqFilter, setReqFilter] = React.useState({
    showPassiveProduct:false,
    selectedCategories:productsFilter
  }); 

  const {setPriceListFilter } = appActions;
  const [state, setState] = useState({
    notData: searchData,
    active: 'active',
  });

  const { notData } = state;

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(productsFilter==="Tümü"){
      dispatch(getProductList("list",reqFilter))
      .then(res=>{
        message.success(messages["alert.updated"],  2 );
      });
    }else{
      dispatch(getProductList("category",reqFilter))
      .then(res=>{
        message.success(messages["alert.updated"],  2 );
      });
    }
    // eslint-disable-next-line
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(productList.sort((a, b) => a.model_sira - b.model_sira || a.urun_sira - b.urun_sira))
    // eslint-disable-next-line
  },[productList]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    setTabID(page)
  }
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    let filter_temp = reqFilter;
    filter_temp.selectedCategories=filterVal;
    if(filterVal==="Tümü"){
      dispatch(getProductList("list",filter_temp));
    }else{
      dispatch(getProductList("category",filter_temp));
    }
    dispatch(setPriceListFilter(filterVal));
    setReqFilter({
      ...reqFilter,
      selectedCategories:filterVal
    });
  }
  //******************************************************************************************************************************************************************/
  const onSearchKey = search => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.urun_adi[lang].toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setDataSource(productList.sort((a, b) => a.model_sira - b.model_sira || a.urun_sira - b.urun_sira))
    }else{
      setDataSource(filteredList.sort((a, b) => a.model_sira - b.model_sira || a.urun_sira - b.urun_sira))
    }
  };

 //console.log(dataSource)
  return (
    <>
      <PageHeader
        ghost
        title={messages["sidebar.products"]}
      />
      <Main>
        <Row gutter={30}>
          <Col className="product-sidebar-col" xxl={5} xl={7} lg={7} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton paragraph={{ rows: 22 }} active />
                </Cards>
              }
            >
              <Filters
                loading={productListLoading}
                products={dataSource}
                messages={messages}
                lang={lang}
                categoryList={categoryList}
                onChangeFilter={onChangeFilter}
                productsFilter={productsFilter}
              
              />
            </Suspense>
          </Col>
          <Col className="product-content-col" xxl={19} lg={17} md={14} xs={24}>
            <TopToolBox>
              <Row gutter={0}>
                <Col xxl={7} lg={8} xs={24}>
                  <Input
                    onKeyDown={(e)=>onSearchKey(e.target.value)}
              
                    placeholder={messages["genel.ara"]}
                    width="100%"
             
                  />
                </Col>
                <Col xxl={7} lg={8} >
                  <p className="search-result">{productList.length +" "+ messages["product.name"]}</p>
                </Col>
                <Col xxl={10}  lg={8} xs={24}>
                  <div className="product-list-action d-flex justify-content-between align-items-center">
                    <div className="product-list-action__tab">

                    </div>
                    {(window.innerWidth <= 991 && window.innerWidth >= 768) ||
                      (window.innerWidth > 575 && (
                        <div className="product-list-action__viewmode">
                          <NavLink to='#' onClick={()=>gotoPage('list')} activeClassName={tabID==='list' ? 'active' : ''}>
                            <FeatherIcon icon="grid" size={16} />
                          </NavLink>
                          <NavLink to='#' onClick={()=>gotoPage('grid')} activeClassName={tabID==='grid' ? 'active' : ''}>
                            <FeatherIcon icon="list" size={16} />
                          </NavLink>
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            </TopToolBox>
            <>
            {tabID==="list" ? 
              <ProductGrid
                loading={productListLoading}
                products={dataSource}
                messages={messages}
                lang={lang}
              />
            :
              <ProductList
                loading={productListLoading}
                products={dataSource}
                messages={messages}
                lang={lang}        
              />
            }
            </>



          </Col>
        </Row>
      </Main>
    </>
  );
}

export {Products};
