import React from 'react';
import { NavLink } from 'react-router-dom';
import { ErrorWrapper } from '../Style/style';
import { Main } from '../Style/styled';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';

function NotFound() {
  return (
    <Main>
      <ErrorWrapper>
        <img src={require(`../../static/img/pages/404.svg`).default} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>Sayfa bulunamadı veya Artık Mevcut Değil !</p>
        <NavLink to="/admin">
          <Button size="default" type="primary" to="/admin">
            AnaSayfa
          </Button>
        </NavLink>
      </ErrorWrapper>
    </Main>
  );
}

export  {NotFound};
