import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Form, Modal,Button } from 'antd';
import { FormWrapper } from '../../Style/style';
const StepModal = ({ task_steps,messages,stepIndex,stepModalActive,toggleModal,updateTaskStep,saveTask}) => {
  const { taskDetailLoading } = useSelector((state) => state.taskDetails);
  const [form] = Form.useForm();
  return (
  <>
  {task_steps && task_steps.length && stepIndex !== null &&
    <Modal
      title={messages["genel.edit"]}

      type='primary'
      open={stepModalActive}
      footer={
        <div key={0}>
        <Button size="large" type="white" onClick={() => toggleModal("stepModalActive",null)}  >
          {messages["button.cancel"]}
        </Button>
        <Button  size="large" type="primary"  loading={taskDetailLoading} onClick={()=>saveTask()} >
          {messages["button.save"]}
        </Button>
      </div>
      }
      closable={false}
      onCancel={() => toggleModal("stepModalActive",null)}
      forceRender
    >
      <FormWrapper style={{height:'150px'}}>
        <Form form={form} name="add-task" initialValues={task_steps[stepIndex]} >
          <Form.Item name="step_title" rules={[{ required: true, message: 'Please add a Title' }]} >
            <Input onChange={(eData)=>updateTaskStep("step_title",eData.target.value)} />
          </Form.Item>
          <Form.Item name="step_qty" >
            <Input type='number' max={task_steps[stepIndex].maxStepQty} onChange={(eData)=>updateTaskStep("step_qty",eData.target.value)}/>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {StepModal};
