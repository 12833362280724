
import React from 'react';
import {Modal} from 'antd';
import {OrderListReport}  from '../../../reports/orderList-QR';


const OrderListModal = ({printModalActive,closeModal,data}) => {
  return (
    <>
      {printModalActive && 
      <Modal
        forceRender
        type='primary'
        className="sDash_addTask-modal"
        open={printModalActive}
        footer={null}
        width="100%"
        onCancel={() => closeModal('printModalActive')}
        closable={false}
      >
        <OrderListReport data={data}/>
      </Modal>}
    </>
  );

}



export  {OrderListModal};
