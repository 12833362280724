import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGear,faIndent,faUsers,faCircleDot,faInfoCircle,faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { ReactSVG } from 'react-svg';

import propTypes from 'prop-types';
import { NavTitle } from './style';
import {useIntl} from "react-intl";


const { SubMenu } = Menu;

function MenuItems({ darkMode, toggleCollapsed, topMenu, events }) {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  const { messages } = useIntl();
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FontAwesomeIcon icon={faIndent} />}
      openKeys={openKeys}
    >

      <SubMenu key="1" icon={!topMenu &&<FontAwesomeIcon icon={faLightbulb} />} title={messages["sidebar.products"]}>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/products`}>
                <FontAwesomeIcon icon={faLightbulb} />
              </NavLink>
            )
          }
          key="products"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/products`}>
            {messages["sidebar.products"]}
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="2" icon={!topMenu &&<FontAwesomeIcon icon={faInfoCircle} />} title={messages["sidebar.about"]}>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/about`}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </NavLink>
            )
          }
          key="about"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/about`}>
            {messages["sidebar.about"]}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/qualitypolicy`}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </NavLink>
            )
          }
          key="qualitypolicy"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/qualitypolicy`}>
            {messages["sidebar.qualitypolicy"]}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/mission`}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </NavLink>
            )
          }
          key="mission"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/mission`}>
            {messages["sidebar.mission"]}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/vision`}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </NavLink>
            )
          }
          key="vision"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/vision`}>
            {messages["sidebar.vision"]}
          </NavLink>
        </Menu.Item>
      </SubMenu>









    </Menu>
  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
