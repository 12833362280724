import {
	BUYING_DETAILS_ACTION,
	BUYING_DETAILS_SUCCESS,
    BUYING_DETAILS_ERROR,
	CREATE_BUYING_ACTION,
	CREATE_BUYING_SUCCESS,
	CREATE_BUYING_ERROR,
	UPDATE_BUYING_ACTION,
	UPDATE_BUYING_SUCCESS,
	UPDATE_BUYING_FAILURE,
	DELETE_BUYING_ACTION,
	DELETE_BUYING_SUCCESS,
	DELETE_BUYING_FAILURE
} from '../actions';

const INIT_STATE = {
	buyingDetail: {},
	buyingDetailLoading:false,
	buyingDetailStatus:null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case BUYING_DETAILS_ACTION: 
			return { 
				...state, 
				buyingDetailLoading: true 
			};
		case BUYING_DETAILS_SUCCESS:
            return { 
				...state,
                buyingDetailLoading: false,  
				buyingDetail: action.response
			};
		case BUYING_DETAILS_ERROR:
            return { 
				...state, 
				buyingDetailLoading: false,
				buyingDetailStatus: action.response 
			};	
            	
		case CREATE_BUYING_ACTION: 
			return { 
				...state, 
				buyingDetailLoading: true 
			};
		case CREATE_BUYING_SUCCESS:
			return { 
				...state, 
				buyingDetailLoading: false, 
				buyingDetail: action.response 
			};
		case CREATE_BUYING_ERROR:
			return { 
				...state, 
				buyingDetailLoading: false ,
				buyingDetailStatus: action.response
			};	
		case UPDATE_BUYING_ACTION: 
			return { 
				...state, 
				buyingDetailLoading: true 
			};
		case UPDATE_BUYING_SUCCESS:
			return { 
				...state, 
				buyingDetailLoading: false, 
				buyingDetail: action.response 
			};
		case UPDATE_BUYING_FAILURE:
			return { ...state, 
				buyingDetailLoading: false ,
				buyingDetailStatus: action.response
			};
		case DELETE_BUYING_ACTION: 
			return { 
				...state, 
				buyingDetailLoading: true 
			};
		case DELETE_BUYING_SUCCESS:
			return { 
				...state, 
				buyingDetailLoading: false
			};
		case DELETE_BUYING_FAILURE:
			return { 
				...state, 
				buyingDetailLoading: false,
				buyingDetailStatus: action.response 
			};
		default: return state;
	}
}
