import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
  Container: {
    flexDirection: 'column',
    marginTop:2,
    marginLeft:145,
    alignItems: 'right',
    height: 65,
    width:155,
    border:0.5,
    borderRadius:5
  },
  LabelContainer: {
    flexDirection: 'row',
    marginTop:2,
  
    alignItems: 'left',
    height: 20,
    width:155
  }   
});

const Total = styled.Text`
paddingTop:2px;
paddingRight:5px;
font-size: 8px;
text-align:right;
height:20px;
width:75px;
font-family:'ClearSans';
`;
const Tax = styled.Text`
paddingTop:2px;
paddingRight:5px;
font-size: 8px;
text-align:right;
height:20px;
width:75px;
font-family:'ClearSans';
`;
const GrandTotal = styled.Text`
paddingTop:2px;
paddingRight:5px;
font-size: 8px;
text-align:right;
height:20px;
width:75;
font-family:'ClearSans';
`;
const Labels = styled.Text`
font-family:"OpenSansFontBold";
paddingTop:2px;
paddingRight:2px;
margin-left: 2px;
marginTop:2px;
font-size: 8px;
width:80px;
height:20px;
text-align:right;

`;

  const InvoiceItemsTable = ({data,doviz,messages}) => {

    const OfferrCurrency = doviz === "$" ?  'USD' :  doviz === "€" ? 'EUR' : "TRY"
    const total = (Number(data.teklif_tutardoviz).toLocaleString('tr-TR', { style: 'currency', currency: OfferrCurrency }))
    const tax = (Number(data.teklif_kdvdoviz).toLocaleString('tr-TR', { style: 'currency', currency: OfferrCurrency }))
    const grandtotals = (Number(data.teklif_gtoplamdoviz).toLocaleString('tr-TR', { style: 'currency', currency: OfferrCurrency }))

    return(
    <View style={styles.Container}>
      <View style={styles.LabelContainer}>
          <Labels>{messages["genel.totalprice"].toUpperCase()}</Labels>
          <Total>{total}</Total>
      </View>
      <View style={styles.LabelContainer}>
          <Labels>{messages["genel.kdv"].toUpperCase()}</Labels>
          <Tax>{tax}</Tax>
      </View>        
      <View style={styles.LabelContainer}>
          <Labels>{messages["genel.gtotal"].toUpperCase()}</Labels>
          <GrandTotal>{grandtotals}</GrandTotal>
      </View>  

        

    </View>
  )};
  
  export default InvoiceItemsTable