import React from 'react';
import {  useSelector } from 'react-redux';
import {  Spin, Form, Input,Select,Row,Col } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import { FormWrapper } from '../../Style/style';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ModelGenerals =({model,modelDetailLoading,lang,messages,certificates,updateData})=> {
  const {KDVList,UnitList,categoryList} = useSelector(state => state.App);
  const [form] = Form.useForm();
  React.useEffect(() => {
    form.setFieldsValue(model)
   }, [model])
   const values = Form.useWatch([], form);
   const [submittable, setSubmittable] = React.useState(false);
   React.useEffect(() => {
     form.validateFields({ validateOnly: true }).then(
       () => {
         setSubmittable(true);
       },
       () => {
         setSubmittable(false);
       },
     );
   }, [values]);

   const { TextArea } = Input;
  return (

    <Cards
      title={messages["model.info"]}
      loading={modelDetailLoading && <Spin indicator={faSpinner}  />}
      bodyStyle={{ minHeight: '730px' }}
    >
      <FormWrapper>
        <Spin spinning={modelDetailLoading} delay={100}>
          <Form form={form} initialValues={model}>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.sira"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="model_sira" >
                  <Input type='number' onChange={(eData)=>updateData("model_sira",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["model.name"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name={['model_adi', `${lang}`]} rules={[{ required: true, message: 'Please input your lastname!' }]}>
                  <Input  onChange={(eData)=>updateData("model_adi",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["model.code"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name="model_kodu" rules={[{required: true, message: 'Please input Model Code!' }]}>
                  <Input  onChange={(eData)=>updateData("model_kodu",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["model.descripion"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name={['model_aciklama', `${lang}`]} rules={[{required: true, message: 'Please input Model Code!' }]}>
                  <TextArea rows={6} onChange={(eData)=>updateData("model_aciklama",eData.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["model.category"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name="model_kategori"  rules={[{required: true, message: 'Please input Model Category!' }]}>
                  <Select options={categoryList.map(x=>{return({label:messages[x.label],value:x.value})})} onSelect={(eData)=>updateData("model_kategori",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={4} xs={12}>
                <label htmlFor="input-text">{messages["genel.unit"]}</label>
              </Col>
              <Col md={8} xs={12}>
                <Form.Item  name="model_birimi"  >
                  <Select options={UnitList.map(x=>{return({label:messages[x.label],value:x.value})})} onSelect={(eData)=>updateData("model_birimi",eData)}/>
                </Form.Item>
              </Col>
              <Col md={4} xs={12}>
                <label htmlFor="input-text">{messages["genel.kdv"]}</label>
              </Col>
              <Col md={8} xs={12}>
                <Form.Item  name="model_kdv"  >
                  <Select options={KDVList} onSelect={(eData)=>updateData("model_kdv",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.kdv"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name="model_kdv"  >
                  <Select options={KDVList} onSelect={(eData)=>updateData("model_kdv",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["model.certificates"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name="certificates"  >
                  <Select  mode="tags" tokenSeparators={[","]} onChange={(eData)=>updateData("certificates",eData)}>
                    {certificates && certificates.length && certificates.map((cer,i)=>{
                      return(
                        <Select.Option key={i} value={cer.certificate}>{cer.certificate}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>          
          </Form>
        </Spin>
      </FormWrapper>
    </Cards>
  );
}

export  {ModelGenerals};
