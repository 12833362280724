import {
	ORDERITEM_DETAILS_ACTION,
	ORDERITEM_DETAILS_SUCCESS,
	ORDERITEM_DETAILS_ERROR,
	UPDATE_ORDERITEM_ACTION,
	UPDATE_ORDERITEM_SUCCESS,
	UPDATE_ORDERITEM_FAILURE,
	CREATE_ORDERITEM_ACTION,
	CREATE_ORDERITEM_SUCCESS,
	CREATE_ORDERITEM_FAILURE,
	DELETE_ORDERITEM_ACTION,
	DELETE_ORDERITEM_SUCCESS,
	DELETE_ORDERITEM_FAILURE,
} from '../actions';
const InitData =  {
	urun_id:"",
	urun_adi: "",
	urun_kodu: "",
	urun_kdv:20,
	urun_miktari: 1.00,
	urun_birimi:"Adet",
	urun_fiyati: 0.00,
	urun_dovizi : "$",
	toplamTL:0.00,
	toplamDoviz:0.00,
	toplamKDVTL:0.00,
	toplamKDVDoviz:0.00,
	siparis_tarihi:"",
	baslama_tarihi:"",
	bitis_tarihi:"",
	order_type:"default",
	offer:""
}
const INIT_STATE = {
	orderItemDetail: InitData,
	orderItemDetailLoading:false,
	orderItemDetailStatus:null
};

const orderItemDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ORDERITEM_DETAILS_ACTION: //GET OFFERITEM DETAILS
			return { 
				...state, 
				orderItemDetailLoading: true,
				orderItemDetailStatus:null
			};
		case ORDERITEM_DETAILS_SUCCESS:
            return { 
				...state,
				orderItemDetailLoading: false, 
				orderItemDetail: action.response,
				orderItemDetailStatus:200
			};
		case ORDERITEM_DETAILS_ERROR:
            return { 
				...state, 
				orderItemDetailLoading: false,
				orderItemDetailStatus: action.response 
			};	
		case UPDATE_ORDERITEM_ACTION: //UPDATE OFFERITEM
			return { 
				...state, 
				orderItemDetailLoading: true 
			};
		case UPDATE_ORDERITEM_SUCCESS:
			return {
				...state, 
				orderItemDetailLoading: false, 
				orderItemDetail: action.response,
				orderItemDetailStatus:200 
			};
		case UPDATE_ORDERITEM_FAILURE:
			return { 
				...state, 
				loading: false,
				orderItemDetailStatus:action.response 
			};
		case CREATE_ORDERITEM_ACTION: //UPDATE OFFERITEM
			return { 
				...state, 
				orderItemDetailLoading: true 
			};
		case CREATE_ORDERITEM_SUCCESS:
			return { 
				...state, 
				orderItemDetailLoading: false, 
				orderItemDetail: action.response,
				orderItemDetailStatus:200 
			};
		case CREATE_ORDERITEM_FAILURE:
			return { 
				...state, 
				orderItemDetailLoading: false,
				orderItemDetailStatus:action.response 
			};
		case DELETE_ORDERITEM_ACTION: //DELETE OFFERITEM
			return { 
				...state, 
				orderItemDetailLoading: true 
			};
		case DELETE_ORDERITEM_SUCCESS:
			return { 
				...state,
				 orderItemDetailLoading: false,
				 orderItemDetailStatus:200  
				};
		case DELETE_ORDERITEM_FAILURE:
			return { 
				...state, 
				orderItemDetailLoading: false,
				orderItemDetailStatus: action.response  
			}; 
		default: {
			return state
		}
	}
}
export default orderItemDetails