import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams,Link ,useHistory} from 'react-router-dom';
import { Row, Col ,Button,message,Modal,notification} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars,faCircleInfo,faCamera,faAlignLeft,faAlignRight,faList,faX,faFileCirclePlus} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import { MenuListWrapper,NoteNav,ListContent} from '../Style/style';
import moment from 'moment';
import {getMaterialsList} from '../../redux/materials/materialsActions';
import {getCompanyList} from '../../redux/CompanyList/companyListActions';
import {getBuyingDetails,deleteBuying,updateBuying, createBuying} from '../../redux/buyingDetails/buyingDetailActions';
import {createStock} from '../../redux/stockDetails/stockDetailActions';
import {updateMaterial} from '../../redux/materialDetails/materialDetailActions';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import {BuyingGenerals} from "./buyings/buyingGenerals";
import {ReceivedList} from "./buyings/receivedList";
import {CompanyListModal} from "../commonComponent/companyListModal";
import {ReceivedModal} from "./buyings/receivedModal";
import {QcModal} from "./buyings/qcModal";
import {PrintModal} from "./buyings/printModal";
import blank from '../../static/img/resyok.jpeg';

const BuyingDetails = ( ) => {
  let history = useHistory();
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { buyingDetail, buyingDetailLoading,buyingDetailStatus } = useSelector(state => state.buyingDetail);
  const [dataSource, setDataSource] = React.useState(buyingDetail);
  const {tcmb} = useSelector(state => state.tcmb);
  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  // const USDAlis = tcmb && tcmb.Data[0].ForexBuying;
  // const EURAlis = tcmb && tcmb.Data[1].ForexBuying;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const Questions = useSelector(state => state.myCompany.myCompany.QCQuestions);
  const {companyList,companyListLoading,companyListStatus} = useSelector((state) => state.companyList);
  const [companies, setCompanies] = React.useState(companyList);
  const {CurrencyList,UnitList} = useSelector(state => state.App);

  const [state, setState] = React.useState({
    companyListModalActive:null,
    materialListModalActive:null,
    receivedModalActive:null,
    qcPrintModal:null,
    qcModalActive:null,
    receivedIndex:null,
    tabID:"general",
    collapsed:false
  });
  const dispatch = useDispatch();
  const { buyingID } = useParams();
  const { messages } = useIntl();
  const lang = locale === 'tr' ? 0 :1
  const User = useSelector((state) => state.auth.user);
  const UserEmail = useSelector((state) => state.auth.email);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(companyListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [companyListStatus]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (buyingID !=="new" && dataSource._id !== buyingID) {
      dispatch(getBuyingDetails({id:buyingID},history))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      })
    }else{
      if(buyingID ==="new"){
        setDataSource(initData)
      }
    }
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(buyingDetail){
      let tempdata = buyingDetail; 
      tempdata.related_person = buyingDetail.related_person ? buyingDetail.related_person  : User;
      tempdata.related_person_email = buyingDetail.related_person_email ? buyingDetail.related_person_email  : UserEmail ;
      tempdata.USD_Kur=USDSatis;
      tempdata.EUR_Kur=EURSatis;
      tempdata.material_price=parseFloat(buyingDetail.material_price).toFixed(2).toLocaleString('tr-TR', { style: 'currency'});
      tempdata.Doviz_TotalPrice=parseFloat(buyingDetail.material_qty*buyingDetail.material_price).toFixed(2);
      tempdata.TL_TotalPrice=buyingDetail.material_doviz==="$" ? parseFloat(buyingDetail.material_qty*buyingDetail.material_price*(buyingDetail.USD_Kur ? Number(buyingDetail.USD_Kur) : Number(USDSatis))).toFixed(2) : buyingDetail.material_doviz==="€" ? parseFloat(buyingDetail.material_qty*buyingDetail.material_price*(buyingDetail.EUR_Kur ? Number(buyingDetail.EUR_Kur ): Number(EURSatis))).toFixed(2):"";
      setDataSource(tempdata);
    }
  }, [buyingDetail]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setCompanies(companyList)
  },[companyList]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    setState({
      ...state,
      tabID:page,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,index) =>{
    if(name==="companyListModalActive"){
      if(dataSource.satinalma_durumu===0){
        setState({
          ...state,
          companyListModalActive:val
        });
        dispatch(getCompanyList(null))
        .then(res=>{
          message.loading(messages["alert.updated"]);
        })
      }
    }else if (name==="materialListModalActive"){
      if(dataSource.satinalma_durumu===0){
        dispatch(getMaterialsList('list',null))
        .then(res=>{
          message.loading(messages["alert.updated"]);
        })
        setState({
          ...state,
          materialListModalActive:val
        });
      }
    }else if (name==="receivedModalActive"){
        setState({
          ...state,
          receivedModalActive:val,
          receivedIndex:index
        });
    }else if (name==="qcModalActive"){
  
      setState({
        ...state,
        qcModalActive:val,
        receivedIndex:index
      });
    }else if (name==="qcPrintModal"){
      val===true && onSaveBuying();
      setState({
        ...state,
        qcPrintModal:val,
        receivedIndex:index
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const updateGenerals = (values) => {
    let tempdata = dataSource;
    tempdata.tedarikci_id=values.tedarikci_id;
    tempdata.teslim_tarihi=values.teslim_tarihi;
    tempdata.siparis_notu=values.siparis_notu;
    tempdata.related_person=User;
    tempdata.related_person_email=UserEmail ;
    tempdata.tedarikci_id=values.tedarikci_id;
    tempdata.material_qty=parseFloat(values.material_qty).toFixed(2);
    tempdata.material_price=parseFloat(values.material_price).toFixed(2);
    tempdata.material_doviz=values.material_doviz;
    tempdata.USD_Kur=values.USD_Kur;
    tempdata.EUR_Kur=values.EUR_Kur;
    tempdata.Doviz_TotalPrice=parseFloat(values.material_qty*values.material_price).toFixed(2);
    tempdata.TL_TotalPrice=values.material_doviz==="$" ? parseFloat(values.material_qty*values.material_price*(values.USD_Kur ? Number(values.USD_Kur) : Number(USDSatis))).toFixed(2) : values.material_doviz==="€" ? parseFloat(values.material_qty*values.material_price*(values.EUR_Kur ? Number(values.EUR_Kur ): Number(EURSatis))).toFixed(2):"";
    setDataSource(tempdata);
    onSaveBuying();
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const searchCompany = search => {
    let filteredList =[]; 
    filteredList =  companyList.filter(
        item => {return item.company_name.toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setCompanies(companyList)
    }else{
      setCompanies(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const selectCompany = (company) => {
    setDataSource({
      ...dataSource,
      tedarikci_id:company._id,
      tedarikci:company.company_name,
      tedarikci_eposta:company.company_email
    });
    setState({
      ...state,
      companyListModalActive:null
    });
  };
  //******************************************************************************************************************************************************************/
  const updateBuyingDate = (field,date) => {
    setDataSource({
      ...dataSource,
      [field]:date
    });
  };
  //******************************************************************************************************************************************************************/
  const onSaveBuying = () => {
    let temp =dataSource;
    if(dataSource.siparis_tarihi===null && dataSource.tedarikci_id && dataSource._id){
      temp.siparis_tarihi = moment(new Date());
      temp.satinalma_durumu = 1;
      dispatch(updateBuying(temp,history))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      })
    }else{
      if(temp._id){
        if(temp.received.length){
          let kapananalim = temp.received.reduce((a,v) =>  a = a + v.closed , 0 );
          let toplamalim = temp.received.length;
          if (kapananalim===toplamalim){
            temp.satinalma_durumu=3;
          }else{
            temp.satinalma_durumu=2;
          }
        }else{
          temp.satinalma_durumu=1;
        }
      }else{
        dispatch(createBuying({...dataSource}, history))
        .then(res=>{
          message.success(messages["alert.createsuccess"], 2);
        });
      }
    };
    if(temp.newReceivedRow && temp.satinalma_durumu===2){
      Modal.confirm({
        title: 'Stok Girişi Oluşturulacak !',
        icon: <FontAwesomeIcon icon={faCircleInfo} size={"2x"} />,
        content: 'Onaylıyor musunuz ?',
        okText: 'Evet',
        cancelText: 'Hayır',
        onCancel:()=>{},
        onOk:()=>{
          temp.received[temp.received.length-1].stockSaved=true;
          const newRow ={
            operation_date: moment(new Date()),
            operation_center:"fatura",
            operation_type: "giris",
            order_id:dataSource.order_id.id,
            stock_name:dataSource.material_id.material_name,
            stock_category:dataSource.material_id.material_category,
            stock_group:dataSource.material_id.material_group,
            qty:dataSource.material_qty,  
            unit:dataSource.material_id.material_unit,
            description:"Satınalma Fişi Üzerinden Giriş",
            price:dataSource.material_price,
            doviz:dataSource.material_doviz,
            stock_id:dataSource.material_id._id,
            sumQty:dataSource.material_qty,
            sumPrice:dataSource.material_qty*dataSource.material_price
          }
          dispatch(createStock(newRow))
          .then(res=>{
            message.success("Stok Girişi Oluşturuldu !", 5 );
          });
          dispatch(updateBuying(temp,history))
          .then(res=>{
            message.loading(messages["alert.updated"]);
          })
        }
      });
    }else{
      dispatch(updateBuying(temp,history))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      })
    }
    {temp.newReceivedRow && temp.satinalma_durumu===2 &&
    Modal.confirm({
      title: 'Malzeme Fiyatı Güncellenecek !',
      icon: <FontAwesomeIcon icon={faCircleInfo} size={"2x"} />,
      content: 'Onaylıyor musunuz ?',
      okText: 'Evet',
      cancelText: 'Hayır',
      onCancel:()=>{},
      onOk:()=>{
        const initData = {
          _id:dataSource.material_id._id,
          material_name:dataSource.material_id.material_name,
          material_group:dataSource.material_id.material_group,
          material_category:dataSource.material_id.material_category,
          material_unit:dataSource.material_id.material_unit,
          material_price:dataSource.material_price,
          material_doviz:dataSource.material_doviz
        }
        dispatch(updateMaterial(initData))
        .then(res=>{
          message.success("Malzeme Fiyatı Güncellendi.", 2 );
        })
      }
    });}
  };
  //******************************************************************************************************************************************************************/
  const onDeleteBuying = () => {
    if(dataSource._id){
      dispatch(deleteBuying(buyingID,history))
      .then(res=>{
        message.success(messages["alert.deletesuccess"], 2 );
      })
    }
  };
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const addNewReceived =()=>{
    let temp = dataSource.received ? dataSource.received :[];
    const row = {
      date: moment(new Date()),
      receiver:User,
      qty:dataSource.material_qty,
      unit: "Adet",
      price: dataSource.material_price,
      doviz:dataSource.material_doviz,
      toplamTL:( dataSource.material_doviz==="₺" ? dataSource.material_price*dataSource.material_qty : dataSource.material_doviz==="$" ? dataSource.material_price*dataSource.material_qty *dataSource.USD_Kur : dataSource.material_price*dataSource.material_qty *dataSource.EUR_Kur).toFixed(2),
      toplamDoviz:(dataSource.material_price*dataSource.material_qty).toFixed(2)
    }
    temp.push(row)
    setDataSource({
      ...dataSource,
      received:temp,
      newReceivedRow:true//Yenimalzeme satırı eklendi diye Save bölümünde kullanılıyor
    });
    if (temp.length>0){ 
      setState({
        ...state,
        receivedModalActive:true,
        receivedIndex:(temp.length-1)
      })
    };
  }
  //******************************************************************************************************************************************************************/
  const onUpdatereceived = (values) => {
    let valTemp = values;
    valTemp.toplamTL=( dataSource.material_doviz==="₺" ? valTemp.price*valTemp.qty : dataSource.material_doviz==="$" ? valTemp.price*valTemp.qty *dataSource.USD_Kur : valTemp.material_price*valTemp.qty *dataSource.EUR_Kur).toFixed(2),
    valTemp.toplamDoviz=(valTemp.price*valTemp.qty).toFixed(2)

    let temp = dataSource.received;
    temp[state.receivedIndex]=valTemp;
    setDataSource({
      ...dataSource,
      material_price:valTemp.price,
      material_doviz:valTemp.doviz,
      received:temp
    });
    setState({
      ...state,
      receivedModalActive:null,
      receivedIndex:null
    });
   // onSaveBuying();
  }
  //******************************************************************************************************************************************************************/
  const deleteReceivedRow = (index) => {
    let valTemp = dataSource.received;
    if (valTemp.length>0){ 
      valTemp.splice(index, 1);
      setDataSource({
        ...dataSource,
        received:valTemp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const onUpdateQc = (field,value) => {
    let tempdata = dataSource.received;
    tempdata[state.receivedIndex][field] = value;
    tempdata[state.receivedIndex].staff=User;
    setDataSource({
      ...dataSource,
      received:tempdata
    });
  };
  //******************************************************************************************************************************************************************/
  const onUpdateQcArray= (field,value,index) => {
    let tempdata = dataSource.received;
    let Resultsum =  tempdata[state.receivedIndex].qc_answers_result.reduce((a,v) =>  a = a + v , 0 )
    let result = (Questions.length/Resultsum)===1 ? 0:(Questions.length/Resultsum)<=2 ? 1:2

    value===1 ? Resultsum++ : Resultsum--;
    tempdata[state.receivedIndex][field][index] = value
    if(field ==='qc_answers_result'){
      tempdata[state.receivedIndex].closed = result<2 ? 1:0; // Kabul ise Teslim Satırı kapatılıyor 
      tempdata[state.receivedIndex].qc_result = result; // Sipariş teslimatı kabul veya red 
    }
    tempdata[state.receivedIndex].staff=User;
    setDataSource({
      ...dataSource,
      received:tempdata
    });
  };

  return (
    
    <>
      <PageHeader
        ghost
        title={messages["buying.detail"]}
        buttons={[
          <div key="1" className="page-header-actions">
            <Link to="/admin/buyings">
              <Button  type="primary">
                <FontAwesomeIcon icon={faBars} size={"1x"} />
                {messages["sidebar.buyings"]}
              </Button>
            </Link>
          </div>,
        ]}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={8} lg={8} xs={24}>
                {window.innerWidth <= 991 && (
                  <Button type="link" className="mail-sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                    <FontAwesomeIcon icon={state.collapsed ? faAlignLeft : faAlignRight} />
                  </Button>
                )}
                {window.innerWidth > 991 ? (
                  <div className="sidebar-card">
                    <Cards headless>
                      <div className="note-sidebar-bottom">
                        <NoteNav>
                          <div className="author-info">
                            <figure>
                              <img width="120px" src={blank} alt="" />
                                <NavLink to="#">
                                  <FontAwesomeIcon icon={faCamera} size={"1x"} />
                                </NavLink>
                            </figure>
                            <figcaption>
                              <div className="info">
                                <Heading as="h6">{dataSource && dataSource.urun_adi && dataSource.urun_adi}</Heading>
                                <p>{messages["buying.buyingStatus"+dataSource.satinalma_durumu]}</p>
                              </div>
                            </figcaption>
                          </div>
                          <div className="nav-labels">
                            <ul>
                              <ListContent style={{height:'300px'}}>
                                  <li>
                                    <NavLink to='#' onClick={()=>gotoPage("general")} activeClassName={state.tabID === "general" ? "active":""}>
                                      <FontAwesomeIcon icon={faCircleInfo} size={"1x"} />
                                      {messages["buying.detail"]}
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to='#' onClick={()=>gotoPage("received")} activeClassName={state.tabID === "received" ? "active":""}>
                                      <FontAwesomeIcon icon={faList} size={"1x"} />
                                      {messages["buying.received"]}
                                    </NavLink>
                                  </li>
                              </ListContent>
                            </ul>
                          </div>
                        </NoteNav>
                      </div>
                    </Cards>
                  </div>
                ) : (
                  <div className={state.collapsed ? 'sidebar-card note-sideabr show' : 'sidebar-card note-sideabr hide'}>
                    <Cards headless>
                      <Button
                        type="link"
                        className="mail-sidebar-trigger trigger-close"
                        style={{ marginTop: 0 }}
                        onClick={toggleCollapsed}
                      >
                        <FontAwesomeIcon icon={faX} />
                      </Button>
                      <div className="note-sidebar-bottom">
                        <NoteNav>
                          <div className="author-info">
                            <figure>
                              <img width="120px" src={blank} alt="" />
                                <NavLink to="#">
                                  <FontAwesomeIcon icon={faCamera} size={"1x"} />
                                </NavLink>
                            </figure>
                            <figcaption>
                              <div className="info">
                                <Heading as="h6">{dataSource && dataSource.company_name}</Heading>
                                <p>{messages["company."+ dataSource.company_category]}</p>
                              </div>
                            </figcaption>
                          </div>
                          <div className="nav-labels">
                            <ul>
                              <li>
                                <NavLink to='#' onClick={()=>gotoPage("general")} activeClassName={state.tabID === "general" ? "active":""}>
                                  <FontAwesomeIcon icon={faCircleInfo} size={"1x"} />
                                  {messages["buying.detail"]}
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to='#' onClick={()=>gotoPage("received")} activeClassName={state.tabID === "received" ? "active":""}>
                                  <FontAwesomeIcon icon={faList} size={"1x"} />
                                  {messages["buying.received"]}
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </NoteNav>
                      </div>
                    </Cards>
                  </div>
                )}
            </Col>
            <Col xxl={18} xl={16} lg={16} xs={24}>
              {state.tabID === "general" ? 
                <BuyingGenerals
                  loading={buyingDetailLoading}
                  messages={messages}
                  lang={lang}
                  buying={dataSource}
                  CurrencyList={CurrencyList}
                  updateGenerals={updateGenerals}
                  toggleModal={toggleModal}
                  updateBuyingDate={updateBuyingDate}
                  onDeleteBuying={onDeleteBuying}
                />
              :state.tabID === "received" ? 
                <Cards
                  bodypadding="0"
                  isbutton=
                  {
                    <>
                      <Button size="small" type="default" loading={buyingDetailLoading} onClick={()=>onSaveBuying()}>
                        {messages["button.save"]}
                      </Button>
                      &nbsp;
                        &nbsp;
                      {dataSource.tedarikci_id && <Button className="btn-addUser" outlined="true" type="white" size="small" onClick={()=>addNewReceived()}>
                        <FontAwesomeIcon icon={faFileCirclePlus} size='1x' />
                      </Button>}
                    </>
                  }
                >
                  {dataSource.received && dataSource.received.length ? dataSource.received.map((received,index)=>{
                  return(
                    <ReceivedList
                      key={index}
                      index={index}
                      loading={buyingDetailLoading}
                      messages={messages}
                      received={received}
                      onSaveBuying={onSaveBuying}
                      toggleModal={toggleModal}
                      deleteReceivedRow={deleteReceivedRow}
                    />)}):null}
                </Cards>
              :null}
            </Col>
          </Row>
        </MenuListWrapper>

      </Main>
      <CompanyListModal
        companyList={companies}
        messages={messages}
        lang={lang}
        loading={companyListLoading}
        companyListModalActive={state.companyListModalActive}
        searchCompany={searchCompany}
        selectCompany={selectCompany}
        toggleModal={toggleModal}
      />
      {state.receivedIndex>=0 && 
      <ReceivedModal
        messages={messages}
        toggleModal={toggleModal}
        receivedModalActive={state.receivedModalActive}
        UnitList={UnitList}
        CurrencyList={CurrencyList}
        received={dataSource.received && dataSource.received.length && dataSource.received[state.receivedIndex]}
        onUpdatereceived={onUpdatereceived}
      />}
      {/* <QcModal
        messages={messages}
        toggleModal={toggleModal}
        qcModalActive={state.qcModalActive}
        received={dataSource.received && dataSource.received.length && dataSource.received[state.receivedIndex]}
        onUpdateQc={onUpdateQc}
        onUpdateQcArray={onUpdateQcArray}
        index={state.receivedIndex}
      />  */}
      <PrintModal
        messages={messages}
        toggleModal={toggleModal}
        qcPrintModal={state.qcPrintModal}
        index={state.receivedIndex}
      />
    </>
  );
}



export  {BuyingDetails};
