import styled from 'styled-components';

export function borderRadius(radius = 0) {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}


const PopupChooserDropdown = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom:5px;
  max-width: 300px;
  .ant-btn {
    width: 100%;
    height: 20px;
    font-size: 12px;
    border: 0;
    font-color:#fffff;
    outline: 0;
    padding: 0;
    margin: ${(props) =>
      props['data-rtl'] === 'rtl' ? '0 0 0 15px' : '0 15px 0 0'};
    ${borderRadius('3px')};

    &:empty {
      visibility: visible;
    }

    &:last-child {
      margin: 0;
    }

    &:nth-child(n + 6) {
      margin-top: 5px;
    }

    &:nth-child(5n) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;

export default PopupChooserDropdown;
