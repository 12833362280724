import React from 'react';
import { useSelector } from 'react-redux';
import {Row, Col,Tag,Spin,Popconfirm,Button} from 'antd';
import { ListRowContentT5} from '../../Style/style';
import {ComponentPopup} from '../../../components/PopupComponent';
import {PopupChoser} from '../../../components/PopupChoser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';

const MaterialList = ({messages,component,SelectedMaterial,onChangeComponent,onChangeControl,deleteComponentRow}) => {
  const {material_group,material_category,task,material,totalQty,materialQty,order_id,controlled,materialSelectList} = component;
  const { orderItemDetailLoading } = useSelector(state => state.orderItemDetail);
  const {productList, productListLoading } = useSelector((state) => state.productList);
  const { componentDetailLoading } = useSelector((state) => state.componentDetails);
  const {controlList}= useSelector(state => state.App);
  const YarıMamulUrun = productList && productList.length && productList.filter(product=>product._id===material_group)[0];
  return (
      <ListRowContentT5>
        <Row gutter={10}>
          <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
            <div className="material-column">
              <span className="activity-month">{task==="Yarı Mamül" ?" " : material_group}</span>
              <h1 >{task==="Yarı Mamül"?messages["model.kategori"+material_category]  :material_category}</h1>
            </div>
          </Col>
          <Col xxl={6} lg={6} md={4} sm={12} xs={24}>
            <div className="material-column">
            {productListLoading && task==="Yarı Mamül" ?
              <Spin indicator={faSpinner} size='small'/>
              :<h1>{(SelectedMaterial && SelectedMaterial.material_name ? SelectedMaterial.material_name.slice(0,50):(task==="Yarı Mamül"?(YarıMamulUrun && YarıMamulUrun.urun_adi[0]) :"Malzeme Seçiniz"))}</h1>}
            </div>
          </Col>
          <Col xxl={5} lg={5} md={4} sm={12} xs={24}>
            <div className="material-column">
              <span className="activity-month">{messages["genel.qty"]}: {materialQty && materialQty } {messages["genel.Adet"]} </span>
              <h1>{messages["genel.totalqty"]}:{totalQty && totalQty } {messages["genel.Adet"]} </h1>

            </div>
          </Col>
          <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
            <div className="material-column">
              <h1>{messages["genel.stocksum"]}:{task==="Yarı Mamül" ? (YarıMamulUrun && YarıMamulUrun.stockSum) : (SelectedMaterial && SelectedMaterial.stockSum && SelectedMaterial.stockSum) }  </h1>
              {material && (SelectedMaterial && SelectedMaterial.stockSum && SelectedMaterial.stockSum<0) && <Tag className="red">Hatalı Stok</Tag>}
              {task !=="Yarı Mamül" && materialSelectList && materialSelectList.length && 
              <ComponentPopup
                className="blue"
                option={materialSelectList.map((materialData) => ({ label: (task !== "Yarı Mamül" ? materialData.material_name +  "   (Stok => "+materialData.stockSum+" "+materialData.material_unit+")" :materialData.urun_adi[0]), value: materialData._id }))}
                choseData={(e)=>onChangeComponent(component,e,order_id)}
                selectedData={""}
              />}
            </div>
          </Col>
          <Col xxl={3} lg={3} md={3} sm={12} xs={24}>
            <div className="material-column-center">
              {task !=="Yarı Mamül" && material ?
                <PopupChoser
                  option={controlList}
                  choseData={(e)=>onChangeControl(component,e,order_id)}
                  selectedData={controlled}
                />
                : 
                (task ==="Yarı Mamül" ?
                <PopupChoser
                  option={controlList}
                  choseData={(e)=>onChangeControl(component,e,order_id)}
                  selectedData={controlled}
                  disabled={orderItemDetailLoading}
                />
                :null)
              }
            </div>
          </Col>
          <Col xxl={2} lg={2} md={2} sm={2} xs={24} className='action-column'>
            {componentDetailLoading ?
            <Spin indicator={faSpinner} size='small'/>
            :
            <Popconfirm
              title={messages["alert.deletequest"]}
              okText={messages["genel.yes"]}
              cancelText={messages["genel.no"]}
              onConfirm={()=>deleteComponentRow(component)}
            >
              <Button shape="circle">
                <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
              </Button>  
            </Popconfirm>}
          </Col>
        </Row>
      </ListRowContentT5>
  );
}



export  {MaterialList};
