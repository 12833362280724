import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {useIntl} from "react-intl";
import { useHistory } from 'react-router-dom';
import { ProjectDetailsWrapper } from '../Style/style';
import { message,notification} from 'antd';
import { Main } from '../Style/styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import {ComponentSummary} from '../dashboard/DashBoardItems/componentsSummary';
import {getComponentList} from '../../redux/componentList/componentListActions';


const  ProjectMaterialList =()=> {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();

  const { componentListStatus } = useSelector(state => state.componentList);
  const { componentDetailStatus } = useSelector(state => state.componentDetails);
  const [state, setState] = React.useState({
    componentfilter:'Tümü',
  });

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(state.componentfilter==="Tümü"){
      dispatch(getComponentList("filter",0,2))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      });
    }else{
      dispatch(getComponentList("filter",state.componentfilter,state.componentfilter))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      });
    }
  }, [])
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(componentListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [componentListStatus]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(componentDetailStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [componentDetailStatus]);
  //******************************************************************************************************************************************************************/
  const handlechangeFilter = filterValue => {
    const hide = message.loading('Güncelleniyor...');
    if (filterValue==="Tümü"){
      dispatch(getComponentList("filter",0,2))
      .then(res=>{
        hide();
      });
    }else{
      dispatch(getComponentList("filter",filterValue,filterValue))
      .then(res=>{
        message.success('Güncellendi!',2);
      });
      setState({
        ...state,
        componentfilter:filterValue
      });
    }
  }
  //******************************************************************************************************************************************************************/
  return (
    <>

      <PageHeader
        title={messages["sidebar.projectMaterialList"]}
      />

      <Main>
          <Components
            height='700px'
            handlechangeFilter={handlechangeFilter}
            componentfilter={state.componentfilter}
          />
      </Main> 
    </>
  );
}



export  {ProjectMaterialList};
