import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import styled from '@react-pdf/styled-components';



const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'left',
        height: 15,
    
    }
});


const Tarih = styled.Text`
paddingLeft:2px;
margin-top: 2px;
font-size: 10px;
text-align: left;
width:60px;
`;


const Qty = styled.Text`
margin-left: 2px;
paddingTop:2px;
font-size: 10px;
text-align: center;
width:60px;
`;

const Isim = styled.Text`
margin-left: 2px;
paddingTop:2px;
paddingRight:2px;
font-size: 10px;
text-align: right;
width:90px;
`;


const orderRowDetail = ({delivery,messages}) => {
    return(

        <View style={styles.rowContainer}>      
            <Tarih>{moment(delivery.date).format('DD.MM.YYYY')}</Tarih>
            <Qty>{delivery.qty+" "+messages["genel."+delivery.unit]}</Qty>
            <Isim>{delivery.deliverer}</Isim>
            <Isim>{delivery.receiver}</Isim>
        </View>
)};
  
export default orderRowDetail