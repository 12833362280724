import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Modal,Spin,Badge } from 'antd';
import { SearchListSidebar,ListContent,ListWrapper} from '../Style/style';
import { NavLink} from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';
import {getProductCode} from '../../helpers/getProductCode';
import {currencyFormatter} from '../../helpers/currencyFormat';
import blank from '../../static/img/resyok.jpeg';
const ProductListModal = ({lang, messages,productListModalActive,toggleModal,selectProduct}) => {
  const { productList, productListLoading } = useSelector((state) => state.productList);
  const [_productList, setProductList] = React.useState(productList);
  const rtl  = useSelector(state => state.ChangeLayoutMode.rtlData);
  const left = !rtl ? 'left' : 'right';
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setProductList(productList)
  }, [productList]);
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const onSearchKey = (search) => {

    let filteredList =[]; 
    filteredList =  productList.filter(
        item => {return item.urun_adi[lang].toLowerCase().includes(search.toLowerCase()) || (item.urun_model.toLowerCase().includes(search.toLowerCase()))})
    if (search===""){
      setProductList(productList.sort((a, b) => a.urun_sira - b.urun_sira))
    }else{
      setProductList(filteredList.sort((a, b) => a.urun_sira - b.urun_sira))
    }
  };

  return (
  <>
  {productList && productListModalActive && 
    <Modal
      title={messages["sidebar.products"]}
      type='primary'
      open={productListModalActive}
      footer={null}
      closable={false}
      onCancel={() => toggleModal("productListModalActive",null)}
      width={610}
      forceRender
    >
      <SearchListSidebar>
        <Cards headless  bodypadding="0px">
          <div className="chatbox-search">
            <Input  onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
          </div>
          <ListContent style={{height:'550px'}}>
            <Scrollbars
              className="custom-scrollbar"
              autoHide
              autoHideTimeout={500}
              autoHideDuration={200}
              renderThumbHorizontal={renderThumbHorizontal}
              renderThumbVertical={renderThumbVertical}
              renderView={renderView}
              renderTrackVertical={renderTrackVertical}
            >
              <ListWrapper>
                <ul>
                  {_productList && _productList.length ? 
                    _productList.map((product, key) => {
                      const { urun_adi,urun_fiyat,urun_doviz, urun_kodu,model_category,listimage,urun_model,urun_birimi, urun_optik, urun_cct, urun_panel,urun_aku,stockSum,_id } = product;
                        return (
                          <li key={key} className="chat-link-signle" >
                            <NavLink to='#' onClick={()=>selectProduct(product)}>
                              <div className="author-figure">
                                <img src={listimage && listimage.length ? listimage[0].image : blank} alt="" />
                                <span className={false ? 'active' : 'inactive'} />
                              </div>
                              <div className="author-info">
                                <span className="author-name" style={{display: 'block'}}>{urun_adi[lang ? lang : 0]}</span>
                                <span className="author-chatText" >
                                {getProductCode(model_category,urun_model,urun_kodu,urun_optik,urun_cct,urun_panel,urun_aku)}
                                </span>
                              </div>
                              <div className="author-chatMeta">
                                <Badge className={"badge-success"} count={currencyFormatter(urun_fiyat)+" "+urun_doviz} />
                                <Badge className={stockSum > 0 ? "badge-success" : "badge-danger"} count={currencyFormatter(stockSum)+" "+urun_birimi} />
                              </div>
                            </NavLink>
                          </li>
                        );
                      }):
                    <div className="spin"><Spin/></div>
                  }
                </ul>
              </ListWrapper>
            </Scrollbars>
          </ListContent>
        </Cards>
      </SearchListSidebar>
    </Modal>
  }
  </>
  );
}

export  {ProductListModal};
