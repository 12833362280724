import React from 'react';
import { useSelector,useDispatch} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner,faArrowsRotate,faRefresh,faPrint,faX,faSquareArrowUpRight,faTrash} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
//import CreateProject from './overview/CreateProject';
import { ListTableRow,ListRowContentT5,ListContent} from '../../Style/style';
import {Spin,Row,Col,Button,Tag,Popconfirm,Popover} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import moment from 'moment';
import {currencyFormatter} from '../../../helpers/currencyFormat';
import { Scrollbars } from 'react-custom-scrollbars';
import {getBuyingsList} from '../../../redux/buyingList/buyingListActions';

const NumberOptions = {
  significantDigits: null,
  thousandsSeparator: '.',
  decimalSeparator: ',',

}
const  Buyings =({height,deleteBuyingRow})=> {
  const { buyingList, buyingListLoading } = useSelector((state) => state.buyingList);
  const { buyingDetailLoading } = useSelector(state => state.buyingDetail);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const left = !rtl ? 'left' : 'right';
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>dispatch(getComponentList("filter",0,2))}>
        <FontAwesomeIcon icon={faRefresh} />
        <span>{messages["button.refresh"]}</span>
      </NavLink>
      <NavLink to="#">
        <FontAwesomeIcon icon={faPrint} />
        <span>Printer</span>
      </NavLink>
      <NavLink to="#">
        <FontAwesomeIcon size="1x" icon={faX}/>
        <span>PDF</span>
      </NavLink>
    </>
  );
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    dispatch(getBuyingsList("list","date",0,0))
  }, []);
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  return (
    <>
      <Cards 
        title={messages["dashboard.buyinglist"] }
        loading={buyingListLoading}
        more={moreContent}
      >
        <ListRowContentT5>
          <Row gutter={10}>
            <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
              <div className="material-column">
                <h1>{messages["genel.date"]}</h1>
              </div>
            </Col>
            <Col xxl={9} lg={9} md={9} sm={24} xs={24}>
              <div className="material-column">
                <h1>{messages["material.material"]}</h1>
              </div>
            </Col>
            <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
              <div className="list-column">
                <h1>{messages["product.status"]}</h1>
              </div>
            </Col>              
            <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
              <div className="list-column">
                <h1>{messages["genel.qty"]}</h1>
              </div>
            </Col>
            <Col xxl={3} lg={3} md={3} sm={12} xs={24}>
              <div className="list-column">
                <h1>{messages["genel.stocksum"]}</h1>
              </div>
            </Col>
            <Col xxl={2} lg={2} md={2} sm={24} xs={24}>
              <div className="list-column">
                <h1>X</h1>
              </div>
            </Col>  
          </Row>
        </ListRowContentT5>
        <ListContent style={{height:height}}>
          <Scrollbars
            className="custom-scrollbar"
            autoHide
            autoHideTimeout={500}
            autoHideDuration={200}
            renderThumbHorizontal={renderThumbHorizontal}
            renderThumbVertical={renderThumbVertical}
            renderView={renderView}
            renderTrackVertical={renderTrackVertical}
          >
              {buyingList && buyingList.length ? buyingList
              .map((buyingRow,index)=>{
                const {talep_tarihi,satinalma_durumu,_id,siparis_tarihi,order_id,related_person_email,material_qty,Doviz_TotalPrice,material_doviz,material_id} = buyingRow;
                const content = (
                  <div>
                    <h5>{order_id && order_id.urun_adi}</h5>
                  </div>
                );
                let color
                if (satinalma_durumu === 0 ){color = 'red'} 
                else if (satinalma_durumu === 1){color = 'blue'} 
                else if (satinalma_durumu === 2){color = 'green'} 
                else if (satinalma_durumu === 3){color = 'yellow'} 
                else if (satinalma_durumu === 4){color = "#24bc42"} 
                //console.log(buyingRow)
                return(
                  <ListTableRow key={index}>
                    <Row gutter={10}>
                      <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
                        <div className="material-column">
                          <h1>{moment(talep_tarihi).format("DD.MM.YYYY")}</h1>
                        </div>
                      </Col>
                      <Col xxl={9} lg={9} md={9} sm={24} xs={24}>
                        <div className="material-column">
                          <Popover placement="right" title={<h5>{order_id && (order_id.teklifno.firma_adi.length>100 ?  order_id.teklifno.firma_adi.substring(0,50):order_id.teklifno.firma_adi)}</h5>} content={content}>
                            <h1>{material_id && ( material_id.material_category+" / "+material_id.material_group+" / "+material_id.material_name)}</h1>
                          </Popover>
                        </div>
                      </Col>
                      
                      <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
                        <div className="material-column-center">
                          <Tag color={color}>{messages["buying.buyingStatus"+satinalma_durumu]}</Tag>
                        </div>
                      </Col>
      
                      <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
                        <div className="material-column-center">
                          <h1>{currencyFormatter(material_qty,NumberOptions) + " " + (material_id && material_id.material_unit)}</h1>
                        </div>
                      </Col>
                      <Col xxl={3} lg={3} md={3} sm={12} xs={24}>
                        <div className="material-column-center">
                          <h1>{}</h1>
                        </div>
                      </Col>
                      <Col xxl={2} lg={2} md={2} sm={12} xs={24}>
                        <div className="action-column">
                          <Button shape="circle" className='btn-info' href={"/admin/buyingdetails/"+_id} >
                            <FontAwesomeIcon icon={faSquareArrowUpRight} />
                          </Button>  
                          <Popconfirm
                            title={messages["alert.deletequest"]}
                            okText={messages["genel.yes"]}
                            cancelText={messages["genel.no"]}
                            onConfirm={()=>deleteBuyingRow(_id)}
                            okButtonProps={{ loading: buyingDetailLoading }}
                          >
                            <Button shape="circle">
                              <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
                            </Button>  
                          </Popconfirm>
                        </div>
                      </Col>
                    </Row>
                  </ListTableRow>
                )
              }):null}
              
          </Scrollbars>
        </ListContent> 

      </Cards>
    </>
  );
}



export  {Buyings};
