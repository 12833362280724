import React from 'react';
import { Form, Modal,Button, Radio } from 'antd';
import { FormWrapper } from '../../Style/style';
const BoxLabelSetting = ({ boxLabelSettingActive,messages,toggleModal,logoOpen,bg_logo_Open}) => {
  return (
  <>
  {boxLabelSettingActive && 
    <Modal
      title={messages["genel.edit"]}
      open={boxLabelSettingActive}
      footer={        
      <div>
        <Button  size="large" type="primary" onClick={() => toggleModal("boxLabelSettingActive",null,null)}>
          {messages["button.close"]}
        </Button>
      </div>}
      onCancel={() => toggleModal("boxLabelSettingActive",null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'auto'}}>

          <Form.Item label={messages["company.logo"]} >
            <Radio.Group defaultValue={logoOpen} onChange={(e)=>bg_logo_Open(e.target.value)}>
              <Radio.Button value={true}>{messages["genel.true"]}</Radio.Button>
              <Radio.Button value={false}>{messages["genel.false"]}</Radio.Button>
            </Radio.Group>
          </Form.Item>

      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {BoxLabelSetting};
