import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from "moment";
const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        borderBottom:0.3,
        height:30
    },
    x60Container:{
        flexDirection: 'column',
        borderRight:0.3,
        width:60,
        justifyContent:'center'
    },
    x20Container:{
        flexDirection: 'column',
        borderRight:0.3,
        width:20,
        justifyContent:'center'
    },
    x120Container:{
        flexDirection: 'column',
        borderRight:0.3,
        width:120,
    },
    x60RowContainer:{
        flexDirection: 'row',
        width:60,
        justifyContent:'center'
    },
  });
  const Title = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  `;
  const Birim = styled.Text`
    font-size:6px;
    text-align:center;
    width:60px;
  `;
  const Taskqty = styled.Text`
  paddingLeft:2px;
  font-size:12px;
  text-align:center;
  width:60px;
  `;
  const Field120px = styled.Text`
  paddingTop:4px;
  paddingLeft:2px;
  font-size: 6px;
  text-align: left;
  height:15px;
  width:120px;
  `;
  const Field120x30px = styled.Text`
  paddingTop:4px;
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  height:30px;
  width:120px;
  `;
  const Status = styled.Text`
  font-size:6px;
  text-align:center;

  `;

const tasksRow = ({row}) => {

    return(

            <View style={styles.rowContainer}>

                <View style={styles.x60Container}>
                    <Title>{row.title}</Title>
                </View>
                <View style={styles.x120Container}>
                    <Field120px>Başlama Tarihi.: {row.startDate ? moment(row.startDate).format('DD.MM.YYYY'):"___/___/20__"}</Field120px>
                    <Field120px>Bitiş Tarihi.: {row.endDate ? moment(row.endDate).format('DD.MM.YYYY'):"___/___/20__"}</Field120px>
                </View>
                <View style={styles.x120Container}>
                    <Field120x30px>{row.description && row.description}</Field120x30px>
                </View>
                <View style={styles.x60Container}>
                    <Taskqty>{row.task_qty}</Taskqty>
                    <Birim>Birim</Birim>
                </View>
                <View style={styles.x20Container}>
                    <Status>{(row && row.status && row.status > 0) || !row.status  ? "X" :""}</Status>
                </View>
                <View style={styles.x20Container}>
                    <Status>{row && row.status && row.status >= 1 ? "X":"" }</Status>
                </View>
                <View style={styles.x20Container}>
                    <Status>{row && row.status && row.status >= 2 ? "X":"" }</Status>
                </View>
                
             


                

            </View>
   

  
)};
  
export default tasksRow