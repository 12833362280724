import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useParams,NavLink,Link ,useHistory} from 'react-router-dom';
import { Row, Col ,Button,message,Popconfirm,Tooltip,Modal,notification} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faSave, faList, faTrash} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { MenuListWrapper} from '../Style/style';
import { Main, } from '../Style/styled';
import {getEmployeeDetails,deleteEmployee,createEmployee,updateEmployee} from '../../redux/employee/employeeDetailActions';
import {getEmployeeFileList} from '../../redux/employeefiles/employeeFileListActions';
import {createEmployeeFile,updateEmployeeFile,deleteEmployeeFile} from '../../redux/employeefiles/employeeFileDetailActions';
import {Profile} from "./EmployeeDetail/employeeProfile";
import {Contact} from "./EmployeeDetail/employeeContact";
import {DetailMenu} from "../commonComponent/detailMenu";
import { EmployeeFiles } from './EmployeeDetail/employeeFiles';
import { FileModal } from './EmployeeDetail/fileModal';
import blank from '../../static/img/blank.png';
import moment from 'moment';


const initData = {
  idno:"",
  img:"",
  firstname:"",
  lastname: "",
  birddate:"",
  mobileno: "",
  email:"",
  startdate:"",
  enddate:"",
  task:"",
  department:"",
  adress:"",
  notes:"",
  status:""
}

const EmployeeProfile = ( ) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  let history = useHistory();
  const { employeeID } = useParams();
  const { employeeDetail, employeeDetailLoading,employeeDetailStatus} = useSelector(state => state.employeeDetails);
  const [dataSource, setDataSource] = React.useState(employeeDetail);
  const [fileDataSource, setFileDataSource] = React.useState();
  const [submittable, setSubmittable] = React.useState(false);
  const [state, setState] = React.useState({
    fileRowModalActive:null,
    previewModalActive:null,
    collapsed:false,
    tabID:"profile"
  });
  const MenuList =[
    {tabID:"profile",MenuName:messages["employee.editprofile"]},
    {tabID:"contact",MenuName:messages["employee.contact"]},
    {tabID:"files",MenuName:messages["employee.files"]},
  ]
  const  mainImage = dataSource.img ? dataSource.img :blank
  const {collapsed,tabID} = state;

  //******************************************************************************************************************************************************************/
  useEffect(() => {
    let tempdata= employeeDetail;
    tempdata.birddate=employeeDetail.birddate ? moment(employeeDetail.birddate):"";
    tempdata.startdate=employeeDetail.startdate ? moment(employeeDetail.startdate):"";
    tempdata.enddate=employeeDetail.enddate ? moment(employeeDetail.enddate):"";
    setDataSource(tempdata)
  }, [employeeDetail]);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    if (employeeID !=="new" && dataSource._id !== employeeID) {
      dispatch((getEmployeeDetails({id:employeeID})));
      message.loading(messages["alert.updated"],3,)
    }else{
      if(employeeID ==="new"){
        setDataSource(initData)
      }
    }
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(employeeDetailStatus && employeeDetailStatus.status===401){
      notification.warning({
        message: employeeDetailStatus.statusText,
        description: employeeDetailStatus.data,
        duration:1
      });
      history.push({pathname:"/admin/401"})
    }else if (employeeDetailStatus && employeeDetailStatus===200){
      notification.info({
        message: 'Bilgi !',
        description:'İşlem Başarılı..',
      });   
    }else if(employeeDetailStatus && employeeDetailStatus.status===404){
      notification.warning({
        message: employeeDetailStatus.statusText,
        description: employeeDetailStatus.data,
        duration:1
      });
      history.push({pathname:"/admin/404"})
    }
  }, [employeeDetailStatus]);
  //******************************************************************************************************************************************************************/
  const saveEmployee = (values) => {
    if(dataSource._id){
      dispatch(updateEmployee(dataSource))
    }else{
      if(employeeID==='new'){
        dispatch(createEmployee(dataSource))          
      }
    }
  }
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    setState({
      ...state,
      tabID:page,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  function convertBase64  (file)  {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  //******************************************************************************************************************************************************************/
  const beforeUpload = async (field,file) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      message.error('Image must smaller than 500Kb!');
      return false;
    }
    const base64 = await convertBase64(file)
    let tempData = dataSource
    tempData.img = base64
    setDataSource(tempData);
    message.success('Image uploaded successfully!');
    return true;
  }
  //******************************************************************************************************************************************************************/
  const uploadFileRow = async (field,file)=>{
    if(field==='file'){
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        message.error('Image must smaller than 4Mb!');
        return false;
      }
      const base64 = await convertBase64(file);
     
      let tempdata = fileDataSource;
      tempdata.file = base64;
    }
    setFileDataSource({
      ...fileDataSource,
      tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  const addNewFileRow = () => {
    const newFileRow = {
      file:"",
      description:"",
      date:moment(new Date ()),
      staff_id:dataSource._id,
      file_type : "İmage"
    }
    setFileDataSource(newFileRow)
    setState({
      ...state,
      fileRowModalActive:true
    })
  };
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,index) =>{
    if(name==="fileRowModalActive"){
        setState({
          ...state,
          fileRowModalActive:val
        });
        if(val){
          const newFileRow = {
            description:index.description,
            date:moment(index.date),
            file_type:index.file_type,
            staff_id:index.staff_id,
            _id:index._id,
            file:index.file
          }
          setFileDataSource(newFileRow)
        }
    }else if("previewModalActive"){
      setState({
        ...state,
        previewModalActive:val
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const updateFileRowData = (Field,eData) => {
    setFileDataSource({
      ...fileDataSource,
      [Field]:eData
    });
  }
 //******************************************************************************************************************************************************************/
  const saveFileRow = () => {
    if(fileDataSource._id){
      dispatch(updateEmployeeFile(fileDataSource))
      .then(res=>{
        message.success(messages["alert.savesuccess"]);
        dispatch((getEmployeeFileList('list',dataSource._id)));
        setState({
          fileRowModalActive:null,
          tabID:"files"
        });
      })
    }else{
      dispatch(createEmployeeFile(fileDataSource))
      .then(res=>{
        message.success(messages["alert.savesuccess"]);
        dispatch((getEmployeeFileList('list',dataSource._id)));
        setState({
          fileRowModalActive:null,
          tabID:"files"
        });
      })           
    }
  }
  //******************************************************************************************************************************************************************/
  const removeFileRow = (id) => {
    if(id){
      dispatch(deleteEmployeeFile(id))
      .then(res=>{
        dispatch((getEmployeeFileList('list',dataSource._id)));
        message.success(messages["alert.savesuccess"], 2 );
      })
    }
  };
 //******************************************************************************************************************************************************************/
  const previewFile =(record)=>{
    setState({
      ...state,
      previewModalActive:true
    })
    const newFileRow = {
      description:record.description,
      date:moment(record.date),
      staff_id:record.staff_id,
      file_type: record.file_type,
      _id:record._id,
      file:record.file
    }
    setFileDataSource(newFileRow)
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    setDataSource({
      ...dataSource,
      [Field]:eData
    });
  }
  //******************************************************************************************************************************************************************/
  const Submittable = (e) => {
    setSubmittable(e)
  }
  //console.log(fileDataSource)
  return (

    <>
      <PageHeader
        title={dataSource.firstname ? dataSource.firstname+" "+dataSource.lastname : "Yükleniyor"}
        buttons={[
          <div key={0} >
            <Popconfirm
              title={messages["alert.deletequest"]}
              okText={messages["genel.yes"]}
              cancelText={messages["genel.no"]}
              //onConfirm={()=>onDeleteCompany()}
            >
                <Button shape="circle"  size='large' icon={<FontAwesomeIcon icon={faTrash} color="#ce1818"/>} style={{marginRight:'10px'}}/>
            </Popconfirm>
            <Tooltip title= {messages["button.save"]} placement="left">
                <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faSave}/>} disabled={!submittable} onClick={()=>saveEmployee()} style={{marginRight:'10px'}}/>
            </Tooltip>
            <Tooltip title= {messages["sidebar.dashboard"]} placement="left">
              <NavLink to="/admin/employee">
                <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faList}/>}/>
              </NavLink>
            </Tooltip>
          </div>
        ]}
      />   
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
              <DetailMenu 
                MenuList={MenuList} 
                data0={dataSource.department ? dataSource.department : "Yükleniyor"} 
                data1={dataSource.task ? dataSource.task : "Yükleniyor"} 
                messages={messages} 
                image={mainImage} 
                imageField={"img"}
                imageWidth={100}
                tabID={tabID}
                beforeUpload={beforeUpload}
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
                gotoPage={gotoPage}
                updateData={updateData}
              />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              {tabID==="profile" ? 
                <Profile
                  employeeDetailLoading={employeeDetailLoading}
                  data={dataSource}
                  messages={messages}
                  updateData={updateData}
                  setSubmittable={Submittable}
                />
              :tabID==="contact" ? 
                <Contact
                  employeeDetailLoading={employeeDetailLoading}
                  data={dataSource}
                  messages={messages}
                  updateData={updateData}
                  setSubmittable={Submittable}
                />        
              :tabID==="files" ? 
                <EmployeeFiles
                  id={dataSource._id}
                  messages={messages}
                  addNewFileRow={addNewFileRow}
                  removeFileRow={removeFileRow}
                  toggleModal={toggleModal}
                  previewFile={previewFile}
                />        
              :
              tabID==="password" ? 
null 
              :null}
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 
      <FileModal
        fileRowModalActive={state.fileRowModalActive}
        fileDataSource={fileDataSource}
        messages={messages}
        toggleModal={toggleModal}
        UploadFile={uploadFileRow}
        updateFileRowData={updateFileRowData}
        saveFileRow={saveFileRow}
      />
      {state.previewModalActive && 
      <Modal open={state.previewModalActive} title={"Önizle"} footer={null} onCancel={()=>toggleModal("previewModalActive",null,null)} width="600px">
        {fileDataSource.file_type ==="PDF" ? 
        <object data={fileDataSource.file} type="application/pdf" width="100%" height="600">
          <p>Alternative text - include a link <a href={fileDataSource.file}>to the PDF!</a></p>
        </object>
        :
        <img alt="hy" style={{ width: '100%' }} src={fileDataSource.file} />}
      </Modal>}
    </>
  );
}



export  {EmployeeProfile};
