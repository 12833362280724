import {
    ACTEST_DETAILS_ACTION,
	ACTEST_DETAILS_SUCCESS,
    ACTEST_DETAILS_ERROR,
    UPDATE_ACTEST_DETAILS_ACTION,
    UPDATE_ACTEST_DETAILS_SUCCESS,
	UPDATE_ACTEST_DETAILS_FAILURE,
	CREATE_ACTEST_ACTION,
	CREATE_ACTEST_SUCCESS,
	CREATE_ACTEST_FAILURE,
	DELETE_ACTEST_ACTION,
	DELETE_ACTEST_SUCCESS,
	DELETE_ACTEST_FAILURE

} from '../actions';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';
import { getCompanyList } from '../CompanyList/companyListActions';

import axios from 'axios';
//import {notify} from 'reapop';

// Get - Company Details ****************************************************************************************************************************************************
export const actest_details_pending = () => ({
	type: ACTEST_DETAILS_ACTION
});

export const actest_details_success = (res) => ({
	type: ACTEST_DETAILS_SUCCESS,
	response: res
});

export const actest_details_failure = (res) => ({
	type: ACTEST_DETAILS_ERROR,
	response: res
});

export const getActestDetails = (id) => dispatch =>{

	const url = `/actest/${id.companyId}`
    dispatch(actest_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            //console.log(res);
            if (res.status === 200) {
                dispatch(actest_details_success(res.data));
                resolve(true);
            } else {
                dispatch(actest_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(actest_details_failure(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }
            reject(err);
            
        });
    });
};


// update CompanyDetails ****************************************************************************************************************************************************
export const update_actest_details_pending = (companyId) => ({
	type: UPDATE_ACTEST_DETAILS_ACTION,
	companyId: companyId
});

export const update_actest_details_successful = (res) => ({
	type: UPDATE_ACTEST_DETAILS_SUCCESS,
	response: res
});

export const update_actest_details_failed = (res) => ({
	type: UPDATE_ACTEST_DETAILS_FAILURE,
	response:res
});

export const updateACTest = (data,history) => dispatch => {
    const url = `/actest/update/${data._id}`
	dispatch(update_company_details_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,        
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_actest_details_successful(res.data));
				//dispatch(notify({message: "res.data", status: "res.status"}));
				resolve(true);
			} else {
				dispatch(update_actest_details_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(update_actest_details_failed(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
				
			}
			reject(err);
		});
	});
}

// create Company ****************************************************************************************************************************************************
export const create_actest_pending = () => ({
	type: CREATE_ACTEST_ACTION,
});

export const create_actest_successful = (res) => ({
	type: CREATE_ACTEST_SUCCESS,
	response: res
});

export const create_actest_failed = (res) => ({
	type: CREATE_ACTEST_FAILURE,
	response:res
});

export const createACTest = (data,history) => dispatch => {
    const url = `/actest/create/`
	dispatch(create_company_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + url, data,{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_actest_successful(res.data));
				history.push({
					pathname : "/admin/companydetails/" + res.data._id
				})
				resolve(true);
			} else {
				dispatch(create_actest_failed());

				reject(res);
			};
		})
		.catch((err) => {
			dispatch(create_actest_failed(err.response.status));
			if (err.response && err.response.status && err.response === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}


// delete Company ****************************************************************************************************************************************************
export const delete_actest_pending = () => ({
	type: DELETE_ACTEST_ACTION,
});

export const delete_actest_successful = (res) => ({
	type: DELETE_ACTEST_SUCCESS,
	response:res
});

export const delete_actest_failed = (res) => ({
	type: DELETE_ACTEST_FAILURE,
	response:res
});

export const deleteAcTest = (id) => dispatch => {
	const url = `/actest/delete/${id}`
	dispatch(delete_actest_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_actest_successful(res.data));
				dispatch(getCompanyList(null));
				resolve(true);
			} else {
				dispatch(delete_actest_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_actest_failed(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }
			reject(err);
		});
	});
}







