import styled from 'styled-components';

export function borderRadius(radius = 0) {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}

const PopupChooserDropdown = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;
  .page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 2px;
    color: #5f63f2;
  }
`;

export default PopupChooserDropdown;
