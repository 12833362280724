import {
    MATERIALS_GET_LIST,
    MATERIALS_GET_LIST_SUCCESS,
	MATERIALS_GET_LIST_ERROR
} from '../actions';
const INIT_STATE = {
	materialList: [],
	materialListLoading:false,
	materialListStatus:null
};

const materials = (state = INIT_STATE, action) => {
	switch (action.type) {
		case MATERIALS_GET_LIST: // GEL LIST 
			return { 
				...state, 
				materialListLoading: true,
				materialListStatus:null 
			};
		case MATERIALS_GET_LIST_SUCCESS:
            return { ...state, 
                materialListLoading: false, 
                materialList: action.response,  
				materialListStatus:200
            };
		case MATERIALS_GET_LIST_ERROR:
			return { 
				...state, 
				materialListLoading: false ,
				materialListStatus:action.response
			};
		default: return  state;
	}
}
export default materials