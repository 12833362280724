import React, {  useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Progress, Spin,Tag ,Card,Modal,message,Tooltip,Popconfirm,notification,Alert} from 'antd';
import { NavLink, useParams,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb,faPalette,faSpinner, faEye,faCircleArrowRight,faBackwardStep,faFlagCheckered,faPlay,faShoppingCart,faTruck,faListCheck,faFileCirclePlus,faPenToSquare,faSave,faPrint, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { ProjectDetailsWrapper, ProjectTabContent , UserCard} from '../Style/style';
import { PageHeader } from '../../components/page-headers/page-headers';

import { Main } from '../Style/styled';
import Heading from '../../components/heading/heading';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import {getProductCode} from '../../helpers/getProductCode';
import blank from '../../static/img/resyok.jpeg';
import moment from 'moment';
import {getOrderItemDetails,orderItemActions,updateOrderItem} from '../../redux/orderItemDetail/orderItemActions';


// const TaskList = lazy(() => import('./overview/TaskList'));
// const Activities = lazy(() => import('./overview/Activities'));

const componentRowInit = {
  task:null,
  material_category:"",
  material_group:"",
  materialQty:1,
  material:null,
  stockSum:0,
  totalQty:null
}
const taskRowInit = {
  tasktype:0,
  task_qty:1,
  date:moment(new Date()),
  title:"",
  description:"",
  importance:1,
  status:0,

}

const  LastCheckDetails = ()=> {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();
  const { projectID } = useParams();

  const { orderItemDetail, orderItemDetailLoading,orderItemDetailStatus } = useSelector(state => state.orderItemDetail);
  const [dataSource, setDataSource] = React.useState(orderItemDetail);
  const tasks = useSelector(state => state.taskList.data);
  const taskloading = useSelector(state => state.taskDetails.loading);
  const taskListStatus = useSelector(state => state.taskList.status);
  const componentloading = useSelector(state => state.componentDetails.loading);
  //const tasksloading = useSelector(state => state.taskList.loading);
  const components = useSelector(state => state.componentList.data);
  const componentsloading = useSelector(state => state.componentList.loading);
  const componentListStatus = useSelector(state => state.componentList.status);
  const deliveryList = useSelector(state => state.deliveryList.data);
  const deliveryListStatus = useSelector(state => state.deliveryList.status);
  const [delivery, setDelivery] = React.useState(null);
  const [TaskList, setTaskList] = React.useState();
  const [componentRow, setComponentRow] = React.useState();
  const {deliveryStatus,deliveryStatusDescription,taskTypeList,statusList,UnitList,controlList,CCTList,optikList,alert}= useSelector(state => state.App);
  const materials = useSelector((state) => state.materials.data);
  const [materialList, setMaterialList] = React.useState(materials);

  const [task, setTask] = React.useState(null);
  const [tabID,setTabID] = React.useState("tasklist")
  const User = useSelector((state) => state.auth.user);
  const [state, setState] = React.useState({
    stepModalActive:null,
    componentRowModalActive:null,
    stepComponentListActive:null,
    stepIndex:null,
    orderNotesModalActive:null,
    notesModalIndex:null,
    deliveryModalActive:null,
    orderInfoModalActive:null,
    materialListModalActive:null,
    optionModalActive:null,
    printModalActive:null,
    taskModalActive:null,
    option:null,
    productCodeIndex:null,
    activeTab:"1",
    itemtype:"product"
  });
 const productImage = orderItemDetail &&  orderItemDetail.urun_id && data.urun_id.productImages && data.urun_id.productImages.length ? data.urun_id.productImages[0].image.smallimage : blank;
 let color; let statusicon = ""
 if (dataSource && dataSource.siparis_durumu && dataSource.siparis_durumu === 0 ){color = 'magenta'} 
 else if (dataSource && dataSource.siparis_durumu && dataSource.siparis_durumu === 1){color = 'red',statusicon="pause-circle"} 
 else if (dataSource && dataSource.siparis_durumu && dataSource.siparis_durumu === 2){color = 'blue',statusicon="play-circle"} 
 else if (dataSource && dataSource.siparis_durumu && dataSource.siparis_durumu === 3){color = 'yellow',statusicon="list-ol"} 
 else if (dataSource && dataSource.siparis_durumu && dataSource.siparis_durumu === 4){color = "#24bc42",statusicon="check-circle"} 
 else if (dataSource && dataSource.siparis_durumu && dataSource.siparis_durumu === 5){color = '#24bc42',statusicon="check-double"} 
 else {color = 'purple'};

  //******************************************************************************************************************************************************************/
  useEffect(() => {
    if (projectID !=="new" && dataSource._id !== projectID) {
      dispatch(getOrderItemDetails({id:projectID}))
     
    }
  },[]);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    setDataSource(orderItemDetail);
  }, [orderItemDetail]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(orderItemDetailStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [orderItemDetailStatus]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (i) => {
    setTabID(i)
  }

  //******************************************************************************************************************************************************************/
  const onSaveOrderItem =()=>{
    message.loading(messages["alert.updated"]);
    if(dataSource._id){
      dispatch(updateOrderItem(dataSource))
      .then(res=>{
        message.success(messages["alert.savesuccess"],2);
      })
    }
  }



  //******************************************************************************************************************************************************************/

  return (
    <ProjectDetailsWrapper>
      <PageHeader
        ghost
        title={messages["production.details"]}
        buttons={[
          <div key={0} className="project-action">
            <NavLink key={4} to="/admin/lastchecklist" className="project-edit">
              <FontAwesomeIcon icon={faPrint} size='1x' />
              {messages["sidebar.projects"]}
            </NavLink>
          </div>
        ]}
      />
      
        <Main>
          <Card title={dataSource && dataSource.urun_adi && dataSource.urun_adi} extra={loading && <Spin indicator={faSpinner} />}>
            <Row gutter={15} >
              <Col xxl={5} lg={7} md={9} xs={24}>
                <UserCard>
                  <div className="card team-card">
                      <figure>
                        <img src={productImage} alt="" />
                        <figcaption>
                          <p>{dataSource && dataSource.urun_kodu && dataSource.urun_kodu && getProductCode("",dataSource.urun_kodu[0],dataSource.urun_kodu[1],dataSource.urun_kodu[2],dataSource.urun_kodu[3],dataSource.urun_kodu[4],dataSource.urun_kodu[5])}</p>
                        </figcaption>
                      </figure>
                  </div>
                </UserCard>
              </Col>
              <Col xxl={19} lg={17} md={15} xs={24}>
                <Row>
                  <div className="state-single">
                    <div className="color-primary">
                      <NavLink to="#">
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          size="2x"
                        />
                      </NavLink>
                    </div>
                    <div>
                      <Heading as="h1">{dataSource && dataSource.urun_miktari &&dataSource.urun_miktari} {messages["genel.Adet"]}</Heading>
                      <p>{messages["offer.OfferStatus1"]}</p>
                    </div>
                  </div>
                  <div className="state-single">
                    <div className="color-success">
                      <NavLink to="#">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="2x"
                        />
                      </NavLink>
                    </div>
                    <div>
                      <Heading as="h1">{0} {messages["genel.Adet"]}</Heading>
                      <p>Test Edilmiş</p>
                    </div>
                  </div>
                </Row>

                <Row gutter={5} style={{marginTop:"20px",marginBottom:"10px"}}>
                    <div className="state-single">
                      <div className="color-warning">
                        <NavLink to="#">
                          <FontAwesomeIcon
                            icon={faBackwardStep}
                            size="2x"
                          />
                        </NavLink>
                      </div>
                      <div>
                        <Heading as="h2">{moment(dataSource && dataSource.siparis_tarihi && dataSource.siparis_tarihi).format("DD.MM.YYYY")}</Heading>
                        <p>{messages["order.date"]}</p>
                      </div>
                    </div>
                    <div className="state-single">
                      <div className="color-primary">
                        <NavLink to="#">
                          <FontAwesomeIcon
                            icon={faPlay}
                            size="2x"
                          />
                        </NavLink>
                      </div>
                      <div>
                        <Heading as="h2">{dataSource && dataSource.baslama_tarihi && dataSource.baslama_tarihi ? moment(dataSource.baslama_tarihi).format("DD.MM.YYYY"): "--.---.----"}</Heading>
                        <p>{messages["order.startdate"]}</p>
                      </div>
                    </div>

                    <div className="state-single">
                      <div className="color-success">
                        <NavLink to="#">
                          <FontAwesomeIcon
                            icon={faFlagCheckered}
                            size="2x"
                          />
                        </NavLink>
                      </div>
                      <div>
                        <Heading as="h2">{dataSource && dataSource.bitis_tarihi && dataSource.bitis_tarihi ? moment(dataSource.bitis_tarihi).format("DD.MM.YYYY"): "--.---.----"}</Heading>
                        <p>{messages["order.enddate"]}</p>
                      </div>
                    </div>
          
                </Row>
              </Col>
            </Row>
          </Card>
          <Col xs={24}>
            <Card style={{marginTop:"25px"}}>
              <Row gutter={5}>
                {dataSource && dataSource.urun_kodu && dataSource.urun_kodu.length ?  
                <>
                  <div className="state-single">
                    <div className="color-primary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,3)}>
                        <FontAwesomeIcon
                          icon={faLightbulb}
                          size="2x"
                        />
                      </NavLink>
                    </div>
                    <div>
                      <Heading as="h2">{(dataSource.urun_kodu[3] ? dataSource.urun_kodu[3]: " ---- ")}</Heading>
                      <p>{messages["product.cct"]}</p>
                    </div>
                  </div>
                  <div className="state-single">
                    <div className="color-secondary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,2)}>
                        <FontAwesomeIcon
                          icon={faEye}
                          size="2x"
                        />
                      </NavLink>
                    </div>
                    <div>
                      <Heading as="h2">{(dataSource.urun_kodu[2] ? dataSource.urun_kodu[2]: " ---- ")}</Heading>
                      <p>{messages["product.optik"]}</p>
                    </div>
                  </div>
                  <div className="state-single">
                    <div className="color-secondary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,6)}>
                        <FontAwesomeIcon
                          icon={faPalette}
                          size="2x"
                        />
                      </NavLink>
                    </div>
                    <div>
                      <Heading as="h2">{(dataSource.urun_kodu[6] ? dataSource.urun_kodu[6]: " ---- ")}</Heading>
                      <p>{messages["product.bodycolor"]}</p>
                    </div>
                  </div>
                  <div className="state-single">
                    <div className="color-primary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,7)}>
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          size="2x"
                        />
                      </NavLink>
                    </div>
                    <div>
                      <Heading as="h2">{(dataSource.urun_kodu[7] ? dataSource.urun_kodu[7]: " ---- ")}</Heading>
                      <p>{messages["product.ledmark"]}</p>
                    </div>
                  </div>
                  <div className="state-single">
                    <div className="color-primary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,8)}>
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          size="2x"
                        />
                      </NavLink>
                    </div>
                    <div>
                      <Heading as="h2">{(dataSource.urun_kodu[8] ? dataSource.urun_kodu[8]: " ---- ")}</Heading>
                      <p>{messages["product.leddriver"]}</p>
                    </div>
                  </div>
                </>
                :null}
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <ProjectTabContent style={{marginTop:"25px"}}>
              <Cards
                title={
                  <nav>
                    <NavLink to={`#`} onClick={()=>gotoPage("tasklist")}  activeClassName={tabID === "tasklist" ? "active":""}>{messages["task.list"]}</NavLink>
        
                  </nav>
                }
              >
              {tabID==="tasklist" ?
                <div>

                  <Alert message="Veri bulunamadı" type="info" />
                  
                </div>
              :null}
              </Cards>
            </ProjectTabContent>
          </Col>
        </Main>
    </ProjectDetailsWrapper>
  );
}


export {LastCheckDetails};
