import {
    COMPONENT_GET_LIST,
    COMPONENT_GET_LIST_SUCCESS,
	COMPONENT_GET_LIST_ERROR,
	COMPONENT_SUM_GET_LIST,
    COMPONENT_SUM_GET_LIST_SUCCESS,
	COMPONENT_SUM_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	componentList: [],
	componentListLoading:false,
	componentListStatus:null,
	componentListSum: null,
	componentListSumLoading:false,
	componentListSumStatus:null,
};

const componentList =(state = INIT_STATE, action) => {
	switch (action.type) {
		case COMPONENT_GET_LIST: // GEL LIST 
			return { 
				...state, 
				componentListLoading: true,
				componentListStatus:null
			};
		case COMPONENT_GET_LIST_SUCCESS:
            return { ...state, 
                componentListLoading: false, 
                componentList: action.response.componentlist,
				componentListStatus:200
            };
		case COMPONENT_GET_LIST_ERROR:
			return { 
				...state, 
				componentListLoading: false,
				componentListStatus:action.response 
			};
		case COMPONENT_SUM_GET_LIST: // GEL LIST 
			return { 
				...state, 
				componentListSumLoading: true,
				componentListSumStatus:null,
				componentListSum:null
			};
		case COMPONENT_SUM_GET_LIST_SUCCESS:
            return { ...state, 
                componentListSumLoading: false, 
                componentListSum: action.response,
				componentListSumStatus:200
            };
		case COMPONENT_SUM_GET_LIST_ERROR:
			return { 
				...state, 
				componentListSumLoading: false,
				componentListSumStatus:action.response,
				componentListSum:null
			};
		default: {
			return state
		}
	}
}
export default componentList