import React from 'react';
import { View, StyleSheet,Image,Link } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { useSelector, useDispatch } from 'react-redux';

const BoderColor = '#E7E7E7'

const styles = StyleSheet.create({
    MainContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        borderColor:BoderColor,
    },
    Rows: {
        flexDirection:"row",
        width: 400,
        justifyContent:'center',
        minHeight:25,
        borderBottomWidth:0.2,
        marginBottom:2
    },
    leftContainer: {
        flexDirection: 'column',
        width: 200,
        justifyContent:'center'
    },
    leftYN: {
        flexDirection: 'column',
        width: 30,
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
    rightContainer: {
        flexDirection: 'column',
        width: 75,
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    }
  });
    const QuestRow = styled.Text`
    font-family:'OpenSansFontBold';
    paddingLeft:2px;
    font-size: 7px;
    `;
    const YesNo = styled.Text`
    font-family:'OpenSansFontBold';
    margin-top: 1px;
    font-size: 4px;
    width:30px;
    `;
    const Blok = styled.Text`
    font-family:'OpenSansFontBold';
    font-size: 8px;
    border:1px;
    width:12px;
    height:12px;
    text-align:center;
    `;





const Quest = ({company,data}) => {
    const Questions = company.QCQuestions;
    let Question1 = [];
    let Question2 = [];
    let Question3 = [];
    let Question4 = [];    
    let Question5 = [];
    let Question6 = [];
    let Question7 = [];
    let Question8 = [];    
    if(Questions && Questions.length){
     Question1 = Questions[0].Question.split("*");
     Question2 = Questions.length>1 && Questions[1].Question.split("*");
     Question3 = Questions.length>2 && Questions[2].Question.split("*");
     Question4 = Questions.length>3 && Questions[3].Question.split("*");
     Question5 = Questions.length>4 && Questions[4].Question.split("*");
     Question6 = Questions.length>5 && Questions[5].Question.split("*");
     Question7 = Questions.length>6 && Questions[6].Question.split("*");
     Question8 = Questions.length>7 && Questions[7].Question.split("*");
    }
    return(

        <View style={styles.MainContainer}>
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question1[0]}</QuestRow>}
                    {Question1[1] && Question1[1].trim() &&<QuestRow>{Question1[1]}</QuestRow>}
                    {Question1[2] && Question1[2].trim() &&<QuestRow>{Question1[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[0]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[0]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[0]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[0]===0?"X":" "}</Blok>
                </View>      
            </View>

            {Questions.length>1 && 
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question2[0]}</QuestRow>}
                    {Question2[1] && Question2[1].trim() &&<QuestRow>{Question2[1]}</QuestRow>}
                    {Question2[2] && Question2[2].trim() &&<QuestRow>{Question2[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[1]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[1]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[1]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[1]===0?"X":" "}</Blok>
                </View>      
            </View>}

            {Questions.length>2 && 
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question3[0]}</QuestRow>}
                    {Question3[1] && Question3[1].trim() &&<QuestRow>{Question3[1]}</QuestRow>}
                    {Question3[2] && Question3[2].trim() &&<QuestRow>{Question3[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[2]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[2]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[2]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[2]===0?"X":" "}</Blok>
                </View>      
            </View>}

            {Questions.length>3 && 
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question4[0]}</QuestRow>}
                    {Question4[1] && Question4[1].trim() &&<QuestRow>{Question4[1]}</QuestRow>}
                    {Question4[2] && Question4[2].trim() &&<QuestRow>{Question4[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[3]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[3]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[3]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[3]===0?"X":" "}</Blok>
                </View>      
            </View>}

            {Questions.length>4 && 
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question5[0]}</QuestRow>}
                    {Question5[1] && Question5[1].trim() &&<QuestRow>{Question5[1]}</QuestRow>}
                    {Question5[2] && Question5[2].trim() &&<QuestRow>{Question5[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[4]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[4]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[4]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[4]===0?"X":" "}</Blok>
                </View>      
            </View>}

            {Questions.length>5 && 
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question6[0]}</QuestRow>}
                    {Question6[1] && Question6[1].trim() &&<QuestRow>{Question6[1]}</QuestRow>}
                    {Question6[2] && Question6[2].trim() &&<QuestRow>{Question6[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[5]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[5]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[5]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[5]===0?"X":" "}</Blok>
                </View>      
            </View>}

            {Questions.length>6 && 
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question7[0]}</QuestRow>}
                    {Question7[1] && Question7[1].trim() &&<QuestRow>{Question7[1]}</QuestRow>}
                    {Question7[2] && Question7[2].trim() &&<QuestRow>{Question7[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[6]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[6]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[6]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[6]===0?"X":" "}</Blok>
                </View>      
            </View>}

            {Questions.length>6 && 
            <View style={styles.Rows}>
                <View style={styles.leftContainer}>
                    {<QuestRow>{Question8[0]}</QuestRow>}
                    {Question8[1] && Question8[1].trim() &&<QuestRow>{Question8[1]}</QuestRow>}
                    {Question8[2] && Question8[2].trim() &&<QuestRow>{Question8[2]}</QuestRow>}  
                </View>
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers[7]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers[7]===0?"X":" "}</Blok>
                </View>          
                <View style={styles.rightContainer}>
                    <QuestRow>Sonuç Uygun mu ?</QuestRow>
                </View>  
                <View style={styles.leftYN}>
                    <YesNo>EVET</YesNo>
                    <Blok>{data.qc_answers_result[7]===1?"X":" "}</Blok>
                </View> 
                <View style={styles.leftYN}>
                    <YesNo>HAYIR</YesNo>
                    <Blok>{data.qc_answers_result[7]===0?"X":" "}</Blok>
                </View>      
            </View>}
        </View>

  
)};
  
export default Quest