import React from 'react';
import { Col,Row,Button, Spin, Form, Input,Select,InputNumber,Card,DatePicker,Collapse,Tooltip,Segmented } from 'antd';
import {currencyFormatter} from '../../../helpers/currencyFormat';
import { HorizontalFormStyleWrap,BasicFormWrapper } from '../../Style/style';
import { faEdit, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
const { Panel } = Collapse;
const defaultOptions = {
  significantDigits: 4,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ""
}
const OfferInfo =({offer_Status,offer,loading,toggleModal,messages,updateOfferData,CurrencyList,EURSatis,USDSatis,USDAlis,EURAlis,setSubmittable})=> {
  const {firma_id,teklif_durumu} = offer
  const [form3] = Form.useForm();
  React.useEffect(() => {
    form3.setFieldsValue(offer)
  },[offer])

  const values = Form.useWatch([], form3);
  React.useEffect(() => {
    form3.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);

  let orderOptions = [
    {label:messages['offer.OfferStatus0'],value:0},
    {label:messages['offer.OfferStatus1'],value:1},
    {label:messages['offer.OfferStatus2'],value:2},
    {label:messages['offer.OfferStatus3'],value:3},
    {label:messages['offer.OfferStatus4'],value:4},
  ]

  if(offer_Status === 0){
    orderOptions = [
      {label:messages['offer.OfferStatus0'],value:0},
      {label:messages['offer.OfferStatus1'],value:1},
    ]
  }else if (offer_Status===1){
    orderOptions = [
      {label:messages['offer.OfferStatus1'],value:1},
      {label:messages['offer.OfferStatus2'],value:2},
      {label:messages['offer.OfferStatus3'],value:3},
      {label:messages['offer.OfferStatus4'],value:4},
    ]
  }else if (offer_Status===4){
    orderOptions = [
      {label:messages['offer.OfferStatus0'],value:0},
      {label:messages['offer.OfferStatus4'],value:4},
    ]
  }else{
    orderOptions = [
      {label:messages['offer.OfferStatus0'],value:0},
      {label:messages['offer.OfferStatus1'],value:1},
      {label:messages['offer.OfferStatus2'],value:2},
      {label:messages['offer.OfferStatus3'],value:3},
      {label:messages['offer.OfferStatus4'],value:4}
    ]}
  return (

      <Card style={{marginBottom:'20px'}}>
        {loading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          <BasicFormWrapper>
            <HorizontalFormStyleWrap>
              <Form form={form3} initialValues={offer} >
                <Collapse defaultActiveKey={['2','4']}  >
                  <Panel header={messages['offer.OfferStatus0'] + " " + messages['offer.status'] }key="0" collapsible={teklif_durumu===3 || (!offer.firma_id) ? "disabled":""}>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.status"]}</label>
                      </Col>
                      <Col md={18} xs={24} align="left" style={{paddingBottom:1}}>

                        <Segmented size="small" defaultValue={teklif_durumu} options={orderOptions} onChange={(e)=>updateOfferData("teklif_durumu",e)}/>

                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={messages['offer.subject'] + " / " + messages['offer.relatedperson'] }key="1">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.subject"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="teklif_konu"  >
                          <Input onChange={(e)=>updateOfferData("teklif_konu",e.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.relatedperson"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="related_person"  >
                          <Input onChange={(e)=>updateOfferData("related_person",e.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={messages['genel.date'] + " / " + messages['offer.offerno'] + " / " + messages["offer.referance"] } key="2">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["genel.date"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item  >
                          <DatePicker
                            value={moment(new Date(offer.teklif_tarihi))}
                            format="DD/MM/YYYY"
                            style={{width:"100%", display:'inline-block'}}
                            onChange={(e)=>updateOfferData("teklif_tarihi",e)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.offerno"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="teklif_no"  >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.referance"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="teklif_referansno"  >
                          <Input onChange={(e)=>updateOfferData("teklif_referansno",e.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={messages["offer.offercurrency"]} key="3">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.offercurrency"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name='teklif_dovizi' style={{ display: 'inline-block', width: 'calc(30% - 5px)' }} >
                          <Select options={CurrencyList} onSelect={(e)=>updateOfferData("teklif_dovizi",e)}/>
                        </Form.Item>
                        {offer.teklif_dovizi === '$' ? 
                        <Tooltip title="USD Kur Bilgisi ">
                          <Form.Item name="teklif_usdkur" style={{ display: 'inline-block', width: 'calc(70%)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input USD price!' }]}>
                            <InputNumber decimalSeparator=',' onChange={(e)=>updateOfferData("teklif_usdkur",e)}/>
                          </Form.Item>
                        </Tooltip>
                        :offer.teklif_dovizi === '€' ? 
                        <Tooltip title="EUR Kur Bilgisi ">
                          <Form.Item name="teklif_eurkur" style={{ display: 'inline-block', width: 'calc(70%)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input EUR price!' }]}>
                            <InputNumber decimalSeparator=',' onChange={(e)=>updateOfferData("teklif_eurkur",e)}/>
                          </Form.Item>
                        </Tooltip>
                        :offer.teklif_dovizi === '₺' ? 
                        <>
                          <Tooltip title="USD Kur Bilgisi ">
                            <Form.Item name="teklif_usdkur" style={{ display: 'inline-block', width: 'calc(35% -5 px)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input USD price!' }]}>
                              <InputNumber decimalSeparator=',' onChange={(e)=>updateOfferData("teklif_usdkur",e)}/>
                            </Form.Item>
                          </Tooltip>
                          <Tooltip title="EUR Kur Bilgisi ">
                            <Form.Item name="teklif_eurkur" style={{ display: 'inline-block', width: 'calc(35%)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input EUR price!' }]}>
                              <InputNumber decimalSeparator=','  onChange={(e)=>updateOfferData("teklif_eurkur",e)}/>
                            </Form.Item>
                          </Tooltip>
                        </> : null
                        }
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
              
                      </Col>
                      <Col md={18} xs={24}>
                        {offer.teklif_dovizi === '$' ? 
                          <>
                            <Button onClick={()=>updateOfferData("teklif_usdkur",USDSatis)}>{currencyFormatter(USDSatis,defaultOptions)}</Button>
                            <Button style={{marginLeft:'20px'}} onClick={()=>updateOfferData("teklif_usdkur",USDAlis)}>{currencyFormatter(USDAlis,defaultOptions)}</Button>
                          </>
                        :offer.teklif_dovizi === '€' ? 
                          <>
                            <Button onClick={()=>updateOfferData("teklif_eurkur",EURSatis)}>{currencyFormatter(EURSatis,defaultOptions)}</Button>
                            <Button style={{marginLeft:'20px'}} onClick={()=>updateOfferData("teklif_eurkur",EURAlis)}>{currencyFormatter(EURAlis,defaultOptions)}</Button>
                          </>
                        :
                          <>
                            <Button onClick={()=>updateOfferData("teklif_usdkur",USDSatis)}>{currencyFormatter(USDSatis,defaultOptions)}</Button>
                            <Button style={{marginLeft:'20px'}} onClick={()=>updateOfferData("teklif_usdkur",USDAlis)}>{currencyFormatter(USDAlis,defaultOptions)}</Button>
                            <Button style={{marginLeft:'40px'}} onClick={()=>updateOfferData("teklif_eurkur",EURSatis)}>{currencyFormatter(EURSatis,defaultOptions)}</Button>
                            <Button style={{marginLeft:'20px'}} onClick={()=>updateOfferData("teklif_eurkur",EURAlis)}>{currencyFormatter(EURAlis,defaultOptions)}</Button>
                          </>
                        }
                      </Col>
                    </Row>

                  </Panel>
                  <Panel header={messages["offer.company"]} key="4">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.company"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="firma_adi" rules={[{ required: true, message: 'Please input Company Name!' }]} >
                          <Input 
                            addonAfter={
                              <>
                                <FontAwesomeIcon icon={faMagnifyingGlass} size='1x' onClick={()=>toggleModal("companyListModalActive",true)} style={{marginRight:'20px'}}/>
                                {firma_id && <FontAwesomeIcon icon={faEdit} size='1x' onClick={()=>toggleModal("companyListModalActive",true)}/>}
                              </>
                            } 
                            onChange={(e)=>updateOfferData("firma_adi",e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["company.address"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="firma_adres"  >
                          <Input onChange={(e)=>updateOfferData("firma_adres",e.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
              
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name='firma_ilce' style={{ display: 'inline-block', width: 'calc(35% - 5px)' }} >
                          <Input onChange={(e)=>updateOfferData("firma_ilce",e.target.value)}/>
                        </Form.Item>
                        <Form.Item name="firma_il" style={{ display: 'inline-block', width: 'calc(35%- 5px)', marginLeft: '5px' }}>
                          <Input onChange={(e)=>updateOfferData("firma_il",e.target.value)}/>
                        </Form.Item>
                        <Form.Item name="firma_ulke" style={{ display: 'inline-block', width: 'calc(30% )', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input Country' }]}>
                          <Input onChange={(e)=>updateOfferData("firma_ulke",e.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["company.e-mail"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="firma_eposta" rules={[{ required: true, message: 'Please input E-Mail address!',type: 'email' }]} >
                          <Input onChange={(e)=>updateOfferData("firma_eposta",e.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Form>
            </HorizontalFormStyleWrap>
          </BasicFormWrapper>
        )}
      </Card>
  );
}

export  {OfferInfo};
