import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from "moment";
const styles = StyleSheet.create({
    Container: {
        marginTop: 1,
        flexDirection: "column",
        width:400,
        border:1,
        borderRadius:2
    },
    ContainerRow: {
        flexDirection: "row",
        borderBottom:0.2,
        borderBottomColor:'#666666'
    }
  });
const Label = styled.Text`
font-size: 9px;
text-align: left;
margin:3px;
color:#666666;
width:150px;
`;
const Value = styled.Text`
font-size: 9px;
text-align: left;
margin:3px;
width:250px;
`;



const Info = ({data,index}) => {
    const deliveryadresscontainer = data.teklif_sevkadresi==="" ? false : true;
    const taxcontaineropen = data.firma_vergidairesi==="" ? false : true
    return(
    
<View style={styles.Container}>

    <View style={styles.ContainerRow}>
        <Label>TEDARİKÇİ FİRMA</Label>
        <Value> : {data.tedarikci_id.company_name.toUpperCase()}</Value>
    </View>
    <View style={styles.ContainerRow}>
        <Label>FATURA / İRSALİYE NO ve TARİHİ</Label>
        <Value> : {data.received[index].waybill_no.toUpperCase() +"/"+moment(data.received[index].waybill_date).format('DD.MM.YYYY')}</Value>
    </View>
    <View style={styles.ContainerRow}>
        <Label>MALZEME BİLGİSİ</Label>
        <Value> : {data.material_id.material_category.toUpperCase() +" / "+data.material_id.material_group.toUpperCase() +" / "+data.material_id.material_name.toUpperCase() }</Value>
    </View>
    <View style={styles.ContainerRow}>
        <Label>GELEN MİKTAR</Label>
        <Value> : {data.received[index].qty+" "+data.received[index].unit }</Value>
    </View>
    <View style={styles.ContainerRow}>
        <Label>KONTROL EDİLEN MİKTAR</Label>
        <Value> : {data.received[index].qty+" "+data.received[index].unit }</Value>
    </View>

</View>
)};
  
export default Info