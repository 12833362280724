import React from 'react';
import {Tooltip,Card,Spin,Button } from 'antd';
import { Link } from 'react-router-dom';
import { NotificationWrapper } from '../../Style/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus} from '@fortawesome/free-solid-svg-icons';

import Heading from '../../../components/heading/heading';

const listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
  padding: 0,
};

const ModelFiles = ({files,messages,lang,loading,toggleModal,onSaveModel,removeDownloadRow,addNewDownloadRow}) => {
  return (
    <NotificationWrapper>
      <Card style={{marginBottom:'20px'}}>
        <div className="notification-box-single">
          <Card bodyStyle={{ backgroundColor: '#F7F8FA', borderRadius: 10 }}>
            <div
              style={{
                height: '50px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              className="notification-header"
            >
              <Heading className="notification-header__text" as="h4">
                {messages["model.downloads"]}
              </Heading>
              <Link className="btn-toggle" to="#">
                <Tooltip title={"Yeni Özellik satırı Ekler"} placement="top" >
                  <FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"25px",color:'#9299B8'}} size='lg' onClick={()=>addNewDownloadRow()}/>
                </Tooltip>
              </Link>
            </div>
            <div className="notification-body">
              <Card>
                <nav>
                {loading ? (
                  <div className="spin">
                    <Spin />
                  </div>
                ) : (
                  <ul
                    style={{margin: 0,padding: 0}}>
                    {files.map((file,index)=>{
                      return(
                        <li style={listStyle} key={index}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              {index+1}{ " => "} {file.name}
                            </Heading>
                            <p>{file.file_descirpiption[lang]}</p>
                            <p>{file.type +"  /  " +file.size}</p>
                          </div>
                          <div className='notification-actions'>
                            <span className="activity-icon bg-info">
                              <Link to="#">
                                <FontAwesomeIcon icon={faEdit} size='sm' onClick={()=>toggleModal("uploadModalIndex",index)}/>
                              </Link>
                            </span>
                            <span className="activity-icon bg-danger">
                              <Link  to="#">
                                <FontAwesomeIcon icon={faTrash} size='sm'  onClick={()=>removeDownloadRow(index)} />
                              </Link>
                            </span>
                          </div>

                        </li>
                      )
                    })}

                  </ul>)}
                </nav>
              </Card>
            </div>
          </Card>
        </div>
        <div className="notification-actions">
          <Button size="default" type="primary" onClick={()=>onSaveModel()}>
            {messages["button.save"]}
          </Button>
        </div>
      </Card>
    </NotificationWrapper>
  );
}

export  {ModelFiles};
