import React from 'react';
import { Col, Row, Modal,Button, Form,Input, Divider,Switch } from 'antd';
import {ProductBoxLabel}  from '../../../reports/ProductBoxLabel';
const LabelModal = ({labelData,logoOpen,messages,labelModalActive,toggleModal,bg_logo_Open,onChangeLabelData}) => {
  const [form9] = Form.useForm();
  return (
  
    labelModalActive && 
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={labelModalActive}
      footer={          
        <div>
          <Button  size="large" type="primary" onClick={() => toggleModal("labelModalActive",null)}>
            {messages["button.close"]}
          </Button>
        </div>}
      onCancel={() => toggleModal("labelModalActive",null)}
      forceRender
      width={800}
      closable={false}
    >
          <Row align="middle"  gutter={15}>
            <Col md={14} xs={24}>
              <Form form={form9} initialValues={labelData}>
                <Row align="middle">
                  <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                    <label htmlFor="input-text">{messages["product.name"]}</label>
                  </Col>
                  <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                    <Form.Item name="urun_adi"  >
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                    <label htmlFor="input-text">{messages["product.power"]}</label>
                  </Col>
                  <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                    <Form.Item name="power"  >
                      <Input  onChange={(eData)=>onChangeLabelData("power",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                    <label htmlFor="input-text">{messages["product.lumen"]}</label>
                  </Col>
                  <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                    <Form.Item name="lumen"  >
                      <Input  onChange={(eData)=>onChangeLabelData("lumen",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                    <label htmlFor="input-text">{messages["product.weight"]}</label>
                  </Col>
                  <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                    <Form.Item name="weight"  >
                      <Input  onChange={(eData)=>onChangeLabelData("weight",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                    <label htmlFor="input-text">{messages["product.dimensions"]}</label>
                  </Col>
                  <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                    <Form.Item name="dimensions"  >
                      <Input  onChange={(eData)=>onChangeLabelData("dimensions",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                    <label htmlFor="input-text">{messages["genel.unit"]}</label>
                  </Col>
                  <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                    <Form.Item name="unit"  >
                      <Input  onChange={(eData)=>onChangeLabelData("unit",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider/>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["company.logo"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Switch checked={logoOpen} onChange={(e)=>bg_logo_Open(e)} />
                  </Col>
                </Row>



              </Form>
            </Col>
            <Col md={10} xs={24}>
                <ProductBoxLabel labelData={labelData}/>
            </Col>
          </Row>


      
    </Modal>
  );
}

export  {LabelModal};
