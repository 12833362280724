import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        height: '12px',
        borderBottom:0.3,
        marginTop:3,
        borderBottomColor:"grey",
        width:550
    }
  });
  const Material = styled.Text`
  paddingLeft:2px;
  font-size: 6px;
  text-align: left;
  width:100px;
  `;
  const MaterialCategory = styled.Text`
  paddingLeft:2px;
  font-size: 6px;
  text-align: left;
  width:60px;
  `;
  const MaterialQty = styled.Text`
  paddingLeft:2px;
  font-size: 6px;
  text-align: center;
  width:30px;
  `;
  const Task = styled.Text`
  paddingLeft:2px;
  font-size: 6px;
  text-align: left;
  width:60px;
  `;
  const Status = styled.Text`
  paddingLeft:2px;
  font-size: 6px;
  text-align: center;
  border-left: 0.3;
  width:20px;
  `;
  const Description = styled.Text`
  paddingLeft:2px;
  font-size: 10px;
  text-align: left;
  border-left: 0.3;
  width:100px;
  `;
const componentsRow = ({row}) => {

    return(


        <View style={styles.rowContainer}>
            <MaterialCategory>{row && row.material_category && row.material_category}</MaterialCategory>
            <MaterialCategory>{row && row.material_group && row.material_group}</MaterialCategory>
            <Material>{row && row.material && row.material.material_name}</Material>
            <MaterialQty>{row && row.materialQty && row.materialQty}</MaterialQty>
            <MaterialQty>{row && row.totalQty && row.totalQty}</MaterialQty>
            <MaterialQty>{row && row.material && row.material.material_unit}</MaterialQty>
            <Task>{row && row.task && row.task}</Task>
            <Status>{(row && row.controlled && row.controlled > 0) || !row.controlled  ? "X" :""}</Status>
            <Status>{row && row.controlled && row.controlled >= 1 ? "X":"" }</Status>
            <Status>{row && row.controlled && row.controlled >= 2 ? "X":"" }</Status>
            <Status>{row && row.controlled && row.controlled >= 3 ? "X":"" }</Status>
            <Description></Description>
        </View>
  
)};
  
export default componentsRow