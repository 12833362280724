import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';



import {CRM} from '../../pages/dashboard/CRM';

function DashboardRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CRM} />
    </Switch>
  );
}

export default DashboardRoutes;
