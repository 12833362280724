

import actions from './actions';
const initState = {
	offerReportType:0,
	productsFilter:0,
	offersFilter:0,
	orderFilter:"Tümü",
	materialFilter:'Tümü',
	modelFilter:'Tümü',
	listPerVal:30,
	priceListTextOpen:true,
	copyForm:"",
	copyValues:[],
	pricelisttext:`Değerli Müşterimiz, Ürünlerimize ait fiyat  listesi  aşağıda tarafınıza sunulmuştur. Fiyat listemiz üzerinden %XX iskonto  uygulamaktayız. Bilgilerinize sunar iyi çalışmalar dileriz.`,
	pricelisttype:0,
	logoOpen:false,
	categoryList : [
		{value : 0 , label : "model.kategori"+0, color:"magenta"},
		{value : 1 , label : "model.kategori"+1, color:"orange"},
		{value : 2 , label : "model.kategori"+2, color:"gold"},
		{value : 3 , label : "model.kategori"+3, color:"blue"},
		{value : 4 , label : "model.kategori"+4, color:"green"},
		{value : 5 , label : "model.kategori"+5, color:"purple"},
		{value : 6 , label : "model.kategori"+6, color:"lime"},
		{value : 7 , label : "model.kategori"+7, color:"cyan"},
		{value : 8 , label : "model.kategori"+8, color:"magenta"},
	],
	CurrencyList: [
		{ value: '₺', label: '₺' },
		{ value: '$', label: '$' },
		{ value: '€', label: '€' }
	],
	CCTList: [
		{ value: 'XXXX', label: 'XXXX' },
		{ value: '6500', label: '6500' },
		{ value: '6000', label: '6000' },
		{ value: '5000', label: '5000' },
		{ value: '4000', label: '4000' },
		{ value: '3500', label: '3500' },
		{ value: '3000', label: '3000' },
		{ value: 'GREEN', label: 'GREEN' },
		{ value: 'RED', label: 'RED' },
		{ value: 'BLUE', label: 'BLUE' },
		{ value: 'AMBER', label: 'AMBER' }
	],
	optikList: [
		{ value: 'XX', label: 'XX' },
		{ value: 'AS', label: 'AS' },
		{ value: 'ST', label: 'ST' },
		{ value: 'SP', label: 'SP' },
		{ value: '15', label: '15' },
		{ value: '30', label: '30' },
		{ value: '45', label: '45' },
		{ value: '60', label: '60' },
		{ value: '90', label: '90' },
	], 
	UnitList : [
		{ value: 'Adet', label: "genel.Adet"},
		{ value: 'Kg', label: "genel.Kg" },
		{ value: 'Metre', label: "genel.Metre"},
		{ value: 'Takım', label: "genel.Takım"},
		{ value: 'Paket', label:"genel.Paket"}
	],
	operationCenterList:[
		{value: "fatura", label: "Fatura"},
		{value: "ithalat", label: "İthalat"},
		{value: "uretim", label: "Üretim"},
		{value: "Teslimat",label :"Teslimat"},
		{value: "fire", label: "Fire"},
	],
	operationTypeSelect:[
		{ value: "giris", label: "Giriş" },
		{ value: "cikis", label: "Çıkış" }
	],
	KDVList : [
		{ value: '20', label: '20' },
		{ value: '18', label: '18' },
		{ value: '8', label: '8' },
		{ value: '0', label: '0' }
	],
	importanceList: [
		{ value: 0, label: 'task.importance0'},
		{ value: 1, label: 'task.importance1'},
		{ value: 2, label: 'task.importance2'}
	],
	statusList: [
		{ value: 0, label: 'task.status0',color:'red'},
		{ value: 1, label: 'task.status1',color:'blue'},
		{ value: 2, label: 'task.status2',color:'green'}
	],
	taskTypeList: [
		{ value: 0, label: 'task.production',color:'red'},
		{ value: 1, label: 'task.control',color:'blue'},
		{ value: 2, label: 'task.operation',color:'green'}
	],
	listperValOptions : [
		{value : 0 ,label : "0"},
		{value : 10 ,label :"10"},
		{value : 20 ,label :"20"},
		{value : 30 ,label :"30"},
		{value : 40 ,label :"40"},
		{value : 50 ,label :"50"},
		{value : 60 ,label :"60"},
		{value : 70 ,label :"70"},
		{value : 80 ,label :"80"},
		{value : 90 ,label :"90"},
		{value : 100 ,label :"100"},
	],
	controlList: [
		{ value: 0, label: 'order.componentstatus0',color:'red'},
		{ value: 1, label: 'order.componentstatus1',color:'blue'},
		{ value: 2, label: 'order.componentstatus2',color:'yellow'},
		{ value: 3, label: 'order.componentstatus3',color:'green'},
		{ value: 4, label: 'order.componentstatus4',color:'magenta'}
	],
	formList: [
		{ value: "users", label: 'Kullanıcılar'},
		{ value: "myCompany", label: 'Firma Ayarları'},
		{ value: "companies", label: 'Firmalar'},
		{ value: "employee", label: 'Personel'},
		{ value: "orderitemdetail", label: 'Proje Detayları'},
		{ value: "orderitemlist", label: 'Proje Listesi'},
		{ value: "materiallist", label: 'Malzemeler'},
		{ value: "materialdetail", label: 'Malzeme Detayları'},
		{ value: "modellist", label: 'Model Listesi'},
		{ value: "modeldetail", label: 'Model Detayları'},
		{ value: "productlist", label: 'Ürün Listesi'},
		{ value: "productdetail", label: 'Ürün Detayları'},
		{ value: "componentlist", label: 'Üretim Malzeme Listesi'},
		{ value: "componentdetail", label: 'Üretim Malzeme Detayları'},
		{ value: "offers", label: 'Teklifler'},
		{ value: "offerdatail", label: 'Teklif Detayları'},
		{ value: "buyings", label: 'Satın Almalar'},
		{ value: "buyingdetails", label: 'Satın Alma Detaylar'},
		{ value: "tasklist", label: 'Görev Listesi'},
		{ value: "taskdetail", label: 'Görev Detayları'},
		{ value: "deliverylist", label: 'Teslimat Listesi'},
		{ value: "deliverydetail", label: 'Teslimat Detayları'},
		{ value: "actestdetail", label: 'Son Kontrol Test Yetkisi'},
		{ value: "actestlist", label: 'Son Kontrol Listesi'},
	],
	userFilter: [
		{ value: 0, label: 'user.0',color:'red'},
		{ value: 1, label: 'user.1',color:'blue'},
		{ value: 2, label: 'user.2',color:'lime'},
		{ value: 3, label: 'user.3',color:'green'},
		{ value: 4, label: 'user.4',color:'magenta'},
		{ value: 5, label: 'user.5',color:'yellow'},
		{ value: 6, label: 'user.6',color:'gray'},
		{ value: 7, label: 'user.7',color:'gold'}
	],
	userAuthList: [
		{ value: 0, label: 'user.auth0',color:'gold'},
		{ value: 1, label: 'user.auth1',color:'green'},
		{ value: 2, label: 'user.auth2',color:'blue'},
		{ value: 3, label: 'user.auth3',color:'red'},
	],
	OfferStatus: [
		{value : 0 , label:"offer.OfferStatus0" },
		{value : 1 , label:"offer.OfferStatus1" },
		{value : 2 , label:"offer.OfferStatus2" },
		{value : 3 , label:"offer.OfferStatus3" },
		{value : 4 , label:"offer.OfferStatus4" }
	],
	orderStatus: [
		{value : 0 , label:"order.orderStatus0" },
		{value : 1 , label:"order.orderStatus1" },
		{value : 2 , label:"order.orderStatus2" },
		{value : 3 , label:"order.orderStatus3" },
		{value : 4 , label:"order.orderStatus4" },
		{value : 5 , label:"order.orderStatus5" },
		{value : 6 , label:"order.orderStatus6" }
	],
	BuyingStatus: [
		{value : 0 , label:"buying.buyingStatus0" },
		{value : 1 , label:"buying.buyingStatus1" },
		{value : 2 , label:"buying.buyingStatus2" },
		{value : 3 , label:"buying.buyingStatus3" },
		{value : 4 , label:"buying.buyingStatus4" },
	],
	CompanyGroup: [
		{ value: "Suppliers", label: "company.Suppliers"},
		{ value: "Customer", label: "company.Customer" },
	],
	imageDescriptionList : [
		{value : "Ana Görsel" , label : "Ana Görsel"},
		{value : "Yan Görsel" , label : "Yan Görsel"},
		{value : "Teknik Resim" , label : "Teknik Resim"},
		{value : "Renkli Resim" , label : "Renkli Resim"},
	],
	deliveryStatus :[
		{value : 0 , label:'delivery.0',color:"red"},
		{value : 1 , label:'delivery.2',color:"green"},
		{value : 2 , label:'delivery.2',color:"green"}
	],
	deliveryStatusDescription :[
		{value : 0 , label:'delivery.statusdescription0',color:"blue"},
		{value : 1 , label:'delivery.statusdescription1',color:"yellow"},
		{value : 2 , label:'delivery.statusdescription2',color:"green"}
	],
	ColorList : [
		{ value: 'red',label: 'color.red' }, 
		{ value: 'blue',label: 'color.blue' }, 
		{ value: 'green',label: 'color.green'}, 
		{ value: 'purple',label: 'color.purple'},
		{ value: 'gold' ,label: 'color.gold'}, 
		{ value: 'lime',label: 'color.lime' }, 
		{ value: 'magenta',label: 'color.magenta'}, 
		{ value: 'cyan',label: 'color.cyan' } 
	],
	visibilityList : [
		{value : 0 , label : "product.visibility"+0, color:"magenta"},
		{value : 1 , label : "product.visibility"+1, color:"orange"},
		{value : 2 , label : "product.visibility"+2, color:"gold"},
		{value : 3 , label : "product.visibility"+3, color:"blue"},
		{value : 4 , label : "product.visibility"+4, color:"green"},

		{value : 6 , label : "product.visibility"+6, color:"pink"},
		{value : 7 , label : "product.visibility"+7, color:"yellow"},
		{value : 8 , label : "product.visibility"+8, color:"red"},
		{value : 10 , label : "product.visibility"+10, color:"gold"},


	],
};

export default function appReducer(state = initState, action) {
	switch (action.type) {
		case actions.COLLPSE_CHANGE:
		return {
			...state,
			collapsed: !state.collapsed,
		};
		case actions.COLLPSE_OPEN_DRAWER:
		return {
			...state,
			openDrawer: !state.openDrawer,
		};
		case actions.TOGGLE_ALL:
		if (state.view !== action.view || action.height !== state.height) {
			const height = action.height ? action.height : state.height;
			return {
			...state,
			collapsed: action.collapsed,
			view: action.view,
			height,
			};
		}
		break;
		case actions.CHANGE_OPEN_KEYS:
		return {
			...state,
			openKeys: action.openKeys,
		};
		case actions.CHANGE_CURRENT:
		return {
			...state,
			current: action.current,
		};
		case actions.CLEAR_MENU:
		return {
			...state,
			openKeys: [],
			current: [],
		};
		case actions.SET_OFFER_REPORT_TYPE:
			return {
			...state,
			offerReportType:action.val
		}
		case actions.SET_BW_LOGO_OPEN:
			return {
			...state,
			logoOpen:action.val
		}
		case actions.SET_PRICE_LIST_PERCENT:
			return {
			...state,
			listPerVal:action.val
		}
		case actions.SET_PRICE_LIST_TEXTOPEN:
			return {
			...state,
			priceListTextOpen:action.val
		}
		case actions.SET_PRICE_LIST_TEXT:
			return {
			...state,
			pricelisttext:action.val
		}
		case actions.SET_PRICE_LIST_TYPE:
			return {
			...state,
			pricelisttype:action.val
		}
		case actions.SET_PRICE_LIST_FILTER:
			return {
			...state,
			productsFilter:action.val
		}
		case actions.SET_COPY_FORM:
			return {
			...state,
			copyForm:action.val
		}
		case actions.SET_COPY_VALUES:
			return {
			...state,
			copyValues:action.val
		}
		case actions.SET_OFFER_FILTERS:
			return {
			...state,
			offersFilter:action.val
		}
		case actions.SET_ORDER_FILTERS:
			return {
			...state,
			orderFilter:action.val
		}
		case actions.SET_MATERIAL_FILTERS:
			return {
			...state,
			materialFilter:action.val
		}
		case actions.SET_MODEL_FILTERS:
			return {
			...state,
			modelFilter:action.val
		}
		default:
		return state;
	}
	return state;
}
