import React from 'react';
import {  Table, Tag, Progress } from 'antd';
import { Link, } from 'react-router-dom';
import { FigureCart, ProductTable,ProjectCard } from '../../../Style/style';
import Heading from '../../../../components/heading/heading';
import blank from '../../../../static/img/resyok.jpeg';
import {getProductCode} from '../../../../helpers/getProductCode';
import { faPlayCircle,faListOl,faCheckCircle, faPause,faCheckSquare ,faCheckDouble} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
const OfferRows = ({offer,loading,messages}) => {

  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: ""
  }
  const productTableData = [];
  
  if (offer !== null && offer.offeritem) {
    offer.offeritem.map((data,index) => {
      const { _id, urun_miktari, siparis_tarihi,bitis_tarihi,baslama_tarihi, siparis_durumu, urun_kodu, urun_adi,urun_id,order_type,teslim_edilmis,tamamlanmaOrani} = data;
      const rowImg = urun_id && urun_id.productImages && urun_id.productImages[0] && urun_id.productImages[0].image && urun_id.productImages[0].image.smallimage ? urun_id.productImages[0].image.smallimage : blank;
      const productCode = getProductCode("",urun_kodu[0],urun_kodu[1],urun_kodu[2],urun_kodu[3],urun_kodu[4],urun_kodu[5]);
      let color;let statusicon=faCheckSquare;
      if (siparis_durumu === 0 ){color = 'magenta'} 
      else if (siparis_durumu === 1){color = 'red',statusicon=faPause} 
      else if (siparis_durumu === 2){color = 'blue',statusicon=faPlayCircle} 
      else if (siparis_durumu === 3){color = 'yellow',statusicon=faListOl} 
      else if (siparis_durumu === 4){color = "#24bc42",statusicon=faCheckCircle} 
      else if (siparis_durumu === 5){color = '#24bc42',statusicon=faCheckDouble} 
      else {color = 'purple'};
      let orderType = "";
      let orderColor = "";
      if (order_type==="material"){
        orderType=messages["material.material"];
        orderColor="blue"
      }else{
        orderType=messages["product.name"];
        orderColor="green"
      }
      return productTableData.push({
        key: index,
        product: (
          <div className="cart-single">
            <FigureCart>
              <img style={{ width: 80 }} src={rowImg} alt="" />
              <figcaption>
                <div className="cart-single__info">
                  <Heading as="h6">{urun_adi.substring(0,50)}</Heading>
                  <ul className="info-list">
                    <li>
                      <span className="info-title">{messages['product.code']}</span>
                      <span>{productCode}</span>
                    </li>
                  </ul>
                  <ProjectCard>
                    <div className="project-timing">
                      <div>
                        <span>{messages["order.date"]}</span>
                        <strong>{moment(siparis_tarihi).format("DD.MM.YYYY")}</strong>
                      </div>
                      <div>
                        <span>{messages["order.startdate"]}</span>
                        <strong>{baslama_tarihi ? moment(baslama_tarihi).format("DD.MM.YYYY"): "--.---.----"}</strong>
                      </div>
                      <div>
                        <span>{messages["order.enddate"]}</span>
                        <strong>{bitis_tarihi ?  moment(bitis_tarihi).format("DD.MM.YYYY") : "--.--.----"}</strong>
                      </div>
                    </div>
                  </ProjectCard>
                </div>
              </figcaption>
            </FigureCart>
          </div>
        ),
        price: 
        <div className="product-single-info">
          <Link  className="btn-heart" to="#">
            <Tag color={orderColor}>{orderType}</Tag>
          </Link>
          <p className="product-single-price">
            <span className="product-single-price__new"></span>
          </p>
          <ProjectCard>
            <div className="project-timing">
              <div>
                <span>{messages["order.componentstatus2"]}</span>
                <strong>{urun_miktari} {messages["genel.Adet"]}</strong>
              </div>
              <div>
                <span>{messages["order.orderStatus5"]}</span>
                <strong>{teslim_edilmis} {messages["genel.Adet"]}</strong>
              </div>
            </div>
            <div className="project-progress">
              <Progress
                percent={tamamlanmaOrani.toFixed(2)}
                strokeWidth={5}
                status="primary"
                className="progress-primary"
              />
              <p>12/15 Task Completed</p>
            </div>
          </ProjectCard>
        </div>
      });
    });
  }

  const productTableColumns = [
    {
      title: messages["product.name"],
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: messages["genel.notes"],
      dataIndex: 'price',
      key: 'price',
      align:'right'
    }
  ];

  return (
    <>
      <ProductTable>
        <div className="table-cart table-responsive">
            <Table pagination={false} loading={loading} dataSource={productTableData} columns={productTableColumns} />
        </div>
      </ProductTable>
    </>
  );
}

export {OfferRows};
