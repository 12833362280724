import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import {useIntl} from "react-intl";
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './message';
import Notification from './notification';
import Settings from './settings';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logoutUser } from '../../../redux/auth/actions';
import actions from '../../../redux/languageSwitcher/actions';
import options from '../../../redux/languageSwitcher/config'
import Heading from '../../heading/heading';
import Blank from '../../../static/img/blank.png';


const { changeLanguage } = actions;

function AuthInfo() {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const auth = useSelector(state => state.auth);
  const ActiveLang = useSelector(state => state.LanguageSwitcher.language)



  const SignOut = e => {
    e.preventDefault();
    if (auth.status) {
      dispatch(logoutUser());
    } else {
      dispatch(logoutUser());
    }
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={auth.user_img ?auth.user_img :Blank} alt="" style={{width:'50px'}}/>
          <figcaption>
            <Heading as="h5">{auth.user}</Heading>
            <p>{messages['user.'+auth.role]}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/signin">
              <FeatherIcon icon="user" /> {messages["button.login"]}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> {messages["button.logout"]}
        </Link>
      </div>
    </UserDropDwon>
  );



  const country = (
    <NavAuth>
      {options && options.options.map((option,i) => {
        const { text, icon,languageId } = option;
        return (
          <Link onClick={() => dispatch(changeLanguage(languageId))} to="#" key={i}>
            <img src={icon} alt="" />
            <span>{text}</span>
        </Link>
        )})}
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      {/* <Notification /> */}

      {/* <Settings /> */}
      {/* <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={ActiveLang.icon} alt="" />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={auth.user_img ?auth.user_img :Blank} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
