import React from 'react';
import { Form, Modal,DatePicker,Select,Col,Row,Button,Input, InputNumber,Tooltip, Divider } from 'antd';
import { FormWrapper } from '../../Style/style';
import moment from 'moment';
import {currencyFormatter} from '../../../helpers/currencyFormat';

const DeliveryModal = ({ delivery,messages,onSaveDelivery,deliveryDetailStatus,deliveryDetailLoading, deliveryModalActive,UnitList,CurrencyList,toggleModal,EURSatis,USDSatis,USDAlis,EURAlis,DeliveryModalOpen}) => {
  const [form5] = Form.useForm();
  const defaultOptions = {
    significantDigits: 4,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: ""
  }

  React.useEffect(() => {
    form5.setFieldsValue({ 
      "date" : moment(delivery.date),
      "productname" : delivery.productname,
      "product_id":delivery.product_id,
      "qty" : delivery.qty,
      "unit" : delivery.unit,
      "price" : delivery.price,
      "doviz" : delivery.doviz,
      "usd" : delivery.usd,
      "eur" : delivery.eur,
      "sumPrice" : delivery.sumPrice,
      "sumPriceTl" : delivery.sumPriceTl ?delivery.sumPriceTl : Number(delivery.doviz==="₺" ? Number(delivery.price).toFixed(2) : delivery.doviz==="€" ? Number(delivery.price*delivery.eur ).toFixed(2) : Number(delivery.usd *delivery.price).toFixed(2)),
      "sumPriceUsd" : delivery.sumPriceUsd,
      "status" : delivery.status,
      "receiver" : delivery.receiver,
      "deliverer" : delivery.deliverer,
      "_id" : delivery._id,
      "status" : delivery.status,
      "statusdescription" : delivery.statusdescription,
      "orderitem_id":delivery.orderitem_id,
      "order_id":delivery.order_id,
      "component_type":delivery.component_type
    });
   },[delivery]);

   
  
   const onChange=()=>{
    let doviz;let price;let qty;let TopTL;let TopDoviz;let usd; let eur;
    doviz = form5.getFieldValue("doviz");
    price = form5.getFieldValue("price");
    qty = form5.getFieldValue("qty");
    usd = Number(form5.getFieldValue("usd"));
    eur = Number(form5.getFieldValue("eur"));
    let TLPrice = Number(doviz==="₺" ? Number(price).toFixed(2) : doviz==="€" ? Number(price*eur ).toFixed(2) : Number(usd *price).toFixed(2));
    TopTL = Number(TLPrice) * Number(qty);
    TopDoviz = doviz === "€" ? (Number(TopTL) / Number(eur)) : ( Number(TopTL) / Number(usd));
    form5.setFieldsValue({'sumPriceUsd':Number(TopDoviz.toFixed(2))});
    form5.setFieldsValue({'sumPriceTl':Number(TopTL.toFixed(2))});
  };

  return (
  <>
    <Modal
      forceRender
      title={messages["genel.edit"]}
      type='primary'
      open={deliveryModalActive}
      afterOpenChange={()=>DeliveryModalOpen()}
      getContainer={false}
      width={800}
      footer={              
      <div>
        <Button  size="large" type="white" onClick={() => toggleModal("deliveryModalActive",null)}  >
          {messages["button.cancel"]}
        </Button>
        <Button size="large" type="primary" onClick={() => form5.submit()} loading={deliveryDetailLoading} disabled={deliveryDetailLoading}>
          {messages["button.save"]}
        </Button>
    </div>}
      onCancel={() => toggleModal(null)}
      maskClosable={false}
      closable={false}
    >

        <FormWrapper style={{height:'auto'}}>
          <Form 
            form={form5} 
            initialValues={{ 
              "date" : moment(delivery.date),
              "productname" : delivery.productname,
              "product_id":delivery.product_id,
              "qty" : delivery.qty,
              "unit" : delivery.unit,
              "price" : delivery.price,
              "doviz" : delivery.doviz,
              "usd" : delivery.usd,
              "eur" : delivery.eur,
              "sumPrice" : delivery.sumPrice,
              "sumPriceTl" : delivery.sumPriceTl,
              "sumPriceUsd" : delivery.sumPriceUsd,
              "status" : delivery.status,
              "receiver" : delivery.receiver,
              "deliverer" : delivery.deliverer,
              "_id" : delivery._id,
              "status" : delivery.status,
              "statusdescription" : delivery.statusdescription,
              "orderitem_id":delivery.orderitem_id,
              "order_id":delivery.order_id,
              "component_type":delivery.component_type
            }} 
            onValuesChange={()=>onChange()} 
            onFinish={(eData)=>onSaveDelivery(eData)}
          >
            <Row align="middle"  gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.date"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name="date">
                  <DatePicker
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    animateYearScrolling={true}
                    style={{width:"100%"}}
                    readOnly
                    //onChange={(e)=>onChangeDeliveryRow("date",e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle"  gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.name"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item  name="productname">
                  <Input readOnly/>
                </Form.Item>
                <Form.Item  name="product_id" hidden>
                  <Input readOnly/>
                </Form.Item>
                <Form.Item  name="_id" hidden>
                  <Input readOnly/>
                </Form.Item>
                <Form.Item  name="status" hidden>
                  <Input readOnly/>
                </Form.Item>
                <Form.Item  name="statusdescription" hidden>
                  <Input readOnly/>
                </Form.Item>
                <Form.Item  name="order_id" hidden>
                  <Input readOnly/>
                </Form.Item>
                <Form.Item  name="orderitem_id" hidden>
                  <Input readOnly/>
                </Form.Item>
                <Form.Item  name="component_type" hidden>
                  <Input readOnly/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.qty"]}</label>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name="qty">
                  <InputNumber decimalSeparator=','/>
                </Form.Item>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name='unit' >
                <Select style={{width:"100%"}}>
                    {UnitList.map((unit,i)=>{
                    return(
                      <Select.Option key={i} value={unit.value}>{messages[unit.label]}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["offer.usdprice"]}</label>
              </Col>
              <Col md={9} xs={24}>
                <Tooltip title="USD Kur Bilgisi ">
                  <Form.Item name="usd" >
                    <InputNumber decimalSeparator=','/>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col md={9} xs={24}>
                  <Form.Item>
                    <Button onClick={()=>form5.setFieldsValue({"usd":Number(USDSatis)})}>{currencyFormatter(USDSatis,defaultOptions)}</Button>
                    <Button style={{marginLeft:'20px'}} onClick={()=>form5.setFieldsValue({"usd":Number(USDAlis)})}>{currencyFormatter(USDAlis,defaultOptions)}</Button>
                  </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["offer.eurprice"]}</label>
              </Col>
              <Col md={9} xs={24}>
                <Tooltip title="USD Kur Bilgisi ">
                  <Form.Item name="eur" >
                    <InputNumber decimalSeparator=','/>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col md={9} xs={24}>
                  <Form.Item>
                    <Button onClick={()=>form5.setFieldsValue({"eur":Number(EURSatis)})}>{currencyFormatter(EURSatis,defaultOptions)}</Button>
                    <Button style={{marginLeft:'20px'}} onClick={()=>form5.setFieldsValue({"eur":Number(EURAlis)})}>{currencyFormatter(EURAlis,defaultOptions)}</Button>
                  </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.price"]}</label>
              </Col>
              <Col md={6} xs={24}>
                  <Form.Item name="price" >
                    <InputNumber decimalSeparator=',' />
                  </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                  <Form.Item name="sumPrice" >
                    <InputNumber decimalSeparator=','  readOnly/>
                  </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                  <Form.Item name='doviz'>
                    <Select  options={CurrencyList}/>
                  </Form.Item>
              </Col>
            </Row>

            <Divider/>
            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.gtotal"]+ " ₺"}</label>
              </Col>
              <Col md={6} xs={24}>
                  <Form.Item name="sumPriceTl" >
                    <InputNumber decimalSeparator=','  readOnly/>
                  </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                  <Form.Item >
                    <Input value={"₺"} />
                  </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                  <Form.Item name="sumPriceUsd" >
                    <InputNumber decimalSeparator=','  readOnly/>
                  </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                  <Form.Item >
                    <Input readOnly  value=" $"/>
                  </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <Row align="middle"  gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["delivery.deliverer"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item  name="deliverer">
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle"  gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["delivery.receiver"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item  name="receiver">
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
    </Modal>
  </>
  );
}

export  {DeliveryModal};
