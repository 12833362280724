import React, { Fragment } from 'react';
import {  useSelector } from 'react-redux';
import { Spin} from 'antd';
import {PDFViewer} from '@react-pdf/renderer'
import DeliveryListReport from './DeliveryListReport/deliverylistreport';
import {useIntl} from "react-intl";
const DeliveryList = ({companyDetail}) => {
  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {deliveryDetailLoading,deliveryList} = useSelector(state => state.deliveryList);
  const { messages } = useIntl();
  const lang = locale === 'tr' ? 0 :1
     
    return   !myCompanyLoading  ?  
    (
      <Fragment>
        {  myCompany ?                
        <PDFViewer  width="100%" height={600}>
            <DeliveryListReport company={myCompany} data={deliveryList}  messages={messages} companyDetail={companyDetail}/>
        </PDFViewer>:
        <Spin />
        }       
      </Fragment>
    ) : (
      <div className="spin">
        <Spin />
      </div>
    );
    
}

export  {DeliveryList};



