import React from 'react';
import { Row, Col, Spin,Upload,Button } from 'antd';
import {  Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import blank from '../../../static/img/resyok.jpeg';
import { faCamera, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ImageCard } from '../../Style/style';

const ReportImages =({data,loading,messages,beforeUpload,updateData})=> {

  const ImageBlock =({field,img,title}) =>{
    return(
      <ImageCard>
      <div className="card image-card">
        <Cards headless>
          <figure>
            <img src={img ? img:blank} alt="" />
          </figure>
          <figcaption>
            <div className="card__content">
              <Heading className="card__name" as="h6"><Link to="#">{title}</Link></Heading>
            </div>
            <div className="card__actions">
              <Upload
                multiple={false}
                showUploadList={false}
                beforeUpload={(file) =>beforeUpload(field,file)}
              >
                <Button size="default" type="white">
                  <FontAwesomeIcon icon={faCamera}  />
                </Button>
              </Upload>
              <Button size="default" type="white" onClick={()=>updateData(field,"")}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          </figcaption>
        </Cards>
      </div>
    </ImageCard>
    )
  }
  return (
    <>
        <Cards
          title={ <Heading as="h5">{messages["company.reportsetting"]}</Heading>}
          loading={loading && <Spin indicator={faSpinner}  />}
          bodyStyle={{ minHeight: '0px' }}
        />      
            <Row gutter={25} >
              <Col xxl={6} md={12} sm={12} xs={24}>
                <ImageBlock field ="sirket_rapor_logo" img={data.sirket_rapor_logo} title={messages["company.logo"]}/>
              </Col>
              <Col xxl={6} md={12} sm={12} xs={24}>
                <ImageBlock field ="sirket_kase" img={data.sirket_kase} title={messages["company.kase"]}/>
              </Col>
              <Col xxl={6} md={12} sm={12} xs={24}>
                <ImageBlock field ="sirket_kaseimza" img={data.sirket_kaseimza} title={messages["company.kaseimza"]}/>
              </Col>
              <Col xxl={6} md={12} sm={12} xs={24}>
                <ImageBlock field ="bwlogo" img={data.bwlogo} title={messages["company.bwlogo"]}/>
              </Col>
          </Row>
    </>
  );
}

export  {ReportImages};
