import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    BuyerContainer: {
        marginTop: 10,
        flexDirection: "column",
        width:300,
        border:0.5,
        borderRadius:5
    },
    TaxContainer: {
        flexDirection: "row",
    },
    AdressContainer:{
        flexDirection: "row",
        width:550
    }
  });
const BuyerCompanyName = styled.Text`
font-size: 9px;
text-align: left;
margin:3px;
`;
const BuyerAdres1 = styled.Text`
font-size: 9px;
text-align: left;
margin:3px;
`;
const BuyerAdres2 = styled.Text`
font-size: 9px;
text-align: left;
margin:3px;
`;
const TaxOffice = styled.Text`
margin:3px;
font-size: 9px;
text-align: left;
width:149px;
`;
const TaxNo = styled.Text`
marginLeft:2px;
margin:3px;
font-size: 9px;
text-align: left;
width:149px;
color : #004678;
`;
const BuyerTitle = styled.Text`
font-family:"OpenSansFontBold";
font-size: 9px;
text-align: left;
borderBottomWidth: 0.5;
margin:3px;ß
`;
const DeliveryAddress = styled.Text`
margin: 0px;
font-size: 8px;
text-align: left;
`;

const BuyerInfo = ({orderItem,messages}) => {
    const {offer} = orderItem;
    return(
    
        <View style={styles.BuyerContainer}>
            <BuyerTitle style={styles.billTo}>{messages["offer.buyer"]}</BuyerTitle>
            <BuyerCompanyName>{offer.firma_adi}</BuyerCompanyName>
            <BuyerAdres1>{(offer.firma_adres && offer.firma_adres) +" "+(offer.firma_ilce && offer.firma_ilce)}</BuyerAdres1>
            <BuyerAdres2>{(offer.firma_il && offer.firma_il)+" "+(offer.firma_ulke && offer.firma_ulke)}</BuyerAdres2>
    
        </View>
)};
  
export default BuyerInfo