import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGrip,faBars,faEllipsisVertical,faPrint } from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
//import CreateProject from './overview/CreateProject';
import { ProjectDetailsWrapper } from '../Style/style';
import { message,notification} from 'antd';
import { Main } from '../Style/styled';
import { PageHeader } from '../../components/page-headers/page-headers';



const  ProjectMaterialListSum =()=> {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();
  const { componentListStatus,componentList } = useSelector(state => state.componentList);
  const [state, setState] = React.useState({
    materialfilter:'Tümü',
  });


  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(componentListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [componentListStatus]);
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        title={messages["sidebar.projectMaterialList"]}
        buttons={[
          <div key="1" className="project-action">
            <Link key={2} to="#" className="project-edit" onClick={()=>printOrders()}>
              <FontAwesomeIcon icon={faPrint} size='1x' />
              {messages["button.print"]}
            </Link>
          </div>
        ]}
      />
      <Main>
        <ComponentListSum
          height='650px'
          messages={messages}
        />
      </Main> 
    </>
  );
}



export  {ProjectMaterialListSum};
