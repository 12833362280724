import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table ,Button,Popconfirm,Spin} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus, faEye,faSpinner} from '@fortawesome/free-solid-svg-icons';
import Heading from '../../../components/heading/heading';
import {getEmployeeFileList} from '../../../redux/employeefiles/employeeFileListActions';
import moment from 'moment';


const EmployeeFiles = ({id,messages,toggleModal,addNewFileRow,removeFileRow,previewFile}) => {
  const dispatch = useDispatch();
  const { employeeFileList,employeeFileListLoading } = useSelector(state => state.employeeFileList);
  React.useEffect(() => {
    id && dispatch((getEmployeeFileList('list',id)));
  }, []) ;
  let files = employeeFileList && employeeFileList.length && employeeFileList.map((el, idx) => ({key: idx, ...el}))
  const filesColumn = [
    {
      title: [messages["genel.date"]],
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      render: (text) => moment(text).format("DD.MM.YYYY"),
      width: '15%',
    },
    {
      title: [messages["model.OperationDescription"]],
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      render: (text) => text,
      width: '50%',
    },
    {
      title: messages["employee.filetype"],
      dataIndex: 'file_type',
      key: 'file_type',
      align: 'left',
      render: (text) => text,
      width: '15%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>previewFile(record)}
        >
          <FontAwesomeIcon icon={faEye} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("fileRowModalActive",true,record)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        {record._id && 
        <Popconfirm
          title={messages["alert.deletequest"]}
          okText={messages["genel.yes"]}
          cancelText={messages["genel.no"]}
          onConfirm={()=>removeFileRow(record._id)}
        >
          <Button
            shape="circle"
            size="small"
          >
            <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
          </Button>  
        </Popconfirm>}
      </>
    }
  ];

  return (
      <div className="full-width-table">
        <Cards
          title={<Heading as="h5">{messages["employee.files"]}</Heading>}
          bodypadding="0px"
          bodyStyle={{ minHeight: '715px' }}
          loading={employeeFileListLoading && <Spin indicator={faSpinner}  />}
          isbutton={
            <Button onClick={() => addNewFileRow()} shape="circle" >
              <FontAwesomeIcon icon={faCirclePlus} size='sm' />
            </Button>
          }
        >
          <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'660px'}}>
            <Table 
              columns={filesColumn} 
              dataSource={files} 
              pagination={false} 
              scroll={{ y: 660 }}
            />
          </div>
        </Cards>
      </div>
  );
}


export  {EmployeeFiles};
