import React from 'react';
import {Modal} from 'antd';
import {QualityControl}  from '../../../reports/qControl';


const PrintModal = ({qcPrintModal,toggleModal,index}) => {
  
  return (
    <>

      {qcPrintModal && 
      <Modal
        forceRender
        type='primary'
        className="sDash_addTask-modal"
        open={qcPrintModal}
        footer={null}
        width={1000}
        closable={false}
        onCancel={() => toggleModal("qcPrintModal",null,index)}
      >
        <QualityControl index={index}/>
      </Modal>}
    </>

  );

}



export  {PrintModal};