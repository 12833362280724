import {
	USER_DETAILS_ACTION,
	USER_DETAILS_SUCCESS,
    USER_DETAILS_ERROR,
	CREATE_USER_ACTION,
	CREATE_USER_SUCCESS,
	CREATE_USER_ERROR,
	UPDATE_USER_ACTION,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILURE,
	DELETE_USER_ACTION,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE,
	SET_USER_DATA
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:false,
	status:null
};

const  userDetail = (state = INIT_STATE, action) => {
	switch (action.type) {
		case USER_DETAILS_ACTION: //GET OFFER DETAILS
			return { ...state, loading: true,status:null };
		case USER_DETAILS_SUCCESS:
            return { ...state,loading: false,data: action.response,status:200};
		case USER_DETAILS_ERROR:
            return { ...state, loading: false,status:action.response };	
		case CREATE_USER_ACTION: //CREATE MODEL
			return { ...state, loading: true };
		case CREATE_USER_SUCCESS:
			return { ...state, loading: false, data: action.response ,status:200};
		case CREATE_USER_ERROR:
			return { ...state, loading: false,data:{},status:action.response };	
		case UPDATE_USER_ACTION: //UPDATE MODEL
			return { ...state, loading: true ,status:null};
		case UPDATE_USER_SUCCESS: 
			return { ...state, loading: false, data: action.response,status:200 };
		case UPDATE_USER_FAILURE:
			return { ...state, loading: false,status:action.response }; 
		case DELETE_USER_ACTION: //DELETE MODEL
			return { ...state, loading: true,status:null };
		case DELETE_USER_SUCCESS:
			return { ...state, loading: false, status:200 };
		case DELETE_USER_FAILURE:
			return { ...state, loading: false,status:action.response };
		case SET_USER_DATA:
			return { ...state, data:action.val , loading: false};
		default: return state;
	}
}
export default userDetail;