import React from 'react';
import { Row, Col, Form, Input,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import CustomInput from '../../../helpers/customInput';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
const CompanyContact =({data,loading,messages,updateData,setSubmittable})=> {
  const [form23] = Form.useForm();
  React.useEffect(() => {
    form23.setFieldsValue(data)
   }, [data]);
   const values = Form.useWatch([], form23);
   const type = data.company_type;
   React.useEffect(() => {
    form23.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]); 
  return (
      <Cards
        title={<Heading as="h5">{messages["company.contact"]}</Heading>}
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <FormWrapper>
          <Form  form={form23} initialValues={data} >
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.taxoffice"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_taxoffice"  >
                  <Input onChange={(eData)=>updateData("company_taxoffice",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.taxno"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_taxno"  >
                  <CustomInput mask={type ==="legal" ? "999 999 99 99":"999 999 999 99"} placeholder={type ==="legal" ?"___ ___ __ __":"___ ___ ___ __"} onChange={(eData)=>updateData("company_taxno",eData.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.phone"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_phone"  >
                  <CustomInput mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("company_phone",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.fax"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_fax"  >
                  <CustomInput mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("company_fax",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.mobilephone"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_mobilephone"  >
                  <CustomInput mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("company_mobilephone",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.e-mail"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_email" rules={[{ message: 'Please input your email!', type: 'email' }]} >
                  <Input onChange={(eData)=>updateData("company_email",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.web"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_web"  >
                  <Input onChange={(eData)=>updateData("company_web",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>   









          </Form>
        </FormWrapper>
      </Cards>

  );
}

export  {CompanyContact};
