import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Modal,Spin,Badge } from 'antd';
import { SearchListSidebar,ListContent} from '../Style/style';
import { NavLink} from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';
import blank from '../../static/img/resyok.jpeg';
import {currencyFormatter} from '../../helpers/currencyFormat';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
const MaterialListModal = ({messages,materialListModalActive,toggleModal,selectMaterial}) => {

  const {materialList,materialsListLoading} = useSelector((state) => state.materials);
  const rtl  = useSelector(state => state.ChangeLayoutMode.rtlData);
  const [_materialList, setMaterialList] = React.useState(materialList);
  const left = !rtl ? 'left' : 'right';
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setMaterialList(materialList)
  }, [materialList]);
   //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
 //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
 //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
 //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const searchMareials = search => {
    let filteredList =[]; 
    filteredList =  _materialList.filter(
        item => {return item.material_name.toLowerCase().includes(search.toLowerCase()) || (item.material_category.toLowerCase().includes(search.toLowerCase())) })
    if (search===""){
      setMaterialList(materialList)
    }else{
      setMaterialList(filteredList)
    }
  };
  return (
  <>
  {materialList && materialListModalActive && 
    <Modal
      title={messages["sidebar.materials"]}
      open={materialListModalActive}
      footer={null}
      closable={false}
      onCancel={() => toggleModal("materialListModalActive",null)}
      width={610}
      forceRender
    >
      <SearchListSidebar>
        <Cards headless  bodypadding="0px">
          <div className="chatbox-search">
            <Input  onKeyDown={(e)=>searchMareials(e.target.value)} placeholder={messages["genel.ara"]} />
          </div>
          <ListContent style={{height:'550px'}}>
            <Scrollbars
              className="custom-scrollbar"
              autoHide
              autoHideTimeout={500}
              autoHideDuration={200}
              renderThumbHorizontal={renderThumbHorizontal}
              renderThumbVertical={renderThumbVertical}
              renderView={renderView}
              renderTrackVertical={renderTrackVertical}
            >

                <ul>
                  {_materialList && _materialList.length ? 
                    _materialList.map((material, key) => {
                        const { material_category,material_group,_id,material_name,material_price,material_unit,material_doviz,stockSum,material_img} = material;
                        return (
                          <li key={key} className="chat-link-signle" >
                            <NavLink to='#' onClick={()=>selectMaterial(material)}>
                              <div className="author-figure">
                                <img src={material_img ? material_img : blank} alt="" />
                              </div>
                              <div className="author-info">
                                <span className="author-name" style={{display: 'block'}}>{material_name}</span>
                                <span className="author-chatText" >
                                {material_category +" / "+material_group}
                                </span>
                              </div>
                              <div className="author-chatMeta">
                                <Badge className={"badge-success"} count={currencyFormatter(material_price)+" "+material_doviz} />
                                <Badge className={"badge-success"} count={currencyFormatter(stockSum)+" "+material_unit} />
                              </div>
                            </NavLink>
                          </li>
                        );
                      }): <div className="spin"><Spin/></div>
                    }
                </ul>
            </Scrollbars>
          </ListContent>
        </Cards>
      </SearchListSidebar>
    </Modal>
  }
  </>
  );
}

export  {MaterialListModal};
