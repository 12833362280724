
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Select, Modal,Button ,Form,Input,Col,Row,Switch} from 'antd';
import { FormWrapper} from '../../Style/style';
import appActions from '../../../redux/app/actions';

const PriceListModal = ({messages,togglePrintModal,priceListSettingModalActive,setSelectedCategories,selectedCategories,productListLoading}) => {
  const dispatch = useDispatch();
  const {productsFilter,listPerVal,pricelisttype,pricelisttext,priceListTextOpen,listperValOptions,categoryList,} = useSelector(state => state.App);
  const { setPriceListPercent,setPriceListText,setPriceListTextOpen,setPriceListType,setPriceListFilter } = appActions;
  const { TextArea } = Input;
  //******************************************************************************************************************************************************************/
  const setPriceListDetails = (command,val) =>{
    if(command==="oran"){
      dispatch(setPriceListPercent(val))
    }else if(command==="textopen"){
      dispatch(setPriceListTextOpen(val))
    }else if(command==="text"){
      dispatch(setPriceListText(val))
    }else if(command==="listtype"){
      dispatch(setPriceListType(val))
    }else if(command==="filter"){
      dispatch(setPriceListFilter(val))
    }
  }
  return (
    <>
      {priceListSettingModalActive &&
      <Modal
        forceRender
        type='primary'
        title={messages["product.pricelist"]}
        open={priceListSettingModalActive}
        closable={false}
        footer={
          <div>
            <Button size="large" type="white" onClick={() => togglePrintModal("priceListSettingModalActive",null)}  >
              {messages["button.close"]}
            </Button>
            <Button  size="large" type="primary" onClick={() => togglePrintModal("reportModalActive",true)} loading={productListLoading} disabled={productListLoading}>
              {messages["button.print"]}
            </Button>
          </div>
        }
        onCancel={() => togglePrintModal("priceListSettingModalActive",null)}
        width={600}
      >
        <FormWrapper style={{height:'400px'}}>
          <Form >
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.percent"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item  >
                  <Select options={listperValOptions} value={listPerVal} onChange={(e)=>setPriceListDetails("oran",e)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.listtype"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item  >
                  <Select value={pricelisttype} onChange={(e)=>setPriceListDetails("listtype",e)}>
                    <Select.Option value={0}>T-0</Select.Option>
                    <Select.Option value={1}>T-1</Select.Option>
                    <Select.Option value={2}>T-2</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
             <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.pricelistcategory"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item  >
                  <Select mode="multiple" allowClear value={selectedCategories}  options={categoryList.map(x=>{return({label:messages[x.label],value:x.value})})}  onChange={(e)=>setSelectedCategories(e)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.pricelisttext"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item  >
                  <TextArea value={pricelisttext} rows={5}  onChange={(e)=>setPriceListDetails("text",e.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.pricelisttextopen"]}</label>
              </Col>
              <Col md={18} xs={24}>
                  <Switch
                    checked={priceListTextOpen===true}
                    onChange={(e)=>setPriceListDetails("textopen",!priceListTextOpen)}
                  />
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Modal>}
    </>

  );

}



export  {PriceListModal};
