import React from 'react';
import { useSelector } from 'react-redux';
import {Row, Col,Tag,Spin,Popconfirm,Button} from 'antd';
import { ListRowContentT5} from '../../Style/style';
import {PopupChoser} from '../../../components/PopupChoser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
const check = [
  {value : 0 , label:"delivery.print" },
  {value : 1 , label:"delivery.printed" },
]
const DeliveryList = ({messages,delivery,onChangeDeliveryRowStatus,deleteDeliveryRow,toggleModal}) => {
  const {date,qty,deliverer,productname,receiver,status,statusdescription,printed} = delivery
  const {deliveryStatus,deliveryStatusDescription}= useSelector(state => state.App);
  const {deliveryDetailLoading} = useSelector(state => state.deliveryDetails);
  return (
      <ListRowContentT5>
        <Row gutter={10}>
          <Col xxl={3} lg={3} md={3} sm={12} xs={24}>
            <div className="material-column">
              <h1 >{messages["genel.date"]} : {moment(date).format("DD.MM.YYYY")}</h1> 
            </div>
          </Col>
          <Col xxl={7} lg={7} md={7} sm={12} xs={24}>
            <div className="material-column">
              <h1>{productname}</h1>
            </div>
          </Col>
          <Col xxl={2} lg={2} md={2} sm={12} xs={24}>
            <div className="list-column">
              <span className="activity-month">{qty} </span>
              <h1>{messages["genel.Adet"]} </h1>
            </div>
          </Col>
          <Col xxl={6} lg={6} md={6} sm={12} xs={24}>
            <div className="material-column">
              <span className="activity-month">{deliverer} </span>
              <h1>{receiver}</h1>
            </div>
          </Col>
          <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
            <div className="list-column">
              <PopupChoser
                option={deliveryStatus}
                choseData={(e)=>onChangeDeliveryRowStatus(delivery,'status',e)}
                selectedData={status}
              /> 
              {status >0 && 
              <PopupChoser
                option={deliveryStatusDescription}
                choseData={(e)=>onChangeDeliveryRowStatus(delivery,"statusdescription",e)}
                selectedData={statusdescription}
              /> }
              <PopupChoser
                option={check}
                choseData={(e)=>onChangeDeliveryRowStatus(delivery,"printed",e)}
                selectedData={printed}
              /> 
            </div>
          </Col>

          <Col xxl={2} lg={2} md={2} sm={2} xs={24} className='action-column'>
            <Button shape="circle" style={{marginRight:'5px'}} onClick={()=>toggleModal("deliveryPrintModalActive",true,0,delivery._id)}>
              <FontAwesomeIcon icon={faPrint} className='bg-info' size='sm' />
            </Button> 
            {deliveryDetailLoading ? 
            <Spin indicator={faSpinner} size='small'/>
            :
            <Popconfirm
              title={messages["alert.deletequest"]}
              okText={messages["genel.yes"]}
              cancelText={messages["genel.no"]}
              onConfirm={()=>deleteDeliveryRow(delivery)}
            >
              <Button shape="circle"  >
                <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
              </Button>  
            </Popconfirm>}

          </Col>
        </Row>
      </ListRowContentT5>
  );
}



export  {DeliveryList};
