import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,NavLink ,useHistory} from 'react-router-dom';
import {Button,message,Popconfirm} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars,faSave,faPrint} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {useIntl} from "react-intl";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../Style/styled';
import {getCompanyList} from '../../../redux/CompanyList/companyListActions';
import { getCompany } from '../../../redux/company/myCompanyActions';
import {getOfferDetails} from '../../../redux/offerDetails/offerDetailActions';

import appActions from '../../../redux/app/actions';



import {OfferInfo} from "./ordertrack/offerInfo";
import {OfferRows} from "./ordertrack/offerRows";
// import {OfferSummary} from "./offers/offerSummary";

const OrderTrack = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { offerID,companyID,token } = useParams();
  const { messages } = useIntl();

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { data, loading } = useSelector(state => state.offerDetail);
  const [dataSource, setDataSource] = React.useState(data);
  const {companyList} = useSelector((state) => state.companyList);

  const [companies, setCompanies] = React.useState(companyList);
  const products= useSelector((state) => state.productList.data);
  const materials = useSelector(state => state.materials.data);
  const materialListLoading = useSelector(state => state.materials.loading);
  const productListLoading= useSelector((state) => state.productList.loading);
  const [productList, setProductList] = React.useState(products);
  const [materialList, setMaterialList] = React.useState(materials);
  const [submittable, setSubmittable] = React.useState(false);
  const {UnitList,KDVList,OfferStatus,offerReportType,CurrencyList} = useSelector(state => state.App);
  const User = useSelector((state) => state.auth.user);
  const {tcmb} = useSelector(state => state.tcmb);

  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const USDAlis = tcmb && tcmb.Data[0].ForexBuying;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const EURAlis = tcmb && tcmb.Data[1].ForexBuying;
  const lang = locale === 'tr' ? 0 :1
  const [state, setState] = React.useState({
    offerRowModalIndex:null,
    itemtype:"product",
  });

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
 
      dispatch((getOfferDetails(offerID,companyID,token )));

  },[])
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
      setDataSource(data);
  },[data]);
  //******************************************************************************************************************************************************************/
//console.log(dataSource)

  return (
    <>
      <PageHeader
        ghost
        title={messages["order.details"]}

      />

      <Main>    
        <OfferInfo
          messages={messages}
          lang={lang}
          loading={loading}
          offer={dataSource}
          CurrencyList={CurrencyList}
          USDSatis={USDSatis}
          EURSatis={EURSatis}
          USDAlis={USDAlis}
          EURAlis={EURAlis}

        />

        <OfferRows
          messages={messages}
          lang={lang}
          loading={loading}
          offer={dataSource}
          CurrencyList={CurrencyList}
          USDSatis={USDSatis}
          EURSatis={EURSatis}        
        />



      </Main>
    </>
  );
}



export  {OrderTrack};
