import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    ColumnContainer: {
        flexDirection: "column",
        width:550
    },
    rowContainer: {
        flexDirection: 'row',
        height: '12px',
        borderBottom:0.3,
        marginTop:3,
        borderBottomColor:"grey",
        width:550,
        
    }
  });


  const Field370px = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  width:370px;
  `;
  const Status2 = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:center;
  border-left:0.3;
  width:60px;
  `;
const orderControl = () => {

    return(
    <View style={styles.ColumnContainer}>

        <View style={styles.rowContainer}>
            <Field370px>Ürün Elektriksel testleri yapıldı mı ? Değerler Doğrumu</Field370px>
            <Status2></Status2>
            <Status2></Status2>
            <Status2></Status2>
        </View>
        <View style={styles.rowContainer}>
            <Field370px>Ürün Boya Kaynak gibi Bölümlerde görsel hatalar hoş olmayan durumlar var mı ? </Field370px>
            <Status2></Status2>
            <Status2></Status2>
            <Status2></Status2>
        </View>
        <View style={styles.rowContainer}>
            <Field370px>Ürün Etiketlenmesi Üretim ve Elektriksel veriler doğru mu ?</Field370px>
            <Status2></Status2>
            <Status2></Status2>
            <Status2></Status2>
        </View>
        <View style={styles.rowContainer}>
            <Field370px>Ürün Paketlenmesi ve Paket içerigi kontrol edildi mi ?</Field370px>
            <Status2></Status2>
            <Status2></Status2>
            <Status2></Status2>
        </View>

    </View>


  
)};
  
export default orderControl