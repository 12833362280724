import React from 'react';
import { Row, Col, Form, Input,Select,Radio,Popconfirm, Divider,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Account =({data,loading,messages,updateData,companyOption,deleteUserAccount})=> {
  const [form1] = Form.useForm();
  React.useEffect(() => {
    form1.setFieldsValue(data)
   }, [data])
   const status = form1.getFieldValue("status");
  return (
      <Cards
        title={<Heading as="h5">{messages["user.accountsetting"]}</Heading>}
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <FormWrapper>
          <Form  form={form1} initialValues={data} name="editAccount"> 
            <Row align="middle">
              <Col md={8} xs={24}>
                <label >{messages["user.email"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="email" rules={[{ message: 'Please input your email!', type: 'email' }]} >
                  <Input onChange={(eData)=>updateData("firstname",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle">
              <Col md={8} xs={24}>
                <label >{messages["user.usercompany"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="usercompany_id">
                  <Select 
                    style={{ width: '100%' }}
                    showSearch
                    optionFilterProp="children"
                    onSelect={(eData)=>updateData("usercompany_id",eData)}
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {companyOption && companyOption.length &&companyOption.map((company,index)=>{
                      return (
                      <Select.Option key={index} value={company.value}>{company.label}</Select.Option>
                      )}
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle">
              <Col md={8} xs={24}>
                <label >{messages["user.status"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="status" >
                  <Radio.Group onChange={(eData)=>updateData("status",eData.target.value)}>
                    <Radio value={true}>{messages["genel.true"]}</Radio>
                    <Radio value={false}>{messages["genel.false"]}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle">
              <Col md={8} xs={24}>
                <label >{messages["user.role"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="role"  >
                  <Radio.Group onChange={(eData)=>updateData("role",eData.target.value)}>
                    <Radio value={0}>{messages["user.0"]}</Radio>
                    <Radio value={1}>{messages["user.1"]}</Radio>
                    <Radio value={2}>{messages["user.2"]}</Radio>
                    <Radio value={3}>{messages["user.3"]}</Radio>
                    <Radio value={4}>{messages["user.4"]}</Radio>
                    <Radio value={5}>{messages["user.5"]}</Radio>
                    <Radio value={6}>{messages["user.6"]}</Radio>
                    <Radio value={7}>{messages["user.7"]}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <div className="account-closing">
              <Row>
                <Col lg={18} md={24} sm={18} xs={24}>
                  <Heading className="account-closing__title" as="h4">
                  {status ? messages["user.closeaccount"] :messages["user.openaccount"] }
                  </Heading>
                  <p>Hesabı Aktif veya Pasif Yapabilirsiniz. Hesap Silinmez.</p>
                </Col>
                <Col lg={6} md={24} sm={6} xs={24}>
                  {status ?
                  <Button size="small" type="danger" onClick={ form1.setFieldValue({status:false})}>
                    {messages["user.closeaccount"]}
                  </Button>:
                  <Button size="small" type="success" onClick={ form1.setFieldValue({status:true})}>
                    {messages["user.openaccount"]}
                  </Button>
                  }
                </Col>
              </Row>
            </div>
            <Divider/>
            <div className="account-closing">
              <Row>
                <Col lg={18} md={24} sm={18} xs={24}>
                  <Heading className="account-closing__title" as="h4">
                  {messages["user.deleteaccount"]}
                  </Heading>
                  <p>Hesabı tamamen silebilirsiniz. Veriler Kaybolacaktır !!</p>
                </Col>
                <Col lg={6} md={24} sm={6} xs={24}>
                  <Popconfirm
                    title={messages["alert.deletequest"]}
                    onConfirm={deleteUserAccount}
          
                    okText={messages["genel.yes"]}
                    cancelText={messages["genel.no"]}
                  >
                    <Button size="small" type="danger">
                      {messages["user.deleteaccount"]}
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </div>
          </Form>
        </FormWrapper>
      </Cards>
 
  );
}

export  {Account};
