import React from 'react';
import { Button,Badge } from 'antd';
import { useSelector } from 'react-redux';
import { DetailMenuListContent,DetailMenuNav,ListContent} from '../Style/style';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink} from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAlignRight,faX,faAlignLeft,faCirclePlus, faPlus} from '@fortawesome/free-solid-svg-icons';

const ListMenu = ({MenuList, MenuList2,messages,activeFilter,newData,toggleCollapsed,onChangeFilter,onChangeFilter2,collapsed,more}) => {
  const rtl  = useSelector(state => state.ChangeLayoutMode.rtlData);
  const left = !rtl ? 'left' : 'right';

  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/

  return (
  <>
    {window.innerWidth <= 991 && (
      <Button type="link" className="mail-sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
        <FontAwesomeIcon icon={collapsed ? faAlignLeft : faAlignRight} />
      </Button>
    )}
    {window.innerWidth > 991 ? (
      <div className="sidebar-card">
        <Cards headless bodyStyle={{ minHeight: '790px' }}>
          <div className="note-sidebar-top">
            <Button shape="round" type="primary" size="default" block onClick={()=>newData()}>
              <FontAwesomeIcon icon={faCirclePlus} size="1x"  style={{ marginRight: 10 }}  /> {messages["button.add"]}
            </Button>
          </div>
          <div className="note-sidebar-bottom">
            <DetailMenuNav >
              <div className="nav-labels">
              <ListContent style={{height:'700px'}}>
                <Scrollbars
                  className="custom-scrollbar"
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  renderThumbHorizontal={renderThumbHorizontal}
                  renderThumbVertical={renderThumbVertical}
                  renderView={renderView}
                  renderTrackVertical={renderTrackVertical}
                >
                  <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'red'}  status="processing" text={messages["material.category"]}/></p>
                  <ul>
                    <DetailMenuListContent>
                        {more && more}
                        <li>
                          <NavLink to='#' activeClassName={activeFilter === "Tümü" ? "active":""} onClick={()=>onChangeFilter("Tümü")}>
                            {messages["genel.all"]}
                          </NavLink>
                        </li>
                        { MenuList && MenuList.length ? MenuList.map((data,s)=>{
                          return(
                          <li key={s}>
                            <NavLink to='#' activeClassName={activeFilter === data.value ? "active":""} onClick={()=>onChangeFilter(data.value)}>
                              {messages[data.label] ? messages[data.label] : data.label}
                            </NavLink>
                          </li>
                          )
                        }):null}
      
                    </DetailMenuListContent>
                  </ul>
            
                  {MenuList2 && MenuList2.length ? 
                  <>
                    <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'red'}  status="processing" text={messages["material.group"]}/></p>
                    <ul>
                      <DetailMenuListContent>
                          <li>
                            <NavLink to='#' activeClassName={activeFilter === "Tümü" ? "active":""} onClick={()=>onChangeFilter2("Tümü")}>
                              {messages["genel.all"]}
                            </NavLink>
                          </li>
                          {MenuList2 && MenuList2.length && MenuList2.map((data,s)=>{
                            return(
                            <li key={s}>
                              <NavLink to='#' activeClassName={activeFilter === data.value ? "active":""} onClick={()=>onChangeFilter2(data.value)}>
                                {messages[data.label] ? messages[data.label] : data.label}
                              </NavLink>
                            </li>
                            )
                          })}
        
                      </DetailMenuListContent>
                    </ul>
                  </>:null}
                </Scrollbars>
              </ListContent>
              </div>
            </DetailMenuNav>
          </div>
        </Cards>
      </div>
    ) : (
      <div className={collapsed ? 'sidebar-card note-sideabr show' : 'sidebar-card note-sideabr hide'}>
        <Cards headless>
          <Button
            type="link"
            className="mail-sidebar-trigger trigger-close"
            style={{ marginTop: 0 }}
            onClick={toggleCollapsed}
          >
            <FontAwesomeIcon icon={faX} />
          </Button>
          <div className="note-sidebar-top">
            <Button  shape="round" type="primary" size="default" block onClick={()=>NewUsers()}>
              <FontAwesomeIcon icon={faPlus} size='1x' /> {messages["button.add"]}
            </Button>
          </div>

          <div className="note-sidebar-bottom">
            <DetailMenuNav>
              <div className="nav-labels">
                <ListContent style={{height:'700px'}}>
                  <Scrollbars
                    className="custom-scrollbar"
                    autoHide
                    autoHideTimeout={500}
                    autoHideDuration={200}
                    renderThumbHorizontal={renderThumbHorizontal}
                    renderThumbVertical={renderThumbVertical}
                    renderView={renderView}
                    renderTrackVertical={renderTrackVertical}
                  >
                    <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'red'}  status="processing" text={messages["material.category"]}/></p>
                    <ul>
                      <li>
                        <NavLink to='#' activeClassName={activeFilter=== "Tümü" ? "active":""} onClick={()=>onChangeFilter("Tümü")}>
                          {messages["genel.all"]}
                        </NavLink>
                      </li>
                      { MenuList && MenuList.length ? MenuList.map((data,s)=>{
                        return(
                        <li key={s}>
                          <NavLink to='#' activeClassName={activeFilter === data.value ? "active":""} onClick={()=>onChangeFilter(data.value)}>
                            {messages[data.label] ? messages[data.label] : data.label}
                          </NavLink>
                        </li>
                        )
                        }):null}
                    </ul>
                    {MenuList2 && MenuList2.length ? 
                    <>
                      <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'red'}  status="processing" text={messages["material.group"]}/></p>
                      <ul>
                        <DetailMenuListContent>
                            <li>
                              <NavLink to='#' activeClassName={activeFilter === "Tümü" ? "active":""} onClick={()=>onChangeFilter2("Tümü")}>
                                {messages["genel.all"]}
                              </NavLink>
                            </li>
                            {MenuList2 && MenuList2.length && MenuList2.map((data,s)=>{
                              return(
                              <li key={s}>
                                <NavLink to='#' activeClassName={activeFilter === data.value ? "active":""} onClick={()=>onChangeFilter2(data.value)}>
                                  {messages[data.label] ? messages[data.label] : data.label}
                                </NavLink>
                              </li>
                              )
                            })}
          
                        </DetailMenuListContent>
                      </ul>
                    </>:null}
                  </Scrollbars>
                </ListContent>
              </div>
            </DetailMenuNav>
          </div>
        </Cards>
      </div>
    )}
  </>
  );
}

export  {ListMenu};
