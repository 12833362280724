import {
	MATERIAL_DETAILS_ACTION,
	MATERIAL_DETAILS_SUCCESS,
    MATERIAL_DETAILS_ERROR,
	CREATE_MATERIAL_ACTION,
	CREATE_MATERIAL_SUCCESS,
	CREATE_MATERIAL_ERROR,
	UPDATE_MATERIAL_ACTION,
	UPDATE_MATERIAL_SUCCESS,
	UPDATE_MATERIAL_FAILURE,
	DELETE_MATERIAL_ACTION,
	DELETE_MATERIAL_SUCCESS,
	DELETE_MATERIAL_FAILURE
} from '../actions';

const INIT_STATE = {
	materialDetail: {},
	materialDetailLoading:false,
	materialDetailStatus:null
};

const materialDetails =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case MATERIAL_DETAILS_ACTION: //GET MATERIAL DETAILS
			return { 
				...state, 
				materialDetailLoading: true,
				materialDetailStatus:null,
				materialDetail: {}
			};
		case MATERIAL_DETAILS_SUCCESS:
            return { 
				...state,
				materialDetailLoading: false,  
				materialDetail: action.response,
				materialDetailStatus:200
			};
		case MATERIAL_DETAILS_ERROR:
            return {
				...state, 
				materialDetailLoading: false,
				materialDetailStatus:action.response 
			};	
            	
		case CREATE_MATERIAL_ACTION: //CREATE MATERIAL
			return { 
				...state, 
				materialDetailLoading: true,
				materialDetailStatus:null
			};
		case CREATE_MATERIAL_SUCCESS:
			return { 
				...state, 
				materialDetailLoading: false, 
				materialDetail: action.response,
				materialDetailStatus:200
			};
		case CREATE_MATERIAL_ERROR:
			return { 
				...state, 
				materialDetailLoading: false ,
				materialDetailStatus: action.response
			};	
		case UPDATE_MATERIAL_ACTION: //UPDATE MATERIAL
			return { 
				...state, 
				materialDetailLoading: true 
			};
		case UPDATE_MATERIAL_SUCCESS:
			return { 
				...state, 
				materialDetailLoading: false, 
				materialDetail: action.response,
				materialDetailStatus:200 
			};
		case UPDATE_MATERIAL_FAILURE:
			return {
				...state, 
				materialDetailLoading: false ,
				materialDetailStatus: action.response
			};
		case DELETE_MATERIAL_ACTION: //DELETE MATERIAL
			return { 
				...state, 
				materialDetailLoading: true,
				materialDetailStatus:null
			};
		case DELETE_MATERIAL_SUCCESS:
			return { 
				...state, 
				materialDetailLoading: false,  
				materialDetailStatus:200
			};
		case DELETE_MATERIAL_FAILURE:
			return { 
				...state, 
				materialDetailLoading: false,
				materialDetailStatus:action.response
			};
		default: return state;
	}
}
export default materialDetails;