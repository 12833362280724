import React from 'react';
import { Tag ,Popover} from 'antd';
import PopupChooserDropdown from './PopupChoser.style';
import {useIntl} from "react-intl";
import {MenuOutlined } from '@ant-design/icons';

export default function ({ option, selectedData, choseData ,disabled}) {
  const [visible, setVisibility] = React.useState(false);
  const { messages } = useIntl();
  function hide() {
    setVisibility(false);
  }
  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }
  const content = () => (
    <PopupChooserDropdown className="isoColorOptions" disabled={disabled}>
      {option.length >0 && !disabled ? 
      option.map((option, index) => {
        const onClick = () => {
          hide();
          choseData(option.value)
        };
        return <Tag key={index} onClick={onClick} style={{cursor:'pointer'}} color ={option.color} >{messages[option.label]}</Tag>;
      })
      :
      <Tag style={{cursor:'pointer'}} className ="blue">Otomatik İşlem</Tag>}
    </PopupChooserDropdown>
  );
  return (
    <Popover
      content={content()}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      disabled={disabled}
    >
      <Tag className = {option[selectedData] && option[selectedData].color ? option[selectedData].color:"blue"} style={{cursor:'pointer'}} icon={<MenuOutlined />} >{option[selectedData] && option[selectedData].label ? messages[option[selectedData].label]:""}</Tag>  
    </Popover>
  );
}
