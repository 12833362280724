
import React from 'react';
import {Modal} from 'antd';
import {WorkOrderReport}  from '../../../reports/OrderWorkOrders';

import {ProductBoxLabel}  from '../../../reports/ProductBoxLabel';
const ReportModal = ({printModalActive,toggleModal,printType}) => {
  
  return (
    <>

      {printModalActive && 
      <Modal
        forceRender
        type='primary'
        open={printModalActive}
        footer={null}
        width={1000}
        closable={false}
        onCancel={() => toggleModal("printModalActive",null)}
      >
        {printType==="workorder" ? 
        <WorkOrderReport />
        :printType==="packetlabel" ? 
        <ProductBoxLabel/>
        :null
        }
        
      </Modal>}
    </>

  );

}



export  {ReportModal};
