import {
    COMPONENT_GET_LIST,
    COMPONENT_GET_LIST_SUCCESS,
	COMPONENT_GET_LIST_ERROR,
	COMPONENT_SUM_GET_LIST,
    COMPONENT_SUM_GET_LIST_SUCCESS,
	COMPONENT_SUM_GET_LIST_ERROR,
} from '../actions';
import {store} from '../store';
import axios from 'axios';
//COMPONENTLİSTS List GET ****************************************************************************************************************************************************

export const component_list_set_pending = () => ({
	type: COMPONENT_GET_LIST
});

export const component_list_set_successful = (res) => ({
	type: COMPONENT_GET_LIST_SUCCESS,
	response: res
});

export const component_list_set_failed = (res) => ({
	type: COMPONENT_GET_LIST_ERROR,
	response: res
});

export const getComponentList = (query,value,value2) => dispatch =>{
	const apiUrl_list0 = `/components/list`;
	dispatch(component_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list0}?query=${query}&value=${value}&value2=${value2}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(component_list_set_successful(res.data));
	
				resolve(true);
			} else { 
				dispatch(component_list_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(component_list_set_failed(err.response.status));
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				
			}
		});
	});
};
// Component List Summary GET ****************************************************************************************************************************************************

export const componentlistsum_pending = () => ({
	type: COMPONENT_SUM_GET_LIST
});

export const componentlistsum_successful = (res) => ({
	type: COMPONENT_SUM_GET_LIST_SUCCESS,
	response: res
});

export const componentlistsum_failed = (res) => ({
	type: COMPONENT_SUM_GET_LIST_ERROR,
	response: res
});

export const getComponentListSum = (query,value,value2) => dispatch =>{
	const apiUrl_list0 = `/components/componentsum`;
	dispatch(componentlistsum_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list0}?query=${query}&value=${value}&value2=${value2}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(componentlistsum_successful(res.data));
				resolve(true);
			} else { 
				dispatch(componentlistsum_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(componentlistsum_failed(err.response.status));
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				
			}
		});
	});
};