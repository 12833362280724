import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRefresh,faPrint,faHome, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { MenuListWrapper,ListWrapper,SearchListSidebar,ListContent} from '../Style/style';
import {Input,message,Spin,Col,Row,Button,Badge,notification,Popover,Switch} from 'antd';
import { Main } from '../Style/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';
import { PageHeader } from '../../components/page-headers/page-headers';
import {getProductList} from '../../redux/productList/productListActions';
import blank from '../../static/img/resyok.jpeg';
import {currencyFormatter} from '../../helpers/currencyFormat';
import {getProductCode} from '../../helpers/getProductCode';
import appActions from '../../redux/app/actions';
import {ListMenu} from "../commonComponent/listMenu";
import { PriceListModal } from './products/priceListModal';
import { ReportModal } from './products/reportModal';

const defaultOptions = {
  significantDigits: 1,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ""
}
const NumberOptions = {
  significantDigits: 1,
  thousandsSeparator: '.',
}

const  Products =()=> {
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { productList, productListLoading,productListStatus } = useSelector((state) => state.productList);
  const {categoryList,productsFilter,listPerVal} = useSelector(state => state.App);
  const rtl  = useSelector(state => state.ChangeLayoutMode.rtlData);
  const [dataSource, setDataSource] = React.useState(productList);
  //const {tcmb} = useSelector(state => state.tcmb);
  // const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  // const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  let history = useHistory();
  const { setPriceListFilter } = appActions;
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const lang = locale === 'tr' ? 0 :1;
  const left = !rtl ? 'left' : 'right';

  const [reqFilter, setReqFilter] = React.useState({
    showPassiveProduct:false,
    selectedCategories:productsFilter
  }); 

  const [state, setState] = React.useState({
    priceListSettingModalActive:null,
    reportModalActive:null,
    visible: false,
    collapsed: false,
    selectedCategories:[productsFilter]
  });
  const { collapsed } = state;
  const buttonList = [
    <div key={0}>        
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>     
      <Popover content= {messages["button.print"]} placement="bottom">
        <Button shape="circle"  onClick={()=>togglePrintModal("priceListSettingModalActive",true)} size='large' icon={<FontAwesomeIcon icon={faPrint} />} style={{marginRight:'10px'}}/>
      </Popover> 
      <Popover content= {messages["sidebar.dashboard"]} placement="bottom">
        <NavLink key={4} to="/admin" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faHome}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  const FilterMenu = (
    <div className="card-nav">
        <label>{messages["product.showPassive"]} </label>
        <Switch checked={reqFilter.showPassiveProduct} onChange={(e)=>onChangeShowPassiveProduct(e)}/>
    </div>
  );
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(productsFilter==="Tümü"){
      dispatch(getProductList("list",reqFilter))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      })
    }else{
      //console.log(reqFilter)
      dispatch(getProductList("category",reqFilter))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      })
    }
  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(productList)
    // eslint-disable-next-line
  }, [productList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(productListStatus && productListStatus.status===401){
      notification.warning({
        message: productListStatus.statusText,
        description: productListStatus.data,
        duration:3
      });
      history.push({pathname:"/admin/401"})
    }else if (productListStatus===200){
      if(productsFilter<9){
        notification.info({
          message: 'Bilgi !',
          description: (productList && productList.length && productList.length) +" Adet "+messages["model.kategori"+productsFilter]+" Listelendi..",
          duration:3
        }); 
      }else{
        notification.info({
          message: 'Bilgi !',
          description: (productList && productList.length && productList.length) +" Adet Ürün Listelendi..",
          duration:3
        }); 
      }
    }
  }, [productListStatus]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = search => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.urun_adi[lang].toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setDataSource(data)
    }else{
      setDataSource(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const onChangeShowPassiveProduct = e => {
    let temp = reqFilter;
    temp.showPassiveProduct=e ; 
    setReqFilter({
      ...reqFilter,
      showPassiveProduct:e
    });
    dispatch(getProductList("category",temp));
  };
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    let filter_temp = reqFilter;
    filter_temp.selectedCategories=filterVal;
    if(filterVal==="Tümü"){
      dispatch(getProductList("list",filter_temp));
    }else{
      dispatch(getProductList("category",filter_temp));
    }
    dispatch(setPriceListFilter(filterVal));
    setReqFilter({
      ...reqFilter,
      selectedCategories:filterVal
    });
    setState({
      ...state,
      collapsed:false
    });
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed
    });
  };
  //******************************************************************************************************************************************************************/
  const togglePrintModal = (modalName,value) => {
    if(modalName==="reportModalActive" && value===true && state.selectedCategories.length>1){
      dispatch(getProductList("list","model_category"))
    }
      setState({
        ...state,
        [modalName]:value
      });
    
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const NewProduct = () => {
    history.push("/admin/productdetails/new")
  }
  //******************************************************************************************************************************************************************/
  const refreshDataSource = () => {
    if(productsFilter==="Tümü"){
      dispatch(getProductList("list",reqFilter))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      })
    }else{
      dispatch(getProductList("category",reqFilter))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      })
    }
  }
  //******************************************************************************************************************************************************************/
  const setSelectedCategories = (value) => {
    setState({
      ...state,
      selectedCategories:value
    });
  }
  //******************************************************************************************************************************************************************/
  //console.log(dataSource)
  return (
    <>
     
      <PageHeader
        title={messages["sidebar.products"]}
        buttons={buttonList}
      />
      <Main>
        <MenuListWrapper>
          <Row  gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
                <ListMenu 
                  MenuList={categoryList} 
                  messages={messages} 
                  activeFilter={productsFilter} 
                  newData={NewProduct}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  onChangeFilter={onChangeFilter}
                />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              <SearchListSidebar>
                <Cards 
                  title={
                    <div className="chatbox-search">
                      <Input  onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                    </div>
                  }
                  loading={productListLoading && <Spin indicator={faSpinner} />}
                  isbutton={FilterMenu}
              
                >

                  <Spin spinning={productListLoading} delay={10}>
                    <ListContent style={{height:'690px'}}>
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={renderThumbHorizontal}
                        renderThumbVertical={renderThumbVertical}
                        renderView={renderView}
                        renderTrackVertical={renderTrackVertical}
                      >
                        <ListWrapper>
                          <ul>
                            {dataSource &&
                              dataSource
                                .sort((a, b) => {
                                  return  a.urun_sira - b.urun_sira;
                                })
                                .map((product, key) => {
                                  const { urun_adi,urun_fiyat,urun_doviz, urun_kodu,model_category,listimage,urun_model, urun_optik, urun_cct, urun_panel,urun_aku,stockSum,_id,urun_kdv,urun_birimi } = product;
                                  return (
                                    <li key={key} className="chat-link-signle" >
                                      <NavLink to={`/admin/productdetails/${_id}`}>
                                        <div className="author-figure">
                                          <img src={listimage && listimage.length ? listimage[0].image : blank} alt="" />
                                          <span className={false ? 'active' : 'inactive'} />
                                        </div>
                                        <div className="author-info">
                                          <span className="author-name" style={{display: 'block'}}>{urun_adi[lang]}</span>
                                          <span className="author-chatText" >
                                          {urun_kodu && getProductCode(model_category,urun_model,urun_kodu,urun_optik,urun_cct,urun_panel,urun_aku)}
                                          </span>
                                        </div>
                                        <div className="author-chatMeta">
                                          <span>{currencyFormatter(urun_fiyat,defaultOptions)} {urun_doviz}</span>
                                          <Badge className={stockSum>0 ? "badge-success":"badge-danger"} count={(currencyFormatter(stockSum,NumberOptions))} />
                                        </div>
                                      </NavLink>
                                    </li>
                                  );
                                })}
                          </ul>
                        </ListWrapper>
                      </Scrollbars>
                    </ListContent>
                  </Spin>
                </Cards>
              </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 
      <PriceListModal
        priceListSettingModalActive={state.priceListSettingModalActive}
        togglePrintModal={togglePrintModal}
        setSelectedCategories={setSelectedCategories}
        selectedCategories={state.selectedCategories}
        productListLoading={productListLoading}
        messages={messages}
      />
      <ReportModal
        reportModalActive={state.reportModalActive}
        togglePrintModal={togglePrintModal}
        selectedCategories={state.selectedCategories}

      />
    </>
  );
}



export  {Products};
