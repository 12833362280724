import React from 'react';
import { Input, Form, Modal,Button,DatePicker,Upload, Select } from 'antd';
import { FormWrapper } from '../../Style/style';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faDownload} from '@fortawesome/free-solid-svg-icons';

const FileModal = ({ fileDataSource,fileRowModalActive,messages,toggleModal,UploadFile,updateFileRowData,saveFileRow}) => {
  const [form2] = Form.useForm();
  const { employeeFileDetail,employeeFileDetailLoading } = useSelector(state => state.employeFileDetails);
  React.useEffect(() => {
    fileDataSource && form2.setFieldsValue(fileDataSource)
   }, [fileDataSource]);
   const values = Form.useWatch([], form2);
   const [submittable, setSubmittable] = React.useState(false);
   React.useEffect(() => {
     form2.validateFields({ validateOnly: true }).then(
       () => {
         setSubmittable(true);
       },
       () => {
         setSubmittable(false);
       },
     );
   }, [values]);
  return (
  <>
  {fileRowModalActive && fileDataSource &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={fileRowModalActive}
      footer={
          <Button  size="large" type="primary"  disabled={!submittable} loading={employeeFileDetailLoading} onClick={()=>saveFileRow()}>
            {messages["button.save"]}
          </Button>
      }
      onCancel={() => toggleModal("fileRowModalActive",null,null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'auto'}}>
        <Form form={form2}  initialValues={fileDataSource}>
          <Form.Item name="date" label={messages["genel.date"]} rules={[{ required: true, message: 'Please input  Date!' }]}>
            <DatePicker
              format="DD.MM.YYYY"
              style={{width:"100%", display:'inline-block'}}
              onChange={(eData)=>updateFileRowData("date",eData)}
            />
          </Form.Item>
          <Form.Item name="description" label={messages["images.description"]} rules={[{ required: true, message: 'Please input Description!' }]}>
            <Input onChange={(eData)=>updateFileRowData("description",eData.target.value)}/>
          </Form.Item>
          <Form.Item name="file_type" label={messages["employee.filetype"]} rules={[{ required: true, message: 'Please Select File Type !' }]}>
            <Select options={[{label:"PDF",value:"PDF"},{label:"Image",value:"Image"}]} onSelect={(eData)=>updateFileRowData("file_type",eData)}/>
          </Form.Item>
          <Form.Item label={messages["model.file"]} >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={(file)=>UploadFile("file",file)}
              multiple={false}
            >
              <Button icon={<FontAwesomeIcon icon={faDownload} />}>Yükle (Max: 1)</Button>
            </Upload>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {FileModal};
