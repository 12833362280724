import React from 'react';
import { useSelector } from 'react-redux';
import {Row, Col,Tag,Spin,Popconfirm,Button} from 'antd';
import { ListRowContentT5} from '../../Style/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const LastCheckList = ({messages,lastCheckRow,deleteLastCheckListRow}) => {
  const {date,user,timer,currency,voltage,power,testType,testResult,order_id,_id} = lastCheckRow;
  const { acTestListLoading } = useSelector((state) => state.acTestList);
  return (
      <ListRowContentT5>
        <Row gutter={10}>
          <Col xxl={3} lg={3} md={3} sm={12} xs={24}>
            <div className="material-column">
              <h1>{moment(date).format("DD.MM.YYYY HH:mm")}</h1>
            </div>
          </Col>
          <Col xxl={2} lg={2} md={2} sm={6} xs={24}>
            <div className="list-column">
              <h1>{testType} </h1>
            </div>
          </Col>
          <Col xxl={2} lg={2} md={2} sm={6} xs={24}>
            <div className="list-column">
             <Tag className={testResult ==="PASS" ? "green" : "red"}>{testResult} </Tag>
            </div>
          </Col>
          <Col xxl={3} lg={3} md={3} sm={6} xs={24}>
            <div className="list-column">
              <h1>{power+ " W"} </h1>
            </div>
          </Col>
          <Col xxl={3} lg={3} md={3} sm={6} xs={24}>
            <div className="list-column">
              <h1>{timer} </h1>
            </div>
          </Col>
          <Col xxl={3} lg={3} md={3} sm={6} xs={24}>
            <div className="list-column">
              <h1>{currency} </h1>
            </div>
          </Col>
          <Col xxl={2} lg={2} md={2} sm={6} xs={24}>
            <div className="list-column">
              <h1>{voltage} </h1>
            </div>
          </Col>
          <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
            <div className="material-column">
              <h1>{user} </h1>
            </div>
          </Col>
          <Col xxl={2} lg={2} md={2} sm={2} xs={24} className='action-column'>
            {acTestListLoading ? 
            <Spin indicator={faSpinner} size='small'/>
            :
            <Popconfirm
              title={messages["alert.deletequest"]}
              okText={messages["genel.yes"]}
              cancelText={messages["genel.no"]}
              onConfirm={()=>deleteLastCheckListRow(lastCheckRow)}
            >
              <Button shape="circle">
                <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
              </Button>  
            </Popconfirm>}
          </Col>
        </Row>
      </ListRowContentT5>
  );
}
export  {LastCheckList};
