import {
    GET_DELIVERY_LIST,
    GET_DELIVERY_LIST_SUCCESS,
	GET_DELIVERY_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	deliveryList: [],
	deliveryListLoading:false,
	deliveryListStatus:null
};

const deliveriedList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_DELIVERY_LIST: // GEL LIST 
			return { 
				...state,
				deliveryList:[], 
				deliveryListLoading: true,
				deliveryListStatus:null 
			};
		case GET_DELIVERY_LIST_SUCCESS:
            return { ...state, 
                deliveryListLoading: false, 
                deliveryList: action.response.deliveried, 
				deliveryListStatus:200 
            };
		case GET_DELIVERY_LIST_ERROR:
			return { 
				...state,
				deliveryList:[], 
				deliveryListLoading: false,
				deliveryListStatus:action.response 
			};
		default: return  {...state};
	}
}
export default deliveriedList