import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDollarSign,faEuroSign,faLandmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { SettingDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';

const TcmbInfo =({tcmb,messages})=> {
  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const USDAlis = tcmb && tcmb.Data[0].ForexBuying;
  const EURAlis = tcmb && tcmb.Data[1].ForexBuying;
  const content = (
    <SettingDropdwon>
      <div className="setting-dropdwon">
        <Row gutter="10">
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <FontAwesomeIcon icon={faDollarSign} size='2x' style={{marginRight:'20px'}} />
              <figcaption>
                <Heading as="h5">{messages["genel.usdBuying"]}</Heading>
                <p>{USDAlis}</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <FontAwesomeIcon icon={faDollarSign} size='2x' style={{marginRight:'20px'}} />
              <figcaption>
                <Heading as="h5">{messages["genel.usdSelling"]}</Heading>
                <p>{USDSatis}</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <FontAwesomeIcon icon={faEuroSign} size='2x' style={{marginRight:'20px'}} />
              <figcaption>
                <Heading as="h5">{messages["genel.eurBuying"]}</Heading>
                <p>{EURAlis}</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <FontAwesomeIcon icon={faEuroSign} size='2x' style={{marginRight:'20px'}} />
              <figcaption>
                <Heading as="h5">{messages["genel.eurSelling"]}</Heading>
                <p>{EURSatis}</p>
              </figcaption>
            </figure>
          </Col>

        </Row>
      </div>
    </SettingDropdwon>
  );

  return (
    <div className="settings">
      <Popover placement="bottomRight" content={content} action="click">
        <Link to="#" className="head-example">
          <FontAwesomeIcon icon={faLandmark} size='lg' />
        </Link>
      </Popover>
    </div>
  );
}

export  {TcmbInfo};
