import {
	COMPONENT_DETAILS_ACTION,
	COMPONENT_DETAILS_SUCCESS,
    COMPONENT_DETAILS_ERROR,
	CREATE_COMPONENT_ACTION,
	CREATE_COMPONENT_SUCCESS,
	CREATE_COMPONENT_ERROR,
	UPDATE_COMPONENT_ACTION,
	UPDATE_COMPONENT_SUCCESS,
	UPDATE_COMPONENT_FAILURE,
	DELETE_COMPONENT_ACTION,
	DELETE_COMPONENT_SUCCESS,
	DELETE_COMPONENT_FAILURE,
} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';
// Get - Component Details ****************************************************************************************************************************************************
export const component_details_pending = () => ({
	type: COMPONENT_DETAILS_ACTION
});

export const component_details_success = (res) => ({
	type: COMPONENT_DETAILS_SUCCESS,
	response: res
});

export const component_details_failure = (res) => ({
	type: COMPONENT_DETAILS_ERROR,
	response: res
});

export const getComponentDetails = (id) => dispatch =>{
	const url = `/components/componentDetail/${id.id}` 
	dispatch(component_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				//console.log(res.data);
				dispatch(component_details_success(res.data))
				
                resolve(true);
            } else {
				dispatch(component_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(component_details_failure(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
            reject(err);
            
        });
    });
};

// POST - COMPONENT Create ****************************************************************************************************************************************************
export const create_component_pending = () => ({
	type: CREATE_COMPONENT_ACTION
});

export const create_component_success = (res) => ({
	type: CREATE_COMPONENT_SUCCESS,
	response: res
});

export const create_component_failure = (res) => ({
	type: CREATE_COMPONENT_ERROR,
	response:res
});

export const createComponent = (data) => dispatch => {
    const url = `/components/create/`
	dispatch(create_component_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_component_success(res.data));
				resolve(true);
			} else {
				dispatch(create_component_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_component_failure(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){
			}else{
			}
			reject(err);
		});
	});
}
// update COMPONENT ****************************************************************************************************************************************************
export const update_component_pending = (componentID) => ({
	type: UPDATE_COMPONENT_ACTION,
	componentID: componentID
});

export const update_component_successful = (res) => ({
	type: UPDATE_COMPONENT_SUCCESS,
	response: res
});

export const update_component_failed = (res) => ({
	type: UPDATE_COMPONENT_FAILURE,
	response:res
});

export const updateComponent = (data) => dispatch => {
	const url = `/components/update/${data._id}`
	dispatch(update_component_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_component_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_component_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_component_failed(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}

// delete COMPONENT ****************************************************************************************************************************************************
export const delete_component_pending = () => ({
	type: DELETE_COMPONENT_ACTION,
});

export const delete_component_successful = (res) => ({
	type: DELETE_COMPONENT_SUCCESS,
	response:res
	
});

export const delete_component_failed = (res) => ({
	type: DELETE_COMPONENT_FAILURE,
	response:res
});

export const deleteComponent = (id) => dispatch => {
	const url = `/components/delete/${id}` 
	dispatch(delete_component_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_component_successful(res.data));        
				resolve(true);
			} else {
				dispatch(delete_component_failed(err.response.status));
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_component_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
