import React from 'react';
import { View, StyleSheet,Image,Link } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import blank from '../../static/img/resyok.jpeg';
import Item from 'antd/lib/list/Item';
const BackGround = ''
const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 125,
        borderBottomWidth: 0.5,
        width:550,
    },
    ProductImagesContainer: {
        width: 90,
        height: 120,
        marginLeft: '0',
        backgroundColor: BackGround,
        flexDirection: 'column',
        justifyContent:'center'
    },
    ProductImages: {
        width: 90,
        height: 90,
        marginLeft: '0',
        backgroundColor: BackGround,
    },
    ProductContainer: {
        flexDirection: 'column',
        width: 400,
        height: 125,
        marginLeft: '2',
        backgroundColor: BackGround,
    },
    ProductColContainer: {
        flexDirection: 'column',
        width: 400,
    },
    FeatureContainer: {
        flexDirection: 'row',
        height:'auto',
        width:250,
        textAlign:'left'
    },
    PriceContainer: {
        flexDirection: 'column',
        width: 60,
        height: 90,
        marginLeft: '2',
        backgroundColor: BackGround,
    }
  });

  const ProductName = styled.Text`
  paddingLeft:5px;
  margin-top: 1px;
  font-size: 10px;
  text-align: left;
  font-family:'OpenSansFontBold';

  `;
  const ProductCode = styled.Text`
  paddingLeft:5px;
  margin-bottom: 5px;
  font-size: 8px;
  text-align: left;
  color:#1c2eed;
  font-family:'OpenSansFontBold';

  `;
  const ProductDetails = styled.Text`
  paddingLeft:5px;
  margin-top: 1px;
  font-size: 8px;
  text-align: left;
  `; 
  const FeatureRowLabel = styled.Text`
  paddingLeft:5px;
  font-size: 7px;
  text-align: left;
  color:#5c0619;
  font-family:'OpenSansFontBold';
  width:105px;
  `; 
  const FeatureRowValue = styled.Text`
  paddingLeft:5px;
  font-size: 7px;
  text-align: left;
  font-family:'OpenSansFontBold';
  width:145px;
  `; 

  const Price = styled.Text`
  margin-left: 2px;
  paddingTop:20px;
  paddingRight:5px;
  font-size: 12px;
  text-align: right;
  color:#111d9c;
  font-family:'OpenSansFontBold';
  `;

const PriceListRow = ({data,oran,messages,lang} ) => {
  //console.log(data);

    const item = data;
    const {electricalInfo,opticalInfo,mechanicalInfo,otherInfo} = data;
    let img = blank ;
    if(item.listimage && item.listimage.length ){
        img =  item.listimage[0].image;
    }else{
        img =blank ;
    }

    let SpecData = [];
    electricalInfo && electricalInfo.length && electricalInfo.forEach(row => {
        SpecData.push(row);
    })
    opticalInfo && opticalInfo.length && opticalInfo.forEach(row => {
        SpecData.push(row);
    });
    mechanicalInfo && mechanicalInfo.length && mechanicalInfo.forEach(row => {
        SpecData.push(row);
    });
    otherInfo && otherInfo.length && otherInfo.forEach(row => {
        SpecData.push(row);
    });

    //console.log(SpecData);

    let filter = null;
    filter = SpecData.filter(x=>x.visibility===1)
    const ProductPower = filter && filter.length ? SpecData.filter(x=>x.visibility===1)[0].value[0] : "???"
    filter = SpecData.filter(x=>x.visibility===2)
    const ProductLumen = filter && filter.length ? SpecData.filter(x=>x.visibility===2)[0].value[0] : "???"

    filter = SpecData.filter(x=>x.visibility===3)
    const ProductDimensions = filter && filter.length ? SpecData.filter(x=>x.visibility===3)[0].value[0] : "???"
    filter = SpecData.filter(x=>x.visibility===4)
    const ProductWeight = filter && filter.length ? SpecData.filter(x=>x.visibility===4)[0].value[0] : "???"

    const ModelKodu = item.urun_model ? item.urun_model  : ""
    const PCBKodu = item.urun_kodu ? ("-"+item.urun_kodu):""
    const Optik = item.urun_optik ? ("-"+item.urun_optik):""
    const CCT  = item.urun_cct ? ("-"+item.urun_cct):""
    const Solar  = item.urun_panel ? ("-"+item.urun_panel):""
    const Battery  = item.urun_aku ? ("-"+item.urun_aku):""    











    const productName = item.model_category <= 6 ? item.urun_adi[lang]+" ("+ProductLumen + " lm)" : item.model_category === 7  ? item.urun_adi[lang]+" " :item.urun_adi[lang]+" ("+item.urun_kapasite + " Ah)" ;
    const productCode = item.model_category <= 2 || item.model_category === 4 || item.model_category === 5 || item.model_category === 6 ? ModelKodu+PCBKodu+Optik+CCT : item.model_category === 3  ? ModelKodu+PCBKodu+Optik+CCT+Solar+Battery : ModelKodu+PCBKodu ;

 //console.log(lang)
    return(

        <View style={styles.rowContainer} wrap={false} >
            <View style={styles.ProductImagesContainer}>
                <Link src={"https://moduled.akaysis.com/web/productdetails/"+item._id}><Image style={styles.ProductImages} src={ img}/></Link>
                <ProductDetails style={styles.ProductName}><Link src={"https://moduled.akaysis.com/web/productdetails/"+item._id}>{messages["product.pricelistclickfordetails"]}</Link> </ProductDetails>
            </View>

            <View style={styles.ProductContainer}>
                <ProductName style={styles.ProductName}>{productName}    </ProductName>
                <ProductCode style={styles.ProductCode}>{messages["product.code"]+" : "+productCode}  {messages["product.dimensions"]+":"+ProductDimensions+" "+messages["product.weight"]+":"+ ProductWeight+ " Kg"}</ProductCode>
                <View style={styles.ProductColContainer}>
                        {electricalInfo && electricalInfo ? electricalInfo.map((row,index)=>{
                        return(
                            <View style={styles.FeatureContainer} key={index}>
                                <FeatureRowLabel style={styles.FeatureRowLabel} >{row.label[lang]==="" || row.label[lang]==="-" ? row.label[0] :row.label[lang] }</FeatureRowLabel> 
                                <FeatureRowValue style={styles.FeatureRowValue} >: {row.value[lang]==="" || row.value[lang]==="-" ? row.value[0]:row.value[lang]}</FeatureRowValue>
                            </View>)
                        }):null}




                </View>

                
               
            
                

                    
            </View >
            <View style={styles.PriceContainer}>
                <Price style={styles.ProductName}>{item.urun_fiyat ? (parseFloat(item.urun_fiyat+ ((item.urun_fiyat/(100-oran))*oran))).toFixed(2) +" "+item.urun_doviz : 0}</Price>
            </View >
            
        </View>

  
)};
  
export default PriceListRow