import React from 'react';
import { Form, Modal,DatePicker,Select,Col,Row,Button, InputNumber } from 'antd';
import { FormWrapper } from '../../Style/style';


const StokOperationModal = ({ stockOperationsLoading,messages,onSaveStock, stockOperationModalActive, stockRow,operationCenterList,UnitList,operationTypeSelect,CurrencyList,toggleModal,onChangeStockRow}) => {
  const [form5] = Form.useForm();
  React.useEffect(() => {
    stockRow && form5.setFieldsValue(stockRow)
   },[stockRow]);
  return (
  <>
  {stockOperationModalActive && 
    <Modal
      forceRender
      title={messages["genel.edit"]}
      type='primary'
      open={stockOperationModalActive}
      getContainer={false}
      footer={              
      <div>
        <Button  size="large" type="white" onClick={() => toggleModal("stockOperationModalActive",null,null)}  >
          {messages["button.cancel"]}
        </Button>
        <Button size="large" type="primary" onClick={()=>onSaveStock()} loading={stockOperationsLoading} disabled={stockOperationsLoading}>
          {messages["button.save"]}
        </Button>
    </div>}
      onCancel={() => toggleModal(null)}
      maskClosable={false}
      closable={false}
    >

        <FormWrapper style={{height:'auto'}}>
          <Form form={form5} initialValues={stockRow} >
            <Row align="middle"  gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.date"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='operation_date'>
                  <DatePicker
                    allowClear={false}
                    format="DD/MM/YYYY"
                    animateYearScrolling={true}
                    style={{width:"100%"}}
                    onChange={(e)=>onChangeStockRow("operation_date",e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle"  gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["task.operation"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='operation_center'>
                  <Select options={operationCenterList} onSelect={(eData) => onChangeStockRow('operation_center',eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["stock.operationtype"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='operation_type'>
                  <Select options={operationTypeSelect} onSelect={(eData) => onChangeStockRow('operation_type',eData)}/>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.qty"]}</label>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name="qty">
                  <InputNumber decimalSeparator=',' onChange={(eData) => onChangeStockRow('qty',eData)}/>
                </Form.Item>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name='unit' >
                  <Select style={{width:"100%"}} onSelect={(eData) => onChangeStockRow('unit',eData)}>
                    {UnitList.map((unit,i)=>{
                    return(
                      <Select.Option key={i} value={unit.value}>{messages[unit.label]}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={15}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.price"]}</label>
              </Col>
              <Col md={9} xs={24}>
                  <Form.Item name="price" >
                    <InputNumber decimalSeparator=',' onChange={(eData) => onChangeStockRow('price',eData)}/>
                  </Form.Item>
              </Col>
              <Col md={9} xs={24}>
                  <Form.Item name='doviz'>
                    <Select  options={CurrencyList} onSelect={(eData) => onChangeStockRow('doviz',eData)}/>
                  </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>

    </Modal>
  }
  </>
  );
}

export  {StokOperationModal};
