import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
   
    TitleContainer:{
        marginTop: 10,
        flexDirection: "column",
        width:250
    },
});

const NotesBody = styled.Text`
    margin: 2px;
    font-size: 8px;
    text-align: left;
    max-Height:190px;
`;
const NotesLabel = styled.Text`
    margin: 0px;
    font-size: 8px;
    text-align: left;
    borderBottomWidth:0.5;
`;
  const Notes = ({data,Lang}) => {
    return(
    <View style={styles.TitleContainer}>
        <NotesLabel>{Lang===1 ?  "NOTES : ":"NOTLAR : " }</NotesLabel>
        <NotesBody>{data.teklif_notu}</NotesBody>
    </View>
    )
    };
  
  export default Notes