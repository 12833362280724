import language from '../../config/language.config';

import englishLang from '../../static/img/flag/uk.png';
import turkishLang  from '../../static/img/flag/turkey.png';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      icon: englishLang,
    },
    {
      languageId: 'turkish',
      locale: 'tr',
      text: 'Turkish',
      icon: turkishLang,
    }
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
