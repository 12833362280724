import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faPrint, faX, faTrash, faSquareArrowUpRight} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
//import CreateProject from './overview/CreateProject';
import { ListTableRow,ListRowContentT5,ListContent} from '../../Style/style';
import { TableWrapper} from '../../Style/styled';
import {Button,Row,Col,Tag, Popover,Popconfirm} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import {getComponentList} from '../../../redux/componentList/componentListActions';
import {getComponentListSum} from '../../../redux/componentList/componentListActions';
import { Scrollbars } from 'react-custom-scrollbars';
import {deleteComponent} from '../../../redux/componentDetails/componentDetailsAction';

const  ComponentsList =({height,deleteComponentListRow})=> {
  const {componentList,componentListLoading,componentListStatus} = useSelector(state => state.componentList);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const {controlList} = useSelector(state => state.App);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    order_id:null,
    componentfilter:'Tümü',
  })
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>dispatch(getComponentList("filter",0,2))}>
        <FontAwesomeIcon icon={faRefresh} />
        <span>{messages["button.refresh"]}</span>
      </NavLink>
      <NavLink to="#">
        <FontAwesomeIcon icon={faPrint} />
        <span>Printer</span>
      </NavLink>
      <NavLink to="#">
        <FontAwesomeIcon size="1x" icon={faX}/>
        <span>PDF</span>
      </NavLink>
    </>
  );
  const left = !rtl ? 'left' : 'right';
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {   
    dispatch(getComponentList("filter",0,2))
  },[]);
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
 
  //******************************************************************************************************************************************************************/
  return (
  
  <Cards 
    title={messages["dashboard.componentlist"] }
    loading={componentListLoading}
    more={moreContent}
    //bodyStyle={{ minHeight: "200px" }}
  >
    <ListRowContentT5>
      <Row gutter={10}>
        <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
          <div className="material-column">
            <h1>{messages["material.category"]}</h1>
          </div>
        </Col>
        <Col xxl={6} lg={6} md={6} sm={24} xs={24}>
          <div className="material-column">
            <h1>{messages["material.material"]}</h1>
          </div>
        </Col>
        <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
          <div className="list-column">
            <h1>{messages["product.status"]}</h1>
          </div>
        </Col>              
        <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
          <div className="list-column">
            <h1>{messages["genel.qty"]}</h1>
          </div>
        </Col>
        <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
          <div className="list-column">
            <h1>{messages["genel.stocksum"]}</h1>
          </div>
        </Col>
        <Col xxl={2} lg={2} md={2} sm={24} xs={24}>
          <div className="list-column">
            <h1>X</h1>
          </div>
        </Col>  
      </Row>
    </ListRowContentT5>
    <ListContent style={{height:height}}>
      <Scrollbars
        className="custom-scrollbar"
        autoHide
        autoHideTimeout={500}
        autoHideDuration={200}
        renderThumbHorizontal={renderThumbHorizontal}
        renderThumbVertical={renderThumbVertical}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        {componentList && componentList.length ? componentList
        .map((componentRow,index)=>{
          const {_id,material,material_group,material_category,company_id,product_id,order_id} = componentRow;
          const content = (
            <div>
              <h5>{product_id && product_id.urun_adi[0]}</h5>
            </div>
          );
            return(
            <ListTableRow key={index}>
              <Row gutter={10}>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <Popover placement="right" title={<h5>{company_id && company_id.company_name}</h5>} content={content}>
                    <div className="material-column">
                      <h1>{material_category}</h1>
                    </div>
                  </Popover>
                </Col>
                <Col xxl={6} lg={6} md={6} sm={24} xs={24}>
                  <div className="material-column">
                    <h1>{material_group}</h1>
                    <h1> {material && material.material_name ? material.material_name:<Tag className='red' size="small">Malzeme Seçilmemiş</Tag>}</h1>
                  </div>
                </Col>
                
                <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
                  <div className="material-column-center">
                    <Tag className = {controlList.length > 0 && componentRow ? controlList[componentRow.controlled].color:"blue"} >{messages["order.componentstatus"+componentRow.controlled]}</Tag>
                  </div>
                </Col>
 
                <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
                  <div className="material-column-center">
                    <h1>{componentRow.totalQty.toFixed(2) + " " + (material&& material.material_unit ? material.material_unit : "Birim") } </h1>
                  </div>
                </Col>
  
                {material && material.stockSum ? 
                <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
                  <div className="material-column-center">
                    <h1>{(material && material.stockSum ? material.stockSum.toFixed(2) : 0 )+" "+(material&& material.material_unit && material.material_unit)}</h1>
                  </div>
                </Col>:
                <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
                  <div className="material-column-center">
                    ---
                  </div>
                </Col>
                }
                <Col xxl={2} lg={2} md={2} sm={12} xs={24}>
                  <div className="action-column">
                    <Button shape="circle" className='btn-info' href={"/admin/projectdetails/"+order_id} >
                      <FontAwesomeIcon icon={faSquareArrowUpRight} />
                    </Button>  
                    <Popconfirm
                      title={messages["alert.deletequest"]}
                      okText={messages["genel.yes"]}
                      cancelText={messages["genel.no"]}
                      onConfirm={()=>deleteComponentListRow(_id)}
                    >
                      <Button shape="circle">
                        <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
                      </Button>  
                    </Popconfirm>
                  </div>
                </Col>
              </Row>
            </ListTableRow>
            )
        }):null}
        
    </Scrollbars>
   </ListContent> 
  </Cards>

  );
}



export  {ComponentsList};
