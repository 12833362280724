
import React from 'react';
import { useSelector } from 'react-redux';
import {Select, Modal,Button ,Form,Input,Row,Col,DatePicker} from 'antd';
import { FormWrapper } from '../../Style/style';

const DeliveryModal = ({messages,delivery,UnitList,toggleModal,deliveryModalActive,onSaveNewDelivery,updateNewDeliveryData}) => {
  const { deliveryDetailLoading } = useSelector((state) => state.deliveryDetails);
  const [form9] = Form.useForm();

  return (
    deliveryModalActive &&
      <Modal
        forceRender
        type='primary'
        title={messages["delivery.details"]}
        open={deliveryModalActive}
        footer={
          <div key={0}>
            <Button size="large" type="white" onClick={() => toggleModal("deliveryModalActive",null)}  >
              {messages["button.cancel"]}
            </Button>
            <Button  size="large" type="primary" loading={deliveryDetailLoading} onClick={()=>onSaveNewDelivery()}>
              {messages["button.save"]}
            </Button>
          </div>
        }
        closable={false}
        width={610}
      >
        <FormWrapper style={{height:'300px'}}>
          <Form form={form9} initialValues={delivery}>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.date"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="date" rules={[{ required:true}]} >
                  <DatePicker format="DD/MM/YYYY" onChange={(eData)=>updateNewDeliveryData("date",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["product.name"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="productname"  >
                  <Input readOnly/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.qty"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="qty" rules={[{ required: true, message: 'Please input Product Qty!' }]} style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                  <Input type='number' max={delivery.max} decimalseparator=',' onChange={(eData)=>updateNewDeliveryData("qty",eData.target.value)}/>
                </Form.Item>
                <Form.Item name="unit" style={{ display: 'inline-block', width: 'calc(50% )',marginLeft:'5px' }}>
                  <Select options={UnitList.map(x=>{return({label:messages[x.label],value:x.value})})} onSelect={(eData)=>updateNewDeliveryData("unit",eData)} />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["delivery.deliverer"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="deliverer" onChange={(eData)=>updateNewDeliveryData("deliverer",eData.target.value)} >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["delivery.receiver"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="receiver" onChange={(eData)=>updateNewDeliveryData("receiver",eData.target.value)} >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Modal>


  );

}



export  {DeliveryModal};
