
import React from 'react';
import {Modal,Spin} from 'antd';
import { MaterialList } from '../commonComponents/materialList';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const CompnentListModal = ({loading,messages,componentListModalActive,closeModal,projectProduct,onChangeComponent,onChangeControl,deleteComponentRow}) => {
  const {urun_birimi,urun_adi,urun_miktari,componentList}= projectProduct;
  return (
      componentListModalActive && 
      <Modal
        forceRender
        type='primary'
        open={componentListModalActive}
        footer={null}
        width={1081}
        onCancel={() => closeModal('componentListModalActive')}
        closable={false}
        title={urun_miktari+" "+urun_birimi+" "+urun_adi}
        closeIcon={loading ? <Spin indicator={faSpinner} />:null}
      >
      {componentList && componentList.length ? componentList.map((component,index)=>{
        const SelectedMaterial = component.materialSelectList && component.materialSelectList.length && component.materialSelectList.filter(x=>x._id===component.material)[0]
        return(
          <MaterialList 
            key={index}
            messages={messages}
            component={component}
            product={projectProduct}
            SelectedMaterial={SelectedMaterial}
            onChangeComponent={onChangeComponent}
            onChangeControl={onChangeControl}
            deleteComponentRow={deleteComponentRow}
          />
        )}):null}
      </Modal>

  );

}



export  {CompnentListModal};
