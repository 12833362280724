import React from 'react';
import { Table ,Button,Tag} from 'antd';
import { NavLink } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus,faCopy,faPaste} from '@fortawesome/free-solid-svg-icons';

const ModelSpecifications = ({specifications,messages,modelDetailLoading,removeSpecRow,toggleModal,addNewSpecRow,modelSpecCopy,modelSpecPaste}) => {
  let specs = specifications && specifications.length && specifications.map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>addNewSpecRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>modelSpecCopy()}>
        <span><FontAwesomeIcon icon={faCopy} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.copy"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>modelSpecPaste()}>
        <span><FontAwesomeIcon icon={faPaste} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.paste"]}</span>
      </NavLink>
  
    </>
  );
  
  const specColumn = [
    {
      title: [messages["model.label"]],
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => b.no - a.no,
      sortOrder:'descent',
      align: 'left',
      render: (text) => text,
      width: '15%',
    },
    {
      title: [messages["genel.sira"]],
      dataIndex: 'label',
      key: 'label',
      align: 'left',
      render: (text) => 
      <div style={{display:'flex',flexDirection:'column'}}>
        <Tag className='blue' style={{marginBottom:'5px'}}>{text[0]}</Tag>
        <Tag className='yellow'>{text[1]}</Tag>
      </div>,
      width: '35%',
    },
  
    {
      title: [messages["product.specifications"]]+" TR/EN",
      dataIndex: 'feature',
      key: 'feature',
      render: (text) =>    
      <div style={{display:'flex',flexDirection:'column'}}>
        <Tag className='blue' style={{marginBottom:'5px'}}>{text[0]}</Tag>
        <Tag className='yellow'>{text[1]}</Tag>
      </div>,
      width: '35%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("specModalIndex",record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeSpecRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];

  return (
    <div className="full-width-table" >
      <Cards
        title={messages["model.specifications"]}
        size="large"
        bodypadding="0px"
        more={moreContent}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'730px'}}>
          <Table 
            columns={specColumn} 
            dataSource={specs} 
            pagination={false} 
            loading={modelDetailLoading}
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  );
}

export  {ModelSpecifications};
