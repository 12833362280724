import React from 'react';
import { Row, Col, Form, Input,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import CustomInput from '../../../helpers/customInput';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
const CompanyContact =({data,loading,messages,updateData,setSubmittable})=> {
  const [form1] = Form.useForm();
  React.useEffect(() => {
    form1.setFieldsValue(data)
   }, [data]);
   const values = Form.useWatch([], form1);

   React.useEffect(() => {
    form1.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]); 

  return (

      <Cards
        title={<Heading as="h5">{messages["company.contact"]}</Heading>}
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <FormWrapper>
          <Form  form={form1} initialValues={data} name="editAccount">
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.e-mail"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_email" rules={[{required: true, message: 'Please input your email!', type: 'email' }]} >
                  <Input onChange={(eData)=>updateData("sirket_email",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.e-mail"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_email2" rules={[{ message: 'Please input your email!', type: 'email' }]} >
                  <Input onChange={(eData)=>updateData("sirket_email2",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.web"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_web" rules={[{ message: 'Please input your web site!' }]} >
                  <Input onChange={(eData)=>updateData("sirket_web",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.phone"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_tel" rules={[{required: true, message: 'Please input your web site!' }]} >
                  <CustomInput mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("sirket_tel",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.fax"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_fax" rules={[{ message: 'Please input your web site!' }]} >
                  <CustomInput mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("sirket_fax",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.mobilephone"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_mobile" rules={[{ message: 'Please input your web site!' }]} >
                  <CustomInput mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("sirket_mobile",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>            
          </Form>
        </FormWrapper>
      </Cards>

  );
}

export  {CompanyContact};
