import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import {useIntl} from "react-intl";
import { MenuListWrapper,ListWrapper,SearchListSidebar,ListContent} from '../Style/style';
import {Input,message,Spin,Col,Row,Badge,notification} from 'antd';
import { Main } from '../Style/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';
import { PageHeader } from '../../components/page-headers/page-headers';
import {getCompanyList} from '../../redux/CompanyList/companyListActions';
import blank from '../../static/img/resyok.jpeg';
import {ListMenu} from "../commonComponent/listMenu"

const  Companies =()=> {
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {companyList,companyListLoading,companyListStatus} = useSelector((state) => state.companyList);

  const [dataSource, setDataSource] = React.useState(companyList);
  const rtl  = useSelector(state => state.ChangeLayoutMode.rtlData);

  const { messages } = useIntl();
  const dispatch = useDispatch();
  let history = useHistory();
  const left = !rtl ? 'left' : 'right';
  const [state, setState] = React.useState({
    pricelistModalActive:null,
    visible: false,
    collapsed: false,
    filterVal:"Tümü"
  });
  const { collapsed } = state;
  
  const MenuList =[
    {value:"Customer",label:"company.Customer"},
    {value:"Suppliers",label:"company.Suppliers"},
  ]

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    message.loading(messages["alert.updated"]);
    dispatch(getCompanyList(null));
  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(companyList)
  }, [companyList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(companyListStatus && companyListStatus.status===401){
      notification.warning({
        message: companyListStatus.statusText,
        description: companyListStatus.data,
        duration:1
      });
      history.push({pathname:"/admin/401"})
    }else if (companyListStatus===200){
      notification.info({
        message: 'Bilgi !',
        description: (companyList && companyList.length && companyList.length) + ' Adet Firma Listelendi..',
        duration:1
      });   
    }
  }, [companyListStatus]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = search => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.company_name.toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setDataSource(companyList)
    }else{
      setDataSource(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      dispatch(getCompanyList(null));
    }else{
      dispatch(getCompanyList(filterVal))
    }
    setState({
      ...state,
      filterVal: filterVal,
      collapsed:false
    });
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const NewCompany = () => {
    history.push("/admin/companydetails/new")
  }
  return (
    <>
      <PageHeader title={messages["sidebar.companies"]}/>
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
                <ListMenu 
                  MenuList={MenuList} 
                  messages={messages} 
                  activeFilter={state.filterVal} 
                  newData={NewCompany}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  onChangeFilter={onChangeFilter}
                />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
                <SearchListSidebar>
                  <Cards headless>
                    <div className="chatbox-search">
                      <Input  style={{width:'100%'}} onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                    </div>
                    <Spin spinning={companyListLoading} delay={100}>
                      <ListContent style={{height:'710px'}}>
                        <Scrollbars
                          className="custom-scrollbar"
                          autoHide
                          autoHideTimeout={500}
                          autoHideDuration={200}
                          renderThumbHorizontal={renderThumbHorizontal}
                          renderThumbVertical={renderThumbVertical}
                          renderView={renderView}
                          renderTrackVertical={renderTrackVertical}
                        >
                          <ListWrapper>
                            <ul>
                              {dataSource &&
                                dataSource
                                  .sort((a, b) => {
                                    return  a.company_name.localeCompare (b.company_name);
                                  })
                                  .map((company, key) => {
                                    const { company_name,company_adress,company_district,company_city,company_country,_id,company_category,company_img,company_email } = company;
                                    return (
                                      <li key={key} className="chat-link-signle" >
                                        <NavLink to={`/admin/companydetails/${_id}`}>
                                          <div className="author-figure">
                                            <img src={company_img ? company_img : blank} alt="" />
                                          </div>
                                          <div className="author-info">
                                            <span className="author-name" style={{display: 'block'}}>{company_name}</span>
                                            <span className="author-chatText" >
                                            {company_adress +" "+company_district+" "+company_city+" "+company_country}
                                            </span>
                                          </div>
                                          <div className="author-chatMeta">
                                            <span  >{""}</span>
                                            <Badge className={company_category==="Customer" ? "badge-success":"badge-danger"} count={messages["company."+company_category]}/>
                                        
                                          </div>
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                            </ul>
                          </ListWrapper>
                        </Scrollbars>
                      </ListContent>
                    </Spin>
                  </Cards>
                </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 

    </>
  );
}



export  {Companies};
