import React from 'react';
import { Input, Form, Modal,Button,InputNumber, Select } from 'antd';
import { FormWrapper } from '../../Style/style';
import { useSelector } from 'react-redux';


const TaskModal = ({ tasks,taskRowModalActive,updateTaskRow,messages,toggleModal}) => {
  const [form2] = Form.useForm();
  const {ColorList} = useSelector(state => state.App);
  React.useEffect(() => {
    tasks && form2.setFieldsValue(tasks)
   }, [tasks]);
  return (
  <>
  {taskRowModalActive && tasks &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={taskRowModalActive}
      footer={          
      <div>
        <Button  size="large" type="primary" htmlType="submit" >
          {messages["button.save"]}
        </Button>
      </div>}
      onCancel={() => toggleModal("taskRowModalActive",null,null)}
      forceRender
      closable={false}
    >
       <FormWrapper style={{height:'300px'}}>
        <Form form={form2} name="add-task" onFinish={(eData) => updateTaskRow(eData)} initialValues={tasks}>
          <Form.Item name="no" label={messages["genel.sira"]} >
            <InputNumber/>
          </Form.Item>
          <Form.Item name="tasks" label={messages["company.task"]} >
            <Input />
          </Form.Item>
          <Form.Item name="color" label={messages["genel.color"]} >
            <Select options={ColorList.map(x=>{return({label:messages[x.label],value:x.value})})}/>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {TaskModal};
