import React from 'react';
import { Form, Modal,Button,Select } from 'antd';
import { FormWrapper } from '../../Style/style';
const PrintModal = ({ printModalActive,messages,toggleModal,onSelectPrintType,openReportModal}) => {

const reportOptions = [
  {label:"Malzeme Tanımlama Etiketi",value:0},
  {label:"Stok Raporu",value:1}
]

  return (
  <>
  {printModalActive && 
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={printModalActive}
      footer={            
      <Button  size="large" type="primary" onClick={() => openReportModal()} >
        {messages["button.print"]}
      </Button>}
      onCancel={() => toggleModal("printModalActive",null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'auto'}}>
          <Form.Item label={messages["offer.reporttype"]} >
            <Select options={reportOptions} onChange={(e)=>onSelectPrintType(e)}/>
          </Form.Item>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {PrintModal};
