import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import QRCode from 'qrcode';


const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
        fontSize: 10,      
    },
    column1: {
        flexDirection: 'row',
        alignItems: 'left',
        height: 60,
        fontSize: 10,     
        width:200 
    },
    column2: {
        flexDirection: 'column',
        alignItems: 'right',
        borderLeft:0.3,
        height: 60,
        fontSize: 10,      
        width:175
    },
    column3: {
        flexDirection: 'column',
        alignItems: 'center',
        borderLeft:0.3,
        height: 60,
        fontSize: 10,      
        width:175
    },
    QRImages: {
        flexDirection: 'column',
        width: 54,
        height: 54,
        alignSelf:'center',
        left:3
    },
    QRDescription: {
        flexDirection: 'column',
        alignSelf:'center',
        width: 140,
        left:3
    },
    description: {
        textAlign: 'center',
        paddingRight: 12,
        borderBottom:0.3,
        width:175,
        height:16

    },
    User: {
        textAlign: 'center',
        paddingRight: 12,
        width:175,
        height:44
    }
  });

  const Onay = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: right;
  `;
  const User = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: right;
  `;
  const QRDescription = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;
  `;
const  reportFooter= ({orderItem,Lang}) => {
    let value = window.location.origin+"/admin/projectdetails/"+orderItem.id
    let QRimg ="";
    QRCode.toDataURL(value, function(err, url) {
        QRimg=  url;
    });
    return(    
        <View style={styles.row}>
            <View style={styles.column1}>
                <View style={styles.QRImages}>
                    <Image src={QRimg}></Image>
                </View>
                <View style={styles.QRDescription}>
                    <QRDescription>QR Kodu okutarak Siparişe ait işlem yapabilirsiniz. </QRDescription>
                </View>
            </View>
            <View style={styles.column2}>
                <Onay style={styles.description}>Teslim Eden</Onay>
                {/* <User style={styles.User}>{user}</User> */}
            </View>
            <View style={styles.column3}>
                <Onay style={styles.description}>Teslim Alan(Tarih/İsim/İmza)</Onay>
            </View>
        </View>
    )
};
  
  export default reportFooter