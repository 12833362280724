import React from 'react';
import { Input, Form, Modal,Button,Row,Col, Divider } from 'antd';
import { FormWrapper } from '../Style/style';
const NewModelModal = ({ model,lang,newModelModalActive,updateModelData,messages,toggleModal}) => {
  const [form2] = Form.useForm();


  return (
  <>
  {newModelModalActive &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={newModelModalActive}
      footer={            
      <Button  size="large" type="primary" onClick={() => toggleModal("newModelModalActive",null)} >
        {messages["button.close"]}
      </Button>
      }
      onCancel={() => toggleModal("newModelModalActive",null)}
      forceRender
      closable={false}
    >
       <FormWrapper style={{height:'auto'}}>
        <Form form={form2}  initialValues={model} >
          <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["model.name"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name={['model_adi', `${lang}`]} rules={[{ required: true, message: 'Please input your lastname!' }]}>
                  <Input  onChange={(eData)=>updateModelData("model_adi",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["model.code"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item  name="model_kodu" rules={[{required: true, message: 'Please input Model Code!' }]}>
                  <Input  onChange={(eData)=>updateModelData("model_kodu",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>

        </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {NewModelModal};
