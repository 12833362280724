import {
    MODEL_GET_LIST,
    MODEL_GET_LIST_SUCCESS,
	MODEL_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	modelList: [],
	modelListLoading:true,
	modelListStatus:null
};

const modelList =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case MODEL_GET_LIST: // GEL LIST 
			return { 
				...state, 
				modelListLoading: true,
				modelListStatus:null
			};
		case MODEL_GET_LIST_SUCCESS:
            return { ...state, 
                modelListLoading: false, 
                modelList: action.response,  
                modelListStatus:200
            };
		case MODEL_GET_LIST_ERROR:
			return { 
				...state, 
				modelListLoading: false,
				modelListStatus:action.response 
			};
		default: return  state;
	}
}
export default modelList
