import {
	BUYING_DETAILS_ACTION,
	BUYING_DETAILS_SUCCESS,
    BUYING_DETAILS_ERROR,
	CREATE_BUYING_ACTION,
	CREATE_BUYING_SUCCESS,
	CREATE_BUYING_ERROR,
	UPDATE_BUYING_ACTION,
	UPDATE_BUYING_SUCCESS,
	UPDATE_BUYING_FAILURE,
	DELETE_BUYING_ACTION,
	DELETE_BUYING_SUCCESS,
	DELETE_BUYING_FAILURE
} from '../actions';

import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';
// Get - BUYING Details ****************************************************************************************************************************************************
export const buying_details_pending = () => ({
	type: BUYING_DETAILS_ACTION
});

export const buying_details_success = (res) => ({
	type: BUYING_DETAILS_SUCCESS,
	response: res
});

export const buying_details_failure = (res) => ({
	type: BUYING_DETAILS_ERROR,
	response: res
});

export const getBuyingDetails = (id,history) => dispatch =>{
	const url = `/buying/${id.id}`
	dispatch(buying_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(buying_details_success(res.data))
                resolve(true);
            } else {
				dispatch(buying_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(buying_details_failure(err.response.status));
            if (err.response && err.response.status && err.response.status === 401) {
                dispatch(user_reauth_request(store.getState().auth.token));
            }
			history.push({
				pathname : "/admin/buyings"
			});
            //reject(err);
            
        });
    });
};

// POST - Buying Create ****************************************************************************************************************************************************
export const create_buying_pending = () => ({
	type: CREATE_BUYING_ACTION
});

export const create_buying_success = (res) => ({
	type: CREATE_BUYING_SUCCESS,
	response: res
});

export const create_buying_failure = (res) => ({
	type: CREATE_BUYING_ERROR,
	response:res
});

export const createBuying = (data,history) => dispatch => {
    const url = `/buying/create/`
	dispatch(create_buying_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_buying_success(res.data));
				history.push({
					pathname : "/buyings/buyingdetail/" + res.data._id
				})
				resolve(true);				
			} else {
				dispatch(create_buying_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_buying_failure(err.response.status));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
			}
			reject(err);
		});
	});
}
// update Buying ****************************************************************************************************************************************************
export const update_buying_pending = (material_ID) => ({
	type: UPDATE_BUYING_ACTION,
	materialID: material_ID
});

export const update_buying_successful = (res) => ({
	type: UPDATE_BUYING_SUCCESS,
	response: res
});

export const update_buying_failed = (res) => ({
	type: UPDATE_BUYING_FAILURE,
	response:res
});

export const updateBuying = (data) => dispatch => {
	const url = `/buying/update/${data._id}`
	dispatch(update_buying_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_buying_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_buying_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_buying_failed(err.response.status));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
			}
			reject(err.response);
		});
	});
}

// delete Model ****************************************************************************************************************************************************
export const delete_buying_pending = () => ({
	type: DELETE_BUYING_ACTION,
});

export const delete_buying_successful = (res) => ({
	type: DELETE_BUYING_SUCCESS,
	response:res
	
});

export const delete_buying_failed = (res) => ({
	type: DELETE_BUYING_FAILURE,
	response:res
});

export const deleteBuying = (id,history) => dispatch => {
	const url = `/buying/delete/${id}`
	dispatch(delete_buying_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_buying_successful(res.data));   
				history &&
				history.push({
					pathname : "/admin/buyings"
				})          
				resolve(true);
			} else {
				dispatch(delete_buying_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_buying_failed(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }
			reject(err);
		});
	});
}
