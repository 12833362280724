import React from 'react';
import { Button, Modal,Input, Select ,Form,Row, Col,Upload} from 'antd';
import { FormWrapper } from '../../Style/style';
import { PlusOutlined } from '@ant-design/icons';

const OtherPhotoModal = ({ otherPhoto,otherPhotoModalActive,updateOtherPhotoRow,lang,messages,toggleModal,beforeUpload}) => {
  const [form23] = Form.useForm();
  const uploadButton = (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>{messages["button.upload"]}</div>
    </div>
  );
  return (
  <>
    <Modal
      title={messages["genel.edit"]}
      open={otherPhotoModalActive}
      footer={
        <Button  size="large" type="primary"  onClick={() => toggleModal("otherPhotoModalActive",null)}>
          {messages["button.close"]}
        </Button>
      }
      onCancel={() => toggleModal("otherPhotoModalActive",null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'auto'}}>
          <Form form={form23}  initialValues={otherPhoto} >
            <Row align="middle">
              <Col  xxl={6} xl={6} lg={6} md={6} xs={24}>
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={(file)=>beforeUpload(file,"otherPhotos","image")}
                >
                  {otherPhoto && otherPhoto.image ? <img src={otherPhoto.image} alt="moduled" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </Col>
              <Col  xxl={18} xl={18} lg={18} md={18} xs={24}>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["genel.sira"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name='no'>
                      <Input type='number' onChange={(eData)=>updateOtherPhotoRow("no",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">Açıklama</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['description', `${0}`]}>
                      <Input onChange={(eData)=>updateOtherPhotoRow("description",eData.target.value,0)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">Description</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['description', `${1}`]}>
                      <Input onChange={(eData)=>updateOtherPhotoRow("description",eData.target.value,1)}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Form>
      </FormWrapper>
    </Modal>
  </>
  );
}

export  {OtherPhotoModal};
