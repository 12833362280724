import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHome, faPrint, faSpinner,faRefresh,faInfo, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { SearchListSidebar } from '../Style/style';
import { Input,message,Spin,notification, Badge,Popover,Button} from 'antd';
import { Dropdown } from '../../components/dropdown/dropdown';
import {updateComponent,deleteComponent} from '../../redux/componentDetails/componentDetailsAction';
import { Main } from '../Style/styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import {ProjectListCard} from './Project/projectListCard';
import {OrderListModal} from './Project/orderListReportModal';
import {CompnentListModal} from './Project/projectComponentListModal';
import {getOrderItemList} from '../../redux/orderItemList/orderItemListActions';
import {getProductList} from '../../redux/productList/productListActions';
import appActions from '../../redux/app/actions';

const  Projects =()=> {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();
  const { orderList, orderListLoading ,orderListStatus1,orderListStatus2,orderListStatus3,orderListStatus4,orderListStatus} = useSelector((state) => state.orderItemList);
  const categoryList = useSelector(state => state.App.orderStatus);
  const [dataSource, setDataSource] = React.useState(orderList);
  const {orderFilter} = useSelector(state => state.App);
  const buttonList = [
    <div key={0}>
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>           
      <Popover content= {messages["button.print"]} placement="bottom">
        <Button shape="circle"  onClick={()=>printOrders()} size='large' icon={<FontAwesomeIcon icon={faPrint} />} style={{marginRight:'10px'}}/>
      </Popover> 
      <Popover content= {messages["sidebar.dashboard"]} placement="bottom">
        <NavLink key={4} to="/admin" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faHome}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  const { setOrderFilter } = appActions;
  const [state, setState] = React.useState({
    printModalActive:null,
    componentListModalActive:null,
    projectIndex:null
  });
  const DropDownMenu = (
    <>
      <NavLink key={3} to="#" className="project-edit" onClick={()=>printOrders()}>
        <FontAwesomeIcon icon={faPrint} size='1x' />
        {messages["button.print"]}
      </NavLink>
      <NavLink key={4} to="/admin/project" className="project-edit">
        <FontAwesomeIcon icon={faPrint} size='1x' />
        {messages["sidebar.projects"]}
      </NavLink>
    </>
  );
  const FilterMenu = (
    <div className="card-nav">
      <ul>
        <li className={orderFilter === 'Tümü' || orderFilter < 4 ? 'active' : 'deactivate'} style={{textAlign:"center"}}>
            <Dropdown
              //action={['click']}
              className="wide-dropdwon"
              content={
                <>
                  <NavLink onClick={()=>onChangeFilter("Tümü")} to="#">{messages['genel.all']}</NavLink>
                  <NavLink onClick={()=>onChangeFilter("1")} to="#">{messages["order.orderStatus1"]}</NavLink>
                  <NavLink onClick={()=>onChangeFilter("2")} to="#">{messages["order.orderStatus2"]}</NavLink>
                  <NavLink onClick={()=>onChangeFilter("3")} to="#">{messages["order.orderStatus3"]}</NavLink>
                </>
              }
            >
              <NavLink to="#">
                {orderFilter !=="Tümü" && orderFilter < 4 ? messages["order.orderStatus"+orderFilter]:messages['genel.all']}
              </NavLink>
            </Dropdown>
        </li>
        <li className={orderFilter === 'subproduction' ? 'active' : 'deactivate'} style={{textAlign:"center"}}>
            <Dropdown
              //action={['click']}
              className="wide-dropdwon"
              content={
                <>
                  <NavLink onClick={()=>onChangeFilter("subproduction")} to="#">{messages["product.subproduction"] + " "+messages['genel.all']}</NavLink>
                </>
              }
            >
              <NavLink to="#">
                {messages["product.subproduction"]}
              </NavLink>
            </Dropdown>
        </li>
        <li className={orderFilter === '4' ? 'active' : 'deactivate'}>
          <Badge color='green' dot={orderListStatus4 && orderListStatus4.length>0 ? 'true':''}>
            <NavLink onClick={()=>onChangeFilter("4")} to="#">{messages["order.orderStatus4"]}</NavLink>
          </Badge>
        </li>
        <li className={orderFilter === '5' ? 'active' : 'deactivate'}>
          <NavLink onClick={()=>onChangeFilter("5")} to="#">
          {messages["order.orderStatus5"]}
          </NavLink>
        </li>
        <li className={orderFilter === '6' ? 'active' : 'deactivate'}>
          <NavLink onClick={()=>onChangeFilter("6")} to="#">
          {messages["order.orderStatus6"]}
          </NavLink>
        </li>
      </ul>
    </div>
  );
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (orderFilter==="Tümü"){
      dispatch(getOrderItemList("list","date",1,4))
    }else if(orderFilter==="subproduction"){
      dispatch(getOrderItemList("subproduction","date",1,4))
    }else{
      dispatch(getOrderItemList("list","date",orderFilter,orderFilter))
    }
  }, [])
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(orderList)
    let  Filter = [];
    categoryList && categoryList.length && categoryList.map(x=>{
      return(
        Filter.push({
          text:messages[x.label],
          value:x.value
        })
      )
    })
  }, [orderList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(orderListStatus && orderListStatus.status===401){
      notification.warning({
        message: orderListStatus.statusText,
        description: orderListStatus.data,
        duration:3
      });
      history.push({pathname:"/admin/401"})
    }else if (orderListStatus===200){
      if(orderFilter==='5'){
        notification.info({
          message: 'Bilgi !',
          description: "Son 60 Güne ait "+(orderList && orderList.length && orderList.length) +" Adet Teslimat Listelendi..",
          duration:3
        }); 
      }else{
        notification.info({
          message: 'Bilgi !',
          description: (orderList && orderList.length && orderList.length) +" Adet Sipariş " +(orderFilter==="Tümü" ? " " : messages["order.orderStatus"+orderFilter]),
          duration:3
        }); 
      }
    }
  }, [orderListStatus]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = (search) => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.urun_adi && item.urun_adi.toLowerCase().includes(search.toLowerCase()) || (item.offer && item.offer.firma_adi  && item.offer.firma_adi.toLowerCase().includes(search.toLowerCase())) })
    if (search===""){
      setDataSource(orderList);
    }else{
      setDataSource(filteredList)
    }
  }
  //******************************************************************************************************************************************************************/
  const onChangeFilter = filterValue => {
    if (filterValue==="Tümü"){
      dispatch(getOrderItemList("list","date",1,4))
      dispatch(setOrderFilter("Tümü"))
    }else if(filterValue==="subproduction"){
      dispatch(getOrderItemList("subproduction","date",1,4))
      dispatch(setOrderFilter("subproduction"))
    }else{
      dispatch(getOrderItemList("list","date",filterValue,filterValue))
      dispatch(setOrderFilter(filterValue))
    }
  }
  //******************************************************************************************************************************************************************/
  const printOrders = () =>{
    message.success('Yazırma için Hazırlandı !',3);
    setState({
      ...state,
      printModalActive:true
    });
    
  }
  //******************************************************************************************************************************************************************/
  const openComponentListModal =  (index) =>{
    setState({
      ...state,
      componentListModalActive:true,
      projectIndex:index
    });
    dispatch(getProductList("list","model_category"));
  }
  //******************************************************************************************************************************************************************/
  const closeModal=(modalName,value,index)=>{
    setState({
      ...state,
      [modalName]:value,
      projectIndex:index
    });
  }
  //******************************************************************************************************************************************************************/
  const onChangeComponent =(componentData,val)=>{
    const project = dataSource[state.projectIndex];
    let temp = componentData;
    temp.material = val;
    temp.materialListComplated = project.notComplatedComponentLength <= 1 ? true : false;
    //temp.command ="chanceContolled";
    dispatch(updateComponent(temp))
    .then(res => {
      closeModal("componentListModalActive",false,null);
    })
  }
   //******************************************************************************************************************************************************************/
  const onChangeControl = (componentData,value) => {
    const project = dataSource[state.projectIndex]
    if (value===4) return;
    project.controlled=value;
    project.newTodo = false //Number(value)===Number(1) ? true : false;
    let temp = componentData;
    temp.controlled=value;
    temp.newTodo = false //Number(value)===Number(1) ? true : false;
    temp.command = "chanceContolled";
    dispatch(updateComponent(temp))
    .then(res => {
      closeModal("componentListModalActive",false,null);
      if(orderFilter==="Tümü"){
        dispatch(getOrderItemList("list","date",1,4))
      }else{
        dispatch(getOrderItemList("list","date",orderFilter,orderFilter))
      }
    })
    if(componentData.task==="Yarı Mamül" && value === 1 ){
      Modal.confirm({
        title: 'Onaylıyor musunuz?',
        icon: <ExclamationCircleOutlined />,
        content: 'Yarı Mamül Üretimi Tanımlansın mı ?',
        okText: 'Evet',
        cancelText: 'Hayır',
        onOk:()=>createSubProduction(componentData)
      });
    }


     
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const refreshDataSource = () => {
    message.loading(messages["alert.updated"],5)
    if(orderFilter==="Tümü"){
      dispatch(getOrderItemList("list","date",1,4))
    }else{
      dispatch(getOrderItemList("list","date",orderFilter,orderFilter))
    }
  }
 //******************************************************************************************************************************************************************/
 const deleteComponentRow = (record) => {
    if(record._id){
      dispatch(deleteComponent(record._id))
      .then(res=>{
        message.success( messages["alert.deletesuccess"], 2 );
      })
    }
  }
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        title={messages["sidebar.projects"]}
        buttons={buttonList}
      />
      <Main>
        <SearchListSidebar>
        <Spin spinning={orderListLoading} delay={100}>
          <Cards  
            title={
              <div className="chatbox-search">
                <Input  onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
              </div>
            }
            bodyStyle={{ minHeight: '710px' }} border
            loading={orderListLoading && <Spin indicator={faSpinner} />}
            isbutton={FilterMenu}
            more={DropDownMenu}
          >
              {dataSource && dataSource.length ? dataSource.sort((a,b)=> new Date(b.siparis_tarihi)-new Date(a.siparis_tarihi)).map((projectProduct,i)=>{
                return(
                  <ProjectListCard
                    key={projectProduct._id}
                    index={i}
                    projectProduct={projectProduct}
                    messages={messages}
                    openComponentListModal={openComponentListModal}
                  />
                )
              }):null}
          </Cards>
        </Spin>
        </SearchListSidebar>
        <OrderListModal
          messages={messages}
          closeModal={closeModal}
          printModalActive={state.printModalActive}
          data={dataSource}
        />
        {state.componentListModalActive && state.projectIndex>=0 && !orderListLoading &&
        <CompnentListModal
          messages={messages}
          closeModal={closeModal}
          loading={orderListLoading}
          componentListModalActive={state.componentListModalActive}
          projectProduct={dataSource[state.projectIndex]}
          onChangeComponent={onChangeComponent}
          onChangeControl={onChangeControl}
          deleteComponentRow={deleteComponentRow}
        />}
    </Main> 

    </>
  );
}



export  {Projects};
