import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    NoteContainer: {
        flexDirection: "column",
        height: '12px',
        borderBottom:0.3,
        marginTop:3,
        borderBottomColor:"grey",
        width:550,
    }
  });
  const Note  = styled.Text`
  paddingLeft:2px;
  font-size:6px;
  text-align:left;
  width:540px;
  `;
const orderNotes = ({note}) => {
    return(
        <View style={styles.NoteContainer}>
            <Note>{note}</Note>
        </View>
)};
  
export default orderNotes