import React from 'react';
import { useSelector } from 'react-redux';
import {Select, Modal,Button ,Form,Input,Row,Col, Popover} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FormWrapper } from '../../Style/style';

const ComponentModal = ({messages,componentRowModalActive,onSaveComponentRow,toggleModal,TaskList,componentRow,updateNewComponentData}) => {
  const { componentDetailLoading } = useSelector((state) => state.componentDetails);
  const [form3] = Form.useForm();
  const _task = form3.getFieldValue("task");
  React.useEffect(() => {
    form3.setFieldsValue(componentRow)
   }, [componentRow])
   const values = Form.useWatch([], form3);
   const [submittable, setSubmittable] = React.useState(false);
   React.useEffect(() => {
    form3.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);

  return (
    <>
      {componentRowModalActive &&
      <Modal
        forceRender
        type='primary'
        title={messages["material.newmaterial"]}
        open={componentRowModalActive}
        footer={[
          <div key={0}>
          <Button size="large" type="white" onClick={() => toggleModal("componentRowModalActive",null)}  >
            {messages["button.cancel"]}
          </Button>
          <Button  size="large" type="primary"  disabled={!submittable} onClick={()=>onSaveComponentRow()} loading={componentDetailLoading}>
            {messages["button.save"]}
          </Button>
        </div>
        ]}
        closable={false}
      >
        <FormWrapper style={{height:'300px'}}>
          <Form form={form3} initialValues={componentRow}>
            <Row align="middle">
              <Col xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["stock.operation"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
              {TaskList.length ===0 ?
                <Form.Item name="task" rules={[{ required: true, message: 'Please input Operation NAME!' }]}>
                  <Input onChange={(eData)=>updateNewComponentData("task",eData.target.value)}/>
                </Form.Item>
                :
                <Form.Item name="task" rules={[{ required: true, message: 'Please input Operation NAME!' }]}>
                  <Select  options={TaskList} onSelect={(eData)=>updateNewComponentData("task",eData)}/>
                </Form.Item>
                }
              </Col>
            </Row>
            
            <Row align="middle">
              <Col xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["material.material"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="material" rules={[{ required:_task !== "Yarı Mamül" ?  true:false, message: 'Material is required' }]}>
                  <Input 
                    readOnly
                    addonAfter={
                      <>
                        {_task !== "Yarı Mamül" ? 
                        <Popover content={messages["sidebar.materials"]}>
                          <FontAwesomeIcon icon={faList} size='1x' onClick={()=>toggleModal("materialListModalActive",true)} style={{marginRight:'20px'}}/>
                        </Popover>
                        :
                        <Popover content={messages["sidebar.products"]}>
                          <FontAwesomeIcon icon={faList} size='1x' onClick={()=>toggleModal("productListModalActive",true)}/>
                        </Popover>}
                      </>
                    } 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["material.category"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="material_category"  >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["material.group"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="material_group" rules={[{ required:true}]} >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.qty"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item name="materialQty"  >
                  <Input type='number' onChange={(eData)=>updateNewComponentData("materialQty",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="stockSum" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="material_name" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="status" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="materialSelectList" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="totalQty" hidden>
              <Input/>
            </Form.Item>
          </Form>
        </FormWrapper>

      </Modal>}
    </>

  );

}



export  {ComponentModal};
