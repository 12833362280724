import {

	ORDER_GET_LIST,
    ORDER_GET_LIST_SUCCESS,
	ORDER_GET_LIST_ERROR,
	ORDER_LIST_MAINTENANCE,
    ORDER_LIST_MAINTENANCE_SUCCESS,
	ORDER_LIST_MAINTENANCE_ERROR,
} from '../actions';

const INIT_STATE = {
	orderList: [],
	orderListLoading:true,
	orderListStatus:null,
	orderListStatus1:null,
	orderListStatus2:null,
	orderListStatus3:null,
	orderListStatus4:null,
};

const  orderItemList = (state = INIT_STATE, action) => {
	switch (action.type) {

		case ORDER_GET_LIST: // GET LIST 
			return {
				 ...state, 
				orderListLoading: true ,
				orderListStatus:null
			};
		case ORDER_GET_LIST_SUCCESS:
            return { ...state, 
                orderListLoading: false, 
                orderList: action.response.orders,  
				orderListStatus:200,
                orderListStatus1:action.response.status1,
				orderListStatus2: action.response.status2,
				orderListStatus3: action.response.status3,
                orderListStatus4:action.response.status4,
            };
		case ORDER_GET_LIST_ERROR:
			return { 
				...state, 
				orderListLoading: false ,
				orderListStatus:action.response
			};
		default: {
			return state
		}
	}
}
export default orderItemList
