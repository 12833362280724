import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
    InformationContainer:{
        marginTop: 1,
        flexDirection: "column",
        width:400,
        height:25,
        alignItems:'center'
    }
});

const Title = styled.Text`
    margin-left: 5px;
    font-size: 7px;
    text-align: center;
`;

  const Information = () => {
    return(
    <View style={styles.InformationContainer}>
        <Title>  NOT: Gelen Girdilerde "UYGUNSUZLUK" Tespit Edilirse "DÜZELTİCİ ÖNLEYİCİ FAALİYET RAPORU" düzenlenecek ve Bu raporun EK'i olarak Kalite Yönetim Temsilcisine teslim edilecektir. </Title>
    </View>
    )
    };
  
  export default Information