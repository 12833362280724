import React from 'react';
import { Image} from 'antd';

const TechnicalDrawingsList = ({technicalDrawings,productDetailLoading}) => {
  return (

        <div className="table-bordered top-seller-table table-responsive" >
          <Image.PreviewGroup>
            {technicalDrawings && technicalDrawings.length ? technicalDrawings.map((image,index)=>{
              return(
                <Image width={300} src={image.image} key={index} />
              )
            }):null}
          </Image.PreviewGroup>
        </div>


  );
}
 


export  {TechnicalDrawingsList};
