import React from 'react';
import { Row, Col, Form, Input,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const  Profile = ({data,loading,messages,updateData,setSubmittable}) => {
const [form] = Form.useForm();
React.useEffect(() => {
  form.setFieldsValue(data)
 }, [ data]);
 const values = Form.useWatch([], form);

 React.useEffect(() => {
   form.validateFields({ validateOnly: true }).then(
     () => {
       setSubmittable(true);
     },
     () => {
       setSubmittable(false);
     },
   );
 }, [values]); 

  return (
    <Cards
      title={<Heading as="h5">{messages["company.profile"]}</Heading>}
      loading={loading && <Spin indicator={faSpinner}  />}
      bodyStyle={{ minHeight: '730px' }}
    >
      <FormWrapper>
        <Form form={form} initialValues={data}>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.name"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_adi" rules={[{ required: true, message:  'Şirket Adını Yazınız'}]} >
                  <Input onChange={(eData)=>updateData("sirket_adi",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.address"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_adres" rules={[{ required: true, message:  'Şirket adresini yazınız'}]} >
                  <Input onChange={(eData)=>updateData("sirket_adres",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.city"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_il" rules={[{ required: true, message:'Şirket İlini yazınız' }]} >
                  <Input onChange={(eData)=>updateData("sirket_il",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.town"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_ilce" rules={[{ required: true, message:'Şirket ilçesini yazınız' }]} >
                  <Input onChange={(eData)=>updateData("sirket_ilce",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.country"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_ulke" rules={[{ required: true, message:'Şirket Ülkesini yazınız' }]} >
                  <Input onChange={(eData)=>updateData("sirket_ulke",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.pk"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="sirket_pk"  >
                  <Input onChange={(eData)=>updateData("sirket_pk",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>           
        </Form>
      </FormWrapper>
    </Cards>
  );
}

export {Profile};
