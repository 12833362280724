
//device_list_refresh_request,getTasksList
import {getOrderItemList} from '../redux/orderItemList/orderItemListActions';
import {getOrderItemDetails} from '../redux/orderItemDetail/orderItemActions';
import {getAcList} from '../redux/actest/acTestListActions';
import ding from '../../src/static/sound/ding.mp3';
import { notification} from 'antd';
export const wsMiddleware = store => next => action => {
    const today  = new Date();
	if(action.type === "REDUX_WEBSOCKET::MESSAGE"){
        
        let msg = JSON.parse(action.payload.message);
        console.log("Message Hadled");
        const soundEffect = new Audio(ding);
        //soundEffect.play()

       

        if(msg && msg.collection){
			if(msg.collection==="AcTest"){
                if(msg.operation==="insert"){
                    soundEffect.play();
                }
            }
            else if(msg.collection==="offer"){
                if(msg.operation==="change"){
                  //  store.dispatch(getOfferDetails({id:msg.objectId}));
                }
            }
            else if(msg.collection==="offeritem"){
                if(msg.operation==="change"){
                  //  store.dispatch(getOrderItemDetails({id:msg.objectId}));
                }else if(msg.operation==="insert"){
                    // const filter = store.getState().App.orderFilter
                    // if(filter==="Tümü"){
                    //     store.dispatch(getOrderItemList("list","date",1,4));
                        
                    // }else{
                    //     store.dispatch(getOrderItemList("list","date",filter,filter));
                    // }
                }
            }

            else if(msg.collection==="Materials"){
                //console.log("Materials List  Güncelleniyor..")
                //store.dispatch(getMaterialsList("list"))
                //store.dispatch(getStockOutList(today))

            }else if(msg.collection==="Tasks"){
                if(msg.operation==="insert"){
             

                    //store.dispatch(getTasksList())
                }else if(msg.operation==="update"){
                   // store.dispatch(getTasksList())

                }
            }else if(msg.collection==="Product"){
                if(msg.operation==="update"){
                    //store.dispatch(getProductList("list","model_category"))
                }else if(msg.operation==="insert"){
                   // store.dispatch(getTasksList())
                }
            }else if(msg.collection==="componentList"){
                if(msg.operation==="update"){

                }else if(msg.operation==="insert"){
                   // store.dispatch(getTasksList())
                }
            }else{
                return
            }

			
		}

    }
    return next(action)
}