import React from 'react';
import { Spin,Upload,Table,Input ,Button} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import blank from '../../../static/img/blank.png';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
const Certificates =({data,loading,messages,UploadRowFile,updateRowField})=> {
   const  temp  = data.certificates;
   let cerificates =temp&& temp.length &&  temp.map((el, idx) => ({key: idx, ...el}))
   const editColumns = [
     {
       title: '#',
       dataIndex: 'key',
       rowKey: 'key',
       width: '10%',
       render: (text, certificate) => <span>{certificate.key}</span>,
     },
     {
       title: messages["company.logo"],
       dataIndex: 'file',
       rowKey: 'file',
       width: '20%',
       align:'center',
       render: (text, certificate) => (
         <Upload
           className="isoAvatar"
           showUploadList={false}
           beforeUpload={(file)=>UploadRowFile(certificate.key,"file",file)}
           action=""
         >
           <div className="isoAvatar">
             <img width = "40px" alt="#" src={text ? text : blank} />
           </div>
         </Upload>
       ),
     },
     {
       title: messages["company.bwlogo"],
       dataIndex: 'bwfile',
       rowKey: 'bwfile',
       width: '20%',
       align:'center',
       render: (text, certificate) => (
         <Upload
           className="isoAvatar"
           showUploadList={false}
           beforeUpload={(file)=>UploadRowFile(certificate.key,"bwfile",file)}
           action=""
         >
           <div className="isoAvatar">
             <img width = "40px"  alt="#" src={text ? text : blank} />
           </div>
         </Upload>
       ),
     },
     {
       title: messages["company.certificate"],
       dataIndex: 'certificate',
       rowKey: 'certificate',
       width: '40%',
       align:'left',
       render: (text, certificate) => (
         <Input
            style={{width:'100%'}}
            placeholder="Item Name"
            value={certificate.certificate}
            size='small'
            onChange={(e)=>updateRowField(certificate.key,e)}
         />
       ),
     },
     {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          //onClick={()=>removeEntryControlRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
     }
   ];

  return (
    <div className="full-width-table">
      <Cards
        title={ <Heading as="h5">{messages["company.certificates"]}</Heading>}
        bodypadding="0px"
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'670px'}}>
          <Table 
            columns={editColumns} 
            dataSource={cerificates} 
            pagination={false} 
          />
        </div>
      </Cards>
    </div>
  );
}

export  {Certificates};
