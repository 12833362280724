import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus,faEdit,faTrash,faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,Tag,Spin} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
const Departments = ({loading,messages,data,toggleModal,removeDepartmentRow,addNewDepartmentRow,removeTaskRow,addNewTaskRow}) => {
  let departments = data && data.departments.sort((a, b) => a.no - b.no).map((el, idx) => ({key: idx, ...el}))
  let tasklist = data && data.tasks.sort((a, b) => a.no - b.no).map((el, idx) => ({key: idx, ...el}))
  const moreContentDepartment = (
    <>
      <NavLink to="#" onClick={()=>addNewDepartmentRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
    </>
  );
  const moreContentTask = (
    <>
      <NavLink to="#" onClick={()=>addNewTaskRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
    </>
  );
  const Departments = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no', 
      align: 'left',
      render: (text) =>text,
      width: '10%',
    },
    {
      title: messages["company.department"],
      dataIndex: 'department',
      key: 'department', 
      align: 'left',
      render: (text) =>text,
      width: '55%',
    },
    {
      title: messages["genel.color"],
      dataIndex: 'color',
      key: 'color', 
      align: 'left',
      render: (text) =><Tag color={text}>----</Tag>,
      width: '15%',
    },
    {
      title: "",
      dataIndex: '',
      key: '',
      width: '20%',
      align: 'right',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("departmenRowModalActive",true,record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeDepartmentRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];
  const TaskList = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no', 
      align: 'left',
      render: (text) =>text,
      width: '10%',
    },
    {
      title: messages["company.task"],
      dataIndex: 'tasks',
      key: 'tasks', 
      align: 'left',
      render: (text) =>text,
      width: '55%',
    },
    {
      title: messages["genel.color"],
      dataIndex: 'color',
      key: 'color', 
      align: 'left',
      render: (text) =><Tag color={text}>----</Tag>,
      width: '15%',
    },
    {
      title: "",
      dataIndex: '',
      key: '',
      width: '20%',
      align: 'right',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("taskRowModalActive",true,record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeTaskRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];
  return (
    <div className="full-width-table">
      <Cards
        title={<Heading as="h5">{messages["company.departments"]}</Heading>}
        size="large"
        bodypadding="0px"
        more={moreContentDepartment}
        loading={loading && <Spin indicator={faSpinner}  />}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'300px'}}>
          <Table 
            columns={Departments} 
            dataSource={departments} 
            pagination={false} 
          />
        </div>
      </Cards>
      <Cards
        title={<Heading as="h5">{messages["company.tasklist"]}</Heading>}
        size="large"
        bodypadding="0px"
        more={moreContentTask}
        loading={loading && <Spin indicator={faSpinner}  />}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'300px'}}>
          <Table 
            columns={TaskList} 
            dataSource={tasklist} 
            pagination={false} 
          />
        </div>
      </Cards>
    </div>
  );
}

export {Departments};
