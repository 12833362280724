import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import RowsDetails from './RowsDetails'
import blank from '../../static/img/resyok.jpeg';

const BackGround = '#FFFFFF'

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'column',
        borderTopWidth: 1,
        borderColor:"blue",
        marginTop: '5',
    },
    rowDescriptionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        ///height: 50,
    },
    ProductImages: {
        width: 30,
        marginLeft: '0',
        backgroundColor: BackGround
    },
    ProductNameContainer: {
        flexDirection: 'column',
        width: 200,
        marginLeft: '2',
        backgroundColor: BackGround,
    },
    rowDetailContainer: {
        flexDirection: 'column'     
    },
  });


  const NoImage = styled.Text`
  paddingLeft:2px;
  paddingTop:10px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;


  width:45px;
  `;
  const ProductName = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 10px;
  text-align: left;
  `;
  const ProductCode = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `;
  const InfoRow = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;
  `;
 
  const Qty = styled.Text`
  paddingLeft:2px;
  margin-top: 2px;
  font-size: 8px;
  text-align: left;

  `;

const TableRow = ({orderItem,messages}) => {

    const urun_miktari = parseFloat(orderItem.urun_miktari).toFixed(2);
    let img = orderItem.product && orderItem.product.imageData? orderItem.product.imageData:blank;
    let code = {};



    code = orderItem.urun_kodu[0]+"-"+orderItem.urun_kodu[1]+"-"+orderItem.urun_kodu[2]+"-"+orderItem.urun_kodu[3]+"-"+orderItem.urun_kodu[4]+"-"+orderItem.urun_kodu[5];

    return(

        <View style={styles.rowContainer}>
            
            <View style={styles.rowDescriptionContainer}>
              
                <Image style={styles.ProductImages} src={img}/>
             
                
                 <View style={styles.ProductNameContainer}>
                    <ProductName style={styles.ProductName}>{orderItem.urun_adi}</ProductName>
                    <ProductCode style={styles.ProductName}>{code}</ProductCode>
                    
                    <Qty>{messages["genel.totalqty"]} : {urun_miktari+" "+messages["genel."+orderItem.urun_birimi]}</Qty>
                </View > 
                
                <View style={styles.rowDetailContainer}>
                    {orderItem.deliveryList.length && orderItem.deliveryList.filter(x=>x.printed===0).map((delivery) => {return( <RowsDetails delivery={delivery} messages={messages} key={delivery._id}/>)})}
                </View> 

            </View>
            

        </View>
  
)};
  
export default TableRow