import {
	LIST_MAINTENANCE,
    MAINTENANCE_SUCCESS,
	MAINTENANCE_ERROR,
} from '../actions';
import axios from 'axios';
import {store} from '../store';

//ORDERS List MAINTENANCE ****************************************************************************************************************************************************


export const maintenance_pending = () => ({
	type: LIST_MAINTENANCE
});

export const maintenance_successful = (res) => ({
	type: MAINTENANCE_SUCCESS,
	response: res
});

export const maintenance_failed = (res) => ({
	type: MAINTENANCE_ERROR,
	response: res
});

export const getMaintenanceList = (apiUrl_list,command) => dispatch =>{
	//"https://api.moduled.akaysis.com/ordersv3/testlist?type=list&orderBy=date&filter1=2&filter2=3"
	//const apiUrl_list = "/ordersv3/maintenance";
	//const apiUrl_list = "/ordersv3/testlist";
	dispatch(maintenance_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?command=${command}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				dispatch(maintenance_successful(res.data));

				resolve(true);
			} else {
				dispatch(maintenance_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(maintenance_failed(err.response));
			reject(err);
			//dispatch(returnErrors(err.response.data, err.response.status)); // deneme
			if (err.response && err.response.status && err.response.status === 401) {
				
			}
		});
	});
};