import React from 'react';
import { Button, Spin, Form, Input,Select,DatePicker,Card,Row,Col,InputNumber,Popconfirm ,Divider} from 'antd';
import { HorizontalFormStyleWrap,BasicFormWrapper } from '../../Style/style';
import moment from 'moment';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {currencyFormatter} from '../../../helpers/currencyFormat';
const defaultOptions = {
  significantDigits: null,
  thousandsSeparator: '.',
  decimalSeparator: ',',
}
const default4DigCrrency = {
  significantDigits: 4,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ""
}
let materialDescription  ;


const BuyingGenerals =({buying,loading,messages,CurrencyList,updateGenerals,toggleModal,updateBuyingDate,onDeleteBuying})=> {
  
  const [form21] = Form.useForm();
  React.useEffect(() => {
    form21.setFieldsValue(buying)
    materialDescription = buying && buying.material_id && currencyFormatter(buying.material_id.stockSum,defaultOptions)+" "+buying.material_id.material_unit+" Stok Görünüyor Fiyatı:"+currencyFormatter(buying.material_id.material_price,default4DigCrrency)+buying.material_id.material_doviz;

   }, [buying])
   const values = Form.useWatch([], form21);
   const [submittable, setSubmittable] = React.useState(false);
   React.useEffect(() => {
     form21.validateFields({ validateOnly: true }).then(
       () => {
         setSubmittable(true);
       },
       () => {
         setSubmittable(false);
       },
     );
   }, [values]);



   const { TextArea } = Input;
  return (

      <Card style={{marginBottom:'20px'}}>
        {loading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          <BasicFormWrapper>
            <HorizontalFormStyleWrap>
              <Form form={form21} initialValues={buying} onFinish={(eData) => updateGenerals(eData)}>
                <Form.Item name='tedarikci_id' hidden>
                  <Input />
                </Form.Item>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["offer.company"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={buying.tedarikci_id && buying.tedarikci_id.company_name ? ["tedarikci_id","company_name"]:"tedarikci"} rules={[{ required: true, message: 'Please input Company Name!' }]} >
                      <Input 
                        addonAfter={
                          <>
                            <FontAwesomeIcon icon={faMagnifyingGlass} size='1x' onClick={()=>toggleModal("companyListModalActive",true)} style={{marginRight:'20px'}}/>
                          </>
                        } 
                        disabled={buying.satinalma_durumu >= 1}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {buying.order_id && buying.order_id.urun_adi && 
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["buying.product"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['order_id', `urun_adi`]} >
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                </Row>}
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["buying.buyingStatus0"]} {messages["genel.date"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item>
                      <DatePicker
                        value={moment(new Date(buying.talep_tarihi))}
                        format="DD.MM.YYYY"
                        style={{width:"100%", display:'inline-block'}}
                        onChange={(e)=>updateBuyingDate("talep_tarihi",e)}
                        disabled={buying.satinalma_durumu >= 1}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["buying.buyingStatus1"]} {messages["genel.date"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item >
                      <DatePicker
                        value={buying.siparis_tarihi ? moment(new Date(buying.siparis_tarihi)):null}
                        format="DD.MM.YYYY"
                        style={{width:"100%", display:'inline-block'}}
                        onChange={(e)=>updateBuyingDate("siparis_tarihi",e)}
                        disabled={buying.satinalma_durumu >= 1}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages[ "buying.retaled"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name="related_person">
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["company.e-mail"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name="related_person_email" >
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["material.category"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['material_id', `material_category`]}>
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["material.group"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['material_id', `material_group`]} >
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["material.material"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['material_id', `material_name`]} extra={materialDescription }>
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["buying.notes"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name="siparis_notu">
                      <TextArea/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["genel.usdSelling"]} {messages["genel.eurSelling"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Row >
                      <Form.Item name='USD_Kur' style={{ display: 'inline-block', width: 'calc(50% - 10px)' }} >
                        <InputNumber decimalSeparator=',' addonAfter="₺"/>
                      </Form.Item>
                      <Form.Item name='EUR_Kur'  style={{ display: 'inline-block', width: 'calc(50% )', marginLeft: '10px' }}>
                        <InputNumber decimalSeparator=',' addonAfter="₺"/>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["genel.qty"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name="material_qty">
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["genel.price"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Row >
                      <Form.Item name='material_price' style={{ display: 'inline-block', width: 'calc(50% - 10px)' }} >
                        <InputNumber decimalSeparator=',' />
                      </Form.Item>
                      <Form.Item name='material_doviz'  style={{ display: 'inline-block', width: 'calc(50% )', marginLeft: '10px' }}>
                        <Select options={CurrencyList}/>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["genel.totalprice"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Row >
                      <Form.Item name='Doviz_TotalPrice' style={{ display: 'inline-block', width: 'calc(50% - 10px)' }} >
                        <InputNumber decimalSeparator=',' addonAfter={buying.material_doviz} readOnly/>
                      </Form.Item>
                      <Form.Item name='TL_TotalPrice'  style={{ display: 'inline-block', width: 'calc(50% )', marginLeft: '10px' }}>
                        <InputNumber decimalSeparator=',' addonAfter="₺" readOnly/>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Divider/>
                  <span>{moment(buying.created_date).format("DD.MM.YYYY")} Tarihinde {buying.creator_user} Tarafından oluşturuldu.</span>
                <Divider/>
                <div className="sDash-modal-actions">
                    <Popconfirm
                      title={messages["alert.deletequest"]}
                      okText={messages["genel.yes"]}
                      cancelText={messages["genel.no"]}
                      onConfirm={()=>onDeleteBuying()}
                    >
                    <Button size="default" type="danger" loading={loading}  disabled={!buying._id}>
                      {messages["button.delete"]}
                  
                    </Button>  
                    </Popconfirm>
                  &nbsp;
                  &nbsp;
                  <Button size="default" type="primary" loading={loading} htmlType='submit' disabled={!submittable}>
                    {messages["button.save"]}
                  </Button>

                </div>
              </Form>
            </HorizontalFormStyleWrap>
          </BasicFormWrapper>
    
        )}
      </Card>

  );
}

export  {BuyingGenerals};
