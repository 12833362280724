import React from 'react';
import { Row, Col, Spin,Rate,Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart,faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { NavLink} from 'react-router-dom';
import blank from '../../../../static/img/resyok.jpeg';
import Heading from '../../../../components/heading/heading';
import { ProductCard } from '../../../Style/style';
import {getProductCode} from '../../../../helpers/getProductCode';

const ProductList = ({products,loading,lang,messages}) => {
  
  return (
    <Row gutter={15}>
      {loading ? (
        <div className="spin">
          <Spin />
        </div>
      ) : products.length ? (
        products.map((product,index) => {
          const { urun_adi,urun_fiyat,urun_doviz, urun_kodu,model_category,listimage,modelDetails, urun_optik, urun_cct, urun_panel,urun_aku,stockSum,_id } = product;
          return (
            <Col xs={24} key={index}>
              <ProductCard className="list-view" style={{ marginBottom: 20 }}>
                <div className="product-list">
                  <Row gutter={15}>
                    <Col md={6} xs={24}>
                      <NavLink to={`/web/productdetails/${product._id}`}>
                        <figure>
                          <img src={listimage && listimage.length ? listimage[0].image : blank} />
                        </figure>
                      </NavLink>
                    </Col>
                    <Col md={12} xs={24}>
                      <div className="product-single-description">
                        <Heading className="product-single-title" as="h5">
                          <NavLink to={`/web/productDetails/${product._id}`}>{urun_adi[lang]}</NavLink>
                        </Heading>
                        <p>{messages["product.code"]} :{urun_kodu && getProductCode(model_category,modelDetails.model_kodu,urun_kodu,urun_optik,urun_cct,urun_panel,urun_aku)}</p>
                      </div>
                    </Col>
                    <Col md={6} xs={24}>
                      <div className="product-single-info">
                        <NavLink className="btn-heart" to="#">
                          <FontAwesomeIcon
                            icon={faHeart}
                            size='1x'
                            color={true ? '#FF4D4F' : '#9299B8'}
                            fill={true ? '#FF4D4F' : 'none'}
                          />
                        </NavLink>
                        <p className="product-single-price">
                          <span className="product-single-price__new">${0.00} </span>
                          {0.00 && (
                            <>
                              <del> ${oldPrice} </del>
                              <span className="product-single-price__offer"> 60% Off</span>
                            </>
                          )}
                        </p>
                        <div className="product-single-rating">
                          <Rate allowHalf defaultValue={4.7} disabled /> 4.9
                          <span className="total-reviews"></span>
                        </div>
                        <div className="product-single-action">
                          <NavLink to={`/web/productdatasheet/${product._id}`}>
                            <Button className="btn-cart" size="small" type="white">
                              <FontAwesomeIcon icon={faFilePdf} size='1x' style={{marginRight:'5px'}}/>
                              {messages["product.datasheet"]}
                            </Button>
                          </NavLink>
                          <Button size="small" type="primary">
                            Buy Now
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </ProductCard>
            </Col>
          );
        })
      ) : (
        <Col xs={24}>
          <Heading as="h1">Data Not Found</Heading>
        </Col>
      )}

    </Row>
  );
}

export {ProductList};
