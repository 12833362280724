import React from 'react';
import { Col,Row, Form, Input,Collapse } from 'antd';
import {currencyFormatter} from '../../../helpers/currencyFormat';
import { HorizontalFormStyleWrap,BasicFormWrapper,OrderSummary } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { Cards } from '../../../components/cards/frame/cards-frame';
const { Panel } = Collapse;
const defaultOptions = {
  significantDigits: 4,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ""
}
const { TextArea } = Input;
const OfferSummary =({offer,loading,messages,updateOfferData})=> {
  const {teklif_tutardoviz,teklif_kdvdoviz,teklif_gtoplamdoviz,teklif_dovizi,teklif_notu,created_date,modified_date,offer_creator_user,offer_modified_user} = offer
  const [form7] = Form.useForm();

  return (
 
      <BasicFormWrapper>
        <HorizontalFormStyleWrap>
          <Form form={form7} initialValues={offer} >
                <Collapse defaultActiveKey={['1','2']}  >
                  <Panel header={messages["offer.info"]} key="1">
                    <Row gutter={25}  style={{padding:10}}>
                      <Col md={16} xs={24}>
                        <Form.Item>
                          <TextArea rows={5} value={teklif_notu} onChange={(e)=>updateOfferData("teklif_notu",e.target.value)}/>
                        </Form.Item>
                      </Col>
                      <Col md={8} xs={24}>
                        <OrderSummary style={{marginTop:1,marginBottom:10}}>
                          <div className="order-summary-inner">
                            <ul className="summary-list">
                              <li>
                                <span className="summary-list-title">{messages["genel.totalprice"]}</span>
                                <span className="summary-list-text">{currencyFormatter(teklif_tutardoviz)+" "+(teklif_dovizi ? teklif_dovizi :"")}</span>
                              </li>
                              <li>
                                <span className="summary-list-title">{messages["genel.sumkdv"]}</span>
                                <span className="summary-list-text">{currencyFormatter(teklif_kdvdoviz)+" "+(teklif_dovizi ? teklif_dovizi:"")}</span>
                              </li>
                              <li>
                                <span className="summary-list-title"> --</span>
                                <span className="summary-list-text">0</span>
                              </li>
                            </ul>
                            <Heading className="summary-total" as="h4">
                              <span className="summary-total-label">{messages["genel.gtotal"]}</span>
                              <span className="summary-total-amount">{currencyFormatter(teklif_gtoplamdoviz)+" "+(teklif_dovizi ? teklif_dovizi:"")}</span>
                            </Heading>
                          </div>
                        </OrderSummary>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={messages["button.details"]}key="2">
                    <Row style={{padding:10}} align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.creator"]}</label>
                      </Col>
                      <Col md={9} xs={12}>
                        <Form.Item >
                          <Input readOnly value={created_date}/>
                        </Form.Item>
                      </Col>
                      <Col md={9} xs={12}>
                        <Form.Item  >
                          <Input readOnly value={offer_creator_user}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{padding:10}} align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.modifed"]}</label>
                      </Col>
                      <Col md={9} xs={12}>
                        <Form.Item >
                          <Input readOnly value={modified_date}/>
                        </Form.Item>
                      </Col>
                      <Col md={9} xs={12}>
                        <Form.Item  value={modified_date} >
                          <Input readOnly value={offer_modified_user}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
          </Form>
        </HorizontalFormStyleWrap>
      </BasicFormWrapper>
  
    
  );
}

export  {OfferSummary};
