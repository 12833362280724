import {
	MODEL_DETAILS_ACTION,
	MODEL_DETAILS_SUCCESS,
	MODEL_DETAILS_ERROR,
	CREATE_MODEL_ACTION,
	CREATE_MODEL_SUCCESS,
	CREATE_MODEL_ERROR,
	UPDATE_MODEL_ACTION,
	UPDATE_MODEL_SUCCESS,
	UPDATE_MODEL_FAILURE,
	DELETE_MODEL_ACTION,
	DELETE_MODEL_SUCCESS,
	DELETE_MODEL_FAILURE
} from '../actions';


const INIT_STATE = {
	modelDetail: {},
	modelDetailLoading:false,
	modelDetailStatus:null
};

const modelDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case MODEL_DETAILS_ACTION: //GET OFFER DETAILS
			return { 
				...state, 
				modelDetailLoading: true,
				modelDetailStatus:null
			};
		case MODEL_DETAILS_SUCCESS:
            return { 
				...state,
				modelDetailLoading: false,  
				modelDetail: action.response,
				modelDetailStatus:200
				};
		case MODEL_DETAILS_ERROR:
            return { 
				...state, 
				modelDetailLoading: false,
				modelDetailtatus:action.response, 
			};	
            	
		case CREATE_MODEL_ACTION: //CREATE MODEL
			return { 
				...state, 
				modelDetailLoading: true 
			};
		case CREATE_MODEL_SUCCESS:
			return { 
				...state, 
				modelDetailLoading: false, 
				modelDetail: action.response 
			};
		case CREATE_MODEL_ERROR:
			return { 
				...state, 
				modelDetailLoading: false,
				modelDetail:null,
				modelDetailStatus:action.response 
			};	
		case UPDATE_MODEL_ACTION: //UPDATE MODEL
			return { 
				...state, 
				modelDetailLoading: true,
				modelDetailStatus:null
			};
		case UPDATE_MODEL_SUCCESS:
			return { 
				...state, 
				modelDetailLoading: false, 
				modelDetail: action.response,
				modelDetailStatus:200
			};
		case UPDATE_MODEL_FAILURE:
			return { 
				...state, 
				modelDetailLoading: false ,
				modelDetailStatus:action.response
			};
		case DELETE_MODEL_ACTION: //DELETE MODEL
			return { 
				...state, 
				modelDetailLoading: true 
			};
		case DELETE_MODEL_SUCCESS:
			return { 
				...state, 
				modelDetailLoading: false,
				modelDetailStatus:200
			};
		case DELETE_MODEL_FAILURE:
			return { 
				...state, 
				modelDetailLoading: false,
				modelDetailStatus:action.response 
			};
		default: return state;
	}
}
export default modelDetails