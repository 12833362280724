import React from 'react';
import { NavLink } from 'react-router-dom';
import { Input, Modal,Button,Form,Select,Row,Col, InputNumber,Tooltip,Popover,Image,Divider,Segmented } from 'antd';
import { FormWrapper } from '../../Style/style';
import { faMagnifyingGlass ,faRefresh,faEdit, faPrint, faSearch} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {currencyFormatter} from '../../../helpers/currencyFormat';
import blank from '../../../static/img/resyok.jpeg';

const OfferRowModal = ({messages,offer,offeritem,offerRowModalActive,offerRowModalIndex,toggleModal,UnitList,CurrencyList,KDVList,onUpdateOfferRow,removeOfferRow,onChangeItemType,optikList,CCTList}) => {
  const [form8] = Form.useForm();
  const values = Form.useWatch([], form8);
  const [submittable, setSubmittable] = React.useState(false);
  let doviz;let price;let qty;;let TopTL;let TopDoviz;
  let TLPrice = Number(offeritem.urun_dovizi==="₺" ? Number(offeritem.urun_fiyati).toFixed(2) : offeritem.urun_dovizi==="€" ? Number(offeritem.urun_fiyati*offer.teklif_eurkur ).toFixed(2) : Number(offer.teklif_usdkur *offeritem.urun_fiyati).toFixed(2));
  const category = offeritem.productCategory;
  React.useEffect(() => {
    form8.setFieldsValue(offeritem)
  },[offer]);

   React.useEffect(() => {
    form8.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);
  const onChange=()=>{
    doviz = form8.getFieldValue("urun_dovizi");
    price = form8.getFieldValue("urun_fiyati");
    qty = form8.getFieldValue("urun_miktari");
    TLPrice = Number(doviz==="₺" ? Number(price).toFixed(2) : doviz==="€" ? Number(price*offer.teklif_eurkur ).toFixed(2) : Number(offer.teklif_usdkur *price).toFixed(2));
    TopTL = TLPrice * qty;
    TopDoviz = offer.teklif_dovizi === "€" ? (TopTL / offer.teklif_eurkur) : ( TopTL / offer.teklif_usdkur);
    form8.setFieldsValue({'toplamDoviz':currencyFormatter(TopDoviz)});
    form8.setFieldsValue({'toplamTL':currencyFormatter(TopTL)});
  }

  return (
  <>
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={offerRowModalActive}removeOfferRow
      width={900}
      forceRender
      closable={false}
      footer={     
        <>
        {!offeritem._id && <Button  size="large" type="primary" danger onClick={() => removeOfferRow(offerRowModalIndex)} >
          {messages["button.delete"]}
        </Button>}       
          <Button  size="large" type="primary"  disabled={!submittable} onClick={() => form8.submit()} >
            {messages["button.save"]}
          </Button>
        </>
        }
    >
        <FormWrapper style={{height:'auto'}}>
            <Form form={form8} onValuesChange={()=>onChange()} onFinish={(eData)=>onUpdateOfferRow(eData)}>
                <Row align="middle"  gutter={10}>
                  <Col md={12} xs={24}>
                    <Segmented size="small" options={[{label:messages["product.name"],value:'product'}, {label:messages["material.material"],value:'material'}, {label:messages["product.default"],value:'default'}]} value={offeritem.order_type} onChange={(e)=>onChangeItemType(e)} />
                  </Col>
                  <Col md={12} xs={24} align="right">
                    {offeritem.order_type === "product" && offeritem.urun_id && 
                    <Popover content= {messages["product.profil"]} placement="bottom">
                      <NavLink key={4} to={`/admin/productdetails/${offeritem.urun_id}`} target="_blank">
                        <Button shape="circle"  icon={<FontAwesomeIcon icon={faEdit}/>}  style={{marginRight:'10px'}}/>
                      </NavLink>
                    </Popover>}
                    {offeritem.order_type === "material" && offeritem.material_id && 
                    <Popover content= {messages["material.details"]} placement="bottom">
                      <NavLink key={4} to={`/admin/materialdetails/${offeritem.material_id}`} target="_blank">
                        <Button shape="circle"  icon={<FontAwesomeIcon icon={faEdit}/>}  style={{marginRight:'10px'}}/>
                      </NavLink>
                    </Popover>}  
                    {offeritem.order_type !== "default" &&
                    <Popover content= {messages["genel.ara"]} placement="bottom">
                      <Button shape="circle"  icon={<FontAwesomeIcon icon={faSearch} />} onClick={()=>toggleModal((offeritem.order_type==='product' ? "productListModalActive":"materialListModalActive"),true)}/>
                    </Popover>} 
                  </Col>
                </Row>
                <Divider style={{marginTop:10,marginBottom:10}}/>
                <Row align="middle"  gutter={10}>
                  <Col md={6} xs={24}>
                    <Image width={140} src={offeritem && offeritem.urun_resim ? offeritem.urun_resim :blank  } preview={false}/>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name="urun_adi" label={messages["product.name"]} rules={[{ required: true, message: 'Please input Product Name!' }]} >
                      <Input />
                    </Form.Item>
                    <Form.Item name='urun_id' hidden rules={[{ required: (offeritem.order_type === "product"), message: 'Lütfen Ürün Seçiniz!' }]} >
                      <Input />
                    </Form.Item>
                    <Form.Item name='material_id' hidden rules={[{ required: (offeritem.order_type === "material"), message: 'Lütfen Malzeme Seçiniz!' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['urun_kodu', `${0}`]}  style={{ display: 'inline-block', width: 'calc(20% - 5px)' }} label={messages["product.modelcode"]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['urun_kodu', `${1}`]} style={{ display: 'inline-block', width: 'calc(20% - 5px)',marginLeft:'5px' }} label={messages["product.pcb"]}>
                      <Input />
                    </Form.Item>
                    <Form.Item 
                      name={['urun_kodu', `${2}`]} 
                      style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.optik"]} 
                      rules={[{ required: (offeritem.order_type === "product" && category<7), message: 'Lütfen Optik Regini Seçiniz!' }]}>
                      <Select options={optikList} allowClear />
                    </Form.Item>
                    <Form.Item name={['urun_kodu', `${3}`]} style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.cct"]} rules={[{ required: (offeritem.order_type === "product" && category<7) , message: 'Lütfen Işık Rengi Seçiniz.!' }]}>
                      <Select options={CCTList} allowClear />
                    </Form.Item>
                    <Form.Item name={['urun_kodu', `${4}`]} style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.panel"]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['urun_kodu', `${5}`]} style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.battery"]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{marginTop:0,marginBottom:10}}/>
                <Form.Item 
                  name={['urun_kodu', `${6}`]}  
                  style={{ display: 'inline-block', width: 'calc(35% - 5px)' }} 
                  label={messages["product.bodycolor"]}
                  rules={[
                    {required:( offeritem.order_type==="product" && category<7),message: 'Gövde Rengini yazın !'},
                    () => ({
                      validator(_, value) {
                        if (value && value.toLowerCase() === "standar" ||value && value.toLowerCase() === "standa" || "standart" === value &&  value.toLowerCase()) {
                          return Promise.reject(new Error('Standart diye RENK yok Silver Gri yazın!'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                <Input />
                </Form.Item>
                <Form.Item name={['urun_kodu', `${7}`]} style={{ display: 'inline-block', width: 'calc(35% - 5px)',marginLeft:'5px' }} label={messages["product.ledmark"]}>
                  <Input />
                </Form.Item>
                <Form.Item name={['urun_kodu', `${8}`]} style={{ display: 'inline-block', width: 'calc(30% - 5px)',marginLeft:'5px' }} label={messages["product.leddriver"]}>
                  <Input />
                </Form.Item>
                <Divider style={{marginTop:0,marginBottom:10}}/>
                <Form.Item name="urun_miktari" style={{ display: 'inline-block', width: 'calc(15% - 5px)' }} label={messages["genel.qty"]} rules={[{ required: true, message: 'Please input Product Qty!' },{type:'number'}]}>
                  <InputNumber decimalSeparator=',' />
                </Form.Item>
                <Form.Item name="urun_birimi" style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["genel.unit"]}>
                  <Select options={UnitList.map(x=>{return({label:messages[x.label],value:x.value})})}/>
                </Form.Item>
                <Tooltip title={TLPrice+" ₺"}>
                  <Form.Item name="urun_fiyati" style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["genel.price"]} rules={[{ required: true, message: 'Please input Product Price!' }]}>
                    <InputNumber decimalSeparator=',' />
                  </Form.Item>
                </Tooltip>
                <Form.Item name="urun_dovizi" style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["genel.doviz"]}>
                  <Select options={CurrencyList} />
                </Form.Item>
                <Form.Item name="urun_kdv" style={{ display: 'inline-block', width: 'calc(20% - 5px)',marginLeft:'5px' }} label={messages["genel.kdv"]}>
                  <Select options={KDVList} />
                </Form.Item>
                <Form.Item name="toplamDoviz"  style={{ display: 'inline-block', width: 'calc(20% - 5px)',marginLeft:'5px' }} label={messages["genel.totalprice"]}>
                  <InputNumber readOnly addonAfter={offer.teklif_dovizi}  decimalSeparator=','/>
                </Form.Item>






















            </Form>
 
        </FormWrapper>
    </Modal>

  </>
  );
}

export  {OfferRowModal};
