import {
	DELIVERY_DETAILS_ACTION,
	DELIVERY_DETAILS_SUCCESS,
    DELIVERY_DETAILS_ERROR,
	CREATE_DELIVERY_ACTION,
	CREATE_DELIVERY_SUCCESS, 
	CREATE_DELIVERY_ERROR,
	UPDATE_DELIVERY_ACTION,
	UPDATE_DELIVERY_SUCCESS, 
	UPDATE_DELIVERY_ERROR,
	DELETE_DELIVERY_ACTION,
	DELETE_DELIVERY_SUCCESS,
	DELETE_DELIVERY_FAILURE

} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';
// Get - delivery Details ****************************************************************************************************************************************************
export const delivery_details_pending = () => ({
	type: DELIVERY_DETAILS_ACTION
});

export const delivery_details_success = (res) => ({
	type: DELIVERY_DETAILS_SUCCESS,
	response: res
});

export const delivery_details_failure = (res) => ({
	type: DELIVERY_DETAILS_ERROR,
	response:res
});

export const getdeliveryDetails = (id) => dispatch =>{
	const url = `/deliveried/${id.id}`
	dispatch(delivery_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(delivery_details_success(res.data))
                resolve(true);
            } else {
				dispatch(delivery_details_failure());
                reject();
            }
        })
        .catch((err) => {
		
            dispatch(delivery_details_failure(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
            reject(err);
            
        });
    });
};

// update DeliveryDetails ****************************************************************************************************************************************************
export const update_delivery_details_pending = () => ({
	type: UPDATE_DELIVERY_ACTION,
});

export const update_delivery_details_successful = (res) => ({
	type: UPDATE_DELIVERY_SUCCESS,
	response: res
});

export const update_delivery_details_failed = (res) => ({
	type: UPDATE_DELIVERY_ERROR,
	response:res
});

export const updateDelivery = (data) => dispatch => {
    const url = `/deliveried/update/${data._id}`
	dispatch(update_delivery_details_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,        
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_delivery_details_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_delivery_details_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(update_delivery_details_failed(err.response.status));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){
				
			}
			reject(err);
		});
	});
}

// POST - delivery Create ****************************************************************************************************************************************************
export const create_delivery_pending = () => ({
	type: CREATE_DELIVERY_ACTION
});

export const create_delivery_success = (res) => ({
	type: CREATE_DELIVERY_SUCCESS,
	response: res
});

export const create_delivery_failure = (res) => ({
	type: CREATE_DELIVERY_ERROR,
	response: res
});

export const createDelivery = (data) => dispatch => {
    const url = `/deliveried/create/`
	dispatch(create_delivery_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_delivery_success(res.data));
				resolve(true);
			} else {
				dispatch(create_delivery_failure(err.response));

				reject(res);
			};
		})
		.catch((err) => {
			dispatch(create_delivery_failure(err.response));

			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}

// delete delivery ****************************************************************************************************************************************************
export const delete_delivery_pending = () => ({
	type: DELETE_DELIVERY_ACTION,
});

export const delete_delivery_successful = (res) => ({
	type: DELETE_DELIVERY_SUCCESS,
	response:res
	
});

export const delete_delivery_failed = (res) => ({
	type: DELETE_DELIVERY_FAILURE,
	response:res
});

export const deleteDelivery = (delivery) => dispatch => {
	const url = `/deliveried/delete/${delivery._id}`
	dispatch(delete_delivery_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + url, delivery,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_delivery_successful(res.data));           
				resolve(true);
			} else {
				dispatch(delete_delivery_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_delivery_failed(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}


