import {
	EMPLOYEE_FILE_DETAILS_ACTION,
	EMPLOYEE_FILE_DETAILS_SUCCESS,
    EMPLOYEE_FILE_DETAILS_ERROR,
	CREATE_EMPLOYEE_FILE_ACTION,
	CREATE_EMPLOYEE_FILE_SUCCESS,
	CREATE_EMPLOYEE_FILE_ERROR,
	UPDATE_EMPLOYEE_FILE_ACTION,
	UPDATE_EMPLOYEE_FILE_SUCCESS,
	UPDATE_EMPLOYEE_FILE_FAILURE,
	DELETE_EMPLOYEE_FILE_ACTION,
	DELETE_EMPLOYEE_FILE_SUCCESS,
	DELETE_EMPLOYEE_FILE_FAILURE
} from '../actions';

const INIT_STATE = {
	employeeFileDetail: {},
	employeeFileDetailStatus:"",
	employeeFileDetailLoading:false
};

const  employeFileDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case EMPLOYEE_FILE_DETAILS_ACTION: //GET OFFER DETAILS
			return { 
				...state, 
				employeeFileDetailLoading: true,
				employeeFileDetailStatus:null
			};
		case EMPLOYEE_FILE_DETAILS_SUCCESS:
            return { 
				...state,
				employeeFileDetailLoading: false,  
                employeeFileDetail: action.response
			};
		case EMPLOYEE_FILE_DETAILS_ERROR:
            return { 
				...state, 
				employeeFileDetailLoading: false,
				employeeFileDetailStatus:action.response
			};	
            	
		case CREATE_EMPLOYEE_FILE_ACTION: //CREATE MODEL
			return { 
				...state, 
				employeeFileDetailLoading: true,
				employeeFileDetailStatus:null
			};
		case CREATE_EMPLOYEE_FILE_SUCCESS:
			return { 
				...state, 
				employeeFileDetailLoading: false, 
				employeeFileDetail: action.response 
			};
		case CREATE_EMPLOYEE_FILE_ERROR:
			return { 
				...state, 
				employeeFileDetailLoading: false,
				employeeFileDetail:{},
				employeeFileDetailStatus:action.response 
			};	
		case UPDATE_EMPLOYEE_FILE_ACTION: //UPDATE MODEL
			return { 
				...state, 
				employeeFileDetailLoading: true,
				employeeFileDetailStatus:null
			 };
		case UPDATE_EMPLOYEE_FILE_SUCCESS: 
			return { 
				...state, 
				employeeFileDetailLoading: false, 
				employeeFileDetail: action.response,
				employeeFileDetailStatus:200 
			};
		case UPDATE_EMPLOYEE_FILE_FAILURE:
			return { 
				...state, 
				employeeFileDetailLoading: false,
				status:action.response 
			}; 
		case DELETE_EMPLOYEE_FILE_ACTION: //DELETE MODEL
			return { 
				...state, 
				employeeFileDetailLoading: true,
				employeeFileDetailStatus:null
			};
		case DELETE_EMPLOYEE_FILE_SUCCESS:
			return { 
				...state, 
				employeeFileDetailLoading: false,
				employeeFileDetailStatus:200
			};
		case DELETE_EMPLOYEE_FILE_FAILURE:
			return { 
				...state, 
				employeeFileDetailLoading: false,
				employeeFileDetailStatus:action.response
			};
		default: return state;
	}
}
export default employeFileDetails;