import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRefresh,faHome,faPrint, faX ,faTrash,faSquareArrowUpRight} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
//import CreateProject from './overview/CreateProject';
import { MenuListWrapper,ListWrapper,SearchListSidebar,ListContent,ListTableRow} from '../Style/style';
import {Row,Col,Spin,Button,Input,Popconfirm,notification,Popover,Tag} from 'antd';
import { Main } from '../Style/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import blank from '../../static/img/resyok.jpeg';
import {getMaterialsList} from '../../redux/materials/materialsActions';
import {currencyFormatter} from '../../helpers/currencyFormat';
import { Scrollbars } from 'react-custom-scrollbars';
import appActions from '../../redux/app/actions';
import {ListMenu} from "../commonComponent/listMenu";
const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ""
}
const NumberOptions = {
  significantDigits: 1,
  thousandsSeparator: '.',
}
const  Materials =()=> {

  const {materialList,materialListLoading,materialListStatus} = useSelector((state) => state.materials);
  const [categoryList, setCategoryList] = React.useState();
  const [groupList, setGroupList] = React.useState();
  const [dataSource, setDataSource] = React.useState(materialList);
  const {materialFilter} = useSelector(state => state.App);
  const [groupFilter, setGroupFilter] = React.useState("Tümü");
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const { messages } = useIntl();
  const dispatch = useDispatch(); 
  let history = useHistory();
  const { setMaterialFilter } = appActions;
  const {tcmb} = useSelector(state => state.tcmb);

  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const USDAlis = tcmb && tcmb.Data[0].ForexBuying;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const EURAlis = tcmb && tcmb.Data[1].ForexBuying;
  const [state, setState] = React.useState({
    visible: false,
    collapsed: false,
    totalSumTL:0
  });
  const buttonList = [
    <div key={0}>
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>           
      <Popover content= {messages["button.print"]} placement="bottom">
        <Button shape="circle"  size='large' icon={<FontAwesomeIcon icon={faPrint} />} style={{marginRight:'10px'}}/>
      </Popover> 
      <Popover content= {messages["sidebar.dashboard"]} placement="bottom">
        <NavLink key={4} to="/admin" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faHome}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  const { collapsed } = state;
  const left = !rtl ? 'left' : 'right';
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    let  Filter = [];
    [...new Map(materialList.map(x=> [x.material_category,x])).values()].forEach(row => {
      Filter.push({
          label: row.material_category,
          value: row.material_category,
      });
    });
    setCategoryList(Filter);
    Filter =[];
    [...new Map(materialList.map(x=> [x.material_group,x])).values()].forEach(row => {
      Filter.push({
          label: row.material_group,
          value: row.material_group,
      });
    });
    setGroupList(Filter)

    setDataSource(materialList.sort((a, b) => a.material_name.localeCompare(b.material_name)))

  }, [materialList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(materialFilter==="Tümü"){
      dispatch(getMaterialsList('list',null));
    }else{
      dispatch(getMaterialsList('category',materialFilter));
    }
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(materialListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [materialListStatus]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = search => {
    let filteredList =[]; 
    filteredList =  materialList.filter(
        item => {return item.material_name.toLowerCase().includes(search.toLowerCase()) || (item.material_category.toLowerCase().includes(search.toLowerCase())) })
    if (search===""){
      setDataSource(materialList.sort((a, b) => a.material_name.localeCompare(b.material_name)))
    }else{
      setDataSource(filteredList.sort((a, b) => a.material_name.localeCompare(b.material_name)))
    }
  };
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      dispatch(getMaterialsList('list',null));
      setGroupList([]);
      setGroupFilter("Tümü");
    }else{
      dispatch(getMaterialsList("category",filterVal));
    }
    dispatch(setMaterialFilter(filterVal));
    setState({
      ...state,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
      let totalSumTL = 0 
      dataSource.map(data=>{
        const { material_name, material_category, material_group, material_price, material_doviz,stockSum,material_unit,_id,material_img,stockPriceDoviz,stockPriceSum } = data;
        let TLprice = Number(material_doviz==="₺" ? Number(material_price).toFixed(2) : material_doviz==="€" ? Number(material_price*EURSatis ).toFixed(2) : Number(material_price *USDSatis).toFixed(2));
        totalSumTL = totalSumTL + (TLprice*(stockSum ?stockSum:0 ))
      })
      setState({
        ...state,
        totalSumTL:totalSumTL
      })
  }, [dataSource]);
  //******************************************************************************************************************************************************************/
  const onChangeGroupFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      setDataSource(materialList.sort((a, b) => a.material_name.localeCompare(b.material_name)));
      setGroupFilter("Tümü");
    }else{
      setDataSource(materialList.filter(x=>x.material_group===filterVal).sort((a, b) => a.material_name.localeCompare(b.material_name)));
      setGroupFilter(filterVal);
    }
    setState({
      ...state,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const NewMaterials = () => {
    history.push("/admin/materialdetails/new")
  }
  //******************************************************************************************************************************************************************/
  const refreshDataSource = () => {
    if(materialFilter==="Tümü"){
      dispatch(getMaterialsList('list',null));
    }else{
      dispatch(getMaterialsList('category',materialFilter));
    }
  }

  //******************************************************************************************************************************************************************/
  return (
  
    <>
      <PageHeader
        title={messages["sidebar.materials"]}
        buttons={buttonList}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
                <ListMenu 
                  MenuList={categoryList} 
                  MenuList2={groupList} 
                  messages={messages} 
                  activeFilter={materialFilter} 
                  activeFilter2={groupFilter} 
                  newData={NewMaterials}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  onChangeFilter={onChangeFilter}
                  onChangeFilter2={onChangeGroupFilter}
                />
            </Col>

            <Col xxl={18} xl={17} lg={16} xs={24}>
              <SearchListSidebar>
                <Cards headless>
                  <div className="chatbox-search">
                    <Input  style={{width:'100%'}} onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                  </div>
                  <Spin spinning={materialListLoading} delay={100}>
                    <ListTableRow>
                      <Row gutter={1}>
                        <Col xxl={2} lg={2} md={2} sm={1} xs={1}>
                          <div className="material-column-center">
                            <h1>X</h1>
                          </div>
                        </Col>
                        <Col xxl={12} lg={12} md={12} sm={23} xs={23}>
                          <div className="material-column">
                            <h1>{messages["material.material"]}</h1>
                          </div>
                        </Col>
                        <Col xxl={3} lg={3} md={3} sm={3} xs={23}>
                          <div className="material-column-right">
                            <h1>{messages["genel.price"]}</h1>
                          </div>
                        </Col>
                        <Col xxl={4} lg={4} md={4} sm={12} xs={12}>
                          <div className="material-column-right">
                            <h1  style={{color:"green"}}>{currencyFormatter((state.totalSumTL),defaultOptions)} {"₺"}</h1>
                          </div>
                        </Col>
                        <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
                          <div className="material-column-right">
                            <h1>{messages["genel.stocksum"]}</h1>
                          </div>
                        </Col>
                      </Row>
                    </ListTableRow>

                    <ListContent style={{height:'665px'}}>
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={renderThumbHorizontal}
                        renderThumbVertical={renderThumbVertical}
                        renderView={renderView}
                        renderTrackVertical={renderTrackVertical}
                      >
                        { 
                          dataSource && dataSource.length ?
                          dataSource
                          .sort((a, b) => {
                            return b.material_category - a.material_category;
                          })
                          .map((material, key) => {
                            const { material_name, material_category, material_group, material_price, material_doviz,stockSum,material_unit,_id,material_img,stockPriceDoviz,stockPriceSum } = material;
                            const content = (
                              <div>
                                <img src={material_img ? material_img : blank} alt="" />
                              </div>
                            );
                            let stockcolor = stockSum<0 ? "red":stockSum=== 0  ? "black":"green"
                            let TLPrice = Number(material_doviz==="₺" ? Number(material_price).toFixed(2) : material_doviz==="€" ? Number(material_price*EURSatis ).toFixed(2) : Number(material_price *USDSatis).toFixed(2));
                            return (
                              <ListTableRow key={key}>
                                <Row gutter={1}>
                                  <Col xxl={2} lg={2} md={2} sm={24} xs={24}>
                                    <div className="material-column-center">
                                      <Popover placement="right"  content={content}>
                                        <img src={material_img ? material_img : blank} alt="" width={40}/>
                                      </Popover>
                                    </div>
                                  </Col>
                                  <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                                    <div className="material-column">
                                    <NavLink to={`/admin/materialdetails/${_id}`}>
                                      <h1>{material_name}</h1>
                                    </NavLink>
                                      <h1>{material_category +" / "+material_group}</h1>
                                    </div>
                                  </Col>
                                  <Col xxl={3} lg={3} md={3} sm={12} xs={12}>
                                    <div className="material-column-right">
                                      <h1>{currencyFormatter(material_price,defaultOptions)} {material_doviz}</h1>
                                      <h1>{currencyFormatter(TLPrice,defaultOptions)} {"₺"}</h1>
                                    </div>
                                  </Col>
                                  <Col xxl={4} lg={4} md={4} sm={12} xs={12}>
                                    <div className="material-column-right">
                                      <h1  style={{color:stockcolor}}>{currencyFormatter(stockPriceSum,defaultOptions)} {stockPriceDoviz}</h1>
                                      <h1  style={{color:stockcolor}}>{currencyFormatter((TLPrice && stockSum ? TLPrice*stockSum:0),defaultOptions)} {"₺"}</h1>
                                    </div>
                                  </Col>
                                  <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
                                    <div className="material-column-right">
                                      <h1 style={{color:stockcolor}}>{currencyFormatter(stockSum,NumberOptions)} </h1>
                                      <h1  style={{color:stockcolor}}>{material_unit} </h1>
                                    </div>
                                  </Col>
                                </Row>
                              </ListTableRow>
                            );
                          }):
                          null
                        }
                      </Scrollbars>
                    </ListContent>
                  </Spin>
                </Cards>
              </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 

    </>
  );
}



export  {Materials};
