import React from 'react';
import { Page, Document, StyleSheet, Font, View,Image } from '@react-pdf/renderer';
//************************************************ */
import Header from '../../common/reportHeaders/Header1';
import Buyer from './Buyer';
import Title from './Title';
import OfferInfo from './OfferInfo';
import OfferRowsLabel from './OfferRowsLabel';
import OfferRows from './OfferRows';
import OfferTotals from './OfferTotals';
import OfferNotes from './OfferNotes';

import OpenSansFont from "../../font/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../../font/ClearSans/ClearSans-Regular.ttf";
import SourceSansPro from "../../font/SourceSansPro-It.ttf"


Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });
Font.register({ family: 'SourceSansPro', src: SourceSansPro  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFontBold',
        paddingTop: 20,
        paddingLeft:20,
        paddingRight:20,
        flexDirection: 'column'
    }, 
    ViewContainer: {
        marginTop: 1,
        flexDirection: "row",
    },     
    RowContainer: {
        marginTop: 2,
        marginBottom: 3,
        flexDirection: "column",
        borderBottomWidth: 1,
        width:550
    }, 
    SignContainer: {
        marginLeft: 350,
        flexDirection: "row",
        position:"absolute",
        marginTop:440,
    }, 
    image: {
        width: 170,
        height: 120,
        marginLeft: '0',
        
        
    },  
});

const Invoice = ({data,company,Lang,messages}) =>{
    console.log("Geldi",data)

    return (
    <Document>
        <Page size="A4"  style={styles.page}>
            <Header company={company} messages={messages}/> 
            <Title  data={data}messages={messages}/>
            <View style={styles.ViewContainer}>
                  <Buyer data={data}  messages={messages}/>
                 <OfferInfo data={data} messages={messages}/> 
            </View>
            
             <OfferRowsLabel messages={messages}/>
             <View style={styles.RowContainer}>
                {data && data.offeritem  &&  data.offeritem.length ? data.offeritem.map((a) => {return( <OfferRows messages={messages} Lang={Lang} data={a} doviz={data.teklif_dovizi} key={a._id}/>);}):""}
            </View>  
          <View style={styles.ViewContainer}>
                <OfferNotes data={data} Lang={Lang}/>
                <OfferTotals  data={data} doviz={data.teklif_dovizi} messages={messages}/>
                
            </View>  
             <View style={styles.SignContainer}>
             {data.teklif_kaseimza && <Image style={styles.image} src={company.sirket_kaseimza}/>}  
            </View>
          
           
        </Page>
    </Document>
)};
  
export default Invoice