import {
    GET_DELIVERY_LIST,
    GET_DELIVERY_LIST_SUCCESS,
	GET_DELIVERY_LIST_ERROR,
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from '../auth/actions';
//Product Stock List GET **************************************************************************************************************************************************

export const delivery_list_set_pending = () => ({
	type: GET_DELIVERY_LIST
});

export const delivery_list_set_successful = (res) => ({
	type: GET_DELIVERY_LIST_SUCCESS,
	response: res
});

export const delivery_list_set_failed = (res) => ({
	type: GET_DELIVERY_LIST_ERROR,
	response:res
});

export const getDeliveryList = (query,value) => dispatch =>{
	const apiUrl_list = `/deliveried/list`;
	dispatch(delivery_list_set_pending());
	return new Promise((resolve, reject) => { 
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?query=${query}&value=${value}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(delivery_list_set_successful(res.data));
				//console.log(res.data)
				resolve(true);
			} else {
				dispatch(delivery_list_set_failed());
			}
		})
		.catch((err) => {
			dispatch(delivery_list_set_failed(err.response.status));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});
};

