import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';

import WebLayout from '../layout/withWebLayout';

import {Products} from '../pages/web/products/Products';
import {About} from '../pages/web/about/about';
import {ProductDetails} from '../pages/web/products/ProductDetails';

function NotFound() {
  return <Redirect to="/web/products" />;
}

function FrontendRoutes() {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/web/products" component={Products} />
        <Route exact path="/web/productdetails/:productID" component={ProductDetails} />
        <Route exact path="/web/about" component={About} />
        {/* <Route  path="/web/ordertrack/:offerID/:companyID/:token" component={OrderTrack} /> */}

        <Route exact path="/web/" component={NotFound} />
      </Suspense>
    </Switch>
  );
}

export default WebLayout(FrontendRoutes);
