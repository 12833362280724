
import React from 'react';
import { Modal,Button ,Form,Input,Radio,Row,Col,Space} from 'antd';
import { FormWrapper } from '../../Style/style';

const OrderInfoModal = ({messages,order,toggleModal,orderInfoModalActive,onChangeOrderInfo}) => {
  const [form] = Form.useForm();
  return (
    <>
      {orderInfoModalActive &&
      <Modal
        forceRender
        type='primary'
        title={messages["production.details"]}
        open={orderInfoModalActive}
        footer={              
        <div>
          <Button size="large" type="white" onClick={() => toggleModal("orderInfoModalActive",null)}  >
            {messages["button.close"]}
          </Button>
        </div>}
        closable={false}
        width={600}
      >
        <FormWrapper style={{height:'350px'}}>
          <Form form={form} >
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.type"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item  rules={[{ required:true}]} >
                  <Radio.Group buttonStyle="solid" onChange={(eData) => onChangeOrderInfo("order_type",eData.target.value)} defaultValue={order.order_type}>
                    <Radio.Button value="product">{messages["product.name"]}</Radio.Button>
                    <Radio.Button value="material">{messages["material.material"]}</Radio.Button>
                    <Radio.Button value="default">{messages["product.default"]}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["genel.type"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item  rules={[{ required:true}]} >
                  <Radio.Group size='small' buttonStyle="solid" onChange={(eData) => onChangeOrderInfo("siparis_durumu",eData.target.value)} defaultValue={order.siparis_durumu}>
                    <Space direction="vertical">
                      <Radio.Button value={1}>{messages["order.orderStatus1"]}</Radio.Button>
                      <Radio.Button value={2}>{messages["order.orderStatus2"]}</Radio.Button>
                      <Radio.Button value={3}>{messages["order.orderStatus3"]}</Radio.Button>
                      <Radio.Button value={4}>{messages["order.orderStatus4"]}</Radio.Button>
                      <Radio.Button value={5}>{messages["order.orderStatus5"]}</Radio.Button>
                      <Radio.Button value={6}>{messages["order.orderStatus6"]}</Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col  xxl={8} xl={8} lg={8} md={8} xs={24}>
                <label htmlFor="input-text">{messages["product.name"]}</label>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} xs={24}>
                <Form.Item  rules={[{ required:true}]} >
                  <Input onChange={(eData) => onChangeOrderInfo("urun_adi",eData.target.value)} value={order.urun_adi}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Modal>}
    </>

  );

}



export  {OrderInfoModal};
