import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus,faEdit,faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,Spin} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';

const EntryControl = ({loading,messages,data,toggleModal,removeEntryControlRow,addNewEntryControlRow}) => {
  let Question = data && data.QCQuestions.sort((a, b) => a.no - b.no).map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
    <>
      {Question.length < 8 && 
      <NavLink to="#" onClick={()=>addNewEntryControlRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>}
    </>
  );

  const EntryControl = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no', 
      align: 'left',
      render: (text) =>text,
      width: '10%',
    },
    {
      title: messages["buying.qcQuestion"],
      dataIndex: 'Question',
      key: 'Question', 
      align: 'left',
      render: (text) =>text,
      width: '70%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("entryControlRowModalActive",true,record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeEntryControlRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];

  return (
    <div className="full-width-table">
      <Cards
        title={ <Heading as="h5">{messages["buying.qcQuestion"]}</Heading>}
        bodypadding="0px"
        more={moreContent}
        loading={loading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'670px'}}>
          <Table 
            columns={EntryControl} 
            dataSource={Question} 
            pagination={false} 
          />
        </div>
      </Cards>
    </div>
  );
}

export {EntryControl};
