import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash} from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,Tag} from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';



const MechanicalInfo = ({mechanicalInfo,messages,productDetailLoading,lang}) => {
  let info = mechanicalInfo && mechanicalInfo.length && mechanicalInfo.map((el, idx) => ({key: idx, ...el}))

  
  const mechanicalInfoColumn = [
    {
      title: [messages["genel.sira"]],
      dataIndex: 'key',
      key: 'key',
      align: 'left',
      render: (text) => 
      <div style={{display:'flex',flexDirection:'column'}}>
        {text+1}
      </div>,
      width: '10%',
    },
    {
      title: [messages["model.label"]],
      dataIndex: 'label',
      key: 'label',
      align: 'left',
      render: (text) => 
      <div style={{display:'flex',flexDirection:'column'}}>
        {text[lang]}
      </div>,
      width: '35%',
    },
  
    {
      title: [messages["product.specifications"]]+" TR/EN",
      dataIndex: 'value',
      key: 'value',
      align: 'left',
      render: (text) =>    
      <div style={{display:'flex',flexDirection:'column'}}>
        {text[lang]}
      </div>,
      width: '55%',
    }
  ];

  return (
      <div className="table-bordered top-seller-table table-responsive">
        <Table 
          columns={mechanicalInfoColumn} 
          dataSource={info} 
          pagination={false} 
          loading={productDetailLoading}
          //scroll={{ y: 660 }}
        />
      </div>
  );
}

export {MechanicalInfo};
