import React from 'react';
import { Row, Col, Form, Input,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import CustomInput from '../../../helpers/customInput';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const  Profile = ({data,loading,messages,updateData}) => {
const [form] = Form.useForm();
React.useEffect(() => {
  form.setFieldsValue(data)
 }, [data])      

 const values = Form.useWatch([], form);
 const [submittable, setSubmittable] = React.useState(false);
 React.useEffect(() => {
   form.validateFields({ validateOnly: true }).then(
     () => {
       setSubmittable(true);
     },
     () => {
       setSubmittable(false);
     },
   );
 }, [values]);

  return (
    <Cards
      title={<Heading as="h5">{messages["user.editprofile"]}</Heading>}
      loading={loading && <Spin indicator={faSpinner}  />}
      bodyStyle={{ minHeight: '730px' }}
    >
      <FormWrapper>
        <Spin spinning={loading} delay={100}>
          <Form form={form} initialValues={data}>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["user.firstname"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="firstname" rules={[{ required: true, message: 'Please input your firtname!' }]} >
                    <Input onChange={(eData)=>updateData("firstname",eData.target.value)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["user.lastname"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="lastname" rules={[{ required: true, message: 'Please input your lastname!' }]} >
                    <Input  onChange={(eData)=>updateData("lastname",eData.target.value)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["user.email"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="email" rules={[{required: true, message: 'Please input your email!', type: 'email' }]}>
                    <Input placeholder="name@example.com"  onChange={(eData)=>updateData("email",eData.target.value)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["company.mobilephone"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="mobile" >
                    <CustomInput mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("mobile",eData.target.value)}/>
                  </Form.Item>
                </Col>
              </Row>
          </Form>
        </Spin>
      </FormWrapper>
    </Cards>
  );
}
export {Profile};
