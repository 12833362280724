import React from 'react';
import { Row, Col, Form, Input,Spin } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import CustomInput from '../../../helpers/customInput';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const { TextArea } = Input;
const  Contact = ({data,employeeDetailLoading,messages,updateData,setSubmittable}) => {
const [form] = Form.useForm();

React.useEffect(() => {
  form.setFieldsValue(data)
 }, [ data])      
 const values = Form.useWatch([], form);

 React.useEffect(() => {
   form.validateFields({ validateOnly: true }).then(
     () => {
       setSubmittable(true);
     },
     () => {
       setSubmittable(false);
     },
   );
 }, [values]);

  return (
    <Cards
      title={<Heading as="h5">{messages["employee.contact"]}</Heading>}
      loading={employeeDetailLoading && <Spin indicator={faSpinner}  />}
      bodyStyle={{ minHeight: '730px' }}
    >
      <FormWrapper>
        <Form name="editProfile" form={form} initialValues={data}>
          <Row align="middle">
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["company.mobilephone"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name="mobileno" rules={[{ required: true, message: 'Please input your IDNO !' }]}>
                <CustomInput  mask="+(99) 999 999 99 99" placeholder="+90 (___) ___-__-__" onChange={(eData)=>updateData("mobileno",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle">
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["user.email"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name="email"  rules={[{ type: 'email' }]}>
                <Input placeholder="name@example.com" onChange={(eData)=>updateData("email",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle">
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["company.address"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name="adress"  rules={[{ type: 'email' }]}>
                <TextArea rows={3} onChange={(eData)=>updateData("adress",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle">
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["company.notes"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name="notes" >
                <TextArea rows={3} onChange={(eData)=>updateData("notes",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </Cards>
  );
}

export {Contact};
