import {
	TCMB_ACTION,
	TCMB_SUCCESS,
	TCMB_ERROR
} from '../actions';
import axios from 'axios';
//TCMB KUR GET ****************************************************************************************************************************************************
export const get_tcmb_pending = () => ({
	type: TCMB_ACTION
});

export const get_tcmb_successful = (res) => ({
	type: TCMB_SUCCESS,
	response: res
});

export const get_tcmb_failed = (response) => ({
    type: TCMB_ERROR,
    response:response
});

export const getTcmbData = (date) => dispatch =>{
	if (date){
		
	}else{
		
	}
	const url = `/tcmb/today`;
    dispatch(get_tcmb_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url)
        .then(res => {
            if (res.status === 200) {
				dispatch(get_tcmb_successful(res.data));
                resolve(true);
            } else {
                dispatch(get_tcmb_failed());
                reject();
            }
        })
        .catch((err) => {
            dispatch(get_tcmb_failed(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
            
            }
            reject(err);
        });
    });
};