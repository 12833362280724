import React from 'react';
import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';

//************************************************ */
import Header from './Header'
import Title from './Title'
import Rows from './Rows'

import OpenSansFont from "../font/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../font/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFont',
        paddingTop: 25,
        paddingLeft:20,
        paddingRight:20,
        paddingBottom: 24,
        flexDirection: 'column'
    }, 
    ViewContainer: {
        marginTop: 1,
        flexDirection: "row",
    },     
    RowContainer: {
        marginTop: 2,
        marginButtom: 3,
        flexDirection: "column",
        borderBottomWidth: 1,
        borderColor:"blue",
        width:'550px'
    }, 
    SignContainer: {
        marginLeft: 252,
        flexDirection: "row"
    }, 
    image: {
        width: '170px',
        height: '120px',
        marginLeft: '0',
    },  
  });

const productionList = ({data,company}) =>{
    const  datas = data;
    //console.log(datas)
    return(
    
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <Header company={company} fixed/> 
                    <Title data={data}/>
                    <View style={styles.RowContainer}>
                        {
                        datas ? datas.map((a,y) => {return( <Rows key={y} data={a} />);}): "" 
                        } 
                    </View>

                </Page>
            </Document>
    

)};
  
export default productionList