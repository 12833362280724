import React from 'react';
import { Input, Form, Modal,Button,Row,Col, Divider } from 'antd';
import { FormWrapper } from '../../Style/style';
const SpecModal = ({ specification,specModalActive,updateSpecRow,messages,closeModal}) => {
  const [form2] = Form.useForm();
  React.useEffect(() => {
    form2.setFieldsValue(specification)
   }, [specification])

  return (
  <>
  {specModalActive && specification &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={specModalActive}
      footer={            
      <Button  size="large" type="primary" onClick={() => closeModal("specModalIndex",null)} >
        {messages["button.close"]}
      </Button>
      }
      onCancel={() => closeModal("specModalIndex",null)}
      forceRender
      closable={false}
    >
       <FormWrapper style={{height:'auto'}}>
        <Form form={form2}  initialValues={specification} >
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["genel.sira"]}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name='no'>
                <Input type='number' onChange={(eData)=>updateSpecRow("no",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["model.label"] + " TR"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['label', `${0}`]}>
                <Input onChange={(eData)=>updateSpecRow("label",eData.target.value,0)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["model.label"]+ " EN"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['label', `${1}`]}>
                <Input onChange={(eData)=>updateSpecRow("label",eData.target.value,1)}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["model.spec"]+ " TR"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['feature', `${0}`]}>
                <Input onChange={(eData)=>updateSpecRow("feature",eData.target.value,0)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <label htmlFor="input-text">{messages["model.spec"]+ " EN"}</label>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name={['feature', `${1}`]}>
                <Input onChange={(eData)=>updateSpecRow("feature",eData.target.value,1)}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {SpecModal};
