import {
	OFFER_DETAILS_ACTION,
	OFFER_DETAILS_SUCCESS,
	OFFER_DETAILS_ERROR,
	CREATE_OFFER_ACTION,
	CREATE_OFFER_SUCCESS,
	CREATE_OFFER_ERROR,
	UPDATE_OFFER_ACTION,
	UPDATE_OFFER_SUCCESS,
	UPDATE_OFFER_FAILURE,
	DELETE_OFFER_ACTION,
	DELETE_OFFER_SUCCESS,
	DELETE_OFFER_FAILURE,
	
} from '../actions';

const INIT_STATE = {
	offerDetails: {},
	offerDetailLoading:false,
	offerDetailStatus:null
};

const offerDetails =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case OFFER_DETAILS_ACTION: //GET OFFER DETAILS
			return { 
				...state, 
				offerDetailLoading: true 
			};
		case OFFER_DETAILS_SUCCESS:
            return { 
				...state,
				offerDetailLoading: false,  
                offerDetails: action.response
			};
		case OFFER_DETAILS_ERROR:
            return { 
				...state, 
				offerDetailLoading: false,
				offerDetailStatus: action.response 
			};	   	
		case CREATE_OFFER_ACTION: //CREATE OFFER
			return { 
				...state, 
				offerDetailLoading: true 
			};
		case CREATE_OFFER_SUCCESS:
			return { 
				...state, 
				offerDetailLoading: false, 
				offerDetails: action.response 
			};
		case CREATE_OFFER_ERROR:
			return {
				 ...state, 
				 offerDetailLoading: false,
				 offerDetailStatus: action.response 
				};	
		case UPDATE_OFFER_ACTION: //UPDATE OFFER
			return { 
				...state,
				offerDetailLoading: true 
				};
		case UPDATE_OFFER_SUCCESS:
			return { 
				...state, 
				offerDetailLoading: false, 
				offerDetails: action.response 
			};
		case UPDATE_OFFER_FAILURE:
			return { 
				...state, 
				offerDetailLoading: false ,
				offerDetailStatus: action.response
			};
		case DELETE_OFFER_ACTION: //DELETE OFFER
			return { 
				...state, 
				offerDetailLoading: true 
			};
		case DELETE_OFFER_SUCCESS:
			return { 
				...state, 
				offerDetailLoading: false 
			};
		case DELETE_OFFER_FAILURE:
			return { 
				...state, 
				offerDetailLoading: false, 
				offerDetailStatus: action.response
			}; 
		default: return state;
	}
}
export default offerDetails