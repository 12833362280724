
import React from 'react';
import { useSelector } from 'react-redux';
import {Select, Modal,Button ,Form,Input,DatePicker,Row,Col} from 'antd';
import {PopupChoser} from '../../components/PopupChoser';
import { FormWrapper} from '../Style/style';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const StockRowModal = ({messages,delivery,toggleModal,stockRowModalActive,onSaveStockRow,onChangeStockRowData}) => {
  const {deliveryStatus,deliveryStatusDescription,operationTypeSelect,UnitList,CurrencyList,operationCenterList} = useSelector(state => state.App);
  const {deliveryDetailLoading} = useSelector(state => state.deliveryDetails);
  const [form9] = Form.useForm();
  const values = Form.useWatch([], form9);

  const [submittable, setSubmittable] = React.useState(false);
  React.useEffect(() => {
    form9.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);


  return (
    <>
      {stockRowModalActive &&
      <Modal
        forceRender
        type='primary'
        title={messages["delivery.details"]}
        open={stockRowModalActive}
        footer={                
          <div>
          <Button size="large" type="white" onClick={() => toggleModal("stockRowModalActive",null,null)}  >
            {messages["button.cancel"]}
          </Button>
          <Button  size="large" type="primary" loading={deliveryDetailLoading} disabled={!submittable} onClick={() => onSaveStockRow()} >
            {messages["button.save"]}
          </Button>
        </div>}
        closable={false}
        width={600}
      >
        <FormWrapper style={{height:'auto'}}>
          <Form form={form9} initialValues={delivery}>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.date"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='operation_date' rules={[{ required: true, message: 'Please input Date!' }]}>
                  <DatePicker format="DD/MM/YYYY" onChange={(eData)=>onChangeStockRowData("operation_date",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.name"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='product_id'>
                  <Input readOnly/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["task.operation"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='operation_center' rules={[{ required: true, message: 'Please input Operation  Name!' }]}>
                  <Select options={operationCenterList} onSelect={(eData)=>onChangeStockRowData("operation_center",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["stock.operationtype"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='operation_type' rules={[{ required: true, message: 'Please input Operation type !' }]}>
                  <Select options={operationTypeSelect} onSelect={(eData)=>onChangeStockRowData("operation_type",eData)}/>
                </Form.Item>
              </Col>
            </Row>
            {delivery.operation_center ==="Teslimat" &&
              <>
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["company.name"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                      <Form.Item  rules={[{ required: delivery.operation_center ==="Teslimat" , message: 'Please input Company Name!' }]}  extra={delivery.firma_adi&& delivery.firma_adi}>
                        <Input readOnly value={delivery.company_id} 
                          addonAfter={
                            <>
                              <FontAwesomeIcon icon={faMagnifyingGlass} size='1x' onClick={()=>toggleModal("companyListModalActive",true)} style={{marginRight:'20px'}}/>
                            </>
                          } 
                        />
                      </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col  xxl={6} xl={6} lg={6} md={6} xs={24}>
                    <label htmlFor="input-text">{messages["delivery.deliverer"]}</label>
                  </Col>
                  <Col xxl={18} xl={18} lg={18} md={18} xs={24}>
                    <Form.Item name="deliverer" rules={[{ required: delivery.operation_center ==="Teslimat" , message: 'Please input Deliverer!' }]}>
                      <Input onChange={(eData)=>onChangeStockRowData("deliverer",eData.target.value)} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col  xxl={6} xl={6} lg={6} md={6} xs={24}>
                    <label htmlFor="input-text">{messages["delivery.receiver"]}</label>
                  </Col>
                  <Col xxl={18} xl={18} lg={18} md={18} xs={24}>
                    <Form.Item name="receiver" rules={[{ required: delivery.operation_center ==="Teslimat" , message: 'Please input Receiver!' }]}>
                      <Input onChange={(eData)=>onChangeStockRowData("receiver",eData.target.value)}/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col  xxl={6} xl={6} lg={6} md={6} xs={24}>
                    <label htmlFor="input-text">{messages["delivery.status"]}</label>
                  </Col>
                  <Col xxl={9} xl={9} lg={9} md={9} xs={24}>
                    <Form.Item name="status" >
                      <PopupChoser
                        option={deliveryStatus}
                        choseData={(e)=>onChangeStockRowData('status',e)}
                        selectedData={delivery.status}
                      /> 
                    </Form.Item>
                  </Col>
                  {delivery.status>0 &&
                    <Col xxl={9} xl={9} lg={9} md={9} xs={24}>
                      <Form.Item name="status" >
                        <PopupChoser
                          option={deliveryStatusDescription}
                          choseData={(e)=>onChangeStockRowData('statusdescription',e)}
                          selectedData={delivery.statusdescription}
                        /> 
                      </Form.Item>
                    </Col>
                  }
                </Row>

              </>
            }
            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.qty"]}</label>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name='qty' rules={[{ required: true, message: 'Please input Product Qty!' }]}>
                  <Input type='number' decimalseparator=',' max={delivery && delivery.max && delivery.max}  onChange={(eData)=>onChangeStockRowData("qty",eData.target.value)}/>
                </Form.Item>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name='unit' rules={[{ required: true, message: 'Please input Unit!' }]}>
                  <Select options={UnitList.map(x=>{return({label:messages[x.label],value:x.value})})} onSelect={(eData)=>onChangeStockRowData("unit",eData)}/>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.price"]}</label>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name='price' rules={[{ required: true, message: 'Please input Product Price!' }]}>
                  <Input type='number' decimalseparator=','  onChange={(eData)=>onChangeStockRowData("price",eData.target.value)}/>
                </Form.Item>
              </Col>
              <Col md={9} xs={24}>
                <Form.Item name='doviz' rules={[{ required: true, message: 'Please input Unit!' }]}>
                  <Select options={CurrencyList} onSelect={(eData)=>onChangeStockRowData("doviz",eData)}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Modal>}
    </>

  );

}



export  {StockRowModal};
