import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faPrint, faX} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
//import CreateProject from './overview/CreateProject';
import { ListTableRow,ListRowContentT5,ListContent} from '../../Style/style';
import { TableWrapper} from '../../Style/styled';
import {Table,Row,Col,Tag, Popover} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import {getComponentList} from '../../../redux/componentList/componentListActions';
import {getComponentListSum} from '../../../redux/componentList/componentListActions';
import { Scrollbars } from 'react-custom-scrollbars';


const  ComponentSummary =({height})=> {
  const {componentListSum,componentListSumLoading,componentListSumStatus} = useSelector(state => state.componentList);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    order_id:null,
    componentfilter:'Tümü',
  })
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>dispatch(getComponentList("filter",0,2))}>
        <FontAwesomeIcon icon={faRefresh} />
        <span>{messages["button.refresh"]}</span>
      </NavLink>
      <NavLink to="#">
        <FontAwesomeIcon icon={faPrint} />
        <span>Printer</span>
      </NavLink>
      <NavLink to="#">
        <FontAwesomeIcon size="1x" icon={faX}/>
        <span>PDF</span>
      </NavLink>
  
    </>
  );
  const left = !rtl ? 'left' : 'right';
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {   
    dispatch(getComponentListSum("filter",0,0))
  },[]);
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/

  //******************************************************************************************************************************************************************/
  return (
  
  <Cards 
    title={messages["sidebar.projectMaterialList"] }
    loading={componentListSumLoading}
    more={moreContent}
    //bodyStyle={{ minHeight: "200px" }}
  >
    <ListRowContentT5>
      <Row gutter={10}>
        <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
          <div className="material-column">
            <h1>{messages["material.category"]}</h1>
          </div>
        </Col>
        <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
          <div className="material-column">
            <h1>{messages["material.group"]}</h1>
          </div>
        </Col>
        <Col xxl={6} lg={6} md={6} sm={24} xs={24}>
          <div className="material-column">
            <h1>{messages["material.material"]}</h1>
          </div>
        </Col>              
        <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
          <div className="list-column">
            <h1>{messages["genel.qty"]}</h1>
          </div>
        </Col>
        <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
          <div className="list-column">
            <h1>{messages["genel.stocksum"]}</h1>
          </div>
        </Col>
      </Row>
    </ListRowContentT5>
    <ListContent style={{height:height}}>
      <Scrollbars
        className="custom-scrollbar"
        autoHide
        autoHideTimeout={500}
        autoHideDuration={200}
        renderThumbHorizontal={renderThumbHorizontal}
        renderThumbVertical={renderThumbVertical}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        {componentListSum && componentListSum.length && componentListSum
        .sort((a, b) => {
          return state.componentfilter !== "Tümü" && state.componentfilter>2 &&  b._id.material_category.localeCompare(a._id.material_category);
        })
        .map((sumRow,index)=>{
          const {_id,SumQty,componentList,material} = sumRow;
          const content = (
            <div>
              {componentList.map((order,i)=>{
                return(
                  <NavLink key={i} to={"/admin/projectdetails/"+order.order_id} target="_blank"><h5 > {order.task+" / "+order.material_category+" / "+order.material_group+"  => "+order.totalQty+" Adet" }</h5></NavLink>
                )
              })}
            </div>
          );
          //console.log(sumRow)
            return(
            <ListTableRow key={index}>
              <Row gutter={10}>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <Popover placement="right" title={<h5>{_id.material_category}</h5>} content={content}>
                    <div className="material-column">
                      <h1>{_id.material_category}</h1>
                    </div>
                  </Popover>
                </Col>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <div className="material-column">
                    <h1>{_id.material_group}</h1>
                  </div>
                </Col>
                {material && material.length===1 ? 
                <Col xxl={6} lg={6} md={6} sm={24} xs={24}>
                  <div className="material-column">
                    <h1>{material[0].material_name}</h1>
                  </div>
                </Col>:
                <Col xxl={6} lg={6} md={6} sm={24} xs={24}>
                  <div className="material-column">
                    <Tag className='red'>Malzeme Seçilmemiş</Tag>
                  </div>
                </Col>              
                
                }
                {material && material.length===1 ? 
                <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
                  <div className="material-column-center">
                    <h1>{SumQty + " "+(material[0].stockSum ? material[0].material_unit:"Birim")} </h1>
                  </div>
                </Col>
                :null}
                {material && material.length===1 ? 
                <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
                  <div className="material-column-center">
                    <h1>{(material[0].stockSum ? material[0].stockSum.toFixed(2) : 0 )+" "+(material[0].stockSum ? material[0].material_unit:"Birim") }</h1>
                  </div>
                </Col>:
                <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
                  <div className="material-column-center">
                    <Tag className='red'>Malzeme Seçilmemiş</Tag>
                  </div>
                </Col>
                }
              </Row>
            </ListTableRow>
            )
          })}
        
    </Scrollbars>
   </ListContent> 
  </Cards>

  );
}



export  {ComponentSummary};
