import React from 'react';
import { Input, Form, Modal,Button,Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInbox} from '@fortawesome/free-solid-svg-icons';

const UploadModal = ({ file,uploadModalActive,lang,messages,closeModal,updateImageRow}) => {
  const [form2] = Form.useForm();
  React.useEffect(() => {
    form2.setFieldsValue(file)
   }, [file])
  return (
  <>
  {uploadModalActive  &&
    <Modal
      title={messages["genel.edit"]}
      className="sDash_addTask-modal"
      type='primary'
      open={uploadModalActive}
      footer={null}
      closable={false}
      onCancel={() => closeModal("uploadModalIndex",null)}
      forceRender
    >
      <div className="sDash_addTask-modal-inner">
        <Form form={form2} name="add-task" initialValues={file} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} >
          <Form.Item name="name" label={messages["model.file_name"]}  >
            <Input/>
          </Form.Item>
          <Form.Item name={['file_descirpiption', `${lang}`]} label={messages["model.file_descirpiption"]} >
            <Input onChange={(e)=>updateImageRow('file_descirpiption',e.target.value)} />
          </Form.Item>
          <Form.Item  label={messages["model.file"]} >
            <Upload.Dragger name="files" multiple={false} beforeUpload={(file)=>updateImageRow("file",file)}>
              <p className="ant-upload-drag-icon">
                <FontAwesomeIcon icon={faInbox} size='2x'  />
              </p>
              <p className="ant-upload-text">Tıklayınız veya Sürükle Bırak olarak dosya yükleyiniz..</p>
             
            </Upload.Dragger>
          </Form.Item>



          <div className="sDash-modal-actions">
            <Button  size="small" type="primary" onClick={() => closeModal("uploadModalIndex",null)} >
              {messages["button.save"]}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  }
  </>
  );
}

export  {UploadModal};
