import React from 'react';
import { Tag ,Popover} from 'antd';
import PopupChooserDropdown from './PopupChoser.style';
import {MenuOutlined } from '@ant-design/icons';

export default function ({ option, selectedData, choseData }) {
  const [visible, setVisibility] = React.useState(false);
  function hide() {
    setVisibility(false);
  }
  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }
  const content = () => (
    <PopupChooserDropdown className="isoColorOptions">
      {option.length >0 &&  option.map((option, index) => {
        const onClick = () => {
          hide();
          choseData(option.value)
        };
        return <Tag color={'blue'}key={index} onClick={onClick} style={{cursor:'pointer',marginBottom:'5px'}} >{option.label}</Tag>;
      })}
    </PopupChooserDropdown>
  );
  return (
    <Popover
      content={content()}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      <Tag  style={{cursor:'pointer'}} className={'blue'} icon={<MenuOutlined />}>{selectedData && selectedData.material_name ? selectedData.material_name:"Seç/Değiştir"}</Tag>  
    </Popover>
  );
}
