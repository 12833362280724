import {
	DELETE_PRODUCT_STOCK_ACTION,
	DELETE_PRODUCT_STOCK_SUCCESS,
	DELETE_PRODUCT_STOCK_FAILURE,
	CREATE_PRODUCT_STOCK_ACTION,
	CREATE_PRODUCT_STOCK_SUCCESS,
	CREATE_PRODUCT_STOCK_ERROR,
	UPDATE_PRODUCT_STOCK_ACTION,
	UPDATE_PRODUCT_STOCK_SUCCESS,
	UPDATE_PRODUCT_STOCK_ERROR,
} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';


// delete STOCK ****************************************************************************************************************************************************
export const delete_product_stock_pending = () => ({
	type:DELETE_PRODUCT_STOCK_ACTION,
});

export const delete_product_stock_successful = (res) => ({
	type: DELETE_PRODUCT_STOCK_SUCCESS,
	response:res
});

export const delete_product_stock_failed = (res) => ({
	type: DELETE_PRODUCT_STOCK_FAILURE,
	response:res
});

export const deleteProductStock = (product_id,id) => dispatch => {
	const url = `/productStocks/delete/${product_id}/${id}`
	dispatch(delete_product_stock_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_product_stock_successful(res.data));           
				resolve(true);
			} else {
				dispatch(delete_product_stock_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_product_stock_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
// POST - Product  Stock Create ****************************************************************************************************************************************************
export const create_product_stock_pending = () => ({
	type: CREATE_PRODUCT_STOCK_ACTION
});

export const create_product_stock_success = (res) => ({
	type: CREATE_PRODUCT_STOCK_SUCCESS,
	response: res
});

export const create_product_stock_failure = (response) => ({
	type: CREATE_PRODUCT_STOCK_ERROR,
	response: response
});

export const createProductStock = (data) => dispatch => {
    const url = `/productStocks/create/`
	dispatch(create_product_stock_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_product_stock_success(res.data));

				resolve(true);
			} else {
				dispatch(create_product_stock_failure());

				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_product_stock_failure(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}

// update Offer ****************************************************************************************************************************************************
export const update_product_stock_pending = (id) => ({
	type: UPDATE_PRODUCT_STOCK_ACTION,
	productID: id
});

export const update_product_stock_successful = (res) => ({
	type: UPDATE_PRODUCT_STOCK_SUCCESS,
	response: res
});
 
export const update_product_stock_failed = (response) => ({
	type: UPDATE_PRODUCT_STOCK_ERROR,
	response:response
});

export const updateProductStockOperation = (data) => dispatch => {
	const url = `/productStocks/update/${data._id}`
	dispatch(update_product_stock_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_product_stock_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_product_stock_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_product_stock_failed(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}