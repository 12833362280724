import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Col,Popover,Button,Badge } from 'antd';
import {useIntl} from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowAltCircleDown, faBoxOpen, faBoxesPacking, faClipboardList, faHome, faList, faListCheck, faRectangleList, faRefresh, faRightToBracket} from '@fortawesome/free-solid-svg-icons';
import { NavLink,useHistory } from 'react-router-dom';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import {Note} from '../toDo/Note';
import { TimeLine } from './DashBoardItems/timeline';
import { Buyings } from './DashBoardItems/buyings';
import { StockOut } from './DashBoardItems/stockout';
import { DeliveryList } from './DashBoardItems/deliveryList';

import {deleteBuying} from '../../redux/buyingDetails/buyingDetailActions';
import { ComponentSummary } from './DashBoardItems/componentsSummary';
import { ComponentsList } from './DashBoardItems/componentsList';
import {getDeliveryList} from '../../redux/deliveryList/deliveryListActions';
import {getStockOutList} from '../../redux/stockLists/stockListActions';
import {getlogList} from '../../redux/logList/logListActions';
import {getBuyingsList} from '../../redux/buyingList/buyingListActions';

const  CRM = () =>{
  const User = useSelector((state) => state.auth.user);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const today  = new Date();
  const [state, setState] = React.useState({
    tabID:'materialSummary'
  });
  const buttonList = [
    <div key={0} className='page-header-actions '>        
      <Popover content= {messages["dashboard.componentlistsummary"]} placement="bottom" >
        <Button shape="circle" type={state.tabID === "materialSummary" ? 'primary' : 'default'} onClick={()=>gotoPage('materialSummary')} size='large' icon={<FontAwesomeIcon icon={faClipboardList}  />} />
      </Popover>     
      <Popover content= {messages["dashboard.componentlist"]} placement="bottom">
        <Button shape="circle" type={state.tabID === "componentlist" ? 'primary' : 'default'} onClick={()=>gotoPage("componentlist",true)} size='large' icon={<FontAwesomeIcon icon={faRectangleList} />}/>
      </Popover> 
      <Popover content= {messages["dashboard.buyinglist"]} placement="bottom">
        <Button shape="circle"  type={state.tabID === "buyinglist" ? 'primary' : 'default'}  onClick={()=>gotoPage("buyinglist",true)} size='large' icon={<FontAwesomeIcon icon={faListCheck} />} />
      </Popover> 





      <Popover content= {messages["dashboard.deliveriedlist"]} placement="bottom">
        <Button shape="circle" type={state.tabID === "openDelivery" ? 'primary' : 'default'} onClick={()=>gotoPage("openDelivery",true)} size='large' icon={<FontAwesomeIcon icon={faBoxOpen} />}/>
      </Popover> 
      <Popover content= {messages["dashboard.stockoutlist"]} placement="bottom">
        <Button shape="circle"  onClick={()=>gotoPage("stockOut",true)} size='large' icon={<FontAwesomeIcon icon={faRightToBracket} />} />
      </Popover> 
    </div>
  ];
//******************************************************************************************************************************************************************/
  React.useEffect(() => {
      

      // dispatch(getBuyingsList("list","date",0,4))
      // .then(res=>{
      //   message.loading(messages["alert.updated"]);
      // });
    //   dispatch(getDeliveryList("status",'0'))
    //  .then(res=>{
    //    message.loading(messages["alert.updated"]);
    //  });
   
      // 
      // .then(res=>{
      //   message.loading(messages["alert.updated"]);
      // });
      // dispatch(getlogList({query:"date",date:today}))
      // .then(res=>{
      //   message.loading(messages["alert.updated"]);
      // });
      
  },[]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    if(page==='materialSummary'){

    }else if(page==='openDelivery'){

    }else if(page==='stockOut'){

    }
    setState({
      ...state,
      tabID:page,
    })
  }
   //******************************************************************************************************************************************************************/
   const deleteComponentListRow = (id) => {
    dispatch(deleteComponent(id));
    setState({
      ...state,
      tabID:"componentlist"
    })
  }
   //******************************************************************************************************************************************************************/
   const deleteBuyingRow = (id) => {
    dispatch(deleteBuying(id))
    .then(res=>{
      dispatch(getBuyingsList("list","date",0,0))
    })
    setState({
      ...state,
      tabID:"buyinglist"
    })

  }
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        ghost
        title={User}
        buttons={buttonList}
      />
      <Main>
  

            
   
          <Col xxl={24} md={24} sm={24} xs={24}>

            { state.tabID==="materialSummary" ? 
                <ComponentSummary messages={messages} height='650px' /> :
              state.tabID==="openDelivery" ? 
                <DeliveryList messages={messages}  height='650px'/> :
              state.tabID==="componentlist" ? 
                <ComponentsList messages={messages}  height='650px' deleteComponentListRow={deleteComponentListRow} /> :
              state.tabID==="buyinglist" ? 
                <Buyings messages={messages}  height='650px' deleteBuyingRow={deleteBuyingRow}/>
              :null
            }
          </Col>
          {/* <Col xxl={8} md={8} sm={12} xs={24}>
            <ComponentListSum messages={messages} height={'450px'} />
          </Col>
          <Col xxl={16} md={16} sm={24} xs={24}>
            <Note messages={messages}/>
          </Col>
          <Col xxl={8} md={8} sm={12} xs={24}>
            <StockOut messages={messages}/>
          </Col>

          <Col xxl={8} md={8} sm={12} xs={24}>
            <TimeLine messages={messages}/>
          </Col> */}

        
      </Main>
    
    </>
  );
}

export {CRM};
