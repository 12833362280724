import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Spin,Table,Button,Tag,Popover} from 'antd';
import Heading from '../../../components/heading/heading';
import { NavLink} from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { faCirclePlus,faEdit,faTrash,faCopy,faPaste } from '@fortawesome/free-solid-svg-icons';
const listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
  padding: 0,
};
const ModelProduction = ({production,modelDetailLoading,messages,removeProRow,toggleModal,addNewProRow,modelProductionCopy,modelProductionPaste,onSaveModel}) => {
  let productionData = production && production.length && production.map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>addNewProRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>modelProductionCopy()}>
        <span><FontAwesomeIcon icon={faCopy} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.copy"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>modelProductionPaste()}>
        <span><FontAwesomeIcon icon={faPaste} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.paste"]}</span>
      </NavLink>
  
    </>
  );
  
  const specColumn = [
    {
      title: [messages["genel.sira"]],
      dataIndex: 'No',
      key: 'No',
      align: 'left',
      sorter: (a, b) => b.No - a.No,
      sortOrder:'descent',
      render: (text) => text,
      width: '15%',
    },
    {
      title: [messages["model.operation"]],
      dataIndex: 'Operation',
      key: 'Operation',
      align: 'left',
      render: (text) => 
      <div style={{display:'flex',flexDirection:'column'}}>
        <Tag className='blue' style={{marginBottom:'5px'}}>{text}</Tag>
      </div>,
      width: '35%',
    },
  
    {
      title: [messages["model.OperationComponent"]],
      dataIndex: 'OperationComponent',
      key: 'OperationComponent',
      render: (text,record) =>
        <div style={{display:'flex',flexDirection:'column'}}>
          {text && text.length ? text.map((row,i)=>{
            return(
              <Tag className='green' key={i} style={{marginBottom:'5px'}}>{
                record.Operation === "Yarı Mamül" ? messages["model.kategori"+row]:row
                }</Tag>
            )
          }):null}
        </div>,
      width: '35%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("proModalIndex",record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeProRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];




  return (
    <div className="full-width-table" >
      <Cards
        title={messages["model.OperationComponent"]}
        size="large"
        bodypadding="0px"
        more={moreContent}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'730px'}}>
          <Table
            sortDirections= {['ascend','descend']}
            showSorterTooltip={false}
            columns={specColumn} 
            dataSource={productionData} 
            pagination={false} 
            loading={modelDetailLoading}
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  )
}

export  {ModelProduction};
