import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useParams,NavLink,useHistory} from 'react-router-dom';
import { Row, Col ,Button,message,notification,Tooltip} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faSave} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { MenuListWrapper} from '../Style/style';
import { Main, } from '../Style/styled';
import {getCompanyList} from '../../redux/CompanyList/companyListActions';
import {changePassword,createUser,updateUser,deleteUser,getUserDetails} from '../../redux/users/userDetailActions';
import {Profile} from "./UserDetail/Profile";
import {Account} from "./UserDetail/Account";
import {Password} from "./UserDetail/Passwoard";
import {UserAuth} from "./UserDetail/userAuth";
import {AuthModal} from "./UserDetail/AuthModal";
import {DetailMenu} from "../commonComponent/detailMenu";
import blank from '../../static/img/blank.png';
import appActions from '../../redux/app/actions';


const UserProfile = ( ) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  let history = useHistory();
  const { userID } = useParams();
  const {userAuthList,formList,copyValues,copyForm} = useSelector(state => state.App);
  const { data, loading ,status} = useSelector(state => state.userDetails);
  const [dataSource, setDataSource] = React.useState(data);
  const {companyList} = useSelector((state) => state.companyList);
  const [companyOption, setCompanyOption] = React.useState();

const MenuList =[
  {tabID:"profile",MenuName:messages["user.editprofile"]},
  {tabID:"account",MenuName:messages["user.accountsetting"]},
  {tabID:"password",MenuName:messages["user.setPassword"]},
  {tabID:"auth",MenuName:messages["user.auth"]}
]

  const [state, setState] = React.useState({
    authModalActive:null,
    authModalIndex:null,
    collapsed:false,
    tabID:"profile"
  });
  const initData = {
    firstname:"",
    lastname:"",
    email:"",
    user_img:"",
    password:"",
    usercompany_name:"",
    mobile:"",
    role:7,
    userstate:true,
  }
  const  mainImage = dataSource.user_img ? dataSource.user_img :blank
  const {collapsed,tabID} = state;
  const { setCopyValues,setCopyForm } = appActions;

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (userID !=="new" && dataSource._id !== userID) {
      dispatch((getUserDetails({id:userID})));
      message.loading(messages["alert.updated"],3,)
    }else{
      if(userID ==="new"){
        setDataSource(initData);
      }
    }
  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(userID !=="new"){
      setDataSource(data);
    }
  },[data]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(status && status.status===401){
      notification.warning({
        message: status.statusText,
        description: status.data,
        duration:1
      });
      history.push({pathname:"/admin/401"})
    }else if (status && status===200){
      notification.info({
        message: 'Bilgi !',
        description:'İşlem Başarılı..',
      });   
    }else if(status && status.status===404){
      notification.warning({
        message: status.statusText,
        description: status.data,
        duration:1
      });
      history.push({pathname:"/admin/404"})
    }
  }, [status]);
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    setDataSource({
      ...dataSource,
      [Field]:eData
    });
  }
  //******************************************************************************************************************************************************************/
  const onSaveUser = () => {
    message.loading(messages["alert.updated"],3,)
    if(dataSource._id || userID!=='new'){
      dispatch(updateUser({...dataSource}))
    }else{
      if(userID==='new'){
        dispatch(createUser({...dataSource}))
      }   
    }
  };
  //******************************************************************************************************************************************************************/
  const onChangePassword = (values) => {
    if(values && values.password && values.password1){
      if(values.password.length>4){
        if(values.password === values.password1){
          dispatch(changePassword({id:data._id,password:values.password}))
          .then(res=>{
            message.success(messages["alert.savesuccess"]);
          })
          .catch(err=>{
              if(err){
                message.error(messages["alert.error"]);
              }
          })
        }else{
          message.warning(messages["alert.passworderror"]);
        }
      }else{
        message.warning(messages["alert.passworderror2"]);
      }
    }
  };
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    setState({
      ...state,
      tabID:page,
      collapsed:false
    })
    if (page==="account"){
      companyList && !companyList.length && dispatch(getCompanyList(null));
      let temp =[];
      companyList.forEach(row => {
        temp.push({
            value: row._id,
            label: row.company_name
      });
      setCompanyOption(temp)
    });
    }
  }
  //******************************************************************************************************************************************************************/
  const deleteUserAccount = () => {
    if (data && data._id) {
        dispatch(deleteUser(data._id))
        .then(res=>{
          message.success(messages["alert.savesuccess"]);
          history.push("/admin/users/list")
        })

    }
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  function convertBase64  (file)  {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  //******************************************************************************************************************************************************************/
  const beforeUpload = async (file) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      message.error('Image must smaller than 500Kb!');
      return false;
    }
    const base64 = await convertBase64(file);
    setDataSource({
      ...dataSource,
      user_img: base64
    });
    message.success('Image uploaded successfully!');
    return true;
  }
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,data) =>{
    if(name==="authModalActive"){
      setState({
        ...state,
        [name]: val,
        authModalIndex:data
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const addNewAuthRow = () =>{
    let temp = dataSource.user_form_auth;
    temp.push({
      sira:temp.length ? temp.length +1 :1,
      form:"",
      auth:3
    })
    setDataSource({
      ...dataSource,
      user_form_auth:temp
    });
    setState({
      ...state,
      authModalIndex: temp.length-1,
      authModalActive:true
    });
  };
  //******************************************************************************************************************************************************************/
  const removeAuthRow = (index) => {
    let temp = dataSource.user_form_auth;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        user_form_auth:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateAuthRow = (Field,value) =>{
    let tempdata = dataSource.user_form_auth
    tempdata[state.authModalIndex][Field] = value;
    setDataSource({
      ...dataSource,
      user_form_auth:tempdata
    });
  };
  //******************************************************************************************************************************************************************/
  const copyAuthList=()=>{
    dispatch(setCopyValues(dataSource.user_form_auth));
    dispatch(setCopyForm("authList"));
    message.success("Kopyalandı");
  }
  //******************************************************************************************************************************************************************/
  const pasteAuthList=()=>{
    if(copyValues &&  copyForm === "authList"){
      setDataSource({
        ...dataSource,
        user_form_auth : copyValues
      });
      message.success("Yapıştırıldı");
    }else{
        if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
    }
  }
  //******************************************************************************************************************************************************************/
  //console.log(dataSource)
  return (
    <>
      <PageHeader
        title={messages["sidebar.users"]}
        buttons={[
          <div key={0} >
            <Tooltip title= {messages["button.save"]} placement="left">
              <NavLink  to="#"  onClick={()=>onSaveUser()}>
                <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faSave}/>} style={{marginRight:'10px'}}/>
              </NavLink>
            </Tooltip>
            <Tooltip title= {messages["sidebar.users"]} placement="left">
              <NavLink to="/admin/users">
                <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faBars}/>}/>
              </NavLink>
            </Tooltip>
          </div>
        ]}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
              <DetailMenu 
                MenuList={MenuList} 
                data0={dataSource.firstname ? dataSource.firstname+" "+dataSource.lastname : "Yükleniyor"} 
                data1={messages["user."+dataSource.role]} 
                messages={messages} 
                image={mainImage} 
                imageField={"user_image"}
                imageWidth={100}
                tabID={tabID}
                beforeUpload={beforeUpload}
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
                gotoPage={gotoPage}
                updateData={updateData}
              />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              {tabID==="profile" ? 
                <Profile
                  loading={loading}
                  data={dataSource}
                  messages={messages}
                  updateData={updateData}
                />
              :tabID==="account" ? 
                <Account
                  loading={loading}
                  data={dataSource}
                  messages={messages}
                  updateData={updateData}
                  companyOption={companyOption}
                  deleteUserAccount={deleteUserAccount}
                />        
              :tabID==="password" ? 
                <Password
                  loading={loading}
                  messages={messages}
                  onChangePassword={onChangePassword}
                />     
              :tabID==="auth" ?
                <UserAuth
                  loading={loading}
                  messages={messages}
                  data={dataSource.user_form_auth}
                  addNewAuthRow={addNewAuthRow}
                  toggleModal={toggleModal}
                  copyAuthList={copyAuthList}
                  pasteAuthList={pasteAuthList}
                  removeAuthRow={removeAuthRow}
                  
                />
              :null}
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 
      {state.authModalActive && 
      <AuthModal 
        messages={messages}
        authRow={dataSource.user_form_auth[state.authModalIndex]}
        authModalActive={state.authModalActive}
        userAuthList={userAuthList}
        formList={formList}
        updateAuthRow={updateAuthRow}
        toggleModal={toggleModal}
      />}
    </>
  );
}



export  {UserProfile};
