import React from 'react';
import { Page, Document, StyleSheet, Font, View,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

//************************************************ */

import RobotoRegular from "../font/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../font/Roboto/Roboto-Bold.ttf";
import OpenSansFontRegular from "../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../font/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });

Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });
Font.register({ family: 'RobotoRegular', src: RobotoRegular  });
Font.register({ family: 'RobotoBold', src: RobotoBold  });
const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'RobotoRegular',
        paddingTop: 5,
        paddingBottom:15,
        paddingLeft:15,
        paddingRight:15
    }, 
    Header: {
        width:550,
        height:25,
        flexDirection: "row"
    },
    Version: {
        width:550,
        height:20,
        alignItems:"flex-end",
        flexDirection: "row"
    },
    leftColumn:{
        width:150,
        flexDirection: "column"
    },
    rightColumn:{
        width:400,
        alignItems:'right',
        flexDirection: "column"
    },
    logo: {
        width: 90,
        height: 20,
    },
    Body1: {
        width:550,
        flexDirection: "row"
    },



    ProductImage: {
        width: 140,
        height: 140,
        padding:5
    },
    CertificatesContainer: {
        flexDirection: "row",
        width: 140,
        height: 20,
        alignItems:'center',
    },
    LeftRowContainerA: {
        flexDirection: "row",
        width: 140,
        height: 12,
        backgroundColor:'#9a9795' 
    },
    LeftRowContainerB: {
        flexDirection: "row",
        width: 400,   
    },
    LeftFreeZone: {
        flexDirection: "row",
        width: 100,
        height: 20, 
    },

    RowContainerA: {
        flexDirection: "row",
        width: 400,
        height: 12,
        backgroundColor:'#9a9795'    
    },
    RowContainerB: {
        flexDirection: "row",
        width: 400,
        height: 12,
        backgroundColor:'#eceae9'    
    },
    RowContainerC: {
        flexDirection: "row",
        width: 400,
        height: 12,
        backgroundColor:'#f9f8f6'    
    },
    RowContainerD: {
        flexDirection: "row",
        width: 400,   
    },
    FreeZone: {
        flexDirection: "row",
        width: 400,
        height: 15, 
    },
    TechnicalDraw:{
        marginTop:2,
        width: 120,
        paddingLeft:5,
        paddingRight:5,
        height: 110,
        alignItems:'center',
    },
    PhotoMetry:{
        marginTop:2,
        width: 70,
        paddingLeft:5,
        paddingRight:5,
        height: 60,
        alignItems:'center',
    },
    OtherPhoto:{
        marginTop:2,
        width: 70,
        paddingLeft:5,
        paddingRight:5,
        height: 60,
        alignItems:'center',
    },



  });
const TextHeaderTR = styled.Text`
    height:25;
    padding-top:5px;
    font-size: 10px;
    font-family:'RobotoBold';
    text-align: right;
    text-transform: uppercase; 
`;
const Text6pxRight = styled.Text`
    height:25;
    font-size: 6px;
    text-align: right;
`;

const Certificates = styled.Image`
    margin_left: 2px;
    height: 18;
    width: 18;
    color: #0C71E0;
`;

const LeftSubject = styled.Text`
    height:12px;
    width:400px;
    font-size: 7px;
    text-align: left;
    color:#faf8f6;
    font-family:'RobotoBold';
    paddingLeft:5px;
    paddingTop:2px;
    borderColor:#bebdbc;
    borderBottom:0.2px;
`;
const Description = styled.Text`
    width:100px;
    height:140px;
    font-size: 5px;
    text-align: left;
    paddingLeft:5px;
    paddingTop:3px;
`;
const DrawingDescription = styled.Text`
    width:120px;
    font-size: 5px;
    text-align: center;
    paddingTop:4px;
    height:14px;
`;
const PhotometryDescription = styled.Text`
    width:70px;
    font-size: 5px;
    text-align: center;
    paddingTop:4px;
    height:14px;
`;
const OtherDescription = styled.Text`
    width:70px;
    font-size: 5px;
    text-align: center;
    paddingTop:4px;
    height:14px;
`;
const Subject = styled.Text`
    height:12px;
    width:400px;
    font-size: 7px;
    text-align: left;
    color:#faf8f6;
    font-family:'RobotoBold';
    paddingLeft:5px;
    paddingTop:2px;
    borderColor:#bebdbc;
    borderBottom:0.2px;
`;
const Label = styled.Text`
    height:12px;
    width:100px;
    font-size: 5px;
    text-align: left;
    paddingLeft:5px;
    paddingTop:3px;
    borderColor:#bebdbc;
    borderBottom:0.2px;
    font-family:'RobotoBold';
`;
const Spec = styled.Text`
    height:12px;
    width:300px;
    font-size: 5px;
    text-align: left;
    paddingLeft:5px;
    paddingTop:3px;
    borderColor:#bebdbc;
    borderBottom:0.2px;
`;
const Bod2Subject = styled.Text`
    height:12px;
    width:500px;
    font-size: 7px;
    text-align: left;
    color:#faf8f6;
    font-family:'RobotoBold';
    paddingLeft:5px;
    paddingTop:2px;

`;

const DataSheet = ({company,productDetail,messages,Lang}) =>{
    const {otherInfo,mainImage,electricalInfo,opticalInfo,mechanicalInfo,technicalDrawings,productPhotometry,otherPhotos} = productDetail;
  
  //  const ThecnicalDrawing =  productImageList &&  productImageList.length && productImageList.filter(x=>x.imageType ==="Teknik Resim").length ? (productImageList.filter(x=>x.imageType ==="Teknik Resim"))[0].image :"../assets/images/TRH.png"
    const MainImage =  mainImage.length  ? mainImage[0].image : "../assets/images/TRH.png"
    //const productCertificates =  productDetail && productDetail.modelDetails &&  productDetail.modelDetails.certificates;

    const {certificates}  = company;
    const ModelKodu = productDetail.urun_model;
    const PCBKodu = productDetail.urun_kodu ? ("-"+productDetail.urun_kodu):""
    const Optik = productDetail.urun_optik ? ("-"+productDetail.urun_optik):""
    const CCT  = productDetail.urun_cct ? ("-"+productDetail.urun_cct):""
    const Solar  = productDetail.urun_panel ? ("-"+productDetail.urun_panel):""
    const Battery  = productDetail.urun_aku ? ("-"+productDetail.urun_aku):""
  
    return(
    <Document >
        {company.sirket_rapor_logo && 
        <Page size="A4" style={styles.page} orientation={"portrait"}>

            <View style={styles.Header}>
                <View style={styles.leftColumn}>
                    <Image style={styles.logo} src={company.sirket_rapor_logo}/> 
                </View>
                <View style={styles.rightColumn}>
                    <TextHeaderTR>{productDetail.urun_adi[Lang]}</TextHeaderTR>
                </View>
            </View>
            <View style={styles.Version}>
                <View style={styles.leftColumn}></View>
                <View style={styles.rightColumn}>
                    <Text6pxRight>Version: 3.1</Text6pxRight>
                </View>
            </View>

            <View style={styles.Body1}>
                <View style={styles.leftColumn}>
                    <Image style={styles.ProductImage} src={MainImage}/> 
                    <View style={styles.CertificatesContainer} ></View>
                    <View style={styles.LeftFreeZone}></View>
                    <View style={styles.LeftRowContainerA} >
                        <LeftSubject>{messages["product.description"]}</LeftSubject>
                    </View>
                    <View style={styles.LeftRowContainerB} >
                        <Description>{productDetail.description && productDetail.description.length && productDetail.description[Lang]}</Description>
                    </View>
            
                    <View style={styles.LeftRowContainerA} >
                        <LeftSubject>{messages["product.applications"]}</LeftSubject>
                    </View>
                    <View style={styles.LeftRowContainerB} >
                        <Description>{productDetail.applicationArea && productDetail.applicationArea.length && productDetail.applicationArea[Lang]}</Description>
                    </View>
                </View>

                <View style={styles.rightColumn}>
                    <View style={styles.RowContainerA}>
                        <Subject>{messages["product.electricalinfo"]}</Subject>
                    </View>
                    <View style={styles.RowContainerC}>
                        <Label>{messages["product.code"]}</Label>
                        <Spec>{ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}</Spec>
                    </View>
                    {electricalInfo  && electricalInfo.length  && electricalInfo.map((row,keyIndex)=>{
                        return(
                            <div key={keyIndex}>
                                {
                                    keyIndex%2===0 ? 
                                    <View style={styles.RowContainerB}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                    :
                                    <View style={styles.RowContainerC}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                }
                            </div>
                        )
                    })}
                    <View style={styles.FreeZone}></View>
                    <View style={styles.RowContainerA}>
                        <Subject>{messages["product.opticalinfo"]}</Subject>
                    </View>
                    {opticalInfo  && opticalInfo.length  && opticalInfo.map((row,keyIndex)=>{
                        return(
                            <div key={keyIndex}>
                                {
                                    keyIndex%2===0 ? 
                                    <View style={styles.RowContainerB}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                    :
                                    <View style={styles.RowContainerC}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                }
                            </div>
                        )
                    })}

                    <View style={styles.FreeZone}></View>
                    <View style={styles.RowContainerA}>
                        <Subject>{messages["product.mechanicalinfo"]}</Subject>
                    </View>
                    {mechanicalInfo  && mechanicalInfo.length  && mechanicalInfo.map((row,keyIndex)=>{
                        return(
                            <div key={keyIndex}>
                                {
                                    keyIndex%2===0 ? 
                                    <View style={styles.RowContainerB}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                    :
                                    <View style={styles.RowContainerC}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                }
                            </div>
                        )
                    })}

                    <View style={styles.FreeZone}></View>
                    <View style={styles.RowContainerA}>
                        <Subject>{messages["product.otherinfo"]}</Subject>
                    </View>
                    {otherInfo  && otherInfo.length  && otherInfo.map((row,keyIndex)=>{
                        return(
                            <div key={keyIndex}>
                                {
                                    keyIndex%2===0 ? 
                                    <View style={styles.RowContainerB}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                    :
                                    <View style={styles.RowContainerC}>
                                        <Label>{row.label[Lang]}</Label>
                                        <Spec>{row.value[Lang]}</Spec>
                                    </View>
                                }
                            </div>
                        )
                    })}

                    <View style={styles.FreeZone}></View>
                    <View style={styles.RowContainerA}>
                        <Subject>{messages["product.drawings"]}</Subject>
                    </View>
                    <View style={styles.RowContainerD}> 
                        {technicalDrawings  && technicalDrawings.length  && technicalDrawings.map((col,keyIndex)=>{
                            return(
                                <div key={keyIndex}>
                                    <Image style={styles.TechnicalDraw} src={col.image}/> 
                                    <DrawingDescription>{col.description[Lang]}</DrawingDescription>
                                </div>
                            )
                        })}
                        
                    </View>

                    <View style={styles.RowContainerA}>
                        <Subject>{messages["product.photometry"]}</Subject>
                    </View>
                    <View style={styles.RowContainerD}> 
                        {productPhotometry  && productPhotometry.length  && productPhotometry.map((col,keyIndex)=>{
                            return(
                                <div key={keyIndex}>
                                    <Image style={styles.PhotoMetry} src={col.image}/> 
                                    <PhotometryDescription>{col.description[Lang]}</PhotometryDescription>
                                </div>
                            )
                        })}
                    </View>

                    <View style={styles.RowContainerA}>
                        <Subject>{messages["product.otherphotos"]}</Subject>
                    </View>
                    <View style={styles.RowContainerD}> 
                        {otherPhotos  && otherPhotos.length  && otherPhotos.map((col,keyIndex)=>{
                            return(
                                <div key={keyIndex}>
                                    <Image style={styles.OtherPhoto} src={col.image}/> 
                                    <OtherDescription>{col.description[Lang]}</OtherDescription>
                                </div>
                            )
                        })}
                        
                    </View>
                </View>
            </View>






        </Page>
        }
    </Document>
)};
  
export default DataSheet