import React from 'react';
import { Table ,Button,Spin} from 'antd';
import { NavLink } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus,faCopy,faPaste,faSave, faSpinner} from '@fortawesome/free-solid-svg-icons';
import Heading from '../../../components/heading/heading';

const UserAuth = ({data,messages,loading,removeAuthRow,toggleModal,addNewAuthRow,copyAuthList,pasteAuthList}) => {
  let authdata = data && data.length && data.map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>addNewAuthRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>copyAuthList()}>
        <span><FontAwesomeIcon icon={faCopy} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.copy"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>pasteAuthList()}>
        <span><FontAwesomeIcon icon={faPaste} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.paste"]}</span>
      </NavLink>

    </>
  );
  const AuthColumn = [

    {
      title: [messages["genel.sira"]],
      dataIndex: 'sira',
      key: 'sira',
      align: 'left',
      render: (text) => text,
      width: '20%',
    },
    {
      title: [messages["user.form"]],
      dataIndex: 'form',
      key: 'form',
      render: (text) => text,
      align: 'left',
      width: '30%',
    },
    {
      title: [messages["user.auth"]],
      dataIndex: 'auth',
      key: 'auth',
      align: 'left',
      render: (text) => <p>{messages["user.auth"+text]} </p>,
      width: '30%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("authModalActive",true,record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeAuthRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];
  return (
    <div className="full-width-table" >
      <Cards
        title={<Heading as="h5">{messages["user.authorization"]}</Heading>}
        size="large"
        bodypadding="0px"
        more={moreContent}
        bodyStyle={{ minHeight: '730px' }}
        loading={loading && <Spin indicator={faSpinner}  />}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'710px'}}>
          <Table 
            columns={AuthColumn} 
            dataSource={authdata} 
            pagination={false} 
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  );
}
export  {UserAuth};
