import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link,NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGrip,faBars,faEllipsisVertical,faPrint } from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
//import CreateProject from './overview/CreateProject';
import { ProjectDetailsWrapper, ProjectSorting } from '../Style/style';
import { Row, Col, Input,message,Spin,Tooltip,notification} from 'antd';
import { Main } from '../Style/styled';
import { Dropdown } from '../../components/dropdown/dropdown';
import { PageHeader } from '../../components/page-headers/page-headers';
import {LastCheckListCard} from './lastCheck/lastCheckListCard';

import blank from '../../static/img/resyok.jpeg';
import {getOrderItemList} from '../../redux/orderItemList/orderItemListActions';
import {getComponentList} from '../../redux/componentList/componentListActions';
import {getOrderItemDetails} from '../../redux/orderItemDetail/orderItemActions';
import appActions from '../../redux/app/actions';

const  LastCheckList =()=> {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();
  const { data, loading ,status1,status2,status3,status4,totalItemCount,status} = useSelector((state) => state.orderItemList);
  const categoryList = useSelector(state => state.App.orderStatus);
  const [dataSource, setDataSource] = React.useState(data);
  const {orderFilter} = useSelector(state => state.App);
  const [tabID,setTabID] = React.useState("list")
  const { setOrderFilter } = appActions;
  const [state, setState] = React.useState({
    printModalActive:null,});
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(orderFilter==="Tümü"){
      dispatch(getOrderItemList("list","date",1,4))
      .then(res=>{
        message.success('Üretim Listesi Güncellendi!',2);
      })
    }else{
      dispatch(getOrderItemList("list","date",orderFilter,orderFilter))
      .then(res=>{
        message.success('Üretim Listesi Güncellendi!', 2);
      })
    }
  }, [])
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(data)
    let  Filter = [];
    categoryList && categoryList.length && categoryList.map(x=>{
      return(
        Filter.push({
          text:messages[x.label],
          value:x.value
        })
      )
    })
  }, [data]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(status===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [status]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = (search) => {
    let filteredList =[]; 
    filteredList =  dataSource.filter(
        item => {return item.urun_adi && item.urun_adi.toLowerCase().includes(search.toLowerCase()) || (item.teklifno && item.teklifno.firma_adi  && item.teklifno.firma_adi.toLowerCase().includes(search.toLowerCase())) })
    if (search===""){
      setDataSource(data);
    }else{
      setDataSource(filteredList)
    }
  }

  //******************************************************************************************************************************************************************/
  const onChangeFilter = filterValue => {

    if (filterValue==="Tümü"){
      dispatch(getOrderItemList("list","date",1,4))
      .then(res=>{
        message.success('Güncellendi!', 2 );
      })
      dispatch(setOrderFilter("Tümü"))
    }else{
      dispatch(getOrderItemList("list","date",filterValue,filterValue))
      .then(res=>{
        message.success('Güncellendi!',2);
      })
      dispatch(setOrderFilter(filterValue))
    }
  }
  //******************************************************************************************************************************************************************/
  const gotoProjectDetails=(projectID)=>{
    if(projectID){
      dispatch(getOrderItemDetails({id:projectID}))

    }
  }

  //******************************************************************************************************************************************************************/
  return (
    <>
      <ProjectDetailsWrapper>
        <PageHeader
          title={messages["sidebar.lastchecklist"]}
          //subTitle={<>{dataSource && dataSource.length ? dataSource.length +" "+messages["genel.Adet"]+" "+messages["sidebar.projects"]:" "}</>}
          buttons={[
            <div key="1" className="project-action">
              <Link to="#" className="project-edit" >
                <FontAwesomeIcon icon={faPrint} size='1x' />
        
              </Link>
            </div>
          ]}
        />
      </ProjectDetailsWrapper>
      <Main>
      <ProjectSorting>
          <div className="project-sort-bar">
            <div className="project-sort-search">
              <Input
                onKeyDown={(e)=>onSearchKey(e.target.value)}
                placeholder={messages["genel.ara"]}
              />
            </div>
            <div className="project-sort-group">
              <div className="sort-group">
                <div className="layout-style">
                  {orderFilter==="Tümü" ? messages['genel.all']:messages["order.orderStatus"+orderFilter]}
                  <div className="more">
                    <Dropdown
                      action={['click']}
                      className="wide-dropdwon"
                      content={
                        <>
                          <Link to="#" onClick={()=>onChangeFilter("Tümü")} >{messages['genel.all']}</Link>
                          {categoryList.map((status,index)=>{
                            return(
                              <Link to="#" key={index} onClick={()=>onChangeFilter(status.value)} >{messages[status.label]}</Link>
                            )
                          })}
                        </>
                      }
                    >
                      <Link to="#">
                        <Tooltip title={orderFilter==="Tümü" ? messages['genel.all']:messages["order.orderStatus"+orderFilter]} placement="top" >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </Tooltip>
                      </Link>
                    </Dropdown>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </ProjectSorting>
        <div>
          {loading ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
          <div>
            {dataSource && dataSource.length ? dataSource.sort((a,b)=> new Date(b.siparis_tarihi)-new Date(a.siparis_tarihi)).map((product,i)=>{
              return(
                <LastCheckListCard
                  key={i}
                  loading={loading}
                  product={product}
                  messages={messages}
                  blank={blank} 
                />
              )
            }):null}
          </div>
          )}
        </div>
    </Main> 

    </>
  );
}



export  {LastCheckList};
