import {
	EMPLOYEE_DETAILS_ACTION,
	EMPLOYEE_DETAILS_SUCCESS,
    EMPLOYEE_DETAILS_ERROR,
	CREATE_EMPLOYEE_ACTION,
	CREATE_EMPLOYEE_SUCCESS,
	CREATE_EMPLOYEE_ERROR,
	UPDATE_EMPLOYEE_ACTION,
	UPDATE_EMPLOYEE_SUCCESS,
	UPDATE_EMPLOYEE_FAILURE,
	DELETE_EMPLOYEE_ACTION,
	DELETE_EMPLOYEE_SUCCESS,
	DELETE_EMPLOYEE_FAILURE,

} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/actions';


// Get - Offer Details ****************************************************************************************************************************************************
export const employe_details_pending = () => ({
	type: EMPLOYEE_DETAILS_ACTION
});

export const employe_details_success = (res) => ({
	type: EMPLOYEE_DETAILS_SUCCESS,
	response: res
});

export const employe_details_failure = (res) => ({
	type: EMPLOYEE_DETAILS_ERROR,
	response: res
});

export const getEmployeeDetails = (id) => dispatch =>{
	const url = `/employee/${id.id}` 
	dispatch(employe_details_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(employe_details_success(res.data))
                resolve(true);
            } else {
				dispatch(employe_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(employe_details_failure( err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(employe_reauth_request(store.getState().auth.token));
			}
            reject(err);
            
        });
    });
};

// POST - USER Create ****************************************************************************************************************************************************
export const create_employe_pending = () => ({
	type: CREATE_EMPLOYEE_ACTION
});

export const create_employe_success = (res) => ({
	type: CREATE_EMPLOYEE_SUCCESS,
	response: res
});

export const create_employe_failure = (res) => ({
	type: CREATE_EMPLOYEE_ERROR,
	response:res
});

export const createEmployee = (data) => dispatch => {
    const url = `/employee/create/`
	dispatch(create_employe_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_employe_success(res.data));
				resolve(true);
			} else {
				dispatch(create_employe_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_employe_failure(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}else{
				console.log(err)
			}
			reject(err);
		});
	});
}
// update USER ****************************************************************************************************************************************************
export const update_employe_pending = (UserID) => ({
	type: UPDATE_EMPLOYEE_ACTION,
	UserID: UserID
});

export const update_employe_successful = (res) => ({
	type: UPDATE_EMPLOYEE_SUCCESS,
	response: res
});

export const update_employe_failed = (res) => ({
	type: UPDATE_EMPLOYEE_FAILURE,
	response:res
});

export const updateEmployee = (data) => dispatch => {
	const url = `/employee/update/${data._id}`
	dispatch(update_employe_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_employe_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_employe_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_employe_failed(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}

// delete USER ****************************************************************************************************************************************************
export const delete_employe_pending = () => ({
	type: DELETE_EMPLOYEE_ACTION,
});

export const delete_employe_successful = (res) => ({
	type: DELETE_EMPLOYEE_SUCCESS,
	response:res
});

export const delete_employe_failed = (res) => ({
	type: DELETE_EMPLOYEE_FAILURE,
	response:res
});

export const deleteEmployee = (id) => dispatch => {
	const url = `/employee/delete/${id}` 
	dispatch(delete_employe_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				//console.log(res)
				dispatch(delete_employe_successful(res.data));        
				resolve(true);
			} else {
				dispatch(delete_employe_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_employe_failed());
			if (err.response && err.response.status && err.response === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
