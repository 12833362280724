import React from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import PriceListT1 from "./PriceList/pricelistT1"; 
import {useIntl} from "react-intl";
import { Spin} from 'antd';



const PriceList=({selectedCategories})=> {

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);

  const {user } = useSelector((state) => state.auth);
  const { myCompany, myCompanyLoading,myCompanyStatus } = useSelector(state => state.myCompany);
  const { productList, productListLoading,productListStatus } = useSelector((state) => state.productList);
  const {productsFilter,listPerVal,pricelisttype,pricelisttext,priceListTextOpen} = useSelector(state => state.App);
  const priceListText = pricelisttext && pricelisttext.replace("%XX",("%"+listPerVal))

  const { messages } = useIntl();
  const lang = locale === 'tr' ? 0 :1
  console.log("Report ",pricelisttype)
    return productList.length && myCompany && !productListLoading ? (
        <div>
          <PDFViewer width="100%" height={600} >
            {Number(pricelisttype) === 0 ?
              <PriceListT1 
                data={productList} 
                company={myCompany} 
                oran={listPerVal} 
                kategori={productsFilter} 
                pricelisttext={priceListText} 
                messages={messages} 
                priceListTextOpen={priceListTextOpen} 
                lang={lang}
                selectedCategories={selectedCategories}
                user={user}
                />
            :
            null}
          </PDFViewer>
        </div>
    ) : (
      <div className="spin">
        <Spin />
      </div>
    );
    
}
export {PriceList}
