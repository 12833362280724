import {
	CREATE_PRODUCTIMAGE_ACTION,
	CREATE_PRODUCTIMAGE_SUCCESS,
	CREATE_PRODUCTIMAGE_ERROR,
	UPDATE_PRODUCTIMAGE_ACTION,
	UPDATE_PRODUCTIMAGE_SUCCESS,
	UPDATE_PRODUCTIMAGE_FAILURE,
	DELETE_PRODUCTIMAGE_ACTION,
	DELETE_PRODUCTIMAGE_SUCCESS,
	DELETE_PRODUCTIMAGE_FAILURE,
} from '../actions';

const INIT_STATE = {
	productImageDetail: {},
	productImageDetailStatus:null,
	productImageDetailLoading:false
};

const  productImageDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CREATE_PRODUCTIMAGE_ACTION: //CREATE IMAGE
			return { 
				...state, 
				productImageDetailLoading: true,
				productImageDetailStatus:null
			};
		case CREATE_PRODUCTIMAGE_SUCCESS:
			return { 
				...state, 
				productImageDetailLoading: false, 
				productImageDetail: action.response,
				productImageDetailStatus:200
			};
		case CREATE_PRODUCTIMAGE_ERROR:
			return { 
				...state, 
				productImageDetailLoading: false,
				productImageDetail:{},
				productImageDetailStatus:action.response 
			};	
		case UPDATE_PRODUCTIMAGE_ACTION: //UPDATE IMAGE
			return { 
				...state, 
				productImageDetailLoading: true,
				productImageDetailStatus:null
			};
		case UPDATE_PRODUCTIMAGE_SUCCESS: 
			return { 
				...state, 
				productImageDetailLoading: false, 
				productImageDetail: action.response,
				productImageDetailStatus:200
			};
		case UPDATE_PRODUCTIMAGE_FAILURE:
			return { 
				...state, 
				productImageDetailLoading: false,
				productImageDetailStatus:action.response 
			}; 
		case DELETE_PRODUCTIMAGE_ACTION: //DELETE IMAGE
			return { 
				...state, 
				productImageDetailLoading: true,
				productImageDetailStatus:null
			};
		case DELETE_PRODUCTIMAGE_SUCCESS:
			return { 
				...state, 
				productImageDetailLoading: false,
				productImageDetailStatus:200  
			};
		case DELETE_PRODUCTIMAGE_FAILURE:
			return { 
				...state, 
				productImageDetailLoading: false ,
				productImageDetailStatus:action.response
			};
		default: return state;
	}
}
export default productImageDetails