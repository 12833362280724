import React from 'react';
import { Row, Col, Spin ,Rate,Button} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart,faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import {getProductCode} from '../../../../helpers/getProductCode';
import { Link} from 'react-router-dom';
import blank from '../../../../static/img/resyok.jpeg';
import Heading from '../../../../components/heading/heading';
import { NotFoundWrapper,ProductCard } from '../../../Style/style';

const ProductGrid = ({products,loading,lang,messages}) => {

  return (
    <Row gutter={30}>
      {loading ? (
        <Col xs={24}>
          <div className="spin">
            <Spin />
          </div>
        </Col>
      ) : products.length ? (
          products.map((product,index) => {
            const { urun_adi,urun_fiyat,urun_doviz, urun_kodu,model_category,listimage, urun_optik, urun_cct, urun_panel,urun_aku,stockSum,_id } = product;
          return (
            <Col xxl={8} lg={12} xs={24} key={index}>
              <ProductCard style={{ marginBottom: 30 }}>
                <Link to={`/web/productdetails/${product._id}`}>
                  <figure>
                    <img src={listimage && listimage.length ? listimage[0].image : blank} />
                  </figure>
                </Link>
                <figcaption>
                  <Link onClick={() => dispatch(updateWishList(id))} className="btn-heart" to="#">
                    <FontAwesomeIcon
                      icon={faHeart}
                      size='1x'
                      color={true ? '#FF4D4F' : '#9299B8'}
                      fill={true ? '#FF4D4F' : 'none'}
                    />
                  </Link>
                  <Heading className="product-single-title" as="h5">
                    <Link to={`/web/productdetails/${product._id}`}>{product.urun_adi[lang]}</Link>
                  </Heading>
                  <p className="product-single-price">
                    <span className="product-single-price__new">{messages["product.code"]} :{product.urun_kodu && getProductCode(product.model_category,product.urun_model,product.urun_kodu,product.urun_optik,product.urun_cct,product.urun_panel,product.urun_aku)}</span>
                    {/* {1.00 && (
                      <>
                        <del className="product-single-price__old"> ${0.00} </del>
                        <span className="product-single-price__offer"> 60% Off</span>
                      </>
                    )} */}
                  </p>
                  <div className="product-single-rating">
                    <Rate allowHalf defaultValue={4.7} disabled /> 4.9
                    <span className="total-reviews">{messages["model.kategori"+product.model_kategori]}</span>
                  </div>

                  <div className="product-single-action">
                    <Button size="small" type="white" className="btn-cart">
                      <FontAwesomeIcon icon={faShoppingBag} size='1x' />
                       Add To Cart
                    </Button>
                    <Button size="small" type="primary">
                      Buy Now
                    </Button>
                  </div>
                </figcaption>
              </ProductCard>
            </Col>
          );
        })
      ) : (
        <Col md={24}>
          <NotFoundWrapper>
            <Heading as="h1">Data Not Found</Heading>
          </NotFoundWrapper>
        </Col>
      )}
    </Row>
  );
}

export {ProductGrid};
