import React from 'react';
import { Col,Row, Spin, Form, Input,Select,InputNumber,Card,DatePicker,Collapse,Tooltip } from 'antd';
import { HorizontalFormStyleWrap,BasicFormWrapper } from '../../../Style/style';

import moment from 'moment';
const { Panel } = Collapse;

const OfferInfo =({offer,loading,messages,CurrencyList})=> {
  const [form3] = Form.useForm();
  React.useEffect(() => {
    form3.setFieldsValue(offer)
   },[offer])
  return (

      <Card style={{marginBottom:'20px'}}>
        {loading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          <BasicFormWrapper>
            <HorizontalFormStyleWrap>
              <Form form={form3} initialValues={offer} >
                <Collapse defaultActiveKey={['2','4']}  >

                  <Panel header={messages['offer.subject'] + " / " + messages['offer.relatedperson'] }key="1">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.subject"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="teklif_konu"  >
                          <Input  readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.relatedperson"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="related_person"  >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={messages['genel.date'] + " / " + messages['offer.offerno'] + " / " + messages["offer.referance"] } key="2">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["genel.date"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item  >
                          <DatePicker
                            value={moment(new Date(offer.teklif_tarihi))}
                            format="DD/MM/YYYY"
                            style={{width:"100%", display:'inline-block'}}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.offerno"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="teklif_no"  >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
 
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.referance"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="teklif_referansno"  >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={messages["offer.offercurrency"]} key="3">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.offercurrency"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name='teklif_dovizi' style={{ display: 'inline-block', width: 'calc(30% - 5px)' }} >
                          <Select options={CurrencyList} disabled/>
                        </Form.Item>
                        {offer.teklif_dovizi === '$' ? 
                        <Tooltip title="USD Kur Bilgisi ">
                          <Form.Item name="teklif_usdkur" style={{ display: 'inline-block', width: 'calc(70%)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input USD price!' }]}>
                            <InputNumber decimalSeparator=','readOnly/>
                          </Form.Item>
                        </Tooltip>
                        :offer.teklif_dovizi === '€' ? 
                        <Tooltip title="EUR Kur Bilgisi ">
                          <Form.Item name="teklif_eurkur" style={{ display: 'inline-block', width: 'calc(70%)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input EUR price!' }]}>
                            <InputNumber decimalSeparator=','readOnly/>
                          </Form.Item>
                        </Tooltip>
                        :offer.teklif_dovizi === '₺' ? 
                        <>
                          <Tooltip title="USD Kur Bilgisi ">
                            <Form.Item name="teklif_usdkur" style={{ display: 'inline-block', width: 'calc(35% -5 px)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input USD price!' }]}>
                              <InputNumber decimalSeparator=',' readOnly/>
                            </Form.Item>
                          </Tooltip>
                          <Tooltip title="EUR Kur Bilgisi ">
                            <Form.Item name="teklif_eurkur" style={{ display: 'inline-block', width: 'calc(35%)', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input EUR price!' }]}>
                              <InputNumber decimalSeparator=','  readOnly/>
                            </Form.Item>
                          </Tooltip>
                        </> : null
                        }
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header={messages["offer.company"]} key="4">
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.company"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="firma_adi" rules={[{ required: true, message: 'Please input Company Name!' }]} >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.address"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="firma_adres"  >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
              
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name='firma_ilce' style={{ display: 'inline-block', width: 'calc(35% - 5px)' }} >
                          <Input readOnly/>
                        </Form.Item>
                        <Form.Item name="firma_il" style={{ display: 'inline-block', width: 'calc(35%- 5px)', marginLeft: '5px' }}>
                          <Input readOnly/>
                        </Form.Item>
                        <Form.Item name="firma_ulke" style={{ display: 'inline-block', width: 'calc(30% )', marginLeft: '5px' }} rules={[{ required: true, message: 'Please input Country' }]}>
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle">
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["offer.email"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="firma_eposta" rules={[{ required: true, message: 'Please input E-Mail address!',type: 'email' }]} >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Form>
            </HorizontalFormStyleWrap>
          </BasicFormWrapper>
        )}
      </Card>
  );
}

export  {OfferInfo};
