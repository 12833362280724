import {
    COMPANY_ACTION,
    COMPANY_SUCCESS,
    COMPANY_ERROR,
    UPDATE_COMPANY_ACTION,
    UPDATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_FAILURE,
	CREATE_COMPANY0_ACTION,
	CREATE_COMPANY0_SUCCESS,
	CREATE_COMPANY0_FAILURE,
} from '../actions';

const INIT_STATE = {
	myCompany:{},
	myCompanyLoading:false,
	myCompanyStatus:null
};

const myCompany =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case COMPANY_ACTION: // GET DETAILS 
			return {
				...state,
				myCompanyStatus:null, 
				myCompanyLoading: true};
		case COMPANY_SUCCESS:
			return { 
				...state,
				myCompanyLoading: false,
				myCompany: action.response ,
				myCompanyStatus:200
			};
		case COMPANY_ERROR:
			return { 
				...state,
				myCompanyLoading: false, 
				myCompany:{}, 
				myCompanyStatus: action.response 
			};
		case UPDATE_COMPANY_ACTION: //UPDATE
			return { 
				...state,
				myCompanyLoading: true,
				myCompanyStatus:null
			};
		case UPDATE_COMPANY_SUCCESS:
			return { 
				...state, 
				myCompanyLoading: false, 
				myCompany: action.response,
				myCompanyStatus:200 
			};
		case UPDATE_COMPANY_FAILURE:
			return { 
				...state, 
				myCompanyLoading: false,
				myCompanyStatus: action.response 
			};
		case CREATE_COMPANY0_ACTION: //CREATE COMPANY
			return { 
				...state,
				myCompanyLoading: true,
				myCompanyStatus:null};
		case CREATE_COMPANY0_SUCCESS:
			return { 
				...state, 
				myCompanyLoading: false, 
				myCompany: action.response,status:200 
			};
		case CREATE_COMPANY0_FAILURE:
			return { 
				...state, 
				myCompanyLoading: false,
				myCompanyStatus: action.response
			}; 
		default:  {
			return state
		}
	}
}
export default myCompany