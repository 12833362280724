import React from 'react';
import {Modal} from 'antd';
import {PriceList}  from '../../../reports/pricelist';


const ReportModal = ({reportModalActive,togglePrintModal,selectedCategories}) => {
  
  return (
    <>

      {reportModalActive && 
      <Modal
        forceRender
        type='primary'
        open={reportModalActive}
        footer={null}
        width={1000}
        closable={false}
        onCancel={() => togglePrintModal("reportModalActive",null)}
      >
        <PriceList selectedCategories={selectedCategories}/>
      </Modal>}
    </>

  );

}



export  {ReportModal};