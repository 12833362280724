import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
   
    TitleContainer:{
        marginTop: 10,
        flexDirection: "row",
        borderBottomWidth: 0.2,
        width:550,
        fontFamily: 'OpenSansFontBold',
    },
});

const Titlestyle = styled.Text`
    margin: 0px;
    font-size: 12px;
    text-align: center;
    width: 550px;
`;
  const Title = ({messages}) => (
    <View style={styles.TitleContainer}>
        <Titlestyle># {messages["delivery.form"].toUpperCase()} #</Titlestyle> 
    </View>
  );
  
  export default Title