import {
    OFFER_GET_LIST,
    OFFER_GET_LIST_SUCCESS,
	OFFER_GET_LIST_ERROR,
    ORDER_SUM_LIST,
    ORDER_SUM_LIST_SUCCESS,
	ORDER_SUM_LIST_ERROR,
	COMPANY_OFFER_GET_LIST,
    COMPANY_OFFER_GET_LIST_SUCCESS,
	COMPANY_OFFER_GET_LIST_ERROR
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from '../auth/actions';
//Offer List GET ****************************************************************************************************************************************************

export const offer_list_set_pending = () => ({
	type: OFFER_GET_LIST
});

export const offer_list_set_successful = (res) => ({
	type: OFFER_GET_LIST_SUCCESS,
	response: res
});

export const offer_list_set_failed = (res) => ({
	type: OFFER_GET_LIST_ERROR,
	response: res
});

export const getOfferList = (selectedPageSize,selectedOrderOption,filter1,filter2) => dispatch =>{
	const apiUrl_list = "/offers/list";
	dispatch(offer_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?pageSize=${selectedPageSize}&orderBy=${selectedOrderOption.column}&filter1=${filter1}&filter2=${filter2}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(offer_list_set_successful(res.data));
				resolve(true);
			} else { 
				dispatch(offer_list_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(offer_list_set_failed(err.response.status));
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});
};

//Offer List GET ****************************************************************************************************************************************************

export const company_offer_list_set_pending = () => ({
	type: COMPANY_OFFER_GET_LIST
});

export const company_offer_list_set_successful = (res) => ({
	type: COMPANY_OFFER_GET_LIST_SUCCESS,
	response: res
});

export const company_offer_list_set_failed = () => ({
	type: COMPANY_OFFER_GET_LIST_ERROR
});

export const getCompanyOfferList = (companyID,filter1,filter2) => dispatch =>{
	const apiUrl_list = `/offers/companylist/${companyID}`;
	dispatch(company_offer_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}?filter1=${filter1}&filter2=${filter2}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(company_offer_list_set_successful(res.data));
				resolve(true);
			} else { 
				dispatch(company_offer_list_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(company_offer_list_set_failed());
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});

};
//Order Suummary List GET ****************************************************************************************************************************************************

export const order_sum_set_pending = () => ({
	type: ORDER_SUM_LIST
});

export const order_sum_set_successful = (res) => ({
	type: ORDER_SUM_LIST_SUCCESS,
	response: res
});

export const order_sum_set_failed = () => ({
	type: ORDER_SUM_LIST_ERROR
});

export const getOrderSummaryList = () => dispatch =>{
	const apiUrl_list = "/offers/ordersum";
	dispatch(order_sum_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + `${apiUrl_list}`,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(order_sum_set_successful(res.data));
				resolve(true);
			} else { 
				dispatch(order_sum_set_failed());
				reject(res);
			}
		})
		.catch((err) => {
			//console.log(err)
			dispatch(order_sum_set_failed());
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
		});
	});
};