
import React from 'react';
import {Select, Modal,Button ,Form,Input,InputNumber,DatePicker} from 'antd';
import { BasicFormWrapper} from '../../Style/style';
import moment from 'moment';
const ReceivedModal = ({messages,received,UnitList,CurrencyList,toggleModal,receivedModalActive,onUpdatereceived}) => {
  const [form23] = Form.useForm();
  React.useEffect(() => {
    received && form23.setFieldsValue(received)
   },[received])
  return (
    <>
      {receivedModalActive && received ?
      <Modal
        forceRender
        type='primary'
        title={messages["buying.details"]}
        className="sDash_addTask-modal"
        open={receivedModalActive}
        footer={null}
        closable={false}
        width={600}
      >
        <BasicFormWrapper>
           <div className="sDash_addTask-modal-inner-modal">
              <Form form={form23} onFinish={(eData) => onUpdatereceived(eData)} initialValues={received}>
                <Form.Item  label={messages["genel.date"]}>
                  <DatePicker format="DD.MM.YYYY" defaultValue={moment(received.date)}/>
                </Form.Item>

                <Form.Item name="qty" style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} label={messages["genel.qty"]} rules={[{ required: true, message: 'Please input Product Qty!' }]}>
                  <InputNumber decimalSeparator=','  />
                </Form.Item>
                <Form.Item name="unit" style={{ display: 'inline-block', width: 'calc(50% )',marginLeft:'5px' }} label={messages["genel.unit"]}>
                  <Select options={UnitList.map(x=>{return({label:messages[x.label],value:x.value})})} />
                </Form.Item>

                <Form.Item name="price" style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} label={messages["genel.price"]} rules={[{ required: true, message: 'Please input Material Price!' }]}>
                  <InputNumber decimalSeparator=','  />
                </Form.Item>
                <Form.Item name="doviz" style={{ display: 'inline-block', width: 'calc(50% )',marginLeft:'5px' }} label={messages["genel.doviz"]}>
                  <Select options={CurrencyList} />
                </Form.Item>
                <Form.Item name="toplamDoviz" style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} label={messages["genel.totalprice"]}>
                  <InputNumber decimalSeparator=','  addonAfter={received && received.doviz} readOnly/>
                </Form.Item>
                <Form.Item name="toplamTL" style={{ display: 'inline-block', width: 'calc(50% )',marginLeft:'5px' }} label={" "}>
                  <InputNumber decimalSeparator=',' addonAfter="₺" readOnly/>
                </Form.Item>
                <Form.Item name="receiver" label={messages["delivery.receiver"]}>
                    <Input />
                </Form.Item>

                <div className="sDash-modal-actions">
                  <Button size="small" type="white" onClick={() => toggleModal("receivedModalActive",null,null)}  >
                    {messages["button.cancel"]}
                  </Button>
                  <Button  size="small" type="primary" htmlType="submit" >
                    {messages["button.save"]}
                  </Button>
                </div>
              </Form>

          </div>
        </BasicFormWrapper>
      </Modal>:null}
    </>

  );

}



export  {ReceivedModal};
