import React, { useEffect, useState,Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector,useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route,Switch } from 'react-router-dom';
import { ConfigProvider,Spin } from 'antd';
import {store} from './redux/store';
import Admin from './routes/admin';
import Web from './routes/web';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import { IntlProvider } from 'react-intl';
import AppLocale from './config/translation';
import { connect as wsConnect  } from '@giantmachines/redux-websocket';
import {getTcmbData} from './redux/tcmb/tcmbActions';
import {getCompany} from './redux/company/myCompanyActions';
import {DataSheet} from './reports/productDatasheet';
import {OrderTrack} from './pages/emails/ordertracing/ordertrack';
const { theme } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();
 
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const darkMode = useSelector(state => state.ChangeLayoutMode.data);
  const topMenu = useSelector(state => state.ChangeLayoutMode.topMenu);
  const auth = useSelector(state => state.auth.status);
  const isLoaded = useSelector(state => state.auth.loading);
  const { locale } = useSelector(state => state.LanguageSwitcher.language);

  const [path, setPath] = useState(window.location.pathname);
  const isLoggedIn = auth==="successful" ? true :false
  const currentAppLocale = AppLocale[locale];
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    let unmounted = false;
 
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
    
  }, []);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    store.dispatch(wsConnect(String(process.env.REACT_APP_SERVER_URL).replace("https", "wss").replace("http", "ws") + "/ws"));
    // eslint-disable-next-linestore.dispatch(wsDisconnect());
  }, [dispatch]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    dispatch(getTcmbData());
    dispatch((getCompany({id:1})));
  },[]);

  return (

      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={currentAppLocale.antd}>
          <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
            {isLoaded ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <Router basename={process.env.PUBLIC_URL}>
                  {isLoggedIn===false ? 
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >
                      <Switch>
                        <Route path="/signin" component={Auth}/> 
                        <Route path="/web" component={Web}/> 
                        <Route exact path="/productdatasheet/:productID" component={DataSheet} />
                        <Route  path="/ordertrack/:offerID/:companyID/:token" component={OrderTrack} />
                         
                        <Redirect to="/web/products"/> 
                        
                      </Switch>
                    </Suspense>
                    : 
                    <Switch>
                      <Route exact path="/productdatasheet/:productID" component={DataSheet} />
                      <Route  path="/ordertrack/:offerID/:companyID/:token" component={OrderTrack} />
                      <ProtectedRoute path="/admin" component={Admin} />
                    </Switch>

                  }
                  {isLoggedIn ? 
                    ((path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`)  && <Redirect to="/admin" />)
                  :
                    ((path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`)  && <Redirect to="/web"/>)
                  }
                </Router>
              )}
            </IntlProvider>
          </ThemeProvider>
      </ConfigProvider>

  );
}

function App() {
  return (

    <Provider store={store}>
      <ProviderConfig />
    </Provider>

  );
}

export default hot(App);
