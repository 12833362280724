import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Form, Modal,Button } from 'antd';
import { FormWrapper } from '../../Style/style';
const OrderNoteModal = ({ orderNotesModalActive,orderNote,toggleModal,messages,onChangeNotesField}) => {

  return (
  <>
  {orderNotesModalActive &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={orderNotesModalActive}
      footer={
        <div key={0}>
        <Button size="large" type="white" onClick={() => toggleModal("orderNotesModalActive",null)}  >
          {messages["button.close"]}
        </Button>
      </div>
      }
      closable={false}
      onCancel={() => toggleModal("orderNotesModalActive",null)}
      forceRender
    >
      <FormWrapper style={{height:'110px'}}>
          <Form.Item rules={[{ required: true, message: 'Please add a Title' }]} >
            <Input value={orderNote} onChange={(eData)=>onChangeNotesField(eData.target.value)} />
          </Form.Item>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {OrderNoteModal};
