import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams ,useHistory} from 'react-router-dom';
import moment from 'moment';
import { Tag,Button,Table,message,Popconfirm,Tooltip,notification,Popover} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus, faPrint, faList, faSave, faRefresh,} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import {createStock,updateStock,deleteStock} from '../../redux/stockDetails/stockDetailActions';
import {getMaterialDetails,createMaterial,updateMaterial,deleteMaterial} from '../../redux/materialDetails/materialDetailActions';
import {getMaterialsList} from '../../redux/materials/materialsActions';
import {MaterialGenerals} from "./materials/materialGenerals";
import {StokOperationModal} from "./materials/stokOperationModal";
import { Cards } from '../../components/cards/frame/cards-frame';
import { PrintModal } from '../materials/materials/PrintModal';
import { ReportModal } from './materials/reportModal';

function convertBase64  (file)  {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}
const initData = {
  material_no:1,
  material_name:"",
  material_group:"",
  material_category:"",
  material_unit:"Adet",
  material_price:0,
  material_doviz:"$",
  material_img:"",
  stockSum:0,
}

const MaterialDetails = ( ) => {
  let history = useHistory();
  const { materialDetail, materialDetailLoading,materialDetailStatus } = useSelector(state => state.materialDetail);
  const {materialList} = useSelector(state => state.materials);

  const [stockData, setStockData] = React.useState();
  const [stockRow, setStockRow] = React.useState();
  const [dataSource, setDataSource] = React.useState(materialDetail);
  const {UnitList,CurrencyList,} = useSelector(state => state.App);
  const {operationCenterList,operationTypeSelect} = useSelector(state => state.App);

  const {tcmb} = useSelector(state => state.tcmb);
  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const dispatch = useDispatch();

  const { materialID } = useParams();
  const { messages } = useIntl();
  const [state, setState] = React.useState({
    stockOperationModalActive:null,
    MaterialStock:null,
    printModalActive:null,
    reportModalActive:null,
    printTypeCode:null,
    StokGiris:0,
    StokCikis:0,
    activePanel:1,
  });
  const stockOperationsColumn = [
    {
      title: [messages["genel.date"]],
      dataIndex: 'operation_date',
      key: 'operation_date',
      render: (text) => moment(text && text).format("DD.MM.YYYY"),
      width: '15%',
    },
    {
      title: [messages["task.operation"]],
      dataIndex: 'operation_center',
      key: 'operation_center',
      render: (text,record) => text && text.toUpperCase() +" "+ record.productname,
      align: 'left',
      width: '30%',
    },
    {
      title: [messages["stock.operationtype"]],
      dataIndex: 'operation_type',
      key: 'operation_type',
      render: (text) => text &&<p><Tag color={text==="giris" ? "#108ee9":"#f50"}>{text.toUpperCase()}</Tag></p> ,
      width: '10%',
    },
    {
      title: [messages["genel.qty"]],
      dataIndex: 'qty',
      key: 'qty',
      render: (text) => Number(text).toFixed(2),
      width: '15%',
      align: 'right'
    },
    {
      title: [messages["genel.stocksum"]],
      dataIndex: 'runTotal',
      key: 'runTotal',
      render: (text) => <Tag color={text>0  ? "#108ee9":"#f50"}>{text && text.toFixed(2)}</Tag>,
      width: '15%',
      align: 'right'
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>openModal(record)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Tooltip title="İşlem Satırını Siler" placement="bottom" >
          <Popconfirm
            title={messages["alert.deletequest"]}
            okText={messages["genel.yes"]}
            cancelText={messages["genel.no"]}
            onConfirm={()=>deleteStockRow(record)}
          >
            <Button
              shape="circle"
              size="small"
            >
            <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
          </Button>  
          </Popconfirm>
        </Tooltip>
      </>
    }
  ];
  const buttonList = [
    <div key={0}>
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>
      {dataSource && dataSource._id && 
      <Popconfirm
        title={messages["alert.deletequest"]}
        okText={messages["genel.yes"]}
        cancelText={messages["genel.no"]}
        onConfirm={()=>deleteMaterials(dataSource)}
      >
        <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faTrash} color="#ce1818"/>}  style={{marginRight:'10px'}}/>
      </Popconfirm>}
      <Popover content= {messages["button.save"]} placement="bottom">
        <Button shape="circle"  onClick={()=>onSaveMaterial()} size='large' icon={<FontAwesomeIcon icon={faSave} />} style={{marginRight:'10px'}}/>
      </Popover>           

      <Popover content= {messages["product.datasheet"]} placement="bottom">
          <Button shape="circle"   size='large' icon={<FontAwesomeIcon icon={faPrint} />} style={{marginRight:'10px'}} onClick={()=>toggleModal("printModalActive",true,null)}/>
      </Popover> 
      <Popover content= {messages["sidebar.materials"]} placement="bottom">
        <NavLink key={4} to="/admin/materials" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faList}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  const {stockOperations} = dataSource;
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (materialID !=="new" && dataSource._id !== materialID) {
      dispatch((getMaterialDetails({id:materialID})))
      .then(res=>{
       
      })
    }else{
      if(materialID ==="new"){
        setDataSource(initData);
      
      }
    }
    dispatch(getMaterialsList('list',null))
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(materialID !=="new" && materialDetail!==dataSource){
      setDataSource(materialDetail);
    }
  }, [materialDetail]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(materialID !=="new"){
      StockRunTotal();
    }
  }, [stockOperations]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(materialDetailStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [materialDetailStatus]);
  //******************************************************************************************************************************************************************/
  const StockRunTotal = () => {
    
    let stockList = [];
    let StokGiris = 0 ;
    let StokCikis = 0;
    let runingTotal = 0
    stockOperations && stockOperations.length &&  stockOperations.sort((a,b)=> new Date(a.operation_date)-new Date(b.operation_date)).forEach(mtr=>{
        runingTotal = runingTotal + mtr.sumQty
        stockList.push({
            runTotal: runingTotal,
            stock_id : mtr.stock_id,
            order_id : mtr.order_id,
            orderitem_id:mtr.orderitem_id,
            operation_center:mtr.operation_center,
            operation_date : mtr.operation_date,
            operation_type  : mtr.operation_type,
            stock_name : mtr.stock_name,
            stock_category:mtr.stock_category,
            stock_group:mtr.stock_group,
            description:mtr.description,
            qty:mtr.qty,
            unit:mtr.unit,
            price : mtr.price,
            doviz : mtr.doviz,
            sumQty:mtr.sumQty,
            _id:mtr._id,
            key:mtr._id,
            productname:mtr.productname
        });  
    })
    StokGiris = stockOperations && stockOperations.length && stockOperations.filter(x=>x.operation_type==="giris").reduce((prev, current) => (prev + current.sumQty), 0);
    StokCikis = stockOperations && stockOperations.length && stockOperations.filter(x=>x.operation_type==="cikis").reduce((prev, current) => (prev + current.sumQty), 0);
    let MaterialStock =  StokGiris - Math.abs(StokCikis);
    setStockData(stockList.sort((a,b)=> new Date(b.operation_date)-new Date(a.operation_date)).map((el, idx) => ({key: idx, ...el})));
    setState({
      ...state,
      MaterialStock:MaterialStock,
      StokGiris:StokGiris,
      StokCikis:StokCikis
    }); 
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const onSaveMaterial = () => {
    if(dataSource._id){
      dispatch(updateMaterial({...dataSource}))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      })
    }else{
      dispatch(createMaterial({...dataSource}, history))
      .then(res=>{
        message.success(messages["alert.createsuccess"], 2);
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const beforeUpload = async (file) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file! file size :'+(file.size / 1024 / 1024).toFixed(2)+'Mb');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 0.3;
    if (!isLt2M) {
      message.error('Image must smaller than 500Kb! File Size'+(file.size / 1024 / 1024).toFixed(2)+'Mb');
      return false;
    }
    const base64 = await convertBase64(file)
    setDataSource({
      ...dataSource,
      material_img: base64
    });
    message.success('Image uploaded successfully! File size '+(file.size / 1024 / 1024).toFixed(2)+ "Mb");
    return true;
  }
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    setDataSource({
      ...dataSource,
      [Field] :eData
    });
  }
  //******************************************************************************************************************************************************************/
  const onChangeStockRow = (name,e) => {
    if (name==="operation_type"){
      setStockRow({
        ...stockRow,
        [name]:e,
        sumQty:e==="giris" ? 0+stockRow.qty : 0-stockRow.qty
      });
    }else if(name==="qty"){
      setStockRow({
        ...stockRow,
        [name]:e,
        sumQty:stockRow.operation_type==="giris" ? 0+e : 0-e
      });
    }else{
      setStockRow({
        ...stockRow,
        [name]:e
      });
    }

  };
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,index) =>{
    if(name==="stockOperationModalActive"){
      setState({
        ...state,
        [name]:val,
      });
      setStockRow()
    }else if(name==="printModalActive"){
      setState({
        ...state,
        [name]:val
      });
    }else if(name==="reportModalActive"){
      setState({
        ...state,
        [name]:val
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const openModal = (stock) =>{
      setState({
        ...state,
        stockOperationModalActive:true,
      });    
      stock.operation_date=moment(new Date(stock.operation_date))
      setStockRow(stock)
  };
  //******************************************************************************************************************************************************************/
  const onSaveStock = () => {
    if(stockRow._id){
      dispatch(updateStock({...stockRow}))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
        setState({
          ...state,
          stockOperationModalActive:null,
        });
        refreshDataSource();
      })
    }else{
      dispatch(createStock({...stockRow}))
      .then(res=>{
        message.success(messages["alert.createsuccess"], 2 );
        setState({
          ...state,
          stockOperationModalActive:null,
        });
        refreshDataSource();
      });
    }

    
  };
  //******************************************************************************************************************************************************************/
  const addNewStockRow = () => {
    const newRow ={
      operation_date: moment(new Date()),
      operation_center:"",
      operation_type: "",
      stock_name:dataSource.material_name,
      stock_category:dataSource.material_category,
      stock_group:dataSource.material_group,
      qty:1,  
      unit:"Adet",
      description:"",
      price:0,
      doviz:"$",
      stock_id:dataSource._id
  }
    setStockRow(newRow)
    setState({
      ...state,
      stockOperationModalActive:true,
    });    
  }
  //******************************************************************************************************************************************************************/
  const deleteStockRow = (Stock) => {
    if(Stock){
      dispatch(deleteStock(Stock))
      .then(res=>{
        message.success(messages["alert.deletesuccess"], 2 );
        refreshDataSource();
      });
      setState({
        ...state,
        stockOperationModalActive:null,
      });   
    }
  }
  //******************************************************************************************************************************************************************/
  const deleteMaterials = (materialdetails) => {
    if(materialdetails){
      dispatch(deleteMaterial(materialdetails._id))
      .then(res=>{
        history.push("/admin/materials/new")
      })
    }
  }
  //******************************************************************************************************************************************************************/
  const onSelectPrintType = (e) => {
    setState({
      ...state,
      printTypeCode:e,
    });
  }
  //******************************************************************************************************************************************************************/
  const openReportModal = () => {
    setState({
      ...state,
      reportModalActive:true
    });
  }
  //******************************************************************************************************************************************************************/
  const refreshDataSource = () => {
    dispatch((getMaterialDetails({id:materialID})));
  }
  //console.log(dataSource)
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        title={dataSource.material_name ? dataSource.material_name:messages["alert.updated"]}
        buttons={buttonList}
      />
      <Main>
        <MaterialGenerals
          messages={messages}
          materialDetailLoading={materialDetailLoading}
          material={dataSource}
          CurrencyList={CurrencyList}
          UnitList={UnitList}
          materials={materialList}
          updateData={updateData}
          beforeUpload={beforeUpload}
          onSaveMaterial={onSaveMaterial}
          deleteMaterials={deleteMaterials}
          USDSatis={USDSatis}
          EURSatis={EURSatis}
          state={state}
        />
        <div className="full-width-table">

        <Cards
          size="small"
          bodypadding="0px"
          bodyStyle={{ minHeight: '350px'}}
          isbutton={
          <Popover content={messages["button.add"]+" "+messages["order.orderStatus6"]}>
            <Button shape="circle" size='small'onClick={()=>addNewStockRow()} icon={<FontAwesomeIcon icon={faCirclePlus}/>} style={{marginRight:'10px'}}/>
          </Popover>
          }
        >

          <div className="table-bordered top-seller-table table-responsive">
            <Table pagination={false} dataSource={stockData} loading={materialDetailLoading} scroll={{ y: 350 }} columns={stockOperationsColumn}/>
          </div>
          
        </Cards>
   
        </div>
      </Main>
      <StokOperationModal
        messages={messages}
        stockRow={stockRow}
        UnitList={UnitList}
        CurrencyList={CurrencyList}
        stockOperationModalActive={state.stockOperationModalActive}
        stockOperationsLoading={materialDetailLoading}
        operationCenterList={operationCenterList}
        operationTypeSelect={operationTypeSelect}
        toggleModal={toggleModal}
        onChangeStockRow={onChangeStockRow}
        onSaveStock={onSaveStock}
      />
      <PrintModal
        messages={messages}
        toggleModal={toggleModal}
        printModalActive={state.printModalActive}
        onSelectPrintType={onSelectPrintType}
        openReportModal={openReportModal}
      />
      <ReportModal
        messages={messages}
        toggleModal={toggleModal}
        reportModalActive={state.reportModalActive}
        printTypeCode={state.printTypeCode}
      />
    </>
  );
}

export  {MaterialDetails};
