import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';

import {useIntl} from "react-intl";
import { MenuListWrapper,ListWrapper,SearchListSidebar,ListContent} from '../Style/style';
import {Row,Col,Spin,Input,Tag,notification} from 'antd';
import { Main } from '../Style/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Scrollbars } from 'react-custom-scrollbars';
import {ListMenu} from "../commonComponent/listMenu"
import {getEmployeeList} from '../../redux/employee/employeeListActions';
import blank from '../../static/img/blank.png';

const  Employee =()=> {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  let history = useHistory();
  const [dataSource, setDataSource] = React.useState();
  const { employeeList, employeeListLoading,employeeListStatus } = useSelector((state) => state.employeeList);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const left = !rtl ? 'left' : 'right';
  const [state, setState] = React.useState({
    visible: false,
    collapsed: false,
    employeeListFilter:"Tümü"
  });
  const MenuList =[
    {value:"true",label:"user.true"},
    {value:"false",label:"user.false"},
  ]
  const {collapsed,employeeListFilter} =state;
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    dispatch(getEmployeeList('list',null));
  }, []);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
      setDataSource(employeeList)
  }, [employeeList]);
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(employeeListStatus && employeeListStatus.status===401){
      notification.warning({
        message: employeeListStatus.statusText,
        description: employeeListStatus.data,
        duration:1
      });
      history.push({pathname:"/admin/401"})
    }else if (employeeListStatus===200){
      notification.info({
        message: 'Bilgi !',
        description: (employeeList && employeeList.length && employeeList.length) + ' Adet Personel Listelendi..',
        duration:1
      });   
    }
  }, [employeeListStatus]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = (searchText) => {
    let filteredList =[]; 
    filteredList =  employeeList.filter(
        item => {return item.firstname.toLowerCase().includes(searchText.toLowerCase()) })
    if (!!searchText){
      setDataSource(filteredList);
    }else{
      setDataSource(employeeList);
    }
  };
  //******************************************************************************************************************************************************************/

  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      dispatch(getEmployeeList('list',null))
    }else{
      dispatch(getEmployeeList("category",filterVal))
    }
    setState({
      ...state,
      employeeListFilter:filterVal,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const NewEmployee = () => {
    history.push("/admin/employeedetails/new")
  }
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        title={messages["sidebar.employee"]}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
              <ListMenu 
                  MenuList={MenuList} 
                  messages={messages} 
                  activeFilter={employeeListFilter} 
                  newData={NewEmployee}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  onChangeFilter={onChangeFilter}
                />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              <SearchListSidebar>
                <Cards headless>
                  <div className="chatbox-search">
                    <Input  style={{width:'100%'}} onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                  </div>
                  <Spin spinning={employeeListLoading} delay={100}>
                    <ListContent style={{height:'710px'}}>
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={renderThumbHorizontal}
                        renderThumbVertical={renderThumbVertical}
                        renderView={renderView}
                        renderTrackVertical={renderTrackVertical}
                      >
                        <ListWrapper>
                          <ul>
                            {dataSource &&
                              dataSource.length ? 
                              dataSource
                                .sort((a, b) => {
                                  return a.firstname.localeCompare - (b.firstname);
                                })
                                .map((employee, key) => {
                                  const { firstname, lastname, mobileno,task, email,status,_id,img,department } = employee;
                                  return (
                                    <li key={key} className="chat-link-signle" >
                                      <NavLink to={`/admin/employeedetails/${_id}`}>
                                        <div className="author-figure">
                                          <img src={img ? img : blank} alt="" />
                                          <span className={true ? 'active' : 'inactive'} />
                                        </div>
                                        <div className="author-info">
                                          <span className="author-name" style={{display: 'block'}}>{firstname+" "+lastname}</span>
                                          <span className="author-chatText" >{department}</span>
                                          <p className="author-chatText" >{task}</p>
                                        </div>
                                        <div className="author-chatMeta">
                                          <span>{email}</span>
                                          <p>{mobileno}</p>
                                          <Tag color={status==="false"?"red":"green"} >{messages["user."+status]}</Tag>

                                        </div>
                                      </NavLink>
                                    </li>
                                  );
                                }):null}
                          </ul>
                        </ListWrapper>
                      </Scrollbars>
                    </ListContent>
                  </Spin>
                </Cards>
              </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 
    </>
  );
}



export  {Employee};
