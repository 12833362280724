import {
    TASK_GET_LIST,
    TASK_GET_LIST_SUCCESS,
	TASK_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	taskList: [],
	taskListLoading:false,
	taskListStatus:null
};

const taskList =(state = INIT_STATE, action) => {
	switch (action.type) {
		case TASK_GET_LIST: // GEL LIST 
			return { 
				...state, 
				taskListLoading: true ,
				taskListStatus:null
			};
		case TASK_GET_LIST_SUCCESS:
            return { ...state, 
                taskListLoading: false, 
				taskListStatus:200,
                taskList: action.response.tasklist
            };
		case TASK_GET_LIST_ERROR:
			return { 
				...state, 
				taskListLoading: false,
				taskListStatus:action.response 
			};
		default: {
			return state
		}
	}
}
export default taskList