import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus,faEdit,faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,Spin, Select} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';

import {getMaintenanceList} from '../../../redux/maintenance/maintenanceListActions';


const MaintenanceDatabase = ({messages}) => {
  const {maintenanceLoading,maintenanceList,maintenanceStatus} = useSelector(state => state.maintenanceList);
  let data = maintenanceList && maintenanceList.sort((a, b) => a.no - b.no).map((el, idx) => ({key: idx, ...el}))
  const dispatch = useDispatch();
  const optionList =[
    {label : "OrderList",value:"/ordersv3/maintenance"}
  ]
  //******************************************************************************************************************************************************************/
  const [state, setState] = React.useState({
    url:null
  })
  //******************************************************************************************************************************************************************/
  const handlechangeList = filterValue => {
    setState({
      ...state,
      url:filterValue
    })
  }
  //******************************************************************************************************************************************************************/
  const getList = () => {
    dispatch(getMaintenanceList(state.url,"get"));
  }
  //******************************************************************************************************************************************************************/
  const EntryControl = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no', 
      align: 'left',
      render: (text) =>text,
      width: '10%',
    },
    {
      title: messages["buying.qcQuestion"],
      dataIndex: 'Question',
      key: 'Question', 
      align: 'left',
      render: (text) =>text,
      width: '70%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
         // onClick={()=>removeEntryControlRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];
  //******************************************************************************************************************************************************************/

  return (
    <div className="full-width-table">
      <Cards
        title={messages["sidebar.maintenance"]}
        bodypadding="0px"
        loading={maintenanceLoading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >

        <Select style={{padding:'20px',width:'70%'}} options={optionList} onSelect={(eData)=>handlechangeList(eData)}/>
        <Button onClick={()=>getList()} disabled={maintenanceLoading} loading={maintenanceLoading}>{messages["button.get"]}</Button>
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'670px'}}>
          <Table 
            columns={EntryControl} 
            dataSource={data} 
            pagination={false} 
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  );
}

export {MaintenanceDatabase};
