import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Timeline,Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTruck, faShop,faSpinner, faQuestion} from '@fortawesome/free-solid-svg-icons';
import { NoteCardWrap ,TimeLinePointerIconWrap} from '../../Style/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';

import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';

const  DeliveryList = ({messages,height}) => {
  const {deliveryList,deliveryListLoading,DeliveryListStatus} = useSelector(state => state.deliveryList);
  const rtl  = useSelector(state => state.ChangeLayoutMode.rtlData);
  const left = !rtl ? 'left' : 'right';


 

  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };

  return (

    <Cards title={deliveryList.length ? deliveryList.length + messages["dashboard.deliveriedlist"]: "Teslimatlar "} loading={deliveryListLoading && <Spin indicator={faSpinner} />} >
      <NoteCardWrap style={{height:'450px'}}>
        <Scrollbars
          className="custom-scrollbar"
          autoHide
          autoHideTimeout={500}
          autoHideDuration={200}
          renderThumbHorizontal={renderThumbHorizontal}
          renderThumbVertical={renderThumbVertical}
          renderView={renderView}
          renderTrackVertical={renderTrackVertical}
        >

            <ListTableRow key={index}>
              <Row gutter={10}>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <Popover placement="right" title={<h5>{company_id && company_id.company_name}</h5>} content={content}>
                    <div className="material-column">
                      <h1>{material_category}</h1>
                    </div>
                  </Popover>
                </Col>
                <Col xxl={6} lg={6} md={6} sm={24} xs={24}>
                  <div className="material-column">
                    <h1>{material_group}</h1>
                    <h1> {material && material.material_name ? material.material_name:<Tag className='red' size="small">Malzeme Seçilmemiş</Tag>}</h1>
                  </div>
                </Col>
                
                <Col xxl={3} lg={3} md={3} sm={24} xs={24}>
                  <div className="material-column-center">
                    <Tag className = {controlList.length > 0 && componentRow ? controlList[componentRow.controlled].color:"blue"} >{messages["order.componentstatus"+componentRow.controlled]}</Tag>
                  </div>
                </Col>
 
                <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
                  <div className="material-column-center">
                    <h1>{componentRow.totalQty.toFixed(2) + " " + (material&& material.material_unit ? material.material_unit : "Birim") } </h1>
                  </div>
                </Col>
  
                {material && material.stockSum ? 
                <Col xxl={4} lg={4} md={4} sm={12} xs={24}>
                  <div className="material-column-center">
                    <h1>{(material && material.stockSum ? material.stockSum.toFixed(2) : 0 )+" "+(material&& material.material_unit && material.material_unit)}</h1>
                  </div>
                </Col>:
                <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
                  <div className="material-column-center">
                    ---
                  </div>
                </Col>
                }
                <Col xxl={2} lg={2} md={2} sm={12} xs={24}>
                  <div className="action-column">
                    <Button shape="circle" className='btn-info' href={"/admin/projectdetails/"+order_id} >
                      <FontAwesomeIcon icon={faSquareArrowUpRight} />
                    </Button>  
                    <Popconfirm
                      title={messages["alert.deletequest"]}
                      okText={messages["genel.yes"]}
                      cancelText={messages["genel.no"]}
                      onConfirm={()=>deleteComponentListRow(_id)}
                    >
                      <Button shape="circle">
                        <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
                      </Button>  
                    </Popconfirm>
                  </div>
                </Col>
              </Row>
            </ListTableRow>







          <TimeLinePointerIconWrap  style={{height:height}}>
            <Timeline>
              {deliveryList.length ? deliveryList
              .sort((a, b) => {
                return  new Date(b.date)-new Date(a.date)
              })
              .map((delivery,index)=>{
                const {productname,qty,unit,date,status,deliverer,_id,company_id} = delivery;
                //console.log(delivery)
                let icon = faTruck; let color = "#5F63F2"; let link ="/admin/companydetails/"+(company_id &&company_id._id && company_id._id);
                if(status===0){
                  icon = faTruck;
                  color= "#2C99FF"

                }else if(status<0){
                  icon = faTruck;
                  color= "#FF69A5";
                }
                return(
                <Timeline.Item className="primary" dot={<FontAwesomeIcon size="1x" icon={icon} color={color} />} key={index}>
                  <NavLink to={link}>
                    <h3>{company_id && company_id.company_name && company_id.company_name}</h3>
                  </NavLink>
                  <p>{qty +" "+unit +" " + productname +" "+ moment(date).format("DD.MM.YYYY")+" tarihinde "+deliverer +" tarafından teslim edilmiş."}</p>
                </Timeline.Item>
              )}):null }
            </Timeline>
          </TimeLinePointerIconWrap>
        </Scrollbars>
      </NoteCardWrap>
    </Cards>
 
  );
}

export  {DeliveryList};
