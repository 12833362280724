import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';


const styles = StyleSheet.create({
   
      LabelContainer: {
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
        height: 20,
        width:550
    },

   
});
const ProductDetails = styled.Text`
paddingLeft:2px;
margin-right: 2px;
font-size: 10px;
text-align: left;
width: 200px;
`;
const Date = styled.Text`
paddingLeft:2px;
margin-left: 2px;
font-size: 10px;
text-align: left;
width: 60px;
`;
const ImgLabel = styled.Text`
paddingLeft:2px;
margin-left: 2px;
font-size: 10px;
text-align: left;
width: 30px;
`;
const ProductQty = styled.Text`
paddingLeft:2px;
font-size: 10px;
text-align: center;
width: 60px;
`;
const Name = styled.Text`
paddingLeft:2px;
margin-right: 2px;
font-size: 10px;
text-align: right;
width: 90px;
`;
  const InvoiceItemsTable = ({messages}) => (
    
    <View style={styles.LabelContainer}>
        <ImgLabel>{messages["images.image"]}</ImgLabel>
        <ProductDetails>{messages["product.name"]}</ProductDetails>
        <Date>{messages["genel.date"]}</Date>
        <ProductQty>{messages["genel.qty"]}</ProductQty>
        <Name>{messages["delivery.deliverer"]}</Name>
        <Name>{messages["delivery.receiver"]}</Name>
    </View>
  );
  
  export default InvoiceItemsTable