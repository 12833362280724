import React from 'react';
import { Page, Document, StyleSheet, Font, View ,Image} from '@react-pdf/renderer';
import QRCode from 'qrcode';
//************************************************ */
import styled from '@react-pdf/styled-components';
import moment from "moment";
import ArialBold from "../font/arial-bold.ttf"
import Arial from "../font/arial.ttf"
import GillSans from "../font/gillsans.ttf"
import OpenSansFont from "../font/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../font/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });
Font.register({ family: 'ArialBold', src: ArialBold  });
Font.register({ family: 'Arial', src: Arial  });
Font.register({ family: 'GillSans', src: GillSans  });


const styles = StyleSheet.create(
    {
    page: {
        padding: 10,
    }, 
    Header: {
        width:270,
        height:60,
        flexDirection: "row",
        alignItems:"center",
        alignSelf:"center",
        borderBottomWidth: 2
    },
    logo: {
        width: 270,
        height: 55
    },
    ProductInfoContainer: {
        fontFamily: 'ArialBold',
        marginTop:10,
        flexDirection: "column",
        width: 260,
        height: 50,
        alignItems:"center",
        alignSelf:"center",
        borderBottomWidth: 2
    },
    productImage: {
        width: 70,
        height: 70,
        alignItems:"left",
    },
    productText: {
        width: 190,
        height: 70,
        flexDirection: "column",
        alignSelf:'auto',
    },
    Column1: {
        marginLeft:5,
        width:260,
        height:82,
        flexDirection: 'row',
        alignItems:"center",
        borderBottomWidth: 2
    },
    Column2: {
        marginTop:10,
        width:260,
        height:40,
        flexDirection: 'column',
        alignItems:"center",
        borderBottomWidth: 2
    }
});
const Label10px = styled.Text`
margin_left:2px;
height:15;
font-size: 10px;
text-align: left;
`;
const Label14px = styled.Text`
margin_left:2px;
height:50;
font-size: 14px;
text-align:center;
margin:auto; 
`;

const Label10pxCenter = styled.Text`
margin_left:2px;
height:12;
font-size: 10px;
text-align: center;
`;
const Label8px = styled.Text`
margin_Top:2px;
margin_left:2px;
height:10;
font-size: 8px;
text-align: left;
`;

const Label10x10 = ({company,data,messages}) =>{
    let value = data._id ? window.location.origin+"/admin/materialdetails/"+data._id : "www.moduled.com.tr";
    let QrImage = "";
    QRCode.toDataURL(value, function(err, url) {
        QrImage=  url;
    });
    return (
    <Document>
        {company.bwlogo && data  && 
        <Page size={[ 283.4 ,  283.4 ]} orientation={"landscape"} style={styles.page}>
            <View style={styles.Header}>
                <View style={styles.Header}>
                    <Image style={styles.logo} src={company.bwlogo}/>    
                </View>
            </View>
            <View style={styles.ProductInfoContainer}>
                <Label14px>{data.material_name.toUpperCase()}</Label14px>
            </View>   


            <View style={styles.Column1}>
                <View style={styles.productImage}>
                    {QrImage && <Image  src={QrImage}/>}  
                </View>
                <View style={styles.producttext}>
                    <Label10px>Malzeme Kategori :{(data.material_category).toUpperCase()}</Label10px>
                    <Label10px>Malzeme Grubu : {data.material_group.toUpperCase()}</Label10px>
                    <Label10px>Malzeme Birimi : {(messages["genel."+data.material_unit]).toUpperCase()}</Label10px>
                </View>



            </View>
            <View style={styles.Column2}>
                <Label10pxCenter>www.moduled.com.tr</Label10pxCenter>
                <Label10pxCenter> {moment(new Date()).format("DD.MM.YYYY")}</Label10pxCenter>
            </View>
        </Page>
        }
    </Document>
)};
  
export default Label10x10