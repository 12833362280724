import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from 'moment';
const styles = StyleSheet.create({
   
    TitleContainer:{
        marginTop: 10,
        flexDirection: "row",
        borderBottomWidth: 0.5,
        width:'550px',
        borderColor:"blue"
    },
});

const Titlestyle = styled.Text`
    margin: 0px;
    font-size: 12px;
    text-align: center;
    font-family:'OpenSansFontBold';
`;
const date = new Date();
  const Title = () => (
    
    <View style={styles.TitleContainer}>
        <Titlestyle> ÜRETİM LİSTESİ - {moment(date).format("DD.MM.YYYY")} </Titlestyle>
    </View>
  );
  
  export default Title