import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Spin } from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpDownLeftRight,faSpinner} from '@fortawesome/free-solid-svg-icons';
import { NoteCardWrap } from '../../Style/style';
import {NoteCard} from './Card';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { noteDragData } from '../../../redux/note/actionCreator';
import { Scrollbars } from 'react-custom-scrollbars';
const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={faUpDownLeftRight} size="1x" />);

const SortableItem = SortableElement(({ value ,onUpdateTodo,User,onDeleteTodo,messages}) => (
  <Col xxl={8} xl={12} lg={12} sm={12} xs={24} key={value.key}>
    <NoteCard Dragger={DragHandle} data={value} onUpdateTodo={onUpdateTodo} User={User} onDeleteTodo={onDeleteTodo} messages={messages}/>
  </Col>
));

const SortableList = SortableContainer(({ items,onUpdateTodo,User,onDeleteTodo,messages }) => {
  return (
    <Row gutter={24}>
      {items && items.length && items.map((value, index) => (
        <SortableItem key={`item-${value._id}`} index={index} value={value} onUpdateTodo={onUpdateTodo} onDeleteTodo={onDeleteTodo} User={User} messages={messages}/>
      ))}
    </Row>
  );
});

const  NoteData = ({toDoData,onUpdateTodo,User,messages,loading,onDeleteTodo}) => {


  const dispatch = useDispatch();
  const rtl  = useSelector(state => state.ChangeLayoutMode.rtlData);
  const left = !rtl ? 'left' : 'right';

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(noteDragData(arrayMove(toDoData, oldIndex, newIndex)));
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };

  return (
    <Cards title={messages["dashboard.notelist"]+ " "+(toDoData && toDoData.length && toDoData.length) +" Adet Not var !!"}  loading={loading && <Spin indicator={faSpinner} />}>
      <NoteCardWrap style={{height:'460px'}}>
        <Scrollbars
          className="custom-scrollbar"
          autoHide
          autoHideTimeout={500}
          autoHideDuration={200}
          renderThumbHorizontal={renderThumbHorizontal}
          renderThumbVertical={renderThumbVertical}
          renderView={renderView}
          renderTrackVertical={renderTrackVertical}
        >
          <SortableList useDragHandle axis="xy" items={toDoData} onSortEnd={onSortEnd} onUpdateTodo={onUpdateTodo} onDeleteTodo={onDeleteTodo} User={User} messages={messages}/>
        </Scrollbars>
      </NoteCardWrap>
    </Cards>
  );
}

export  {NoteData};
