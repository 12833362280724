import React from 'react';
import { Page, Document, StyleSheet, Font, View,Image } from '@react-pdf/renderer';
//************************************************ */
import Header from '../../common/reportHeaders/A5QCHeader';
import Info from './Info';
import Title from './Title';
import Information from './Information';
import Questions from './Questions';
import Score from './Score';
import Result from './Result';
import Foother from './Foother';
import OpenSansFont from "../../font/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../../font/ClearSans/ClearSans-Regular.ttf";
import SourceSansPro from "../../font/SourceSansPro-It.ttf"


Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });
Font.register({ family: 'SourceSansPro', src: SourceSansPro  });

const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'OpenSansFontBold',
        paddingTop: 10,
        paddingLeft:10,
        paddingRight:10,
        flexDirection: 'column'
    }, 
    Foother: {
        paddingTop: 10,
        paddingLeft:10,
        position: 'absolute',
        flexDirection: "column",
        alignItems:'center',
        height:180,
        bottom:30

    }
  });

const QControl = ({data,company,messages,index}) =>{
    return (
    <Document>
        <Page size="A5"  style={styles.page}>
            <Header company={company} messages={messages} data={data} index={index}/> 
            <Title  title="İDARİ KONTROL"/>
            <Info data={data}  index={index}/>
            <Title  title="KALİTE KONTROL"/>
            <Questions company={company} data={data.received[index]}/>

            <View style={styles.Foother} fixed>
                <Score data={data.received[index]}/>
                <Information/>
                <Result data={data.received[index]}/>
                <Foother data={data.received[index]}/>
            </View>
           
        </Page>
    </Document>
)};
  
export default QControl