import {
    PRODUCT_GET_LIST,
    PRODUCT_GET_LIST_SUCCESS,
	PRODUCT_GET_LIST_ERROR,

} from '../actions';
const INIT_STATE = {
	productList: [],
	productListLoading:false,
	productListStatus:null
};

const productList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCT_GET_LIST: // GEL LIST 
			return { ...state, 
				productListLoading: true,
				productListStatus:null,
			};
		case PRODUCT_GET_LIST_SUCCESS:
            return { ...state, 
                productListLoading: false, 
                productList: action.response,  
				productListStatus:200
            };
		case PRODUCT_GET_LIST_ERROR:
			return { ...state, 
				productListLoading: false,
				productListStatus:action.response 
			};
		default: return state 
	}
}
export default productList
