import React from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import { useSelector, useDispatch } from 'react-redux';
import {Spin} from 'antd';
import WorkOrderList from "./productionList/productList";
import QRCode from 'qrcode';

const OrderListReport = ({data}) => {
  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);
  const [dataSource, setDataSource] = React.useState();

  React.useEffect(() => {
    let orderList =[];
    if(data.length ){
      for (let i of data.filter(x=>x.tamamlanmaOrani<100)) {
       // console.log(i)
        let row = {};
        row.Operations= i.Operations;
        row.productImage = i.listimage && i.listimage.length && i.listimage[0].image;
        row.product=i.urun_adi;
        row.company=i.offer ? i.offer.firma_adi.slice(0,50):" Stok Üretimi / Alt Montaj ";
        row.productCode=i.urun_kodu;
        row.Qty = i.urun_miktari;
        row.Unit = i.urun_birimi;
        row.orderItemID = i._id;
        row.orderStatus  = i.siparis_durumu;
        row.componentList = i.componentList;
        let value = window.location.origin+"/admin/projectdetails/"+i._id;
        QRCode.toDataURL(value, function(err, url) {
          row.QRimage=  url;
        });
        orderList.push(row); 
        //console.log(row)
      }
      setDataSource(orderList)
    }

  },[]);          

    return   !myCompanyLoading && dataSource ? (
        <React.Fragment>
          <PDFViewer width={'100%'}height={600}>
              <WorkOrderList data={dataSource} company ={myCompany} />
          </PDFViewer>
        </React.Fragment>   
    ) : (
      <div className="spin">
        <Spin />
      </div>
    );
    
}

export {OrderListReport};