import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Row, Col, Form, Input,Spin, DatePicker,Select } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import CustomInput from '../../../helpers/customInput';
import {getCompany} from '../../../redux/company/myCompanyActions';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const  Profile = ({data,employeeDetailLoading,messages,updateData,setSubmittable}) => {
const [form] = Form.useForm();
const dispatch = useDispatch();
const { tasks,departments } = useSelector(state => state.myCompany.myCompany);
tasks && !tasks.length && dispatch((getCompany({id:1})));
React.useEffect(() => {
  form.setFieldsValue(data)
 }, [ data])      
 const values = Form.useWatch([], form);
 React.useEffect(() => {
   form.validateFields({ validateOnly: true }).then(
     () => {
       setSubmittable(true);
     },
     () => {
       setSubmittable(false);
     },
   );
 }, [values]);

  return (
    <Cards
      title={<Heading as="h5">{messages["user.editprofile"]}</Heading>}
      loading={employeeDetailLoading && <Spin indicator={faSpinner}  />}
      bodyStyle={{ minHeight: '730px' }}
    >
      <FormWrapper>
        <Spin spinning={employeeDetailLoading} delay={100}>
          <Form form={form} initialValues={data}>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["employee.idno"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="idno" rules={[{ required: true, message: 'Please input your IDNO !' }]}>
                    <CustomInput mask="999 999 999 99" placeholder="___ ___ ___ __" onChange={(eData)=>updateData("idno",eData.target.value)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["user.firstname"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="firstname" rules={[{ required: true, message: 'Please input your First Name!' }]}>
                    <Input onChange={(eData)=>updateData("firstname",eData.target.value)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["user.lastname"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="lastname" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                    <Input onChange={(eData)=>updateData("lastname",eData.target.value)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["employee.birddate"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="birddate" rules={[{ required: true, message: 'Please input your Bird Date!' }]}>
                    <DatePicker
                      format="DD.MM.YYYY"
                      style={{width:"100%", display:'inline-block'}}
                      onChange={(eData)=>updateData("birddate",eData)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["employee.startdate"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="startdate" rules={[{ required: true, message: 'Please input your Start Date!' }]}>
                    <DatePicker
                      format="DD.MM.YYYY"
                      style={{width:"100%", display:'inline-block'}}
                      onChange={(eData)=>updateData("startdate",eData)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["employee.enddate"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="enddate">
                    <DatePicker
                      format="DD.MM.YYYY"
                      style={{width:"100%", display:'inline-block'}}
                      onChange={(eData)=>updateData("enddate",eData)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["employee.task"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="task" rules={[{ required: true, message: 'Please input personel TASK!' }]}>
                    <Select  options={tasks && tasks.length && tasks.map(x=>{return({label:x.tasks,value:x.tasks})})} onChange={(eData)=>updateData("task",eData)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col md={8} xs={24}>
                  <label htmlFor="input-text">{messages["employee.department"]}</label>
                </Col>
                <Col md={16} xs={24}>
                  <Form.Item name="department" rules={[{ required: true, message: 'Please input personel DEPARTMENT !' }]}>
                    <Select  options={departments && departments.length && departments.map(x=>{return({label:x.department,value:x.department})})} onChange={(eData)=>updateData("department",eData)}/>
                  </Form.Item>
                </Col>
              </Row>
    



    
          </Form>
        </Spin>
      </FormWrapper>
    </Cards>
  );
}

export {Profile};
