import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';


const BackGround = ''
const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        height: '120px',
        width:'550px',
        backgroundColor: BackGround,
    },
    DetailsContainer: {
        flexDirection: 'row',
        height: '120px',
        width:'550px',
        backgroundColor: BackGround,
    },    
    DetailsRowContainer: {
        flexDirection: 'column',
        height: '120px',
        border: 0.5,
        width:'300px',
        backgroundColor: BackGround,
    },    
    ProductImage: {
        flexDirection: 'column',
        width: '95px',
        height: '120px',
        marginLeft: '0',
        backgroundColor: BackGround,
        border : 0.5,
        justifyContent:'center'
    },
    QtyContainer: {
        flexDirection: 'column',
        width: '95px',
        height: '120px',
        marginLeft: '0',
        backgroundColor: BackGround,
        border : 0.5,
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center'

    },
    QRImages: {
        flexDirection: 'column',
        width: '95px',
        height: '120px',
        marginLeft: '0',
        backgroundColor: BackGround,
        border : 0.5,
        justifyContent:'center'
    },

  });


const Product = styled.Text`
paddingLeft:5px;
margin-top: 2px;
font-size: 10px;
text-align: left;
font-family:'OpenSansFontBold';
color: #0C71E0;
`;
const Company = styled.Text`
paddingLeft:5px;
margin-top: 2px;
margin-buttom: 2px;
font-size: 10px;
text-align: left;
font-family:'OpenSansFontBold';
color: #A92205;
`;
const Component = styled.Text`
paddingLeft:5px;
margin-top: 2px;
margin-buttom: 2px;
font-size: 8px;
text-align: left;
font-family:'OpenSansFontBold';
color: #0000ff;
`;
const Spec = styled.Text`
paddingLeft:5px;
margin-top: 2px;
margin-buttom: 2px;
font-size: 8px;
text-align: left;
font-family:'OpenSansFontBold';
color: #7b000b;
`;
const ProductCode = styled.Text`
paddingLeft:5px;
margin-top: 1px;
margin-buttom: 1px;
font-size: 8px;
text-align: left;
font-family:'OpenSansFontBold';
`;
const Oty = styled.Text`
paddingLeft:5px;
font-size: 14px;
text-align: center;
font-family:'OpenSansFontBold';

`; 
const Unit = styled.Text`
paddingLeft:5px;
margin-top: 2px;
font-size: 10px;
text-align: center;
font-family:'OpenSansFontBold';
`; 
const OrderTableRow = ({data}) => {
    const item = data;

    const ModelKodu = item &&  item.productCode&&  item.productCode.length && item.productCode[0] ? item.productCode[0] :"";
    const PCBKodu =  item && item.productCode&&  item.productCode.length &&item.productCode[1] ? ("-"+item.productCode[1]):"";
    const Optik =  item && item.productCode&&  item.productCode.length &&item.productCode[2] ? ("-"+item.productCode[2]):"";
    const CCT  =  item && item.productCode&&  item.productCode.length &&item.productCode[3] ? ("-"+item.productCode[3]):"";
    const Solar  =  item && item.productCode&&  item.productCode.length &&item.productCode[4] ? ("-"+item.productCode[4]):"";
    const Battery  =  item && item.productCode&&  item.productCode.length &&item.productCode[5] ? ("-"+item.productCode[5]):"";

    const BodyColor  =  item && item.productCode&&  item.productCode.length &&item.productCode[6] ? ("Gövde Rengi :"+item.productCode[6]):"Gövde Rengi :Standart";
    const LEDColor  =  item && item.productCode&&  item.productCode.length &&item.productCode[3] ? ("LED Rengi :"+item.productCode[3]+" K"):"LED Rengi :Sorunuz";
    const LEDDriver  =  item && item.productCode&&  item.productCode.length &&item.productCode[8] ? ("LED Sürücü :"+item.productCode[8]):"LED Sürücü :Sorunuz";

    return(
     
    <View style={styles.rowContainer} wrap={false} >

    
        <View style={styles.DetailsContainer}>
            <View style={styles.ProductImage}>
                {item.productImage && <Image src={item.productImage}/>}
            </View>
            <View style={styles.DetailsRowContainer}>
                <Company>{(item.company).substring(0,60)}</Company>
                <Product>{item.product}</Product>
                <ProductCode>{ModelKodu+PCBKodu+Optik+CCT+Solar+Battery}</ProductCode>
                <Spec>{BodyColor +" "+ LEDColor}</Spec>
      
                <Spec></Spec>
                {data.componentList && data.componentList.length && data.componentList.map((component,index)=>{
                    const material = component.materialSelectList && component.materialSelectList.length && component.materialSelectList.filter(x=>x._id===component.material && (x.material_category==="LED" || x.material_category==="LED Sürücü" || x.material_category==="SSC" || x.material_category==="PCB"))[0];
                    return(
                        <Component key={index}>{material && material.material_category +" : "+ (material && material.material_name ? material.material_name  :"Malzeme Seçiniz")+" =>"+(material && material.stockSum ? material.stockSum +" Adet Stokta":"")}</Component>
                    )})
                }  
            </View>
            <View style={styles.QtyContainer}>
                <Oty>{item.Qty}</Oty>
                <Unit>{item.Unit+" Üretilecek "}</Unit>
            </View>
            

            <View style={styles.QRImages}>
                <Image src={item.QRimage}/>

       
            </View>

        </View>
    </View>  
)
};
  
export default OrderTableRow