import React from 'react';
import { View, StyleSheet,Image,Link } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const BoderColor = '#E7E7E7'

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 20,
        borderColor:BoderColor,
        borderBottomWidth:0.5,
        marginBottom:2
    },
    ProductImages: {
        width: 20,
        height: 20,
        padding:3,
        borderColor:BoderColor,
        border:0.5,
        borderRadius:17.5,
    },
    ProductNameContainer: {
        flexDirection: 'column',
        width: 235,
        height: 20,
    },
    QTYContainer: {
        flexDirection: 'column',
        width: 50,
        height: 20,
    },
    ProductPriceContainer: {
        flexDirection: 'column',
        width: 60,
        height: 20,
        fontFamily:'ClearSans'
    },
    totalContainer: {
        flexDirection: 'column',
        width: 75,
        height: 20,
        fontFamily:'ClearSans'
    },
    gtotalContainer: {
        flexDirection: 'column',
        width: 75,
        height: 20,
        fontFamily:'ClearSans'
    }
  });


  const NoImage = styled.Text`
  paddingLeft:2px;
  paddingTop:10px;
  margin-top: 2px;
  font-size: 10px;
  text-align: center;
  width:20px;
  `;
  const ProductName = styled.Text`
  paddingLeft:2px;
  margin-top: 1px;
  font-size: 8px;
  text-align: left;
  `;
 
  const Qty = styled.Text`
  marginTop:3px;
  font-size: 8px;
  text-align: center;
  width:50px;
  `;

  const Tax = styled.Text`
  font-size: 8px;
  text-align: center;
  width:25px;
  `;


  const DovizPrice = styled.Text`
  font-size: 8px;
  text-align: right;
  width:60px;
  font-family:'ClearSans';
  `;
  const TLPrice = styled.Text`
  font-size: 8px;
  text-align: right;
  width:60px;
  color:red;
  `;

  const TLTotal = styled.Text`
  font-size: 8px;
  text-align: right;
  width:75px;
  color:red;
  `;
  const DovizTotal = styled.Text`
  font-size: 8px;
  text-align: right;
  width:75px;
  font-family:'ClearSans';
  `;
const OfferTableRow = ({data,doviz,messages}) => {

    const OfferrCurrency = doviz === "$" ?  'USD' :  doviz === "€" ? 'EUR' : "TRY"
    const ProductCurrency = data.urun_dovizi === "$" ?  'USD' :  data.urun_dovizi === "€" ? 'EUR' : "TRY"
    const tlopen = false;

    const urun_fiyati = (Number(data.urun_fiyati).toLocaleString('tr-TR', { style: 'currency', currency: ProductCurrency }))
    const toplamDoviz = (Number(data.toplamDoviz).toLocaleString('tr-TR',{ style: 'currency', currency: OfferrCurrency }))
    const GtoplamDoviz = (Number(data.toplamDoviz+data.toplamKdvDoviz).toLocaleString('tr-TR',{ style: 'currency', currency: OfferrCurrency }))


    const urun_miktari = Number(data.urun_miktari).toLocaleString('tr');

    

    const urun_fiyatiTL = (Number(data.toplamTL/data.urun_miktari).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }));
    const totalTL = (Number(data.toplamTL).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }));
    const gtotalTL = (Number(data.toplamTL+data.toplamKdvTL).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }));


    let img = "../assets/images/ry.png" ;
    if(data.urun_id &&  data.urun_id.productImages && data.urun_id.productImages[0].image  && data.urun_id.productImages[0].image.smallimage ){
        img = data.urun_id.productImages[0].image.smallimage;
    }else{
        img = "../assets/images/ry.png" ;
    }
    

    return(

        <View style={styles.rowContainer}>
            {img ? 
             <Link src={"https://moduledv2.akaysis.com/web/"+(data  && data.urun_id  ? data.urun_id._id : "")}><Image style={styles.ProductImages} src={img}/></Link>
            : <NoImage>  </NoImage>}
            
            <View style={styles.ProductNameContainer}>
                <ProductName style={styles.ProductName}>{data.urun_adi}</ProductName>
            </View >
            <View style={styles.QTYContainer}>
                <Qty>{urun_miktari} {messages["genel."+data.urun_birimi]} </Qty>
            </View >
            <Tax>{"%"+data.urun_kdv}</Tax>
            {tlopen ?
            <View style={styles.ProductPriceContainer}>
                <DovizPrice>{urun_fiyati}</DovizPrice>
                <TLPrice>{urun_fiyatiTL}</TLPrice>
            </View>
            :

                  <DovizPrice>{doviz === '₺' ? urun_fiyatiTL : urun_fiyati}</DovizPrice>

            }
            {tlopen?
            <View style={styles.totalContainer}>
                <DovizTotal>{toplamDoviz}</DovizTotal>
                <TLTotal>{totalTL}</TLTotal>
            </View>:
                <DovizTotal>{toplamDoviz}</DovizTotal>
            }           
             {tlopen?
            <View style={styles.totalContainer}>
                <DovizTotal>{GtoplamDoviz}</DovizTotal>
                <TLTotal>{gtotalTL}</TLTotal>
            </View>:
                <DovizTotal>{GtoplamDoviz}</DovizTotal>
            }            


        </View>

  
)};
  
export default OfferTableRow