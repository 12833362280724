import {
   EMPLOYEE_GET_LIST,
   EMPLOYEE_GET_LIST_SUCCESS,
	EMPLOYEE_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	employeeList: [],
	employeeListLoading:false,
	employeeListStatus:null
};

const EmployeeList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case EMPLOYEE_GET_LIST: // GEL LIST 
			return { 
				...state, 
				employeeListLoading: true,
				employeeListStatus:null 
			};
		case EMPLOYEE_GET_LIST_SUCCESS:
            return { 
				...state, 
                employeeListLoading: false, 
                employeeList: action.response.employee,  
				employeeListStatus:200
            };
		case EMPLOYEE_GET_LIST_ERROR:
			return { 
				...state, 
				employeeListLoading: false ,
				employeeListStatus:action.response
			};
		default: return  state;
	}
}
export default EmployeeList