import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,NavLink,Link ,useHistory} from 'react-router-dom';
import { Row, Col ,Button,message,notification,Popconfirm,Tooltip} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faList, faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { MenuListWrapper} from '../Style/style';
import { Main, } from '../Style/styled';
import {getCompanyDetails,updateCompany,deleteCompany,createCompany} from '../../redux/companyDetails/companyDetailActions';
import {DetailMenu} from "../commonComponent/detailMenu"
import {getDeliveryList} from '../../redux/deliveryList/deliveryListActions';
import {getCompanyOfferList} from '../../redux/offerList/offerListActions';
import {createDelivery,updateDelivery, deleteDelivery,getdeliveryDetails} from '../../redux/deliveryDetails/deliveryDetailActions';

import {CompanyGenerals} from "./companies/companyGenerals";
import {CompanyDelivery} from "./companies/companyDelivery";
import {CompanyContact} from "./companies/companyContact";
import {CompanyNotes} from "./companies/companyNotes";
import {CompanyOffers} from "./companies/companyOffers";
import {CompanyOrders} from "./companies/companyOrders";
import blank from '../../static/img/resyok.jpeg';
import { DeliveryModal } from './companies/deliveryModal';
import { DeliveryListModal } from './companies/deliveryListModal';
//const CoverSection = lazy(() => import('../overview/CoverSection'));


const CompanyDetails = ( ) => {
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { data, loading,status } = useSelector(state => state.companyDetail);
  const [dataSource, setDataSource] = React.useState(data);
  const {deliveryList,deliveryListLoading,deliveryListStatus} = useSelector(state => state.deliveryList);
  const { deliveryDetailStatus,deliveryDetailLoading} = useSelector(state => state.deliveryDetails);
  const {deliveryStatus,CompanyGroup,CurrencyList,UnitList}= useSelector(state => state.App);
  const {tcmb} = useSelector(state => state.tcmb);
  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const USDAlis = tcmb && tcmb.Data[0].ForexBuying;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const EURAlis = tcmb && tcmb.Data[1].ForexBuying;

  const [delivery, setDelivery] = React.useState(null);
  
  const [submittable, setSubmittable] = React.useState(false);
  const { companyID } = useParams();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  let history = useHistory();
  const lang = locale === 'tr' ? 0 :1

  const [state, setState] = React.useState({
    deliveryModalIndex:null,
    deliveryModalActive:null,
    deliveryListModalActive:null,
    openedDeliveryView:true,
    
    collapsed:false,
    tabID:"general"
  });
  const  mainImage = dataSource && dataSource.company_img ? dataSource.company_img : blank;
  const {collapsed,tabID} = state;
  const initData = {
    company_name:"",
    company_adress:"",
    company_district:"",
    company_email:"",
    company_city:"",
    company_country:"Türkiye",
    company_category:"Customer",
    company_phone:"",
    company_mobilephone:"",
    company_fax:"",
    company_taxoffice:"",
    company_taxno:"",
    company_img:"",
    company_notes:"",
    company_status:true,
}
const MenuList =[
  {tabID:"general",MenuName:messages["company.profile"]},
  {tabID:"contact",MenuName:messages["company.contact"]},
  {tabID:"delivery",MenuName:messages["company.deliveryList"]},
  {tabID:"offers",MenuName:messages["sidebar.offers"]},
  {tabID:"orders",MenuName:messages["offer.OfferStatus1"]},
  {tabID:"notes",MenuName:messages["company.notes"]}
]

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(data)
  },[data]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (companyID !=="new" && dataSource._id !== companyID) {
      message.loading(messages["alert.updated"]);
      dispatch((getCompanyDetails({companyId:companyID})));
    }else{
      if(companyID ==="new"){
        setDataSource(initData)
      }
    }
  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(status && status.status===401){
      notification.warning({
        message: status.statusText,
        description: status.data,
        duration:1
      });
      history.push({pathname:"/admin/401"})
    }else if (status && status===200){
      notification.info({
        message: 'Bilgi !',
        description:'İşlem Başarılı..',
      });   
    }else if(status && status.status===404){
      notification.warning({
        message: status.statusText,
        description: status.data,
        duration:1
      });
      history.push({pathname:"/admin/404"})
    }
  }, [status]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(deliveryListStatus===401 ){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Teslimat için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
    }
  }, [deliveryListStatus]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    if(page==='delivery'){
      dispatch(getDeliveryList("",companyID))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      });
    }else if(page==='offers'){
      dispatch(getCompanyOfferList(companyID,0,0))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      });
    }else if(page==='orders'){
      dispatch(getCompanyOfferList(companyID,1,3))
      .then(res=>{
        message.loading(messages["alert.updated"]);
      });
    }
    setState({
      ...state,
      tabID:page,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  const saveCompany = () => {
    if(dataSource._id || companyID!=='new'){
      dispatch(updateCompany(dataSource))
        .then(res=>{
          message.success(messages["alert.savesuccess"], 2 );
        })
    }else{
      if(companyID==="new"){
        dispatch(createCompany(dataSource,history))
        .then(res=>{
          message.success(messages["alert.createsuccess"], 2 );
        })        
      }
    }
  }
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val) =>{

    if(name==="companyListModalActive"){
      setState({
        ...state,
        [name]: val,
        companyListModalActive:true
      });
    }else if (name==="proModalIndex"){
      setState({
        ...state,
        [name]: val,
        proModalActive:true
      });
      !materials.length && dispatch(getMaterialsList('list',null));
    }else if (name==="imageModalIndex"){
      setState({
        ...state,
        [name]: val,
        imageModalActive:true
      });
    }else if (name==="deliveryModalIndex"){
      setState({
        ...state,
        [name]: val,
        deliveryModalActive:true
      });
    }else if (name==="deliveryModalActive"){
      setState({
        ...state,
        [name]: val,
      });
    }else if (name==="deliveryListModalActive"){
      setState({
        ...state,
        [name]: val,
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const onDeleteCompany = () => {
    if(dataSource._id){
      dispatch(deleteCompany(dataSource._id))
      .then(res=>{
        history.push("/admin/companies")
      })
    }
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  function convertBase64  (file)  {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  //******************************************************************************************************************************************************************/
  const beforeUpload = async (file) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      message.error('Image must smaller than 500Kb!');
      return false;
    }
    const base64 = await convertBase64(file)
    setDataSource({
      ...dataSource,
      company_img:base64
    })
    message.success('Image uploaded successfully!');


    return true;
  }
  //******************************************************************************************************************************************************************/
  const Submittable = (e) => {
    setSubmittable(e)
  }
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    let value = null ;
    if (Field ==="company_name" || Field ==="company_district" || Field ==="company_city" || Field ==="company_adress" ){
      var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
      let parca = eData.replace(/(([İIŞĞÜÇÖ]))/g, function(letter){ return letters[letter]; });
      parca = parca.toLowerCase();
      parca = parca.split(" ")

      for(let i=0 ; i<parca.length ; i++){
        let j = parca[i];
        var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
        j = j.replace(/(([iışğüçö]))/g, function(letter){ return letters[letter]; });
        j = j.charAt(0).toUpperCase();
          
        parca[i] = j + parca[i].substr(1).toLowerCase();
      }
        value = parca.join(" ");
    }else{
      value=eData;
    }
    setDataSource({
      ...dataSource,
      [Field]:value
    });
  }
  //******************************************************************************************************************************************************************/
  const onSaveDelivery = (eData) => {
    if(eData._id){
      dispatch(updateDelivery(eData))
      .then(res => {
        setTimeout(() => {
          dispatch(getDeliveryList("",companyID))
        }, 100);
        message.success(messages["alert.createsuccess"],2);
        setState({
          ...state,
          deliveryModalActive:null,
        })
      })
    }else{
      dispatch(createDelivery(eData))
      .then(res => {
        setTimeout(() => {
          dispatch(getDeliveryList("",companyID))
        }, 100);
        message.success(messages["alert.createsuccess"],2);
        setState({
          ...state,
          deliveryModalActive:null,
        })
      })
    }
  }
  

  //******************************************************************************************************************************************************************/
  return (
    
    <>
      <PageHeader
        title={dataSource.company_name ? dataSource.company_name : "Yükleniyor"}
        buttons={[
          <div key={0}>
              <Popconfirm
                title={messages["alert.deletequest"]}
                okText={messages["genel.yes"]}
                cancelText={messages["genel.no"]}
                onConfirm={()=>onDeleteCompany()}
              >
                  <Button shape="circle"  size='large' icon={<FontAwesomeIcon icon={faTrash} color="#ce1818"/>} style={{marginRight:'10px'}}/>
              </Popconfirm>
              <Tooltip title= {messages["button.save"]} placement="left">
                  <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faSave}/>} disabled={!submittable} onClick={()=>saveCompany()} style={{marginRight:'10px'}}/>
              </Tooltip>
              <Tooltip title= {messages["sidebar.dashboard"]} placement="left">
                <NavLink to="/admin/companies">
                  <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faList}/>}/>
                </NavLink>
              </Tooltip>
          </div>
        ]}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
              <DetailMenu 
                MenuList={MenuList} 
                data0="" 
                data1={messages["company."+dataSource.company_category]} 
                messages={messages} 
                image={mainImage} 
                imageField={"company_img"}
                imageWidth={100}
                tabID={tabID}
                beforeUpload={beforeUpload}
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
                gotoPage={gotoPage}
              />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              
              {tabID==="general" ? 
                <CompanyGenerals
                  messages={messages}
                  lang={lang}
                  loading={loading}
                  company={dataSource}
                  CompanyGroup={CompanyGroup}
                  CurrencyList={CurrencyList}
                  updateData={updateData}
                  Submittable={Submittable}
                  setSubmittable={Submittable}
                />
              :tabID==="contact" ? 
                <CompanyContact
                  messages={messages}
                  lang={lang}
                  loading={loading}
                  data={dataSource}
                  updateData={updateData}
                  Submittable={Submittable}
                  setSubmittable={Submittable}
                />
              :tabID==="delivery" ? 
                <CompanyDelivery
                  messages={messages}
                  lang={lang}
                  deliveryListLoading={deliveryListLoading}
                  deliveryList={deliveryList}
                  toggleModal={toggleModal}
                  deliveryStatus={deliveryStatus}
                  companyID={companyID}
                  company_doviz= {dataSource.company_doviz}
              
                />   
              :tabID==="notes" ? 
                <CompanyNotes
                  messages={messages}
                  lang={lang}
                  loading={loading}
                  data={dataSource}
      
                />              
              :tabID==="offers" ? 
                <CompanyOffers
                  messages={messages}
                  lang={lang}
                  loading={loading}
            
      
                />    
              :tabID==="orders" ? 
              <CompanyOrders
                messages={messages}
                lang={lang}
                loading={loading}
          
    
              />    
            :null}
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 
      {state.deliveryModalActive && 
      <DeliveryModal
        messages={messages}
        delivery={deliveryList[Number(state.deliveryModalIndex)]} 
        UnitList={UnitList}
        CurrencyList={CurrencyList}
        deliveryModalActive={state.deliveryModalActive}
        deliveryDetailStatus={deliveryDetailStatus}
        deliveryDetailLoading={deliveryDetailLoading}
        toggleModal={toggleModal}
        USDSatis={USDSatis}
        EURSatis={EURSatis}
        USDAlis={USDAlis}
        EURAlis={EURAlis}
        onSaveDelivery={onSaveDelivery}
      />}
      {state.deliveryListModalActive && 
      <DeliveryListModal
        messages={messages}

        deliveryListModalActive={state.deliveryListModalActive}

        toggleModal={toggleModal}
        openedDeliveryView={state.openedDeliveryView}
        companyDetail={dataSource}
    

      />}
    </>
  );
}



export  {CompanyDetails};
