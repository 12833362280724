import {
    GET_ACTEST_LIST,
    GET_ACTEST_LIST_SUCCESS,
	GET_ACTEST_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	lastCheckList: [],
	lastCheckListLoading:true,
	lastCheckListStatus:null
};

const acTestList =   (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_ACTEST_LIST: // GEL LIST 
			return { 
				...state, 
				lastCheckListLoading: true,
				lastCheckListStatus:null };
		case GET_ACTEST_LIST_SUCCESS:
            return { 
				...state, 
                lastCheckListLoading: false, 
                lastCheckList: action.response.actest,
				lastCheckListStatus: 200
            };
		case GET_ACTEST_LIST_ERROR:
			return { 
				...state, 
				lastCheckListLoading: false ,
				lastCheckListStatus:action.response
			};
		default: {
			return state
		}
	}
}
export default acTestList
