import {
    GET_PRODUCTIMAGE_LIST,
    GET_PRODUCTIMAGE_LIST_SUCCESS,
	GET_PRODUCTIMAGE_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	productImageList: [],
	productImageListStatus:null,
	productImageListLoading:false
};

const imageList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_PRODUCTIMAGE_LIST: // GEL LIST 
			return { 
				...state, 
				productImageListLoading: true,
				productImageListStatus:null
			};
		case GET_PRODUCTIMAGE_LIST_SUCCESS:
            return { 
				...state, 
                productImageListLoading: false, 
                productImageList: action.response.img,
				productImageListStatus:200
            };
		case GET_PRODUCTIMAGE_LIST_ERROR:
			return { 
				...state, 
				productImageListLoading: false,
				productImageListStatus:action.response
			};
		default: {
			return state
		}
	}
}
export default imageList