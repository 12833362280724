export const getProductCode = (category,model,urun_kodu,optik,cct,panel,aku) => {
  let code;
  if(category===0){
    code =   model+"-"+urun_kodu+"-"+optik+"-"+cct
  }else if(category===1){
    code =   model+"-"+urun_kodu+"-"+optik+"-"+cct
  }else if(category===2){
    code =   model+"-"+urun_kodu+"-"+optik+"-"+cct
  }else if(category===3){
    code =   model+"-"+urun_kodu+"-"+optik+"-"+cct+"-"+panel+"-"+aku
  }else if(category===4){
    code =   model+"-"+urun_kodu+"-"+optik+"-"+cct
  }else if(category===5){
    code =   model+"-"+urun_kodu+"-"+optik+"-"+cct
  }else if(category===6){
    code =   model+"-"+urun_kodu+"-"+optik+"-"+cct
  }else if(category===7){
    code =   model+"-"+urun_kodu
  }else if(category===8){
    code =   model+"-"+urun_kodu
  }else {
    
    code =   (!model || model==="" ? "" : (model))+(!urun_kodu || urun_kodu==="" ? "" : ("-"+urun_kodu))+(!optik || optik==="" ? "" : ("-"+optik))+(!cct || cct==="" ? "" : ("-"+cct))+(!panel || panel===null ? "" : ("-"+panel))+(!aku || aku===null ? "" : ("-"+aku))
  }
  return code;
};
