import {
	COMPANY_LIST_GET_LIST,
	COMPANY_LIST_GET_LIST_SUCCESS,
	COMPANY_LIST_GET_LIST_ERROR
} from '../actions';

const INIT_STATE = {
	companyList:[],
	companyListLoading:false,
	companyListStatus:null
};
const companyList =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case COMPANY_LIST_GET_LIST: // GEL LIST 
			return { 
				...state, 
				companyListLoading: true ,
				companyListStatus:null};
		case COMPANY_LIST_GET_LIST_SUCCESS:
			return { ...state, 
				companyListLoading: false, 
				companyListStatus:200,
				companyList: action.response.companyList,
			};
		case COMPANY_LIST_GET_LIST_ERROR:
			return { 
				...state,
				companyListLoading:false, 
				companyListStatus:action.response 
			};

		default:{
			return state
		}
	}
}

export default companyList