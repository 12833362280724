import React from 'react';
import { Row, Col,Spin} from 'antd';
import { AccountWrapper } from '../../Style/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import { BasicFormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

ReactQuill.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ReactQuill.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]
const Mission =({data,loading,messages,onChangeEditorData,lang})=> {
  return (
      <Cards
        title={<Heading as="h5">{messages["sidebar.mission"]}</Heading>}
        bodyStyle={{ minHeight: '730px' }}
        loading={loading && <Spin indicator={faSpinner}  />}
      >
        <ReactQuill
          theme="snow"
          value={data.mission[lang]}
          onChange={(e)=>onChangeEditorData(e)}
          modules={ReactQuill.modules}
          formats={ReactQuill.formats}
        />
      </Cards>

  );
}

export  {Mission};
