import React from 'react';
import { Form, Modal,Button, Radio } from 'antd';
import { BasicFormWrapper } from '../../Style/style';
const ReportSetting = ({ reportSettingModalActive,messages,toggleModal,offer,offerReportType,setOfferReportType,printOffers,onChangeSetting,onSaveOffer}) => {


  return (
  <>
  {reportSettingModalActive && 
    <Modal
      title={messages["genel.edit"]}
      className="sDash_addTask-modal"
      type='primary'
      open={reportSettingModalActive}
      footer={null}
      onCancel={() => toggleModal("reportSettingModalActive",null)}
      forceRender
      closable={false}
    >
      <BasicFormWrapper>
   
          <Form.Item label={messages["offer.reporttype"]} >
            <div className="radio-size-wrap" style={{marginBottom:"20px"}}>
              <div>
                <Radio.Group value={offerReportType} onChange={(e)=>setOfferReportType(e.target.value)}>
                  <Radio.Button value={0}>T0</Radio.Button>
                  <Radio.Button value={1}>T1</Radio.Button>
                  <Radio.Button value={2}>T2</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </Form.Item>
          <Form.Item label={messages["offer.viewDate"]} >
            <div className="radio-size-wrap" style={{marginBottom:"20px"}}>
              <div>
                <Radio.Group defaultValue={offer.teklif_tarih} onChange={(e)=>onChangeSetting("teklif_tarih",e.target.value)}>
                  <Radio.Button value={true}>{messages["genel.true"]}</Radio.Button>
                  <Radio.Button value={false}>{messages["genel.false"]}</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </Form.Item>
          <Form.Item label={messages["offer.stampOffer"]} >
            <div className="radio-size-wrap" style={{marginBottom:"20px"}}>
              <div>
                <Radio.Group value={offer.teklif_kase} onChange={(e)=>onChangeSetting("teklif_kase",e.target.value)}>
                  <Radio.Button value={true}>{messages["genel.true"]}</Radio.Button>
                  <Radio.Button value={false}>{messages["genel.false"]}</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </Form.Item>

          <Form.Item label={messages["offer.stampsignOffer"]} >
            <div className="radio-size-wrap" style={{marginBottom:"20px"}}>
              <div>
                <Radio.Group value={offer.teklif_kaseimza} onChange={(e)=>onChangeSetting("teklif_kaseimza",e.target.value)}>
                  <Radio.Button value={true}>{messages["genel.true"]}</Radio.Button>
                  <Radio.Button value={false}>{messages["genel.false"]}</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </Form.Item>

          <Form.Item label={messages["offer.commercialInvoice"]} >
            <div className="radio-size-wrap" style={{marginBottom:"20px"}}>
              <div>
                <Radio.Group value={offer.commercial_invoice} onChange={(e)=>onChangeSetting("commercial_invoice",e.target.value)}>
                  <Radio.Button value={true}>{messages["genel.true"]}</Radio.Button>
                  <Radio.Button value={false}>{messages["genel.false"]}</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </Form.Item>

          <div className="sDash-modal-actions">
            <Button  size="small" type="primary" onClick={() => onSaveOffer()} >
              {messages["button.save"]}
            </Button>
            <Button  size="small" type="primary" onClick={() => printOffers()} >
              {messages["button.print"]}
            </Button>
          </div>
 
      </BasicFormWrapper>
    </Modal>
  }
  </>
  );
}

export  {ReportSetting};
