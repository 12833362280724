import {
	LIST_MAINTENANCE,
    MAINTENANCE_SUCCESS,
	MAINTENANCE_ERROR,
} from '../actions';

const INIT_STATE = {
	maintenanceLoading: false, 
	maintenanceList:[],  
	maintenanceStatus:null,
};

const  maintenanceList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LIST_MAINTENANCE: // GET LIST 
			return { 
				...state, 
				maintenanceLoading: true ,
				maintenanceStatus:null
			};
		case MAINTENANCE_SUCCESS:
            return { 
				...state, 
                maintenanceLoading: false, 
                maintenanceList: action.response.resData,  
				maintenanceStatus:200,
            };
		case MAINTENANCE_ERROR:
			return { 
				...state, 
				maintenanceLoading: false ,
				maintenanceStatus:action.response
			};
		default: {
			return state
		}
	}
}
export default maintenanceList
