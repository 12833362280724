import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {NavLink,useHistory} from 'react-router-dom';
import { Row, Col ,message,Button,notification,Tooltip} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSave, faHome} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { MenuListWrapper} from '../Style/style';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import {getCompany,updatemyCompany} from '../../redux/company/myCompanyActions';
import {Profile} from "./CompanyDetail/myCompanyProfile";
import {CompanyContact} from "./CompanyDetail/myCompanyContact";
import {CompanyTaxInfo} from "./CompanyDetail/myCompanyTaksInfo";
import {ReportImages} from "./CompanyDetail/myCompanyReportImages";
import {Certificates} from "./CompanyDetail/myCompanyCertificates";
import {EntryControl} from "./CompanyDetail/myCompanyEntryControl";
import {EntryControlModal} from "./CompanyDetail/entryControlModal";
import {About} from "./CompanyDetail/myCompanyAbout";
import {QualityPolicy} from "./CompanyDetail/myCompanyQualityPolicy";
import {Vision} from "./CompanyDetail/myCompanyVision";
import {Mission} from "./CompanyDetail/myCompanyMission";
import {MaintenanceDatabase} from "./CompanyDetail/maintenanceDatabase";
import {DetailMenu} from '../commonComponent/detailMenu';
import { Departments } from './CompanyDetail/myCompanyDepartments';
import { DepartmentModal } from './CompanyDetail/departmentModal';
import { TaskModal } from './CompanyDetail/taskModal';
//const CoverSection = lazy(() => import('../overview/CoverSection'));

const CompanyProfile = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();
  const { myCompany, myCompanyLoading,myCompanyStatus } = useSelector(state => state.myCompany);
  const [dataSource, setDataSource] = React.useState(myCompany);
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const [submittable, setSubmittable] = React.useState(false);
  const [state, setState] = React.useState({
    collapsed:false,
    tabID:"profile",
    entryControlRowModalActive:null,
    entryControlIndex:null,
    departmentRowIndex:null,
    departmenRowModalActive:null,
    taskRowModalActive:null,
    taskRowIndex:null
  });
  const MenuList =[
    {tabID:"profile",MenuName:messages["company.profile"]},
    {tabID:"contact",MenuName:messages["company.contact"]},
    {tabID:"taksinfo",MenuName:messages["company.taxinfo"]},
    {tabID:"report",MenuName:messages["company.reportsetting"]},
    {tabID:"certificates",MenuName:messages["company.certificates"]},
    {tabID:"entrycontrol_qc",MenuName:messages["company.entryqc"]},
    {tabID:"departments",MenuName:messages["company.departments"]},
    {tabID:"about",MenuName:messages["sidebar.about"]},
    {tabID:"qualitypolicy",MenuName:messages["sidebar.qualitypolicy"]},
    {tabID:"vision",MenuName:messages["sidebar.vision"]},
    {tabID:"mission",MenuName:messages["sidebar.mission"]},
    {tabID:"maintenance",MenuName:messages["sidebar.maintenance"]},
  ]
  const lang = locale === 'tr' ? 0 :1
  const {tabID,collapsed} = state;
  //******************************************************************************************************************************************************************/  
  React.useEffect(() => {
      dispatch((getCompany({id:1},null,history)));
      message.loading(messages["alert.updated"],3,)
  }, []);
  //******************************************************************************************************************************************************************/  
  React.useEffect(() => {
    setDataSource(myCompany)
  }, [myCompany]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(myCompanyStatus===401 ){
      notification.warning({
        message: 'Uyarı !',
        description:'Bu işlem için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }else if (myCompanyStatus && myCompanyStatus===200){
      notification.info({
        message: 'Bilgi !',
        description:'İşlem Başarılı ..',
      });   
    }else if(myCompanyStatus && myCompanyStatus.status===404){
      notification.warning({
        message: myCompanyStatus.statusText,
        description: myCompanyStatus.data,
        duration:1
      });
      history.push({pathname:"/admin/404"})
    }
    
  }, [myCompanyStatus]);
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    setDataSource({
      ...dataSource,
      [Field]:eData
    });
  }
  //******************************************************************************************************************************************************************/
  const onChangeEditorData = (values) => {
    let tempData = dataSource
    if(tabID==='about'){
      tempData.about[lang] = values;
    }else if(tabID==='qualitypolicy'){
      tempData.qualitypolicy[lang] = values;
    }else if(tabID==='vision'){
      tempData.vision[lang] = values;
    }else if(tabID==='mission'){
      tempData.mission[lang] = values;
    }
    setDataSource({
      ...dataSource,
      tempData
    })
  };
  //******************************************************************************************************************************************************************/
  const SaveData = () => {
    dispatch(updatemyCompany({...dataSource}));
    
  };
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    setState({
      ...state,
      tabID:page,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  function convertBase64  (file)  {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  //******************************************************************************************************************************************************************/
  const beforeUpload = async (name,file) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      message.error('Image must smaller than 500Kb!');
      return false;
    }
    const base64 = await convertBase64(file)
    setDataSource({
      ...dataSource,
      [name] : base64
    })
    message.success('Image uploaded successfully!');
    return true;
  }
  //******************************************************************************************************************************************************************/
  const UploadRowFile = async (key,name,file) => {
    const isJPG = file.type === 'image/png';
    if (!isJPG) {
      message.error('You can only upload PNG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 0.2;
    if (!isLt2M) {
      message.error('Image must smaller than 200Kb!');

      return false;
    }
    const base64 = await convertBase64(file)
    let tempdata = dataSource.certificates
    tempdata[key][name] = base64;
    setDataSource({
     ...dataSource,
     certificates:tempdata
   });
   message.success('Image uploaded successfully!');
    return true;
  }
 //******************************************************************************************************************************************************************/
 const updateRowField =(key, e) => {
  let tempdata = dataSource.certificates
  tempdata[key].certificate = e.target.value;
    setDataSource({
      ...dataSource,
      certificates:tempdata
    });
  };
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,index) =>{
    if(name==="entryControlRowModalActive"){
        setState({
          ...state,
          entryControlRowModalActive:val,
          entryControlIndex:index
        });
      }else if(name==="departmenRowModalActive"){
        setState({
          ...state,
          departmenRowModalActive:val,
          departmentRowIndex:index
        });
      }else if(name==="taskRowModalActive"){
        setState({
          ...state,
          taskRowModalActive:val,
          taskRowIndex:index
        });
      }
  };
  //******************************************************************************************************************************************************************/
  const updateEntryControlRow = (values) => {
    let tempdata = dataSource.QCQuestions;
    tempdata[state.entryControlIndex].no = values.no;
    tempdata[state.entryControlIndex].Question = values.Question;
    setDataSource({
      ...dataSource,
      QCQuestions:tempdata
    });
    setState({
      ...state,
      entryControlIndex: null,
      entryControlRowModalActive:null
    });

  }
  //******************************************************************************************************************************************************************/
  const removeEntryControlRow = (index) => {
    let temp = dataSource.QCQuestions;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        QCQuestions:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const addNewEntryControlRow = () => {
    let temp = dataSource.QCQuestions;
    temp.push({
      no:temp.length ? temp.length +1 :1,
      Question:"",
    })
    setDataSource({
      ...dataSource,
      QCQuestions:temp
    });
    setState({
      ...state,
      entryControlIndex: temp.length-1,
      entryControlRowModalActive:true
    });
  }
  //******************************************************************************************************************************************************************/
  const addNewDepartmentRow = () => {
    let temp = dataSource.departments;
    temp.push({
      no:temp.length ? temp.length +1 :1,
      department:"",
      color:"green"
    })
    setDataSource({
      ...dataSource,
      departments:temp
    });
    setState({
      ...state,
      departmentRowIndex: temp.length-1,
      departmenRowModalActive:true
    });
  }
  //******************************************************************************************************************************************************************/
  const updateDepartmentRow = (values) => {
    let tempdata = dataSource.departments;
    tempdata[state.departmentRowIndex].no = values.no;
    tempdata[state.departmentRowIndex].department = values.department;
    tempdata[state.departmentRowIndex].color = values.color;
    setDataSource({
      ...dataSource,
      departments:tempdata
    });
    setState({
      ...state,
      departmentRowIndex: null,
      departmenRowModalActive:null
    });

  }
  //******************************************************************************************************************************************************************/
  const removeDepartmentRow = (index) => {
    let temp = dataSource.departments;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        departments:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const addNewTaskRow = () => {
    let temp = dataSource.tasks;
    temp.push({
      no:temp.length ? temp.length +1 :1,
      tasks:"",
      color:""
    })
    setDataSource({
      ...dataSource,
      tasks:temp
    });
    setState({
      ...state,
      taskRowIndex: temp.length-1,
      taskRowModalActive:true
    });
  }
  //******************************************************************************************************************************************************************/
  const removeTaskRow = (index) => {
    let temp = dataSource.tasks;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        tasks:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateTaskRow = (values) => {
    let tempdata = dataSource.tasks;
    tempdata[state.taskRowIndex].no = values.no;
    tempdata[state.taskRowIndex].tasks = values.tasks;
    tempdata[state.taskRowIndex].color = values.color;
    setDataSource({
      ...dataSource,
      tasks:tempdata
    });
    setState({
      ...state,
      taskRowIndex: null,
      taskRowModalActive:null
    });

  }
  //******************************************************************************************************************************************************************/
  const Submittable = (e) => {
    setSubmittable(e)
  }
 //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        title={messages["company.profile"]}
        buttons={[
          <div key={0}>
            <Tooltip title= {messages["button.save"]} placement="left" >
                <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faSave}/>} disabled={!submittable} onClick={()=>SaveData()} style={{marginRight:'10px'}}/>
            </Tooltip>
            <Tooltip title= {messages["sidebar.dashboard"]} placement="left">
              <NavLink to="/admin">
                <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faHome}/>}/>
              </NavLink>
            </Tooltip>
          </div>
        ]}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={8} lg={8} xs={24}>
              <DetailMenu 
                  MenuList={MenuList} 
                  data0={null}
                  data1={null}
                  messages={messages} 
                  image={dataSource.sirket_rapor_logo} 
                  imageField={"sirket_rapor_logo"}
                  imageWidth={70}
                  tabID={tabID}
                  beforeUpload={beforeUpload}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  gotoPage={gotoPage}
                />
            </Col>
            <Col xxl={18} xl={16} lg={16} xs={24}>
              {tabID==="profile" ? 
                <Profile
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  updateData={updateData}
                  setSubmittable={Submittable}
                />
              :tabID==="contact" ? 
                <CompanyContact
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  updateData={updateData}
                  setSubmittable={Submittable}
                />   
              :tabID==="taksinfo" ?      
                <CompanyTaxInfo
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  updateData={updateData}
                  setSubmittable={Submittable}
                />
              :tabID==="report" ?     
                <ReportImages
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  SaveData={SaveData}
                  beforeUpload={beforeUpload}
                  updateData={updateData}
                />
              :tabID==="certificates" ?   
                <Certificates
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  UploadRowFile={UploadRowFile}
                  updateRowField={updateRowField}
                />
              :tabID==="entrycontrol_qc" ?   
                <EntryControl
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  toggleModal={toggleModal}
                  entryControlRowModalActive={state.entryControlRowModalActive}
                  entryControlIndex={state.entryControlIndex}
                  SaveData={SaveData}
                  removeEntryControlRow={removeEntryControlRow}
                  addNewEntryControlRow={addNewEntryControlRow}
                />
              :tabID==="departments" ?   
                <Departments
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  toggleModal={toggleModal}
                  departmenRowModalActive={state.departmenRowModalActive}
                  departmentRowIndex={state.departmentRowIndex}
                  SaveData={SaveData}
                  removeDepartmentRow={removeDepartmentRow}
                  addNewDepartmentRow={addNewDepartmentRow}
                  addNewTaskRow={addNewTaskRow}
                  removeTaskRow={removeTaskRow}
                />
              :tabID==="about" ? 
                <About
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  onChangeEditorData={onChangeEditorData}
                  onSaveData={SaveData}
                  lang={lang}
                />
              :tabID==="qualitypolicy" ? 
                <QualityPolicy
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  onChangeEditorData={onChangeEditorData}
                  onSaveData={SaveData}
                  lang={lang}
                />
              :tabID==="vision" ? 
                <Vision
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  onChangeEditorData={onChangeEditorData}
                  onSaveData={SaveData}
                  lang={lang}
                />
              :tabID==="mission" ? 
                <Mission
                  loading={myCompanyLoading}
                  data={dataSource}
                  messages={messages}
                  onChangeEditorData={onChangeEditorData}
                  onSaveData={SaveData}
                  lang={lang}
                />
              :tabID==="maintenance" ? 
                <MaintenanceDatabase
                  messages={messages}
                />
              :null}
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 
      {state.entryControlRowModalActive && 
      <EntryControlModal
        entryControlRowModalActive={state.entryControlRowModalActive}
        question={dataSource.QCQuestions[state.entryControlIndex]}
        messages={messages}
        updateEntryControlRow={updateEntryControlRow}
      />}
      {state.departmenRowModalActive && 
      <DepartmentModal
        departmenRowModalActive={state.departmenRowModalActive}
        department={dataSource.departments[state.departmentRowIndex]}
        messages={messages}
        updateDepartmentRow={updateDepartmentRow}
        toggleModal={toggleModal}
      />}
      {state.taskRowModalActive && 
      <TaskModal
        taskRowModalActive={state.taskRowModalActive}
        tasks={dataSource.tasks[state.taskRowIndex]}
        messages={messages}
        updateTaskRow={updateTaskRow}
        toggleModal={toggleModal}
      />}
    </>
  );
}



export  {CompanyProfile};
