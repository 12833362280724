import React from 'react';
import { Page, Document, StyleSheet, Font, View } from '@react-pdf/renderer';

//************************************************ */
import Header1 from '../common/reportHeaders/Header1'
import styled from '@react-pdf/styled-components';
import DeliveryRow from './deliveryRows';
//import blank  from '@iso/assets/images/resyok.jpeg';

import RobotoRegular from "../font/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../font/Roboto/Roboto-Bold.ttf";
import OpenSansFont from "../font/OpenSansFont/OpenSans-Light.ttf";
import OpenSansFontRegular from "../font/OpenSansFont/OpenSans-Regular.ttf";
import OpenSansFontBold from "../font/OpenSansFont/OpenSans-Semibold.ttf";
import ClearSans from "../font/ClearSans/ClearSans-Regular.ttf";

Font.register({ family: 'ClearSans', src: ClearSans  });
Font.register({ family: 'OpenSansFont', src: OpenSansFont  });
Font.register({ family: 'OpenSansFontRegular', src: OpenSansFontRegular  });
Font.register({ family: 'OpenSansFontBold', src: OpenSansFontBold  });
Font.register({ family: 'RobotoRegular', src: RobotoRegular  });
Font.register({ family: 'RobotoBold', src: RobotoBold  });


const styles = StyleSheet.create(
    {
    page: {
        fontFamily: 'RobotoRegular',
        paddingTop: 20,
        paddingLeft:20,
        paddingRight:20,
        flexDirection: 'column'
    }, 
    ViewContainer: {
        marginTop: 3,
        flexDirection: "row",
        width:550,
    },     
    ColumnContainer: {
        marginTop: 2,
        flexDirection: "column",
        border: 1,
        width:550
    },
    rowContainer: {
        flexDirection: 'row',
        height: '14px',
        marginTop:3,
        width:549,
        borderBottom:1
    },

    Foother: {
        position: 'absolute',
        flexDirection: "row",
        border:0.3,
        width:550,
        alignItems:'center',
        bottom: 30,
        right:30,
        height:60
    }
  });
  const Label = styled.Text`
  padding:3px;
  font-size:7px;
  text-align:left;
  height:18px;
  `;

  const Text60px8px = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: left;
  width:60px;
  `;
  const Text30px8px = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: center;
  width:30px;
  `;
  const Text60px8pxR = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: right;
  width:60px;
  `;
  const Text200px8px = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: left;
  border-left: 0.3;
  width:200px;
  `;

const DeliveryListReport = ({data,company,messages,companyDetail}) =>{

        let deliverylist = [];

        let totalTL = 0 ; 
        let totalUSD = 0 ;
        for (let i of data) { 
          if (companyDetail.company_doviz ==="₺"){
            if(i.sumPriceTl && i.status<2){
              totalTL = totalTL + i.sumPriceTl
            }
          }else{
            if(i.sumPriceTl && i.status<2){
              totalUSD = totalUSD + i.sumPriceUsd
            }
          }
          let tempData={
            _id:i._id,
            date : i.date,
            productname : i.productname,
            qty : i.qty,
            unit : i.unit,
            price : i.price,
            doviz : i.doviz,
            usd : i.usd,
            eur : i.eur,
            sumPrice : i.sumPrice,
            sumPriceTl : i.sumPriceTl,
            sumPriceUsd : i.sumPriceUsd,
            status : i.status,
            receiver : i.receiver,
            deliverer : i.deliverer,
            runTotal: companyDetail.company_doviz==="₺" ? totalTL : totalUSD
          }
          deliverylist.push(tempData);
        };
    return (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header1 company={company} Dil={"tr"} messages={messages}/>

            <Label>Teslimat Listesi : {companyDetail.company_name} </Label>
            <View style={styles.ColumnContainer}>
                <View style={styles.rowContainer}>
                    <Text60px8px>Tarih</Text60px8px>
                    <Text200px8px>Ürün </Text200px8px>
                    <Text30px8px>Miktar</Text30px8px>
                    <Text60px8pxR>B.Fiyat</Text60px8pxR>
                    <Text60px8pxR>Toplam</Text60px8pxR>
                    <Text60px8pxR>Doviz Toplam</Text60px8pxR>
                    <Text60px8pxR>Bakiye</Text60px8pxR>
                </View>
            </View>
                {deliverylist && deliverylist.map((row,index)=>{
                    return (
                        row.status < 2 && <DeliveryRow key={index} row={row}/>
                    )
                })}
        </Page>
    </Document>
)};
  
export default DeliveryListReport