import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, Modal,Button,Select,Row,Col } from 'antd';
import { FormWrapper } from '../../Style/style';
import {getProductList} from '../../../redux/productList/productListActions';
import {getMaterialsList} from '../../../redux/materials/materialsActions';
import {getModelList} from '../../../redux/modelList/modelListActions';
const ProductionStepModal = ({ productionSteps,productionStepModalActive,messages,toggleModal,updateProductionStep}) => {
  const dispatch = useDispatch();
  const [opNameList, setOpNameList] = React.useState();
  const [materialOptions, setmaterialOptions] = React.useState();
  const [productOptions, setproductionOptions] = React.useState();

  const {materialList,materialListLoading} = useSelector(state => state.materials);
  const {productList} = useSelector(state => state.productList);
  const {modelList,modelListLoading} = useSelector(state => state.modelList);

  const getMaterials = () => {
    dispatch(getProductList("list","model_category"));
    dispatch(getMaterialsList('list',null));
  }

  React.useEffect(() => {
    dispatch(getModelList("productionlist",""));
   }, [])


  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    let materialTemp =[];
    if(materialList.length){
      [...new Map(materialList && materialList.map(x=> [x.material_category,x])).values()].forEach(row => {
          materialTemp.push({
              value: row.material_category,
              label: row.material_category
          });
      });
    }
    if(productList.length){
      let productsTemp =[];
      [...new Map(productList && productList.map(x=> [x.model_category,x])).values()].forEach(row => {
        productsTemp.push({
              value: row.model_category,
              label: messages["model.kategori"+row.model_category]
          });
      });
      
      setproductionOptions(productsTemp)
      setmaterialOptions(materialTemp)
    }
    let _oplist =[];
    for (let x of  modelList) {
        if(x.productiondetail){
            for (let i of x.productiondetail) { 
            _oplist.push(i.Operation)
            }
        }
    }
    let operationList=[];
    [...new Map(_oplist && _oplist.map(x=> [x,x])).values()].forEach(row => {
      operationList.push({
            value: row,
            label: row
      });
    });
    //operationList.push({value:"Yarı Mamül",label:"Yarı Mamül"});
    setOpNameList(operationList)
    // eslint-disable-next-line
  }, [materialList,productList,modelList]);
  //******************************************************************************************************************************************************************/
    const [form2] = Form.useForm();
    React.useEffect(() => {
      form2.setFieldsValue(productionSteps)
    }, [productionSteps]);

  //console.log(production.Operation==="Yarı Mamül",productOptions,materialOptions)
  return (
  <>
  {productionStepModalActive &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={productionStepModalActive}
      footer={
        <Button  size="large" type="primary" onClick={() => toggleModal("productionStepModalActive",null)}>
          {messages["button.close"]}
        </Button>}
      closable={false}
      onCancel={() => toggleModal("productionStepModalActive",null)}
      forceRender
      width={600}
    >
      <FormWrapper style={{height:'auto'}}>
        <Form form={form2} initialValues={productionSteps} >
          <Row align="middle"  gutter={15}>
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["genel.sira"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name='no'>
                <Input type='number' onChange={(eData)=>updateProductionStep("no",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle"  gutter={15}>
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["product.operation"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name='operation'>
                  <Select options={opNameList} onChange={(eData)=>updateProductionStep("operation",eData)} loading={modelListLoading}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle"  gutter={15}>
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["product.operationdescription"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name='operationdescription'>
              <Input onChange={(eData)=>updateProductionStep("operationdescription",eData.target.value)}/>
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle"  gutter={15}>
            <Col md={8} xs={24}>
              <label htmlFor="input-text">{messages["product.OperationComponent"]}</label>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item name='operationcomponent'>
                <Select 
                  mode="multiple" 
                  loading={materialListLoading}
                  options={productionSteps.operation==="Yarı Mamül" ? productOptions:materialOptions} 
                  onChange={(eData)=>updateProductionStep("operationcomponent",eData)}
                  onClick={()=>getMaterials()}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {ProductionStepModal};
