import React from 'react';
import { Col, Row, Modal,Button, Form, Divider,Switch } from 'antd';
import { DeliveryList } from '../../../reports/DeliveryListReport';

const DeliveryListModal = ({messages,deliveryListModalActive,toggleModal,openedDeliveryView,companyDetail}) => {
  const [form9] = Form.useForm();
  return (
  
    deliveryListModalActive && 
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={deliveryListModalActive}
      footer={          
        <div>
          <Button  size="large" type="primary" onClick={() => toggleModal("deliveryListModalActive",null)}>
            {messages["button.close"]}
          </Button>
        </div>}
      onCancel={() => toggleModal("deliveryListModalActive",null)}
      forceRender
      width={1000}
      closable={false}
    >
          <Row align="middle"  gutter={15}>
            <Col md={6} xs={24}>
              <Form form={form9} >
 
    
                <Row align="middle">
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["company.logo"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Switch checked={openedDeliveryView} />
                  </Col>
                </Row>



              </Form>
            </Col>
            <Col md={18} xs={24}>
               <DeliveryList companyDetail={companyDetail} />
            </Col>
          </Row>


      
    </Modal>
  );
}

export  {DeliveryListModal};
