import {
	COMPONENT_DETAILS_ACTION,
	COMPONENT_DETAILS_SUCCESS,
    COMPONENT_DETAILS_ERROR,
	CREATE_COMPONENT_ACTION,
	CREATE_COMPONENT_SUCCESS,
	CREATE_COMPONENT_ERROR,
	UPDATE_COMPONENT_ACTION,
	UPDATE_COMPONENT_SUCCESS,
	UPDATE_COMPONENT_FAILURE,
	DELETE_COMPONENT_ACTION,
	DELETE_COMPONENT_SUCCESS,
	DELETE_COMPONENT_FAILURE,
} from '../actions';

const INIT_STATE = {
	componentDetail: {},
	componentDetailStatus:null,
	componentDetailLoading:false
};

const  componentDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case COMPONENT_DETAILS_ACTION: //GET TASK DETAILS
			return { ...state, loading: true };
		case COMPONENT_DETAILS_SUCCESS:
            return { 
				...state,
                 componentDetailLoading: false,  
                 componentDetail: action.response,
				 componentDetailStatus:200
			};
		case COMPONENT_DETAILS_ERROR:
            return { 
				...state, 
				componentDetailLoading: false ,
				componentDetailStatus:action.response
			};	
            	
		case CREATE_COMPONENT_ACTION: //CREATE TASK
			return { 
				...state, 
				componentDetailLoading: true 
			};
		case CREATE_COMPONENT_SUCCESS:
			return { 
				...state, 
				componentDetailLoading: false, 
				componentDetail: action.response,
				componentDetailStatus:200 
			};
		case CREATE_COMPONENT_ERROR:
			return { 
				...state, componentDetailLoading: false,
				componentDetail:{},
				componentDetailStatus:action.response 
			};	
		case UPDATE_COMPONENT_ACTION: //UPDATE TASK
			return { 
				...state, 
				componentDetailLoading: true 
			};
		case UPDATE_COMPONENT_SUCCESS: 
			return { 
				...state, 
				componentDetailLoading: false, 
				componentDetail: action.response 
			};
		case UPDATE_COMPONENT_FAILURE:
			return { 
				...state, 
				componentDetailLoading: false,
				status:action.response 
			}; 
		case DELETE_COMPONENT_ACTION: //DELETE TASK
			return { 
				...state, 
				componentDetailLoading: true 
			};
		case DELETE_COMPONENT_SUCCESS:
			return { 
				...state, 
				componentDetailLoading: false,  
				componentDetailStatus:200
			};
		case DELETE_COMPONENT_FAILURE:
			return { 
				...state, 
				componentDetailLoading: false,
				componentDetailStatus:action.response 
			};
		default: return state;
	}
}
export default componentDetails