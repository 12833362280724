import {
	DELETE_PRODUCT_STOCK_ACTION,
	DELETE_PRODUCT_STOCK_SUCCESS,
	DELETE_PRODUCT_STOCK_FAILURE,
	CREATE_PRODUCT_STOCK_ACTION,
	CREATE_PRODUCT_STOCK_SUCCESS,
	CREATE_PRODUCT_STOCK_ERROR,
	UPDATE_PRODUCT_STOCK_ACTION,
	UPDATE_PRODUCT_STOCK_SUCCESS,
	UPDATE_PRODUCT_STOCK_ERROR,
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:false
};

const productstockDetails= (state = INIT_STATE, action) => {
	switch (action.type) {
		case DELETE_PRODUCT_STOCK_ACTION: //DELETE STOCK
			return { ...state, loading: true };
		case DELETE_PRODUCT_STOCK_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_PRODUCT_STOCK_FAILURE:
			return { ...state, loading: false };
		case CREATE_PRODUCT_STOCK_ACTION: //CREATE STOCK
			return { ...state, loading: true };
		case CREATE_PRODUCT_STOCK_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_PRODUCT_STOCK_ERROR:
			return { ...state, loading: false, data: action.response  };
		case UPDATE_PRODUCT_STOCK_ACTION: //UPDATE STOCK
			return { ...state, loading: true };
		case UPDATE_PRODUCT_STOCK_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_PRODUCT_STOCK_ERROR:
			return { ...state, loading: false,status:action.response }; 	
		default: return state;
	}
}
export default productstockDetails
