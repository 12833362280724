import React from 'react';
import { Col,Row,Button, Spin, Form, Input,Select,InputNumber,Upload,Popconfirm,Tooltip,Badge } from 'antd';
import ImgCrop from 'antd-img-crop';
import {currencyFormatter} from '../../../helpers/currencyFormat'
import { FormWrapper,DetailMenuNav } from '../../Style/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faUpload} from '@fortawesome/free-solid-svg-icons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Link } from 'react-router-dom';
import blank from '../../../static/img/resyok.jpeg';
import Heading from '../../../components/heading/heading';
const MaterialGenerals =({material,state,materials,materialDetailLoading,messages,updateData,CurrencyList,UnitList,EURSatis,USDSatis,beforeUpload,deleteMaterials,onSaveMaterial})=> {
  const TLPrice = Number(material.material_doviz==="₺" ? Number(material.material_price).toFixed(2) : material.material_doviz==="€" ? Number(material.material_price*EURSatis).toFixed(2) : Number(USDSatis*material.material_price).toFixed(2));
  const [form3] = Form.useForm();
  const values = Form.useWatch([], form3);
  const [submittable, setSubmittable] = React.useState(false);

  React.useEffect(() => {
    form3.setFieldsValue(material)
   },[material]);

   React.useEffect(() => {
     form3.validateFields({ validateOnly: true }).then(
       () => {
         setSubmittable(true);
       },
       () => {
         setSubmittable(false);
       },
     );
   }, [values]);
   let materialCategories=[];
   let materialGroup=[];
   [...new Map(materials && materials.map(x=> [x.material_category,x])).values()].forEach(row => {
    materialCategories.push({
        value: row.material_category,
        label: row.material_category
      });
    });

  [...new Map(materials && materials.map(x=> [x.material_group,x])).values()].forEach(row => {
    if(row.material_category===material.material_category){
        materialGroup.push({
        value: row.material_group,
        label: row.material_group
        });
    }
  });

  return (
      <>
        <Row gutter={25}>
          <Col xxl={6} xl={7} lg={8} xs={24}>
            <Cards headless bodyStyle={{ minHeight: '355px'}}>
              <DetailMenuNav>
                <div className="author-info">
                  <figure>
                    <img src={material.material_img ? material.material_img :blank} alt="" height="135px"/>
                  </figure>
                  <ImgCrop rotationSlider>
                    <Upload
                      showUploadList={false}
                      beforeUpload={(file)=>beforeUpload(file)}
                      shape='circle'
                    >
                        <Button shape='circle'>
                          <FontAwesomeIcon icon={faUpload} />
                        </Button>
                    </Upload>
                  </ImgCrop>
                  <Button shape='circle' onClick={() => updateData("material_img",null)} style={{marginLeft:"10px",marginBottom:"5px"}}>
                    <FontAwesomeIcon icon={faTrash}/>
                  </Button>
                  <figcaption>
                      <Heading as="h6">{material.material_category}</Heading>
                      <p>{material.material_group}</p>
                  </figcaption>
                </div>
                <div className="nav-labels">
                  <p><Badge color={0 <= material.stockSum ? "green":"red"}  status="processing" text={messages['genel.stocksum'] +" "+currencyFormatter(material.stockSum)+" "+messages["genel."+material.material_unit]}/></p>
                </div>
              </DetailMenuNav>
            </Cards>
          </Col>
          <Col xxl={18} xl={17} lg={16} xs={24}>
            <Cards headless bodyStyle={{ minHeight: '355px'}}>
              <FormWrapper>
                <Spin spinning={materialDetailLoading} delay={500}>
                  <Form form={form3} initialValues={material} onFinish={()=>onSaveMaterial()} >
                    <Row align="middle" gutter={15}>
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["genel.sira"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="material_no"  >
                          <Input type='number' onChange={(eData) => updateData('material_no',eData.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={15}>
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["material.material"]}</label>
                      </Col>
                      <Col md={18} xs={24}>
                        <Form.Item name="material_name" rules={[{ required: true, message: 'Please input Material Name!' }]}>
                          <Input onChange={(eData) => updateData('material_name',eData.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={15}>
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["material.category"]}</label>
                      </Col>
                      <Col md={9} xs={24}>
                        <Form.Item name='material_category' rules={[{ required: true, message: 'Please input Material Category!' }]}>
                          <Select options={materialCategories} onSelect={(eData) => updateData('material_category',eData)}/>
                        </Form.Item>
                      </Col>
                      <Col md={9} xs={24}>
                        <Form.Item name='material_category' rules={[{ required: true, message: 'Please input Material Category!' }]}>
                          <Input  onChange={(eData) => updateData('material_category',eData.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={15}>
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["material.group"]}</label>
                      </Col>
                      <Col md={9} xs={24}>
                        <Form.Item name='material_group' rules={[{ required: true, message: 'Please input Material Group!' }]}>
                          <Select options={materialGroup} onSelect={(eData) => updateData('material_group',eData)}/>
                        </Form.Item>
                      </Col>
                      <Col md={9} xs={24}>
                        <Form.Item name='material_group' rules={[{ required: true, message: 'Please input Material Group!' }]}>
                          <Input  onChange={(eData) => updateData('material_group',eData.target.value)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={15}>
                      <Col md={6} xs={24}>
                        <label htmlFor="input-text">{messages["genel.price"]}</label>
                      </Col>
                      <Col md={4} xs={24}>
                        <Form.Item name='material_price'>
                          <Input type='number' decimalseparator=',' onChange={(eData) => updateData('material_price',eData.target.value)}/>
                        </Form.Item>
                      </Col>
                      <Col md={4} xs={24}>
                        <Form.Item name='material_doviz'>
                          <Select  options={CurrencyList} onSelect={(eData) => updateData('material_doviz',eData)}/>
                        </Form.Item>
                      </Col>
                      <Col md={5} xs={24}>
                        <Form.Item>
                          <Input readOnly addonAfter={" ₺"} value={currencyFormatter(TLPrice)}/>
                        </Form.Item>
                      </Col>
                      <Col md={5} xs={24}>
                        <Form.Item name="material_unit">
                          <Select style={{width:"100%"}} onSelect={(eData) => updateData('material_unit',eData)}>
                            {UnitList.map((unit,i)=>{
                            return(
                              <Select.Option key={i} value={unit.value}>{messages[unit.label]}</Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Spin>
              </FormWrapper>
            </Cards>
          </Col>
        </Row>
      </>
  );
}

export  {MaterialGenerals};
