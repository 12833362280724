import React from 'react';
import { Row, Col } from 'antd';
import {  useSelector } from 'react-redux';
import { SupportTopWrap } from '../../Style/style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../../Style/styled';
import {useIntl} from "react-intl";

const  About = () => {

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const lang = locale === 'tr' ? 0 :1;
  const { messages } = useIntl();
  const company = useSelector(state => state.myCompany.data);
  return (
    <>
      <PageHeader
        title={messages["sidebar.about"]}
      />
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <SupportTopWrap>
              <div className="sDash-support-container">
                <Row align="middle">
                  <Col lg={16} sm={14} xs={24}>
                    <div className="sDash_support-content">
                      <h2 className="sDash_support-content__title">Hello, We are here to help</h2>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                    </div>
                  </Col>

                  <Col lg={8} sm={10} xs={24}>
                    <div className="sDash_support-img">
                      <img src={company.sirket_rapor_logo} alt="" />
                    </div>
                  </Col>
                </Row>
              </div>
            </SupportTopWrap>
            <Cards headless>
              <div
                dangerouslySetInnerHTML={{__html: company.about[lang]}}
              />
            </Cards>

          </Col>
        </Row>
      </Main>
    </>
  );
}

export  {About};
