import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import moment from "moment";


const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        height: '12px',
        borderBottom:0.3,
        marginTop:3,
        borderBottomColor:"grey",
        width:550,
        
    }
  });
  const Text60px8px = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: left;
  width:60px;
  `;
  const Text30px8px = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: center;
  width:30px;
  `;
  const Text60px8pxR = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: right;
  width:60px;
  `;
  const Text200px8px = styled.Text`
  paddingLeft:2px;
  font-size: 8px;
  text-align: left;
  border-left: 0.3;
  width:200px;
  `;
const DeliveryRow = ({row}) => {
    //console.log(row)
    return(
        <View style={styles.rowContainer}>
            <Text60px8px>{moment(row.date).format('DD.MM.YYYY')}</Text60px8px>
            <Text200px8px>{row && row.productname && row.productname}</Text200px8px>
            <Text30px8px>{row && row.qty && row.qty +" "+row.unit}</Text30px8px>
            <Text60px8pxR>{row && row.price && (row.price).toFixed(2)+" "+row.doviz}</Text60px8pxR>
            <Text60px8pxR>{row && row.sumPrice && (row.sumPrice).toFixed(2)+" "+row.doviz}</Text60px8pxR>
            <Text60px8pxR>{row && row.sumPriceTl && (row.sumPriceTl).toFixed(2) +" ₺"}</Text60px8pxR>
            <Text60px8pxR>{row && row.runTotal && (row.runTotal).toFixed(2)}</Text60px8pxR>
      

        </View>
  
)};
  
export default DeliveryRow