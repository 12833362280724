import React from 'react';
import {  useSelector } from 'react-redux';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGear,faIndent,faUsers,faCircleDot,faLayerGroup,faLightbulb,faMicrochip,faListCheck, faList, faShop, faShoppingBag, faUserGroup, faChartColumn } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import {useIntl} from "react-intl";


const { SubMenu } = Menu;

function MenuItems({ darkMode, toggleCollapsed, topMenu, events }) {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const {user,user_form_auth,role} = useSelector((state) => state.auth);
 
  //const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  //******************************************************************************************************************************************************************/
  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };
  //******************************************************************************************************************************************************************/
  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  //******************************************************************************************************************************************************************/
  const checkAuth = (form) => {
    let result = false;
    const filterResult = user_form_auth && user_form_auth.length ? user_form_auth.filter(x=>x.form===form):null;
    if(filterResult && filterResult.length && role> 0){
      result = filterResult[0].auth < 3 ? false : true ;
    }else{
      result = role === 0 ? false:true;
    }
    return result;
  }
  //******************************************************************************************************************************************************************/
  const { messages } = useIntl();
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FontAwesomeIcon icon={faIndent} />}
      openKeys={openKeys}
    >

    {/* FİRMA SİSTEM AYARLARI */}
      <SubMenu key="S0" icon={!topMenu &&<FontAwesomeIcon icon={faGear} />} title={messages["sidebar.company"]}>
        {!checkAuth("myCompany") && 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/company`}>
                <FontAwesomeIcon icon={faGear} />
              </NavLink>
            )
          }
          key="myCompany"
        >
          <NavLink to={`${path}/company`}>
            {messages["sidebar.company"]}
          </NavLink>
        </Menu.Item>}
      </SubMenu>

    {/* MÜŞŞTERİLER TEDARİKÇİLER */}
      <SubMenu key="0" icon={!topMenu &&<FontAwesomeIcon icon={faList} />} title={messages["sidebar.companies"]}>
        {!checkAuth("companies") && 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/companies`}>
                <FontAwesomeIcon icon={faList} />
              </NavLink>
            )
          }
          key="companies"
        >
          <NavLink to={`${path}/companies`}>
            {messages["sidebar.companies"]}
          </NavLink>
        </Menu.Item>}
      </SubMenu>

    {/* KULLANICILAR ÇALIŞANLAR  */}
      <SubMenu key="1" icon={!topMenu &&<FontAwesomeIcon icon={faUsers} />} title={messages["sidebar.users"]}>
        {!checkAuth("users") &&
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/users`}>
                <FontAwesomeIcon icon={faUsers} />
              </NavLink>
            )
          }
          key="users"
        >
          <NavLink to={`${path}/users`}>
            {messages["sidebar.users"]}
          </NavLink>
        </Menu.Item>}
        {!checkAuth("employee") && 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/employee`}>
                <FontAwesomeIcon icon={faUserGroup} />
              </NavLink>
            )
          }
          key="employee"
        >
          <NavLink to={`${path}/employee`}>
            {messages["sidebar.employee"]}
          </NavLink>
        </Menu.Item>}
      </SubMenu>

    {/* TEKLİFLER  */}
      <SubMenu key="6" icon={!topMenu &&<FontAwesomeIcon icon={faListCheck} />} title={messages["sidebar.offers"]}>
        {!checkAuth("offers") && 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/offers`}>
                <FontAwesomeIcon icon={faListCheck} />
              </NavLink>
            )
          }
          key="offers"
        >
          <NavLink  to={`${path}/offers`}>
            {messages["sidebar.offers"]}
          </NavLink>
        </Menu.Item>}
      </SubMenu>

    {/* PROJELER */}
      <SubMenu key="2" icon={!topMenu &&<FontAwesomeIcon icon={faCircleDot} />} title={messages["sidebar.projects"]}>
        {!checkAuth("orderitemlist") && 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/project`}>
                <FontAwesomeIcon icon={faCircleDot} />
              </NavLink>
            )
          }
          key="orderitemlist"
        >
          <NavLink to={`${path}/project`}>
            {messages["sidebar.projects"]}
          </NavLink>
        </Menu.Item>}
        {!checkAuth("componentlist") &&
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/projectmateriallist`}>
                <FontAwesomeIcon icon={faList} />
              </NavLink>
            )
          }
          key="componentlist"
        >
          <NavLink to={`${path}/projectmateriallist`}>
            {messages["sidebar.projectMaterialList"]}
          </NavLink>
        </Menu.Item>}
        {!checkAuth("componentlist") &&
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/projectmateriallistsummary`}>
                <FontAwesomeIcon icon={faChartColumn} />
              </NavLink>
            )
          }
          key="componentlistsum"
        >
          <NavLink to={`${path}/projectmateriallistsummary`}>
            {messages["sidebar.projectmateriallistsummary"]}
          </NavLink>
        </Menu.Item>}
      </SubMenu>

    {/* ÜRÜNLER  MODELLER MALZEMELER */}
      <SubMenu key="3" icon={!topMenu &&<FontAwesomeIcon icon={faLayerGroup} />} title={messages["sidebar.products"]}>
        {!checkAuth("productlist") &&
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/products`}>
                <FontAwesomeIcon icon={faLightbulb} />
              </NavLink>
            )
          }
          key="productlist"
        >
          <NavLink to={`${path}/products`}>
            {messages["sidebar.products"]}
          </NavLink>
        </Menu.Item>}
        {!checkAuth("modellist") &&
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/models`}>
                <FontAwesomeIcon icon={faLayerGroup} />
              </NavLink>
            )
          }
          key="modellist"
        >
          <NavLink to={`${path}/models`}>
            {messages["sidebar.models"]}
          </NavLink>
        </Menu.Item>}
        {!checkAuth("materiallist") &&
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/materials`}>
                <FontAwesomeIcon icon={faMicrochip} />
              </NavLink>
            )
          }
          key="materiallist"
        >
          <NavLink to={`${path}/materials`}>
            {messages["sidebar.materials"]}
          </NavLink>
        </Menu.Item>}
      </SubMenu>

    {/* SATIN ALMALAR   */}
      <SubMenu key="9" icon={!topMenu &&<FontAwesomeIcon icon={faShop} />} title={messages["sidebar.buyings"]}>
        {!checkAuth("buyings") && 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/buyings`}>
                <FontAwesomeIcon icon={faShoppingBag} />
              </NavLink>
            )
          }
          key="buyings"
        >
          <NavLink   to={`${path}/buyings`}>
            {messages["sidebar.buyings"]}
          </NavLink>
        </Menu.Item>}
      </SubMenu>
    
    {/* LAST CHECK SON KONTROL  */}
    <SubMenu key="8" icon={!topMenu &&<FontAwesomeIcon icon={faShop} />} title={messages["sidebar.lastchecklist"]}>
      {!checkAuth("lastcheck") && 
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/lastchecklist`}>
              <FontAwesomeIcon icon={faShoppingBag} />
            </NavLink>
          )
        }
        key="lastcheck"
      >
        <NavLink   to={`${path}/lastchecklist`}>
          {messages["sidebar.lastchecklist"]}
        </NavLink>
      </Menu.Item>}
    </SubMenu>





    </Menu>
  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
