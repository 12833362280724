import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
    ScoreContainer:{
        marginTop: 1,
        flexDirection: "column",
        width:400,
        height:50,
        border:1,
        borderRadius:2,
        alignItems:'center'
    },
    ScoreRow:{
        marginTop: 3,
        flexDirection: "row",
        justifyContent:'center',
        alignItems:'center'
    },
    ScoreRowBox:{
        marginRight:15,
        marginLeft:15,
        flexDirection: "row",
        width:100,
        height:25,
        border:1,
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
    ScoreRowBoxCol:{
        flexDirection: "column",
        width:60,
        height:25,
        borderRight:1,
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center'
    },
});

const Title = styled.Text`
    margin-left: 5px;
    font-size: 8px;
    text-align: left;
`;
const Box = styled.Text`
    margin: 0px;
    font-size: 8px;
    text-align: center;
    width:60px;
`;
const ScoreBox = styled.Text`
    margin: 0px;
    font-size: 8px;
    text-align: center;
    width:40px;
`;
const BoxInfo = styled.Text`
    margin: 0px;
    font-size: 6px;
    text-align: center;
    width:60px;
`;
  const Score = ({data}) => {
    return(
    <View style={styles.ScoreContainer}>
        <Title>GELEN GİRDİLERİN PERFORMANS DEĞERLENDİRMESİ</Title>
        <View style={styles.ScoreRow}>
            <View style={styles.ScoreRowBox}>
                <View style={styles.ScoreRowBoxCol}>
                    <Box>KALİTE</Box>
                    <BoxInfo>1-5 Arası Puan</BoxInfo>
                </View>
                <ScoreBox>{data.qc_scoring[0]}</ScoreBox>
            </View>
            <View style={styles.ScoreRowBox}>
                <View style={styles.ScoreRowBoxCol}>
                    <Box>TERMİN</Box>
                    <BoxInfo>1-5 Arası Puan</BoxInfo>
                </View>
                <ScoreBox>{data.qc_scoring[1]}</ScoreBox>
            </View>
            <View style={styles.ScoreRowBox}>
                <View style={styles.ScoreRowBoxCol}>
                    <Box>MİKTAR</Box>
                    <BoxInfo>1-5 Arası Puan</BoxInfo>
                </View>
                <ScoreBox>{data.qc_scoring[2]}</ScoreBox>
            </View>
            
        </View>

    </View>
    )
    };
  
  export default Score