import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
const styles = StyleSheet.create({
    FootherContainer:{
        marginTop: 5,
        flexDirection: "row",
        width:400,
        height:68,
        alignItems:'center',
        
    },
    FootherRowBox:{
        flexDirection: "column",
        width:200,
        height:68,
        border:1,
        borderRadius:2,
        textAlign:'center',
        alignItems:'center'
    }

});

const Title = styled.Text`
    font-size: 8px;
    text-align: left;
`;
const Info = styled.Text`
    font-size: 6px;
    text-align: left;
`;

  const Foother = ({data}) => {
    return(
    <View style={styles.FootherContainer}>
   
        <View style={styles.FootherRowBox}>
            <Title>KONTROL EDEN</Title>
            <Info>Kalite Kontrol Personeli</Info>
            <Info>{data.staff && data.staff}</Info>
        </View>
        <View style={styles.FootherRowBox}>
            <Title>ONAY</Title>
            <Info>Muhasebe Sorumlusu</Info>
        </View>
    </View>
    )
    };
  
  export default Foother;