import React, { Fragment } from 'react';
import {  useSelector } from 'react-redux';
import {useIntl} from "react-intl";
import {PDFViewer} from '@react-pdf/renderer';
import Label10x10 from "./MaterialLabel/label10x10";

const MaterialLabel = () => {

    const {locale } = useSelector((state) => state.LanguageSwitcher.language);
    const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);
    const { materialDetail, materialDetailLoading,materialDetailStatus } = useSelector(state => state.materialDetail);
    const { messages } = useIntl();
    const Lang = locale === 'tr' ? 0 :1;
    

    return !materialDetailLoading &&  !myCompanyLoading ?  
    (
      <Fragment>
      { myCompany && materialDetail ?                
        <PDFViewer width="100%" height={600}>
            {
              <Label10x10  company={myCompany} data={materialDetail} messages={messages}/>
            }
        </PDFViewer>:
              <div className="spin">
                <Spin />
              </div>
      }       
      </Fragment>
    ):null

}


export  {MaterialLabel};


